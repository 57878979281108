import React from 'react';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';
import DeskTopAwards from './DeskTopAwards';
import MobileAwards from './MobileAwards';

const HomeAwards = () => {
  const { outerWidth } = ScreenWidthListener();

  return (
    <div>
      {outerWidth > 850 && <DeskTopAwards />}
      {outerWidth <= 850 && <MobileAwards />}
    </div>
  );
};

export default HomeAwards;
