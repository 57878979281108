import React, { useEffect, useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TokenContext } from '../../models/Customer';
import img from '../../media/images/MobileAppimg.jpg';
import Applestore from '../../media/images/Appstore.png';
import Googleplay from '../../media/images/Googleplay.png';
import useStaticInfo from '../../models/StaticInfo';

const TitleContainer = styled.div`
  background: #fd980f;
  justify-content: center;
  display: flex;
  align-items: center;

  @media (max-width: 670px) {
    padding: 10px 20px 10px 30px;
  }
`;

const TitleWidth = styled.div`
  width: 1000px;
`;

const Title = styled.span`
  color: #fff;
  font-size: 2rem;
  line-height: 80px;

  @media (max-width: 670px) {
    line-height: 40px;
  }
`;

const MainContainer = styled.div`
  background: #dedede;
  display: flex;
  justify-content: center;
`;

const MainWidth = styled.div`
  background: #fff;
  width: 1024px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  box-shadow: 0 0 2px #bbb;
`;

const PromoImgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 21px;
`;

const PromoImg = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
`;

const Text = styled.span`
  color: ${(props) => (props.bold ? '#000' : '#666')};
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

const ListText = styled.span`
  display: list-item;
  list-style-type: decimal;
  margin-left: 19px;
  font-size: 14px;
  color: #666;
`;

const AppImgDiv = styled.div`
  display: flex;
  margin-top: 21px;
  margin-bottom: 42px;
`;

const AppImgA = styled.a``;

const AppImg = styled.img`
  max-width: 170px;
  height: 56px;
`;

const SubText = styled.span`
  color: #666;
  font-weight: bold;
  font-size: 14px;
`;

const Margin = styled.div`
  padding-bottom: ${(props) => props.margin};
`;

const MobileApp = () => {
  // const { t, i18n } = useTranslation();

  const { getStaticValue, StaticInfoContext, language } = useStaticInfo(
    'Pages-Wilson Parking Mobile App'
  );

  const staticInfo = useContext(StaticInfoContext);

  const { name, content } = useMemo(
    () => getStaticValue(['name', 'content'], staticInfo),
    [staticInfo, language]
  );

  return (
    <div className="MobileApp">
      <TitleContainer>
        <TitleWidth>
          {/* <Title>{t('MobileApp.Title')}</Title> */}
          <Title>{name}</Title>
        </TitleWidth>
      </TitleContainer>

      <MainContainer>
        <MainWidth className="sun-editor-editable" dangerouslySetInnerHTML={{ __html: content }} />
        {/* <MainWidth> */}
        {/*  <PromoImgDiv> */}
        {/*    <PromoImg src={img} /> */}
        {/*  </PromoImgDiv> */}
        {/*  <Text> */}
        {/*    {t('MobileApp.text1')} */}
        {/*    <Text bold> */}
        {/*      &nbsp; */}
        {/*      {t('MobileApp.text11')} */}
        {/*      &nbsp; */}
        {/*    </Text> */}
        {/*    {t('MobileApp.text12')} */}
        {/*    &nbsp; */}
        {/*  </Text> */}
        {/*  <AppImgDiv> */}
        {/*    <AppImgA href="https://apps.apple.com/hk/app/wilson-parking-hk/id1234893685?l=zh"> */}
        {/*      <AppImg src={Applestore} /> */}
        {/*    </AppImgA> */}
        {/*    <AppImgA href="https://play.google.com/store/apps/details?id=com.wilsonparking"> */}
        {/*      <AppImg src={Googleplay} /> */}
        {/*    </AppImgA> */}
        {/*  </AppImgDiv> */}
        {/*  <Text> */}
        {/*    {t('MobileApp.text2')} */}
        {/*    &nbsp; */}
        {/*    <Text bold>{t('MobileApp.text22')}</Text> */}
        {/*    {t('MobileApp.text23')} */}
        {/*    <Text bold>{t('MobileApp.text24')}</Text> */}
        {/*    {t('MobileApp.text25')} */}
        {/*    <Text bold> */}
        {/*      &nbsp; */}
        {/*      {t('MobileApp.text26')} */}
        {/*    </Text> */}
        {/*    {t('MobileApp.text27')} */}
        {/*  </Text> */}
        {/*  <Margin margin="42px" /> */}
        {/*  <SubText>{t('MobileApp.subtext')}</SubText> */}
        {/*  <ListText> */}
        {/*    <Text bold>{t('MobileApp.text3')}</Text> */}
        {/*    &nbsp; */}
        {/*    {t('MobileApp.text33')} */}
        {/*  </ListText> */}
        {/*  <ListText> */}
        {/*    <Text bold>{t('MobileApp.text4')}</Text> */}
        {/*    &nbsp; */}
        {/*    {t('MobileApp.text44')} */}
        {/*  </ListText> */}
        {/*  <ListText> */}
        {/*    <Text bold> */}
        {/*      {t('MobileApp.text5')} */}
        {/*      &nbsp; */}
        {/*    </Text> */}
        {/*    {t('MobileApp.text55')} */}
        {/*  </ListText> */}
        {/*  <ListText> */}
        {/*    <Text bold>{t('MobileApp.text6')}</Text> */}
        {/*    &nbsp; */}
        {/*    {t('MobileApp.text66')} */}
        {/*  </ListText> */}
        {/*  <ListText> */}
        {/*    <Text bold>{t('MobileApp.text7')}</Text> */}
        {/*    &nbsp; */}
        {/*    {t('MobileApp.text77')} */}
        {/*  </ListText> */}
        {/*  <Margin margin="62px" /> */}
        {/* </MainWidth> */}
      </MainContainer>
    </div>
  );
};

export default withRouter(MobileApp);
