import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as icon from '../../../media/icons';

const ItemDetailData = styled.span`
  font-size: 0.9rem;

  @media (max-width: 670px) {
    width: 50%;
  }
`;

const Container = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 850px) {
    flex-direction: column;
    border-bottom: 1px solid #bbb;
    padding: 20px 20px 0px 20px;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: rgb(204, 204, 204);

  @media (max-width: 850px) {
    height: 1px;
    width: 100%;
  }
`;

const CostView = styled.div`
  display: flex;
  flex-basis: 20%;
  justify-content: center;
  align-items: center;
  margin-left: -30px;

  @media (max-width: 850px) {
    margin-left: 0px;
    flex-basis: 100%;
  }
`;

const StatusRow = styled.td`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LeftTabel = styled.table`
  width: 50%;
  font-size: 0.8rem;
  border-spacing: 10px;
  border-collapse: separate;

  @media (max-width: 670px) {
    width: 100%;

    th {
      width: 50%;
    }
  }
`;

const RightTabel = styled.table`
  width: 45%;
  font-size: 0.8rem;
  border-spacing: 10px;
  border-collapse: separate;

  @media (max-width: 670px) {
    width: 100%;

    th {
      width: 50%;
    }
  }
`;

const DetailContainer = styled.div`
  display: flex;
  margin-top: 15px;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const StatusDetail = styled.div`
  border: 1px solid #bbb;
  padding-right: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
`;

const PaymentRecordRow = ({ index, details, onlinePaymentIds, offlinePaymentIds }) => {
  // console.log(index);
  const { t } = useTranslation();
  const backgroundColor = index % 2 === 0 ? '#fbf1e7' : '#fff';
  const { visaId, ppsId, fpsId } = onlinePaymentIds || {};
  const { chequeId, sevenElevenId } = offlinePaymentIds || {};

  const { status } = details;

  const recordType = () => {
    switch (details.type) {
      case 'temporary':
        return (
          <>
            <img
              style={{ marginRight: 10 }}
              src={icon.dollarSign}
              width="40"
              alt="temporary_change_registraion_mark"
            />
            <b>{t('PaymentRecords.tempChange')}</b>
          </>
        );
      case 'permanent':
        return (
          <>
            <img
              style={{ marginRight: 10 }}
              src={icon.dollarSign}
              width="40"
              alt="temporary_change_registraion_mark"
            />
            <b>{t('PaymentRecords.permChange')}</b>
          </>
        );
      case 'Renew':
        return (
          <>
            <img
              style={{ marginRight: 10 }}
              src={icon.dollarSign}
              width="40"
              alt="temporary_Renew"
            />
            <b>{t('PaymentRecords.renew')}</b>
          </>
        );
      case 'changeCar':
        return (
          <>
            <img
              style={{ marginRight: 10 }}
              src={icon.dollarSign}
              width="40"
              alt="temporary_Renew"
            />
            <b>{t('PaymentRecords.changeCar')}</b>
          </>
        );
      case 'new':
        return (
          <>
            <img style={{ marginRight: 10 }} src={icon.iMonthly} width="50" alt="iMonthly" />
            <b style={{ fontSize: '1.1rem' }}>{t('PaymentRecords.iMonthly')}</b>
          </>
        );

      default:
        return null;
    }
  };

  function handelStatus() {
    if (status === 'pending' || status === 'overpaid') {
      return icon.warning;
    }
    if (
      status === 'paid' ||
      status === 'renewed' ||
      status === 'settled' ||
      status === ' changed'
    ) {
      return icon.check;
    }
    if (status === 'expired' || status === 'terminated') {
      return icon.cross;
    }
    return null;
  }

  return (
    <Container style={{ backgroundColor }}>
      <div style={{ flexBasis: '70%' }}>
        {recordType()}

        <DetailContainer>
          <LeftTabel>
            <tbody>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('PaymentRecords.status')}</th>
                <StatusRow>
                  <StatusDetail>
                    <img
                      style={{ marginRight: 10, width: 15, height: 15, border: 'none' }}
                      src={handelStatus()}
                      alt=""
                    />
                    <ItemDetailData>
                      <b>{status ? t(`MyAccount.iMonthly.status.${status}`) : '-'}</b>
                    </ItemDetailData>
                  </StatusDetail>
                </StatusRow>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('PaymentRecords.vehicleRegistrationMark')}</th>
                <td>
                  <ItemDetailData>{details.registrationMark}</ItemDetailData>
                </td>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('PaymentRecords.carPark')}</th>
                <td>
                  <ItemDetailData style={{ width: '50%' }}>{details.carPark}</ItemDetailData>
                </td>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('PaymentRecords.paymentRefNo')}</th>
                <td>
                  <ItemDetailData>{details.paymentRefNo}</ItemDetailData>
                </td>
              </tr>
            </tbody>
          </LeftTabel>

          <RightTabel>
            <tbody>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('PaymentRecords.paymentDate')}</th>
                <td>
                  <ItemDetailData>{details.paymentDate}</ItemDetailData>
                </td>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('PaymentRecords.paymentMethod')}</th>
                <td>
                  <ItemDetailData>
                    {(details.PaymentMethodId === visaId && t('IParkVerifySteps.creditCard')) ||
                      (details.PaymentMethodId === ppsId && t('IParkVerifySteps.PPS')) ||
                      (details.PaymentMethodId === fpsId && t('IParkVerifySteps.FPS')) ||
                      (details.PaymentMethodId === chequeId && t('PaymentRecords.cheque')) ||
                      (details.PaymentMethodId === sevenElevenId && t('PaymentRecords.7-11')) ||
                      '-'}
                  </ItemDetailData>
                </td>
              </tr>
            </tbody>
          </RightTabel>
        </DetailContainer>
      </div>

      <Divider />

      <CostView>
        <b style={{ fontSize: '1rem' }}>HK$</b>
        <b style={{ fontSize: '2.3rem', marginTop: -5 }}>{details.cost}</b>
      </CostView>
    </Container>
  );
};

export default PaymentRecordRow;
