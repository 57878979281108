import styled from 'styled-components';

const SubTitle = styled.p`
  color: #ffb24a;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  margin-top: 10px;
`;

export default SubTitle;
