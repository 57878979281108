import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';
import '../Carousel.css';
import NewsPromoItem from './NewsPromoItem';
import useNews from '../../../models/News';
import imgback from '../../../media/images/news_bg.png';
import useGA from '../../../hooks/useGA';

const whitearrow = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-arrow_xl-white.png`;

const Container = styled.div`
  background-image: url(${imgback});
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
`;

const TitleA = styled.a`
  color: #fff;
  font-size: 30px;

  &:hover {
    text-decoration-line: none;
    color: #fff;
  }
`;

const TitleArrow = styled.img`
  color: #fff;
  height: 20px;
  margin-left: 10px;
`;

const MobileNews = () => {
  const { t, i18n } = useTranslation();
  const { news } = useNews();
  const [MobileNewsIndex, setMobileNewsIndex] = React.useState(0);
  const { handleEventTracking } = useGA();

  const MobileNewshandleSelect = (selectedMobileNewsIndex) => {
    setMobileNewsIndex(selectedMobileNewsIndex);
  };

  return (
    <Container>
      <TitleContainer>
        <TitleA href={`/${i18n.language}/news/index`}>
          {t('News.NEWS')}
          <TitleArrow src={whitearrow} />
        </TitleA>
      </TitleContainer>

      <Carousel
        activeIndex={MobileNewsIndex}
        onSelect={MobileNewshandleSelect}
        interval={99999999}
        id="MobileNewsCarousel"
      >
        {news.map((item, index) => (
          <Carousel.Item>
            <NewsPromoItem
              detail={item}
              t={t}
              Mobile
              i18n={i18n}
              index={index}
              handleEventTracking={handleEventTracking}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default MobileNews;
