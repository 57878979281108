import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import gradient from "../../media/images/gradient.png";

const ItemContainer = styled.button`
  width: ${({ count }) => `${100 / count}%`};
  height: 64px;
  background: #fff;
  color: ${({ clicked }) => (clicked ? '#FD980F' : '#666666')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.125rem;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  border-width: 0;
  border-bottom: ${(props) => (props.clicked ? '5px solid #FD980F' : '5px solid transparent')};
  white-space: pre-line;

  @media (max-width: 550px) {
    font-size: 10px;
  }
`;

function TabContainer({
  activeKey: defaultActiveKey = '',
  tabs,
  link,
  buttonStyle,
  onActiveKeyChange,
}) {
  const [activeKey, setActiveKey] = React.useState(defaultActiveKey);
  const { t } = useTranslation();

  const activeButtonStyle = { ...buttonStyle, borderBottom: '3px solid #FD980F' };
  const nonActiveButtonStyle = { ...buttonStyle, borderBottom: '1px solid #dedede' };

  return (
    <Tab.Container
      id="left-tabs-example"
      activeKey={activeKey}
      mountOnEnter={typeof onActiveKeyChange === 'function'}
      // unmountOnExit={typeof onActiveKeyChange === 'function'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Nav
          variant="pills"
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          {tabs.map((tab) => {
            if (tab.key === activeKey) {
              return (
                <ItemContainer
                  count={tabs.length}
                  clicked
                  key={tab.key}
                  onClick={() => {
                    if (typeof onActiveKeyChange === 'function') {
                      onActiveKeyChange(tab.key);
                    }
                    setActiveKey(tab.key);
                  }}
                  style={buttonStyle && activeButtonStyle}
                >
                  {t(tab.title)}
                </ItemContainer>
              );
            }
            return (
              <ItemContainer
                count={tabs.length}
                key={tab.key}
                href={link}
                onClick={() => {
                  if (typeof onActiveKeyChange === 'function') {
                    onActiveKeyChange(tab.key);
                  }
                  setActiveKey(tab.key);
                }}
                style={buttonStyle && nonActiveButtonStyle}
              >
                {t(tab.title)}
              </ItemContainer>
            );
          })}
        </Nav>
        <div />
        <Tab.Content>
          {tabs.map((tab) => (
            <Tab.Pane eventKey={tab.key} key={tab.key}>
              {tab.component}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </div>
    </Tab.Container>
  );
}

TabContainer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    })
  ),
};

export default withRouter(TabContainer);
