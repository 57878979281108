import React, { useContext, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import TopRow from './TopRow/index';
import MiddleRow from './MiddleRow/index';
import BottomRow from './BottomRow/index';
import useStaticInfo from '../../models/StaticInfo';

const OurCompany = () => {
  const { getStaticValue, language, StaticInfoContext } = useStaticInfo('OurCompany');

  const staticInfo = useContext(StaticInfoContext);

  const data = useMemo(
    () =>
      getStaticValue(
        [
          'description',
          'image1',
          'image2',
          'image3',
          'image1_description',
          'image2_description',
          'image3_description',
          'vision_description',
          'mission_description',
          'values_description',
          'quality_assurance',
        ],
        staticInfo
      ),
    [staticInfo, language]
  );

  return (
    <>
      <TopRow data={data} />
      <MiddleRow data={data} />
      <BottomRow data={data} />
    </>
  );
};

export default withRouter(OurCompany);
