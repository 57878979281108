import React from 'react';

export default function MenuIcon({ icon, cartItems }) {
  return (
    <span className="menu-item-icon">
      <img src={icon} alt={icon} />
      <div>{cartItems}</div>
    </span>
  );
}
