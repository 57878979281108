import styled from 'styled-components';
import React from 'react';

// TODO: responsive?

const Container = styled.div`
  display: flex;
`;

const Note = styled.span`
  font-size: 12px;
  margin-top: 10px;
  color: #666;
`;

function Password({ note }) {
  return (
    <Container>
      <Note>{note}</Note>
    </Container>
  );
}

export default Password;
