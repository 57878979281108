import React from 'react';
import { withRouter } from 'react-router-dom';

function External(props) {
  const { to, children, staticContext, ...rest } = props;
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  );
}

export default withRouter(External);
