import styled from 'styled-components';

const DetailLeftDiv = styled.div`
  position: relative;
  right: ${(props) => (props.token ? 'none' : '33.3%')};
  flex: ${(props) => (props.token ? 'none' : '0 0 66.666667%')};
  max-width: ${(props) => (props.token ? 'none' : '66.666667%')};

  @media (max-width: 850px) {
    flex: 0 0 100%;
    max-width: 100%;
    right: 0;
  }
`;

export default DetailLeftDiv;
