import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  div > p:nth-child(2) {
    margin-top: 10px;
  }

  div:not(:first-child) {
    margin-top: 100px;
  }

  div {
    span + p {
      margin-top: 20px;
    }
  }
`;

const Title = styled.div`
  text-decoration: underline;
  font-weight: bolder;
  color: #333;
  font-size: 12px;
  line-height: 1.5;
`;

const Text = styled.p`
  color: #333;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 35px;
`;

const SubTitle = styled.span`
  color: #333;
  font-size: 12px;
  line-height: 1.5;
  text-decoration: underline;
`;

const WithoutMargin = styled.p`
  color: #333;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
`;

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div>
        <Title>{t('IhourlyCart.TermsTitle1')}</Title>
        <Text>
          1)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT1')}
        </Text>
        <Text>
          2)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT2')}
        </Text>
        <Text>
          3)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT3')}
        </Text>
        <Text>
          4)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT4')}
        </Text>
        <Text>
          5)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT5')}
        </Text>
        <Text>
          6)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT6')}
        </Text>
        <Text>
          7)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT7')}
        </Text>
        <Text>
          8)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT8')}
        </Text>
        <Text>
          9)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT9')}
        </Text>
        <Text>
          10)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT10')}
        </Text>
        <Text>
          11)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT11')}
        </Text>
        <Text>
          12)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT12')}
        </Text>
        <Text>
          13)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT13')}
        </Text>
        <Text>
          14)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT14')}
        </Text>
        <Text>
          15)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT15')}
        </Text>
        <Text>
          16)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iMonthlyT16')}
        </Text>
      </div>
      <div>
        <Title>{t('IhourlyCart.TermsTitle2')}</Title>
        <Text>
          1)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iHourlyT1')}
        </Text>
        <Text>
          2)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iHourlyT2')}
        </Text>
        <Text>
          3)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iHourlyT3')}
        </Text>
        <Text>
          4)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iHourlyT4')}
        </Text>
        <Text>
          5)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.iHourlyT5')}
        </Text>
      </div>
      <div>
        <Title>{t('IhourlyCart.TermsTitle3')}</Title>
        <Text>
          1)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.DELIVERYT1')}
        </Text>
        <Text>
          2)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.DELIVERYT2')}
        </Text>
        <Text>
          3)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.DELIVERYT3')}
        </Text>
      </div>
      <div>
        <Title>{t('IhourlyCart.TermsTitle4')}</Title>
        <Text>
          1)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('IhourlyCart.REFUNDT1')}
        </Text>
      </div>
      <div>
        <Title>{t('PRIVACYPOLICY.Title1')}</Title>
        <Text>
          1)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('PRIVACYPOLICY.T1text1')}
        </Text>
        <Text>
          2)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          {t('PRIVACYPOLICY.T1text2')}
        </Text>
        <Title>{t('PRIVACYPOLICY.Title2')}</Title>
        <Text>{t('PRIVACYPOLICY.T2text1')}</Text>
        <Text>{t('PRIVACYPOLICY.T2text2')}</Text>
        <Text>{t('PRIVACYPOLICY.T2text3')}</Text>
        <Text>{t('PRIVACYPOLICY.T2text4')}</Text>
        <Text>{t('PRIVACYPOLICY.T2text5')}</Text>
        <Text>{t('PRIVACYPOLICY.T2text6')}</Text>
      </div>
      <div>
        <Title>{t('PRIVACYPOLICY.Title3')}</Title>
        <Text>{t('PRIVACYPOLICY.T3text1')}</Text>
        <SubTitle>{t('PRIVACYPOLICY.Title4')}</SubTitle>
        <Text>{t('PRIVACYPOLICY.T4text1')}</Text>
        <Text>{t('PRIVACYPOLICY.T4text2')}</Text>
        <SubTitle>{t('PRIVACYPOLICY.Title5')}</SubTitle>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text1')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text2')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text3')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text4')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text5')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text6')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text7')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text8')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text9')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text10')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text11')}</WithoutMargin>
        <WithoutMargin>{t('PRIVACYPOLICY.T5text12')}</WithoutMargin>
        <Text>{t('PRIVACYPOLICY.T5text13')}</Text>
        <SubTitle>{t('PRIVACYPOLICY.Title6')}</SubTitle>
        <Text>{t('PRIVACYPOLICY.T6text1')}</Text>
        <SubTitle>{t('PRIVACYPOLICY.Title7')}</SubTitle>
        <Text>{t('PRIVACYPOLICY.T7text1')}</Text>
        <SubTitle>{t('PRIVACYPOLICY.Title8')}</SubTitle>
        <Text>{t('PRIVACYPOLICY.T8text1')}</Text>
        <SubTitle>{t('PRIVACYPOLICY.Title9')}</SubTitle>
        <Text>{t('PRIVACYPOLICY.T9text1')}</Text>
        <Text>{t('PRIVACYPOLICY.T9text2')}</Text>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
