import styled from 'styled-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../../media/images/logo_WP.png';
import * as icon from '../../../media/icons';
// import { CustomerContext } from '../../../models/User';
import QuickAccessBlock from './QuickAccessBlock';
import { TokenContext } from '../../../models/Customer';
import MobileLogo from '../../../media/images/logo-wp_mobile.png';
import MobileSlideMenu from './MobileSlideMenu';

const Container = styled.div`
  padding: 0 15px;
  min-height: 100px;
  position: relative;
  background-color: #ffffff;
  z-index: 100;
  @media (max-width: 980px) {
    position: fixed;
    width: 100%;
    border-bottom: 1px solid #bbb;
    padding-left: 0;
    padding-right: 0;
    z-index: 9999;
  }
`;

const InnerWidth = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  clear: both;
  height: auto;
`;

const InnerContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
  }
`;

const LeftBlock = styled.div`
  float: left;
  height: 100px;
  position: relative;
  vertical-align: middle;

  @media (max-width: 980px) {
    z-index: 9999;
    display: block;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #bbb;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);

  @media (max-width: 980px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LogoLink = styled(Link)`
  padding: 43px 0 0 0;
  width: 225px;
  background-image: url(${logo});
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
  overflow: hidden;
  height: 0;
  -webkit-background-size: contain;
  background-size: contain;

  @media (max-width: 980px) {
    padding: 27px 0 0 0;
    background-position: center;
    background-image: url(${MobileLogo});
  }
`;
const RightBlock = styled.div`
  padding-left: 225px;
  float: right;
  z-index: 1000;

  @media (max-width: 980px) {
    padding-left: 0;
    float: none;
    display: block;
  }
`;

const RightBlockPadding = styled.div`
  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 750px) {
    padding: 0;
  }
`;

const QuickAccessRow = styled.div`
  width: 600px;
  text-align: right;
  height: 100px;

  @media (max-width: 980px) {
    width: 100%;
    height: 84px;
  }

  @media (max-width: 750px) {
    height: 73px;
  }
`;

const MobileMargin = styled.div`
  @media (max-width: 980px) {
    padding-top: 125px;
  }
  @media (max-width: 750px) {
    padding-top: 114px;
  }
`;

const LowerHeader = () => {
  const { logout, token } = useContext(TokenContext);
  const { i18n } = useTranslation();
  // console.log({ token, logout });

  const menuItems = useMemo(
    () => [
      {
        link: `/${i18n.language}/car_parks/map`,
        icon: icon.find,
        hover: icon.findWhite,
        title: 'header.find_carpark',
      },
      {
        link: token ? `/${i18n.language}/customers/account` : `/${i18n.language}/customers/login`,
        icon: icon.account,
        hover: icon.accountWhite,
        title: 'header.my_account',
      },
      {
        link: token ? `/${i18n.language}` : `/${i18n.language}/customers/login`,
        icon: icon.monthly,
        hover: icon.monthlyWhite,
        title: token ? 'header.logout' : 'header.login',
        onClick: () => (token ? logout() : null),
      },
      {
        link: `/${i18n.language}/cart`,
        icon: icon.cart,
        hover: icon.cartWhite,
        title: 'header.cart',
        dropdown: <div />,
      },
    ],
    [token]
  );

  return (
    <div>
      <Container>
        <InnerWidth>
          <InnerContent>
            <LeftBlock>
              <MobileSlideMenu />
              <LogoContainer>
                <LogoLink to={`/${i18n.language}`} />
              </LogoContainer>
            </LeftBlock>
            <RightBlock>
              <RightBlockPadding>
                <QuickAccessRow>
                  {menuItems.map((record) => (
                    <QuickAccessBlock key={`${record.title}`} detail={record} />
                  ))}
                </QuickAccessRow>
              </RightBlockPadding>
            </RightBlock>
          </InnerContent>
        </InnerWidth>
      </Container>
      <MobileMargin />
    </div>
  );
};

export default LowerHeader;
