import React, { useEffect, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as icon from '../../../../media/icons';
import { useSelectOptions, useCars } from '../../../../models/User';
import StyledButton from '../../../../components/General/StyledButton';
import { useCarPark } from '../../../../models/CarPark';
import PopUpModal from '../../../../components/General/PopUpModal';
import StyledLink from '../../../../components/General/StyledLink';
import { SelectField } from '../../../../components/FormComponents';

const SelectorRow1 = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    width: 15%;
    @media (max-width: 850px) {
      width: 100%;
    }

    &:not(:first-child) {
      padding-left: 20px;

      @media (max-width: 850px) {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const SelectorRow2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  span {
    width: 15%;
    @media (max-width: 850px) {
      width: 100%;
    }

    &:not(:first-child) {
      padding-left: 20px;

      @media (max-width: 850px) {
        padding-left: 0;
      }
    }
  }
`;

const CarDetailContainer = styled.div`
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const LeftTable = styled.table`
  width: 60%;
  font-size: 0.8rem;
  border-spacing: 5px;
  border-collapse: separate;

  th {
    @media (max-width: 850px) {
      width: 50%;
    }
  }

  td {
    @media (max-width: 850px) {
      text-align: center;
    }
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`;

const RightTable = styled.table`
  width: 40%;
  font-size: 0.8rem;
  border-spacing: 5px;
  border-collapse: separate;

  th {
    @media (max-width: 850px) {
      width: 50%;
    }
  }

  td {
    @media (max-width: 850px) {
      text-align: center;
    }
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export default function ChangeService({ show, setChangeService, booking, book }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {
    regionOptions,
    districtOptions,
    setSelectedRegion,
    setSelectedDistrict,
    carParksInDistrict,
    setSelectedCarPark,
    serviceOptions,
    setSelectedService,
    selectedCarParkObject,
    selectedRegion,
    selectedDistrict,
    selectedCarPark,
    selectedService,
  } = useSelectOptions('changeService');

  const methods = useForm();

  const optionValues = [selectedRegion, selectedDistrict, selectedCarPark, selectedService];

  const hook = useCarPark(selectedCarPark);
  const { parkingPlansMap } = hook;

  const [newService, setNewService] = useState();

  const [newServicefee, setNewServiceFee] = useState();

  const linkObj = optionValues.includes(null)
    ? { state: { action: 'Change' } }
    : { pathname: '/cart/2', state: { action: 'Change' } };

  const { getOneCar, carDetail } = useCars();

  const handleChangeCarPark = async () => {
    if (optionValues.includes(null)) {
      alert('Please select an option for each of the fields below.');
    } else {
      const type =
        i18n.language === 'en' ? carDetail[0]?.CarType?.name : carDetail[0]?.CarType?.chineseName;
      const parkingPlanMap = parkingPlansMap.get(type);
      if (!parkingPlanMap) {
        alert('Invalid car type. Please try again.');
        return;
      }
      const parkingPlan = parkingPlanMap.get(selectedService);
      if (!Array.isArray(parkingPlan) || parkingPlan.length <= 0)
        alert('Invalid service. Please try again.');
      const changeItemObj = {
        ...selectedCarParkObject,
        id: booking.id,
        registrationMark: booking.registrationMark,
        CarId: booking.CarId,
        servicePeriod: `${moment(booking.validTo)
          .utcOffset(0)
          .add(1, 'month')
          .startOf('month')
          .format('DD/MM/YYYY')} to ${moment(booking.validTo)
          .utcOffset(0)
          .add(1, 'month')
          .endOf('month')
          .format('DD/MM/YYYY')}`,
        fee: newServicefee,
        ParkingPlanId: parkingPlan[0].id,
        createdDate: moment().toISOString(),
      };
      await book(changeItemObj);
      history.push(linkObj);
    }
  };

  useEffect(() => {
    if (booking.registrationMark) {
      getOneCar(booking.registrationMark);
    }
  }, [booking.registrationMark]);

  useEffect(() => {
    if (carDetail && selectedCarPark) {
      const type =
        i18n.language === 'en' ? carDetail[0]?.CarType?.name : carDetail[0]?.CarType?.chineseName;

      const supportedCarTypes = Array.from(parkingPlansMap.keys()).filter((carType) =>
        carType.includes(type)
      );
      const result = supportedCarTypes.reduce((acc, supportedCarType) => {
        const service = parkingPlansMap.get(supportedCarType);
        if (!service) return acc;
        const serviceResult = service.get(selectedService);
        // console.log(serviceResult)
        if (!Array.isArray(serviceResult)) return acc;
        return [...acc, ...serviceResult];
      }, []);
      if (result.length > 1) {
        setNewService([...result]);
        setNewServiceFee(null);
        return;
      }
      if (result.length === 0) {
        setNewService(null);
        setNewServiceFee(null);
        return;
      }
      result.map(({ amount }) => {
        setNewServiceFee(amount);
        setNewService(null);
      });
    }
  }, [carDetail, selectedCarPark, selectedService]);

  function handelClose() {
    setChangeService(false);
    setSelectedRegion(null);
    setSelectedDistrict(null);
    setSelectedCarPark(null);
    setNewServiceFee(null);
    methods.setValue('Region', null);
    methods.setValue('District', null);
    methods.setValue('CarPark', null);
    methods.setValue('Service', null);
  }

  return (
    <PopUpModal
      Popupshow={show}
      onClose={handelClose}
      widthStyle={{ maxWidth: 1000, width: '90%' }}
    >
      <FormContext {...methods}>
        <h3>{t('MyAccount.changeCarPark')}</h3>
        <h5 style={{ color: '#fd980f' }}>{booking.name}</h5>

        <div
          className="d-flex-polyfill"
          style={{ marginTop: -5, display: 'flex', color: '#979797' }}
        >
          <img src={icon.location} width="15" height="20" alt="location" />
          <p style={{ marginLeft: 5, fontSize: '0.9rem' }}>{booking.address}</p>
        </div>

        <CarDetailContainer>
          <LeftTable cellPadding="5">
            <tbody>
              <tr>
                <th style={{ color: '#fd980f', width: '35%' }}>
                  {t('MyAccount.iMonthly.ServiceStatus')}
                </th>
                <td style={{ width: '50%' }}>
                  {/* <div>{booking.status || '-'}</div> */}
                  {t(`MyAccount.iMonthly.status.${booking.status}`) || '-'}
                </td>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('MyAccount.iMonthly.Service')}</th>
                <td>{booking.service}</td>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('MyAccount.iMonthly.AssignedCar')}</th>
                <td>{booking.registrationMark}</td>
              </tr>
            </tbody>
          </LeftTable>

          <RightTable cellPadding="5">
            <tbody>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('MyAccount.iMonthly.ServiceId')}</th>
                <td>{booking.id}</td>
              </tr>
              <tr>
                <th style={{ color: '#fd980f' }}>{t('MyAccount.iMonthly.ExpiryDate')}</th>
                <td>{booking.lastPaymentDate}</td>
              </tr>
            </tbody>
          </RightTable>
        </CarDetailContainer>
        <hr />
        <p>{t('MyAccount.selectNewCarPark')}</p>
        <SelectorRow1>
          <span>{t('Myprofile.Region')}</span>
          <SelectField
            name="Region"
            options={regionOptions}
            onChange={([{ value }]) => setSelectedRegion(value)}
          />
          <span>{t('Myprofile.District')}</span>
          <SelectField
            name="District"
            options={districtOptions}
            style={{ marginTop: 0 }}
            onChange={([{ value }]) => setSelectedDistrict(value)}
            isDisabled={!selectedRegion}
          />
        </SelectorRow1>

        <SelectorRow2>
          <span>{t('PaymentRecords.carPark')}</span>
          <SelectField
            name="CarPark"
            options={carParksInDistrict}
            onChange={([{ value }]) => setSelectedCarPark(value)}
            isDisabled={!selectedDistrict}
          />
          <span>{t('MyAccount.iMonthly.Service')}</span>
          <SelectField
            name="Service"
            options={serviceOptions}
            onChange={([{ label }]) => setSelectedService(label)}
            isDisabled={!selectedCarPark}
          />
        </SelectorRow2>

        {newService && newService.length > 1 && (
          <SelectorRow2>
            {/* <span>{t('PaymentRecords.carPark')}</span> */}
            <SelectField
              name="CarPark"
              options={newService.map(({ subTitle, amount }) => {
                return { value: amount, label: subTitle };
              })}
              onChange={([{ value }]) => setNewServiceFee(value)}
              isDisabled={!newService}
            />
          </SelectorRow2>
        )}
      </FormContext>

      <hr />

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingBottom: 10 }}
      >
        <span>
          {`${t('MyAccount.serviceFee')} HK$`}
          {newServicefee}
        </span>
      </div>

      <hr />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <StyledButton
          type="button"
          onClick={handelClose}
          label={t('IParkVerifySteps.back')}
          color="grey"
        />
        <StyledLink
          type="button"
          label={t('IParkVerifySteps.next')}
          color="yellow"
          to="#"
          onClick={handleChangeCarPark}
          disabled={!newServicefee}
        />
      </div>
    </PopUpModal>
  );
}
