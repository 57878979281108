import React, { useState, useEffect, useContext } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import * as yup from 'yup';
import ReactLoading from 'react-loading';
import moment from 'moment';
import Swal from 'sweetalert2';
import commonStyle from '../../styles/common';
import cartStyle from '../../styles/cart';
import CartItem from './CartItem';
import Timer from './Timer';
import * as icon from '../../media/icons';
import TimesUpModal from './TimesUpModal';
import { useFirstDataToken, TokenContext } from '../../models/Customer';
import { SelectField, Checkbox, CheckBoxMargin } from '../../components/FormComponents';
import { RadioGroup, RadioButton } from '../../components/Cart';
import StyledLink from '../../components/General/StyledLink';
import StyledButton from '../../components/General/StyledButton';
import Carousell from './Carousell';
import useWilRegistration from '../../models/WilRegistration';

const OrangeContainer = styled.div`
  padding: 25px 15px 15px 15px;
  background-color: #fd980f;
  color: #fff;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const twingle = keyframes`
  0%   { opacity:1; }
  25%  { opacity:0; }
  50%  { opacity:1; }
  75%  { opacity:0; }
  100% { opacity:1; }
`;

const PaymentMethodButton = styled.span`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 123px;
  color: #888;
  text-align: center;
  margin-top: 8px;
  padding: 0 20px;
  background: url(${(props) => props.backgroundUrl}) no-repeat center center #fff;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  border: ${({ errors }) => (errors ? '2px solid red' : '1px solid #ccc ')};
  animation: ${twingle} ${({ errors }) => errors && '1s 1s 4 ease-out'};
`;

const Label = styled.span`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  margin-top: ${(props) => props.marginTop};
`;

const CreditCardLabel = styled.span`
  margin-left: 10px;
  font-size: 0.8rem;
`;

const SubTitle = styled.span`
  font-size: 1.2rem;
`;

const WhiteHorizontalLine = styled.div`
  border-bottom: 1px solid #fff;
  margin-top: 15px;
`;

const PaymentRow = styled.div`
  width: calc(50% - 10px);
  margin-right: 10px;

  @media (max-width: 850px) {
    width: 250px;
  }
`;

export const CardSelector = ({
  activePaymentMethod,
  creditCards,
  visaId,
  setFirstDataTokenId,
  FirstDataTokenId,
}) => {
  const { t } = useTranslation();
  const T = (input) => t(`Cart.PaymentMethod.${input}`);
  return (
    <>
      {creditCards && creditCards.length > 0 && (
        <div hidden={parseInt(activePaymentMethod, 10) !== visaId} className="ml-3 mb-1">
          <CreditCardLabel>{T('SavedCard')}</CreditCardLabel>
          <RadioGroup
            controllerName="FirstDataTokenId"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {creditCards.map((creditCard) => {
              const { id, token } = creditCard;
              const cardType = token.substring(0, 1) === 4 ? 'visa' : 'master';

              return (
                <RadioButton
                  key={`FDT${id}`}
                  value={id.toString()}
                  name="FirstDataTokenId"
                  style={{ marginLeft: 25, marginTop: 5 }}
                  onClick={() => setFirstDataTokenId(id.toString())}
                  checked={FirstDataTokenId === id.toString()}
                >
                  <img
                    src={`https://www.wilsonparking.com.hk/img/logo-flat-${cardType}.png`}
                    alt={cardType}
                  />
                  <CreditCardLabel>
                    &bull;&bull;&bull;&bull;&nbsp;&nbsp;
                    {token.slice(-4)}
                  </CreditCardLabel>
                </RadioButton>
              );
            })}
            <RadioButton
              value="new"
              name="FirstDataTokenId"
              onClick={() => setFirstDataTokenId('new')}
              checked={FirstDataTokenId === 'new'}
            >
              <span>{T('NewCard')}</span>
            </RadioButton>
          </RadioGroup>
        </div>
      )}
    </>
  );
};

export const PaymentMethodSelector = ({
  activePaymentMethod,
  creditCards,
  offlinePaymentIds,
  onlinePaymentIds,
  setFirstDataTokenId,
  errors,
  FirstDataTokenId,
  availablePaymentMethods = [],
  selectedValue,
  allPaymentMethods,
  paymentMethodsLoading,
}) => {
  const { t, i18n } = useTranslation();
  const T = (input) => t(`Cart.PaymentMethod.${input}`);

  const { chequeId = '', sevenElevenId = '' } = offlinePaymentIds || {};
  const { visaId = '', ppsId = '', fpsId = '' } = onlinePaymentIds || {};

  const Today = moment().format('D');

  const isLast2Day = Today >= moment().endOf('month').format('D') - 2;

  const defaultPaymentMethodId = [
    ...Object.values(offlinePaymentIds),
    ...Object.values(onlinePaymentIds),
  ];

  const isEnablePaymentMethods = availablePaymentMethods.filter(
    ({ id: paymentMethodId, isEnable, isOnlineMethod }) =>
      isEnable &&
      defaultPaymentMethodId.includes(paymentMethodId) &&
      (isOnlineMethod || !isLast2Day)
  );

  return (
    <div>
      <div>
        <Label fontSize="1.1rem" fontWeight="bold">
          {T('ChoosePrompt')}
        </Label>
        <br />
        <Label fontSize="0.9rem" marginTop="20px">
          {T('HKDDisclaimer')}
        </Label>
        <br />
        <br />
        <br />
        {paymentMethodsLoading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color="#dedede" height={50} width={50} />
          </div>
        )}
        {!paymentMethodsLoading && availablePaymentMethods.length > 0 ? (
          <RadioGroup
            controllerName="PaymentMethodRadio"
            mobile
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {isEnablePaymentMethods.length > 0 ? (
              isEnablePaymentMethods.map(
                ({ icon: paymentImg, name, id: paymentMethodId, isOnlineMethod }) => {
                  return (
                    <>
                      {isLast2Day && !isOnlineMethod ? (
                        <></>
                      ) : (
                        <PaymentRow>
                          <RadioButton
                            checked={selectedValue === paymentMethodId.toString()}
                            value={paymentMethodId.toString()}
                            name="PaymentMethodRadio"
                            style={{ width: '100%' }}
                          >
                            <Label fontSize="1rem">{T(`${name}`)}</Label>
                            <PaymentMethodButton
                              backgroundUrl={`${process.env.REACT_APP_MEDIA_DOMAIN}${paymentImg}`}
                              errors={errors}
                            />
                          </RadioButton>
                          {paymentMethodId === visaId && (
                            <CardSelector
                              activePaymentMethod={activePaymentMethod}
                              creditCards={creditCards}
                              visaId={visaId}
                              setFirstDataTokenId={setFirstDataTokenId}
                              FirstDataTokenId={FirstDataTokenId}
                            />
                          )}
                        </PaymentRow>
                      )}
                    </>
                  );
                }
              )
            ) : (
              <Label fontSize="1.2rem" style={{ color: errors ? 'red' : '#fff' }}>
                {t('Cart.Separate')}
              </Label>
            )}
          </RadioGroup>
        ) : (
          <Label fontSize="1.2rem" style={{ color: errors ? 'red' : '#fff' }}>
            {t('Cart.Separate')}
          </Label>
        )}
      </div>
    </div>
  );
};

const DiscountView = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-top: 10px;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const DiscountLabel = styled.p`
  margin: 0;
  padding: 0;
  line-height: 1.5;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  padding-top: 5px;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
`;

const DiscountInput = styled.input`
  border: none;
  height: 32px;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
`;

const DiscountDiv = styled.div`
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  display: flex;
`;

export const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Label fontSize="0.9rem" marginTop="20px">
        {t('Cart.TnC.Title')}
      </Label>
      <div
        style={cartStyle.termsConditionsBox}
        dangerouslySetInnerHTML={{ __html: t('Cart.TnC.Content') }}
      >
        {/* <p style={{ color: 'black', fontSize: '0.75rem' }}>{t('Cart.TnC.Content')}</p> */}
      </div>
    </>
  );
};

function Step1({
  bookingDetail,
  onSelectPaymentMethod,
  onSelectToken,
  checkout,
  removeAll,
  action,
  offlinePaymentIds,
  offlinePaymentEnable,
  onlinePaymentIds,
  onlinePaymentEnable,
  redirectToStep2,
  history,
  availablePaymentMethods,
  allPaymentMethods,
  paymentMethodsLoading,
}) {
  const { t, i18n } = useTranslation();
  const { token } = useContext(TokenContext);
  const { firstDataTokens: creditCards } = useFirstDataToken();
  const { checkUserRegistered } = useWilRegistration();
  const { phone, id, reference, CustomerId } = bookingDetail || {};

  const defaultValues = {
    pickup: 'self',
    IsAgreeTermsConditions: false,
    PaymentMethodRadio: null,
    FirstDataTokenId: null,
  };

  const methods = useForm({
    defaultValues,
    validationSchema: yup.object().shape({
      IsAgreeTermsConditions: yup.boolean().oneOf([true], 'IhourlyCart.ErrorMsg'),
      PaymentMethodRadio: yup.string().required(),
      // PickupLocation: yup.string().when('pickup', {
      //   is: (val) => val === 'self',
      //   then: yup.string().required('Cart.locationPickupError'),
      //   otherwise: yup.string(),
      // }),
    }),
  });
  const watchPaymentMethod = methods.watch('PaymentMethodRadio');
  const watchFirstDataToken = methods.watch('FirstDataTokenId');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (creditCards.length === 0) {
      methods.setValue('FirstDataTokenId', 'new');
    } else {
      methods.setValue('FirstDataTokenId', creditCards[0].id.toString());
    }
  }, [creditCards]);

  useEffect(() => {
    const methodId = parseInt(watchPaymentMethod, 10);

    if (methodId === 6 && creditCards.length === 0) {
      onSelectToken('new');
    }

    onSelectPaymentMethod(methodId);
  }, [watchPaymentMethod]);

  useEffect(() => {
    onSelectToken(watchFirstDataToken);
  }, [watchFirstDataToken]);

  function handelCheckout() {
    checkout(token, true).then((e) => {
      if (e) {
        redirectToStep2();
        setLoading(false);
      } else setLoading(false);
    });
  }

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    if (process.env.REACT_APP_WIL_PAYMENTLINK_REMINDER === 'true') {
      await checkUserRegistered(
        phone,
        id,
        reference,
        CustomerId,
        moment().valueOf(),
        handelCheckout,
        setLoading
      );

      return;
    }

    handelCheckout();
  };

  return (
    <div>
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div style={{ textAlign: 'left', width: '95%', paddingLeft: 10, marginTop: 20 }}>
            <div style={{ display: 'flex' }}>
              <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
              <p style={{ marginLeft: 10 }}>
                <b>{t('Cart.Instructions.Header')}</b>
              </p>
            </div>
            <ol style={{ fontSize: '0.87rem', margin: '0px 15px 25px 15px' }}>
              <li>{t('Cart.Instructions.2-1')}</li>
            </ol>
          </div>

          <div style={cartStyle.whiteContainer}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <SubTitle>{t('Cart.Summary')}</SubTitle>

              <Timer timesUp={() => setShowModal(true)} />
              <TimesUpModal showModal={showModal} removeAll={removeAll} />
            </div>

            {bookingDetail && <CartItem index={0} details={bookingDetail} action={action} />}
          </div>

          <Carousell bookingDetail={bookingDetail} />

          <OrangeContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexBasis: '55%',
                fontSize: '0.75rem',
              }}
            >
              <PaymentMethodSelector
                paymentMethodsLoading={paymentMethodsLoading}
                allPaymentMethods={allPaymentMethods}
                availablePaymentMethods={availablePaymentMethods}
                action={action}
                activePaymentMethod={watchPaymentMethod}
                creditCards={creditCards}
                offlinePaymentIds={offlinePaymentIds}
                offlinePaymentEnable={offlinePaymentEnable}
                onlinePaymentIds={onlinePaymentIds}
                onlinePaymentEnable={onlinePaymentEnable}
                setFirstDataTokenId={(id) => methods.setValue('FirstDataTokenId', id)}
                errors={methods.errors.PaymentMethodRadio}
                FirstDataTokenId={methods.getValues('FirstDataTokenId')}
                isLogined={!!token}
                selectedValue={methods.getValues('PaymentMethodRadio')}
              />
              <TermsConditions />
              <CheckBoxMargin>
                <Checkbox
                  style={{ background: '#fff', borderRadius: '2px' }}
                  textStyle={{ color: '#fff' }}
                  name="IsAgreeTermsConditions"
                  Text1={t('Cart.TnC.Agree')}
                  defaultValues={false}
                  rules={{
                    validate: () => methods.getValues('IsAgreeTermsConditions'),
                  }}
                />
                {methods.errors.IsAgreeTermsConditions && (
                  <span style={{ color: 'red', marginLeft: 25 }}>
                    {t(`${methods.errors.IsAgreeTermsConditions?.message}`)}
                  </span>
                )}
              </CheckBoxMargin>
            </div>

            <div style={commonStyle.verticalLine} />

            <div
              style={{
                flexBasis: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <DiscountView>
                  <DiscountLabel>{t('Cart.Discount')}</DiscountLabel>

                  <DiscountDiv>
                    <DiscountInput />
                    <StyledButton
                      type="button"
                      color="grey"
                      style={{ margin: 0, flex: '0 0 41.666667%', maxWidth: '41.666667%' }}
                    >
                      {t('Cart.Apply')}
                    </StyledButton>
                  </DiscountDiv>
                </DiscountView>

                <WhiteHorizontalLine />

                <div style={cartStyle.paymentDisplay}>
                  <Label fontSize="0.875rem" fontWeight="bold">
                    {t('Cart.SubTotal')}
                  </Label>
                  <Label fontSize="0.875rem">
                    HK$
                    {bookingDetail?.fee}
                  </Label>
                </div>

                <div style={cartStyle.paymentDisplay}>
                  <Label fontSize="0.875rem" fontWeight="bold">
                    {t('Cart.HandlingFee')}
                  </Label>
                  <Label fontSize="0.875rem">
                    HK$
                    {bookingDetail?.handelingFee || 0}
                  </Label>
                </div>

                <WhiteHorizontalLine />

                <div style={cartStyle.paymentDisplay}>
                  <Label fontSize="1.3rem" fontWeight="bold">
                    {t('Cart.TotalPrice')}
                  </Label>
                  <Label fontSize="2.4rem">
                    HK$
                    {bookingDetail?.fee + (bookingDetail?.handelingFee || 0)}
                  </Label>
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <StyledLink
                  type="button"
                  color="grey"
                  to={{
                    pathname: `/${i18n.language}`,
                  }}
                  label={t('Cart.Back')}
                  style={{ maxWidth: '100%', height: 35 }}
                  widthoutMargin
                />
                <StyledButton
                  type="submit"
                  color="white"
                  label={t('Cart.Confirm')}
                  style={{ maxWidth: '100%', height: 35 }}
                  widthoutMargin
                  left
                  disabled={loading}
                >
                  {loading && <ReactLoading type="spin" color="#fd980f" height={30} width={30} />}
                </StyledButton>
              </div>
            </div>
          </OrangeContainer>
        </form>
      </FormContext>
    </div>
  );
}

export default Step1;
