import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { Radio, useRadioState, RadioGroup as PrettyRadioGroup } from 'pretty-checkbox-react';

import 'pretty-checkbox';
import '../General/Checkbox/style.scss';

const Container = styled.div`
  & .pretty {
    outline: none !important;
    border: none !important;
  }

  & .pretty {
    &:focus {
      outline: none !important;
    }
  }
`;

function RadioGroup({ children, groupStyle }) {
  // const error = errors[name];
  const radio = useRadioState();

  const newChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { radio });
  });

  return (
    <Container>
      <PrettyRadioGroup style={{ ...groupStyle }} {...radio}>
        {newChildren}
      </PrettyRadioGroup>
    </Container>
  );
}

export default RadioGroup;

export function RadioButton({ radio, name, value, children, style }) {
  const { control } = useFormContext();

  return (
    <Controller
      as={
        <label style={style}>
          <Radio color="warning-o" value={value} name={name} {...radio} />
          {children}
        </label>
      }
      name={name}
      control={control}
    />
  );
}
