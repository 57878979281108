import React from 'react';
import { Marker, StreetViewPanorama } from '@react-google-maps/api';
import * as icon from '../../../media/icons';
import Map from '../../../components/General/Map';

// https://tomchentw.github.io/react-google-maps/

const CarParkMapView = (props) => {
  const { coordinates, isIMonthly, activeTab } = props || {};
  const { latitude, longitude } = coordinates || {};

  return (
    <Map
      center={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
      zoom={15}
      containerStyle={{ height: '50vh', border: '10px solid #fff' }}
      options={{ fullscreenControl: true }}
    >
      {activeTab === 'map' && (
        <Marker
          position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
          draggable={false}
          icon={{
            url: isIMonthly ? icon.markerBlue : icon.markerRed,
            scaledSize: {
              width: 28,
              height: 34,
              widthUnit: 'px',
              heightUnit: 'px',
            },
          }}
        />
      )}
      {activeTab === 'street_view' && (
        <StreetViewPanorama
          position={{
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          }}
          visible
        />
      )}
    </Map>
  );
};

export default CarParkMapView;
