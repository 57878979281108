import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useStaticInfo from '../../models/StaticInfo';

const TitleRow = styled.div`
  background: #fd980f;
  padding: 15px;
  justify-content: center;
  display: flex;
`;

const TitleWidth = styled.div`
  width: 1000px;
`;

const Title = styled.div`
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
`;

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const MainWidth = styled.div`
  background: #fff;
  padding: 20px;
  width: 1020px;
  padding-bottom: 80px;

  p {
    font-size: 0.875rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }
`;

function PrivacyPolicy() {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo('Pages-Privacy Policy');

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <>
      <div id="PrivacyPolicy">
        <TitleRow>
          <TitleWidth>
            <Title>{name}</Title>
          </TitleWidth>
        </TitleRow>

        <MainContainer>
          <MainWidth dangerouslySetInnerHTML={{ __html: content }}>
            {/* <SubTitle>
              {t('PRIVACYPOLICY.Title1')}
              <br />
            </SubTitle>
            <Text>
              1) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {t('PRIVACYPOLICY.T1text1')}
            </Text>
            <Margin />
            <Text>
              2) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {t('PRIVACYPOLICY.T1text2')}
            </Text>
            <Margin />
            <SecondSubTitle>
              {t('PRIVACYPOLICY.Title2')}
              <br />
            </SecondSubTitle>
            <Text>{t('PRIVACYPOLICY.T2text1')}</Text>
            <Margin />
            <Text>{t('PRIVACYPOLICY.T2text2')}</Text>
            <Margin />
            <Text>{t('PRIVACYPOLICY.T2text3')}</Text>
            <Margin />
            <Text>{t('PRIVACYPOLICY.T2text4')}</Text>
            <Margin />
            <Text>{t('PRIVACYPOLICY.T2text5')}</Text>
            <Margin />
            <Text>{t('PRIVACYPOLICY.T2text6')}</Text>
            <Margin XLmargin />
            <SubTitle>
              {t('PRIVACYPOLICY.Title3')}
              <br />
            </SubTitle>
            <Text>{t('PRIVACYPOLICY.T3text1')}</Text>
            <Margin />
            <SecondSubTitle>
              {t('PRIVACYPOLICY.Title4')}
              <br />
            </SecondSubTitle>
            <Text>
              {t('PRIVACYPOLICY.T4text1')}
              <br />
              {t('PRIVACYPOLICY.T4text2')}
            </Text>
            <Margin />
            <SecondSubTitle>
              {t('PRIVACYPOLICY.Title5')}
              <br />
            </SecondSubTitle>
            <TextBox>
              <Text> {t('PRIVACYPOLICY.T5text1')} </Text>
              <Margin />
              <Text>{t('PRIVACYPOLICY.T5text2')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text3')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text4')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text5')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text6')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text7')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text8')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text9')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text10')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text11')}</Text>
              {i18n.language === 'en' && <Margin />}
              <Text>{t('PRIVACYPOLICY.T5text12')}</Text>
              <Margin />
              <Text>{t('PRIVACYPOLICY.T5text13')}</Text>
            </TextBox>
            <Margin />
            <SecondSubTitle>
              {t('PRIVACYPOLICY.Title6')}
              <br />
            </SecondSubTitle>
            <Text>{t('PRIVACYPOLICY.T6text1')}</Text>
            <Margin />
            <SecondSubTitle>
              {t('PRIVACYPOLICY.Title7')}
              <br />
            </SecondSubTitle>
            <Text>{t('PRIVACYPOLICY.T7text1')}</Text>
            <Margin />
            <SecondSubTitle>
              {t('PRIVACYPOLICY.Title8')}
              <br />
            </SecondSubTitle>
            <Text>{t('PRIVACYPOLICY.T8text1')}</Text>
            <Margin />
            <Text>{t('PRIVACYPOLICY.T8text2')}</Text>
            <Margin XLmargin />
            <SubTitle>
              {t('PRIVACYPOLICY.Title9')}
              <br />
            </SubTitle>
            <Text>{t('PRIVACYPOLICY.T9text1')}</Text>
            <Margin />
            <Text> {t('PRIVACYPOLICY.T9text2')}.</Text> */}
          </MainWidth>
        </MainContainer>
      </div>
    </>
  );
}

export default withRouter(PrivacyPolicy);
