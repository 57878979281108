import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import Checkbox from '../../../components/General/Checkbox';

const Container = styled.div``;

const Text = styled.span`
  margin-left: 5px;
  font-size: 13px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};
`;

const Href = styled.a`
  color: #fd980f;
  font-weight: bold;

  &:hover {
    color: #fd980f;
  }
`;

function DistrictSelector({ name, Text1, Text2, onclick, checked, style, textStyle }) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  return (
    <Container>
      <Controller
        style={style}
        as={Checkbox}
        name={name}
        control={control}
        shape="curve"
        color="warning-o"
        animation="smooth"
        defaultValue={!!checked}
      />
      <Text style={textStyle} error={error}>
        {Text1}
        <Href href="#" onClick={onclick}>
          {Text2}
        </Href>
      </Text>
    </Container>
  );
}

export default DistrictSelector;
