import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Autocomplete, DirectionsRenderer, useGoogleMap } from '@react-google-maps/api';
import * as icon from '../../../media/icons';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';
import Button from '../../../components/General/Button';

const MobileInfoWindow = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: none;
  flex-direction: column;

  @media (max-width: 770px) {
    display: flex;
  }
`;

const MobileClickableInfo = styled(Link)`
  background: white;
  padding: 0 10px 2px 40px;
  line-height: 1.4;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:hover {
    text-decoration: none;
  }
`;

const MobileViewName = styled.div`
  color: #fd980f;
  font-size: 1.2rem;
`;

const MobileViewAddress = styled.div`
  font-weight: 400;
  font-size: 0.9rem;
  color: #000;
`;

const GetDirectionsInput = styled.input`
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  border-right: none;
  background-color: #f3f3f3;
  width: 100%;
  padding: 6px 30px 10px 70px;
  color: #666 !important;
  font-size: 1rem;

  &::placeholder {
    font-size: 0.78rem;
    font-style: italic;
    color: #a7a7a7 !important;
  }

  &:focus {
    box-shadow: none;
  }
`;

const ArrowButton = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 23px;
  margin-top: 10px;
  background: #fd980f url(${icon.nextwhite}) no-repeat center;
  background-size: auto 55%;
`;

const BottomButton = styled.div`
  display: none;
  width: 100%;
  position: absolute;
  z-index: 99;
  bottom: 100px;
  justify-content: center;

  @media (max-width: 770px) {
    display: flex;
  }
`;

const InnerWidth = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
`;

export default function MobileInfoDisplay({ selectedMarker, setZoom }) {
  const { t, i18n } = useTranslation();
  const mapRef = useGoogleMap();
  const { outerWidth } = ScreenWidthListener();

  const { id, name, address, coordinates } = selectedMarker;
  const { latitude, longitude } = coordinates;

  const [directions, setDirections] = useState({ lat: '', lng: '' });
  const [autoComplete, setAutoComplete] = useState();
  const [inputAddress, setInputAddress] = useState();
  const [directionsResult, setDirectionsResult] = useState();

  function resetDirections() {
    setDirectionsResult(undefined);
    setInputAddress('');
    setDirections({ lat: '', lng: '' });
    mapRef.panTo({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    });
    mapRef.setZoom(12);
  }

  useEffect(() => {
    if (outerWidth > 770 && directionsResult && mapRef) {
      resetDirections();
    }
  }, [outerWidth, directionsResult, mapRef]);

  useEffect(() => {
    if (directions.lat && directions.lng && mapRef) {
      const directionsService = new window.google.maps.DirectionsService();

      const request = {
        origin: {
          lat: directions.lat,
          lng: directions.lng,
        },
        destination: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
        travelMode: 'DRIVING',
      };

      directionsService.route(request, (response, status) => {
        if (status === 'OK') {
          setDirectionsResult(response);
        }
      });
    }
  }, [selectedMarker, directions, mapRef]);

  return (
    <>
      <MobileInfoWindow>
        <MobileClickableInfo to={`/${i18n.language}/car_parks/detail/${id}`} role="button">
          <div>
            <MobileViewName>{name}</MobileViewName>
            <MobileViewAddress>{address}</MobileViewAddress>
          </div>

          <ArrowButton />
        </MobileClickableInfo>

        <Autocomplete
          restrictions={{ country: 'hk' }}
          onLoad={(e) => setAutoComplete(e)}
          onPlaceChanged={() => {
            const addressObject = autoComplete.getPlace();
            setInputAddress(addressObject.formatted_address);
            setDirections({
              ...directions,
              lat: parseFloat(addressObject.geometry.location.lat()),
              lng: parseFloat(addressObject.geometry.location.lng()),
            });
          }}
        >
          <GetDirectionsInput
            type="text"
            placeholder={t('Map.getDirections')}
            value={inputAddress}
            onChange={(e) => setInputAddress(e.target.value)}
          />
        </Autocomplete>
      </MobileInfoWindow>

      {directionsResult && (
        <DirectionsRenderer
          options={{
            directions: directionsResult,
            disableAutoPan: true,
          }}
        />
      )}

      {directionsResult && (
        <BottomButton>
          <InnerWidth>
            <Button
              Title={t('Map.goolemapOpen')}
              style={{ width: 225 }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/dir/?api=1&origin=${directions.lat},${directions.lng}&destination=${latitude},${longitude}&travelmode=driving`
                );
              }}
            />
            <Button
              Title={t('Map.clearDirections')}
              style={{ width: 225 }}
              onClick={() => {
                resetDirections();
              }}
            />
          </InnerWidth>
        </BottomButton>
      )}
    </>
  );
}
