import styled from 'styled-components';
import loginBackgroundImg from '../../../assets/images/loginBackgroundImg.jpeg';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 980px) {
    padding: 0px;
  }
`;

export const MaxWidth = styled.div`
  max-width: 1024px;
  width: 100%;
  display: flex;
  background-color: #fff;
  height: 580px;
  position: relative;
  align-items: center;

  @media (max-width: 980px) {
    height: 100%;
    justify-content: center;
  }

  @media (max-width: 376px) {
    height: 100vh;
  }
`;

export const ImageCol = styled.div`
  width: 70%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.003) 67%, rgba(255, 255, 255, 1) 100%),
    url(${loginBackgroundImg});
  height: 100%;

  @media (max-width: 980px) {
    width: 100%;
    background-image: url(${loginBackgroundImg});
  }
`;

export const FormCol = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 20px;

  @media (max-width: 980px) {
    right: auto;
    background-color: #fffa;
    padding: 20px;
  }

  @media (max-width: 460px) {
    height: 100%;
  }

  @media (max-width: 376px) {
    justify-content: flex-start;
  }
`;

export const ErrorMsg = styled.p`
  color: #e56e5a;
  font-size: 13px;
  line-height: 1.1;
  margin-bottom: 0px;
`;

export const PasswordRow = styled.div`
  position: relative;
  width: 100%;
  visibility: ${({ isEmailValid }) => (isEmailValid ? 'visible' : 'hidden')};
  margin-top: ${({ isEmailValid }) => (isEmailValid ? '20px' : '0px')};
  transition: all 1000ms ease-in-out;
  opacity: ${({ isEmailValid }) => (isEmailValid ? 1 : 0)};
  position: ${({ isEmailValid }) => (isEmailValid ? 'relative' : 'absolute')};
  margin-bottom: 20px;

  svg {
    fill: #888;
  }
`;

export const PasswordInput = styled.input`
  padding-left: 40px;
  height: 40px;
  width: 100%;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : ' 1px solid rgb(222, 222, 222)')};
`;

export const UsernameRow = styled.div`
  position: relative;
  width: 100%;
  svg {
    fill: #888;
  }
  display: flex;
  flex-direction: column;
`;

export const UsernameInput = styled.input`
  padding-left: 40px;
  height: 40px;
  width: 100%;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '1px solid rgb(222, 222, 222)')};
`;

export const RecaptchaRow = styled.div`
  transition: all 1000ms ease-in-out;
  border: ${({ error }) => (error ? '1px solid #E56E5A !important' : '1px solid #fff !important')};
  visibility: ${({ isEmailValid }) => (isEmailValid ? 'visible' : 'hidden')};
  margin-top: ${({ isEmailValid }) => (isEmailValid ? '40px' : '-20px')};
  position: ${({ isEmailValid }) => (isEmailValid ? 'relative' : 'absolute')};
  background-color: #dedede;
  height: 78px;
  width: 304px;
`;

export const InputCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //  width: 75%;
  width: 372px;
  margin-top: 20px;
  flex-direction: column;

  @media (max-width: 980px) {
    width: 90%;
  }
`;

export const PasswordCol = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%;
`;

export const BackButton = styled.button`
  margin-top: 10px;
  color: rgb(253, 152, 15);
  background-color: transparent;
  border: none;
`;

export const ForgotDiv = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  text-align: right;
`;

export const Forget = styled.a`
  color: #fd980f;
  font-size: 0.75rem;
  text-decoration: underline;

  &:hover {
    color: #fd980f;
  }
`;

export const LoginTitle = styled.span`
  font-size: 25px;
  text-align: center;
  width: 440px;

  @media (max-width: 980px) {
    width: 100%;
  }
`;
