import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const MaxWidth = styled.div`
  max-width: 1024px;
  padding: 20px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  flex-direction: column;
  min-width: 60%;
`;

export const Record = styled.div`
  background-color: #dedede;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;

  @media (max-width: 680px) {
    padding: 10px;
    margin: 10px;
    flex-direction: column;
  }
`;

export const RecordCol = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 680px) {
    border-top: 1px solid #888;
  }
`;

export const RecordRow = styled.div`
  margin: 5px 0px;
  display: flex;
  flex-wrap: nowrap;

  p {
    margin: 0;

    &:nth-child(1) {
      margin-left: 10px;
      white-space: nowrap;
    }

    &:nth-child(2) {
      margin-left: 10px;
      white-space: break-spaces;
    }
  }

  @media (max-width: 680px) {
    align-items: center;
    margin-top: 10px;
  }
`;

export const LogoCol = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  img {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 680px) {
    margin: 10px 0px;
    img {
      width: 60px;
      height: 60px;
    }
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 10px;
    min-height: 40px;
  }
`;
