import React from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import checkedicon from '../../../media/images/checked.png';
import './style.scss';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
    transition: 'none',
    '&$checked': {
      color: '#fd980f',
    },
  },
  icon: {
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    'input:hover ~ &': {
      border: '1px solid #000',
    },
  },
  checkedIcon: {
    width: 10,
    height: 10,
    '&:before': {
      display: 'block',
      width: 10,
      height: 10,
      content: '""',
    },
  },
  checked: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const CheckedIcon = styled.img`
  border: 1px solid #bbb;
  height: 15px;
  width: 15px;
`;

function StyledCheckbox(props, { name }) {
  const classes = useStyles();
  return (
    <Checkbox
      disableRipple
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      color="default"
      icon={<span className={classes.icon} />}
      checkedIcon={<CheckedIcon src={checkedicon} />}
      name={name}
      {...props}
    />
  );
}

export default StyledCheckbox;
