import { useEffect, useState, useCallback } from 'react';

export default function ScreenWidthListener() {
  const [outerWidth, setwindowwidth] = useState(window.innerWidth);
  const [outerHeight, setwindowHeight] = useState(window.innerHeight);

  const handleWindowResize = useCallback(() => {
    setwindowwidth(window.innerWidth);
    setwindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return { outerWidth, outerHeight };
}
