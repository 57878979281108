import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import styled from 'styled-components';
import logo from '../../../media/images/wpLogo.png';

const Container = styled.div`
  border: 10px solid #fff;
  width: 100%;

  .alice-carousel__stage-item {
    width: 100% !important;
  }

  .alice-carousel__dots {
    margin: 0;
    background-color: #fff;
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #fd980f;
  }

  .alice-carousel__dots-item:not(.__custom) {
    height: 10px;
    width: 10px;
  }
`;

function Photo(image) {
  return (
    <Container>
      {/* {Array.isArray(image) && image.length > 0 ? (
        <AliceCarousel
          mouseTracking={imageArray.length > 1}
          disableButtonsControls
          disableDotsControls={imageArray.length === 1}
          autoWidth
          items={imageArray.map((e) => {
            return (
              <img
                onDragStart={(event) => event.preventDefault()}
                src={`${CP_MEDIA_DOMAIN}${e}`}
                width="100%"
                height="100%"
                alt=""
              />
            );
          })}
        />
      ) : (
        <h5>Image cannot be loaded right now</h5>
      )} */}
      {image ? (
        <img
          src={`${process.env.REACT_APP_CP_MEDIA_DOMAIN}${image}`}
          width="100%"
          height="100%"
          alt="carpark_photo"
          style={{ objectFit: 'contain' }}
        />
      ) : (
        <img
          src={logo}
          width="100%"
          height="100%"
          alt="carpark_photo"
          style={{ objectFit: 'contain' }}
        />
      )}
    </Container>
  );
}

export default Photo;
