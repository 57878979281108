/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ReactLoading from 'react-loading';

const StyledButton = styled.button`
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  transition: all 0.3s ease-in-out;
  background: ${({ errors }) => (errors > 0 ? '#666' : '#fd980f')};
  border: 0;
  padding: ${(props) => (props.bottom ? '5px' : null)};
  width: ${(props) => (props.bottom ? '120px' : null)};
  height: ${(props) => (props.bottom ? '32px' : null)};
  margin-top: 3px;
  height: 32px;
  white-space: nowrap;
  &:hover {
    background: ${({ errors }) => (errors > 0 ? '#666' : '#888')};
    color: #fff;
  }

  @media (max-width: 850px) {
    height: ${(props) => (props.bottom ? '32px' : '32px')};
    margin-bottom: ${(props) => (props.gray ? '30px' : null)};
    margin-bottom: ${(props) => (props.bottom ? '0px' : null)};
  }
`;

function SubmitButton({ Title, bottom, isLoading }) {
  const { control, errors } = useFormContext();

  return (
    <StyledButton
      type="submit"
      value={Title}
      bottom={bottom}
      control={control}
      errors={Object.keys(errors).length}
      disabled={Object.keys(errors).length > 0 || isLoading}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      {Title}

      {isLoading && (
        <ReactLoading type="spin" style={{ marginLeft: 5, width: 20, height: 20, fill: '#888' }} />
      )}
    </StyledButton>
  );
}

export default SubmitButton;
