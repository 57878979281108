import styled from 'styled-components';
import React from 'react';

// TODO: responsive?

const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-flex: 1;
  flex: 1;
  padding-right: 20px;
`;

const Title = styled.span`
  font-size: 20.8px;
  font-weight: bold;
  margin-top: 10px;
`;

const Note = styled.span`
  margin-top: 10px;
  font-size: 12px;
  color: gray;
`;

function SectionTitle({ note, error, title, titleStyle, onClick }) {
  return (
    <Container error={error}>
      <Title onClick={onClick} style={titleStyle}>
        {title}
      </Title>
      {note && <Note>{note}</Note>}
    </Container>
  );
}

export default SectionTitle;
