const cartStyle = {
  whiteContainer: {
    background: 'white',
    width: '100%',
    padding: '25px 0px',
  },
  timer: {
    background: '#fd980f',
    padding: 1,
    paddingRight: 4,
    paddingLeft: 4,
    borderRadius: 5,
    color: 'white',
  },
  buttonsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 5,
    width: '100%',
    paddingRight: 30,
  },
  paymentDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    marginTop: 10,
  },
  discountCodeInput: {
    border: 'none',
    paddingTop: 5,
    paddingBottom: 5,
    height: 32,
  },

  // also usable by CHANGE CAR form in iMonthly section of My Account

  termsConditionsBox: {
    background: 'white',
    padding: 5,
    height: 100,
    overflowY: 'scroll',
    border: '1px solid #ccc',
    color: 'black',
    fontSize: '0.75rem',
  },
};

export default cartStyle;
