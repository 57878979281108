import styled, { keyframes } from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import backgroundimage from '../../../media/images/CUSTOMERSERVICE.jpg';
import imgback from '../../../media/images/news_bg.png';

const ContentBarContainer = styled.div`
  display: flex;
  flex: 1;
  background: rgba(253, 152, 15, 0.93);
  padding: 15px;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 25px;

  @media (max-width: 850px) {
    flex-direction: column;
    background: #fd980f;
    padding: 0px;
  }
`;

const ContentBarWidth = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 850px) {
    flex-direction: column;
    padding-left: 0px;
    padding-bottom: 20px;
    padding-right: 0px;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;

  @media (max-width: 850px) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 5px;
  }
`;

const LeftDivTitle = styled.span`
  color: #fff;
  font-size: 24px;
`;

const LeftDivText = styled.span`
  color: #fff;
  font-size: 14px;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
  border-left: 1px solid #fff;
  -webkit-flex: 1;
  flex: 1;

  @media (max-width: 850px) {
    border-top: 1px solid #b9710f;
    border-left: none;
    padding-left: 15px;
    padding-bottom: 5px;
  }
`;

const RightDivRow = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const RightDivRowTextDiv = styled.div`
  display: flex;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 0px;
  }
`;

const RightDivRowText = styled.span`
  color: #fff;
  font-size: 14px;
`;

const RightDivRowA = styled.a`
  color: #fff;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
`;

const RightDivFlex = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding-left: 20px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 5px;
  }
`;

const OfficeHourDiv = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 5px;
  }
`;

const CustomerServiceContainer = styled.div`
  flex: 1;
`;

const TitleBarBackground = styled.div`
  display: flex;
  padding: 21px;
  justify-content: center;
  background-image: url(${imgback});
  background-size: cover;
  background-position: center;

  @media (max-width: 850px) {
    padding-left: 13px;
    padding-top: 13px;
    padding-bottom: 8px;
    padding-right: 5px;
  }
`;

const TitleWidth = styled.div`
  width: 1000px;
`;

const Title = styled.span`
  color: #fff;
  font-size: 2rem;
`;

const Background = styled.div`
  display: flex;
  background-image: url(${backgroundimage});
  background-size: cover;
  background-position: center bottom;
  padding-bottom: 30px;
  flex-direction: column;
`;

const ArrowDiv = styled.div`
  background: transparent;
  justify-content: center;
  display: flex;
  position: relative;
`;

const Arrow = styled.div`
  border-top-width: 35px;
  width: 0px;
  height: 0px;
  border-left-width: 45px;
  border-right-width: 45px;
  border-bottom-width: 0px;
  border-style: solid;
  background: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  color: rgba(253, 152, 15, 0.93);

  @media (max-width: 850px) {
    border-top-width: 30px;
    border-left-width: 40px;
    border-right-width: 40px;
    color: #fd980f;
  }
`;

const FormBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @media (max-width: 850px) {
    margin-top: 0px;
  }
`;

const MainWidth = styled.div`
  background: #fff;
  padding: 5px 0px 0px 0px;
  width: 1024px;
  box-shadow: 0 0 2px #bbb;
`;

const TitleDiv = styled.div`
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 15px;

  @media (max-width: 850px) {
    padding-top: 23px;
  }
`;

const TitleRow = styled.div`
  padding-top: 10px;
  padding-bottom: 9px;
`;

const LevMsgRow = styled.div``;

const LeaveMsg = styled.span`
  font-size: 14px;
  color: #666;
`;

const SubTitle = styled.span`
  color: #fd980f;
  font-weight: bold;
  font-size: 18px;
`;

const ItemRow = styled.div`
  flex: 0 0 50%;
  max-width: ${(props) => (props.Bottom ? '100%' : '50%')};
  display: flex;
  padding-top: 7px;
  padding-bottom: 1px;

  @media (max-width: 850px) {
    max-width: 100%;
    padding-top: 2px;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 6px;
  color: #666;
  height: 32px;
  font-size: 0.8125rem;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
`;

const ItemDiv = styled.div`
  padding-bottom: 10px;
  -webkit-flex: 1;
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
`;

const InputDiv = styled.div`
  flex: ${(props) => (props.Bottom ? '5.91' : '2.3')};
  padding: 3px 15px 0px 5px;
  @media (max-width: 850px) {
    flex: ${(props) => (props.Bottom ? '2.3' : '2.3')};
    flex: ${(props) => (props.Bottom ? '2.04' : '2.04')};
  }
`;

const ItemFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.Bottom ? 'column' : 'row')};
  flex-wrap: wrap;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  font-size: 13px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666666')};
  font-weight: bold;
  margin-bottom: 15px;
`;

const DividerDiv = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  background: #8888;
  width: 97%;
`;

const SubTitleDiv = styled.div`
  padding: 25px 15px 10px 15px;

  @media (max-width: 850px) {
    padding: 20px 15px 10px 15px;
  }
`;

const CheckBoxDiv = styled.div`
  display: flex;
  margin-top: 12px;
  padding-left: 16px;
  align-items: flex-start;
`;

const CheckBoxText = styled.span`
  font-size: 13px;
  margin-left: 10px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};
`;

const PolicyA = styled.a`
  color: #fd980f;
  font-size: 14px;
  &:hover {
    color: #fd7e0f;
  }
`;

const Flicker = keyframes`
  25% {  opacity: 0; } 
  50% { opacity: 1; } 
  75% { opacity: 0; } 
  100% { opacity: 1;}
`;

const RechapchaDiv = styled.div`
  margin-top: 17px;
  margin-left: 15px;
  width: 304px;
  height: 78px;
  border-radius: 5px;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
  animation: ${Flicker} ${({ error }) => (error ? '1s forwards' : 'null')};
  animation-iteration-count: 2;
`;

const ButtonDiv = styled.div`
  display: flex;
  padding: 20px 15px 10px 15px;
  justify-content: flex-end;
`;

const ButtonFlex = styled.div`
  display: flex;

  @media (max-width: 550px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Button = styled.button`
  background: ${(props) => (props.Reset ? '#888' : '#FD980F')};
  color: #fff;
  border-width: 0px;
  font-size: 0.8125rem;
  height: 32px;
  width: 130px;
  padding: 6px;

  &:hover {
    background: #6c6c6c;
  }
`;

const ContentInput = styled(TextareaAutosize)`
  width: 100%;
  border-color: ${({ error }) => (error ? '#E56E5A' : '#8888')};
  padding-left: 5px;
  color: #666;
  outline: none;
  font-size: 0.8125rem;
  height: 110px;
`;

const ErrorMsg = styled.span`
  color: #e56e5a;
  font-size: 13px;
`;

export {
  ContentBarContainer,
  ContentBarWidth,
  LeftDiv,
  LeftDivTitle,
  LeftDivText,
  RightDiv,
  RightDivRow,
  RightDivRowTextDiv,
  RightDivRowText,
  OfficeHourDiv,
  RightDivFlex,
  RightDivRowA,
  CustomerServiceContainer,
  TitleBarBackground,
  TitleWidth,
  Title,
  Background,
  ArrowDiv,
  Arrow,
  FormBoxContainer,
  MainWidth,
  TitleDiv,
  TitleRow,
  LeaveMsg,
  LevMsgRow,
  SubTitle,
  ItemFlex,
  ItemRow,
  ItemDiv,
  Item,
  InputDiv,
  TextInput,
  ErrorMsg,
  DividerDiv,
  Divider,
  SubTitleDiv,
  ContentInput,
  CheckBoxDiv,
  CheckBoxText,
  PolicyA,
  RechapchaDiv,
  ButtonDiv,
  ButtonFlex,
  Button,
};
