import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useParams } from 'react-router-dom';
import qs from 'query-string';
import { useEmailVerify } from '../../../models/Customer';

const Container = styled.div`
  background: #dedede;
  height: 100%;
  flex: 1;
`;

const TitleRow = styled.div`
  padding: 5px 0;

  @media (max-width: 850px) {
    padding: 11px 0 5px 0;
  }
`;

const TitleInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const TitleDiv = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

const TitleBlock = styled.div`
  flex-grow: 1;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: -3px;
`;

const Title = styled.span`
  color: #444;
  font-size: 2rem;
  font-weight: 400;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 850px) {
    line-height: 1.4;
  }
`;

const FormWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const FormInner = styled.div`
  font-size: 0.875rem;
  display: block;

  @media (max-width: 850px) {
    margin-top: -15px;
  }
`;

const FormDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

const FormBlock = styled.div``;

const TextRow = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 850px) {
    margin-top: 19px;
  }
`;

const TextPadding = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const ButtonRow = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 10px 15px;
  margin-top: 25px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const ButtonDiv = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
`;

const ActivatingAccount = () => {
  const { ActiveAccountAPI } = useEmailVerify();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      ActiveAccountAPI(token);
    }
  }, []);

  return (
    <Container>
      <TitleRow>
        <TitleInner>
          <TitleDiv>
            <TitleBlock />
          </TitleDiv>
        </TitleInner>
      </TitleRow>
      <FormWidth>
        <FormInner>
          <FormBlock>
            <FormDiv>
              <TextRow>
                <TextPadding />
              </TextRow>
              <TextRow>
                <ButtonRow>
                  <ButtonDiv />
                </ButtonRow>
              </TextRow>
            </FormDiv>
          </FormBlock>
        </FormInner>
      </FormWidth>
    </Container>
  );
};

export default withRouter(ActivatingAccount);
