import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(235, 235, 235);
`;

const Inner = styled.div`
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  max-width: 1024px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-top: 5vh;
  height: 70%;
`;

const Column = styled.div`
  padding: 10px 30px 20px 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 20px;
  width: 80%;
  height: auto;
`;

const Text = styled.p`
  font-size: ${({ fontsize }) => fontsize};
  color: rgb(50, 50, 50);
  margin-top: ${({ margintop }) => margintop};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  text-align: center;
`;

const UploadButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

const UploadView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { Container, Inner, Column, Text, UploadButton, UploadView };
