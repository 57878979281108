import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm, FormContext, Controller } from 'react-hook-form';
import { Checkbox } from 'pretty-checkbox-react';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import PaymentMethodSelector from '../PaymentMethodSelector';
import { useFirstDataToken } from '../../../../models/Customer';
import {
  useOnlinePaymentMethods,
  useOfflinePaymentMethods,
} from '../../../../models/SelectOptions';
import { CheckBoxMargin, TextField, StyledDatePicker } from '../../../../components/FormComponents';
import { RadioGroup, RadioButton } from '../../../../components/Cart';
import ConfirmModal from '../../../../components/General/ConfirmModal';
import StyledButton from '../../../../components/General/StyledButton';
import PopUpModal from '../../../../components/General/PopUpModal';
import StyledLink from '../../../../components/General/StyledLink';
import { useAssociations } from '../../../../models/Cart';
import { handelCheckLPN } from '../../../../helpers/generalHelper';
import { useBooking } from '../../../../models/Booking';

const VRHRow = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const VRGInnerRow = styled.div`
  flex: 1 1 0%;
  display: flex;
  width: 100%;
  align-items: center;
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const SelectLabel = styled.span`
  font-weight: bold;
  flex: 1;
  font-size: 14px;
  color: rgb(102, 102, 102);
  @media (max-width: 670px) {
    flex: 1.5;
  }
`;

const DickPickLabel = styled.span`
  font-weight: bold;
  flex: 0.42;
  font-size: 14px;
  color: rgb(102, 102, 102);
  @media (max-width: 670px) {
    /* flex: 0.64; */
    flex: 1;
  }
`;

const Label = styled.span`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  margin-top: ${(props) => props.marginTop};
`;

const TermsConditionsBox = styled.div`
  background: white;
  padding: 5px;
  height: 100px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  color: black;
  font-size: 0.75rem;
`;

export const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Label fontSize="0.9rem" marginTop="20px">
        {t('Cart.TnC.Title')}
      </Label>
      <TermsConditionsBox dangerouslySetInnerHTML={{ __html: t('Cart.TnC.Content') }}>
        {/* <p style={{ color: 'black', fontSize: '0.75rem' }}>{t('Cart.TnC.Content')}</p> */}
      </TermsConditionsBox>
    </>
  );
};

export default function ChangeCar({
  show,
  onHide,
  changeLPN,
  onSelectPaymentMethod,
  onSelectToken,
  removeAll,
  handleCheckout,
  loading,
  booking,
  bookingLoading,
  serviceId,
  book,
}) {
  const { firstDataTokens: creditCards } = useFirstDataToken();
  const { onlinePaymentIds } = useOnlinePaymentMethods();
  const { offlinePaymentIds, allPaymentMethods } = useOfflinePaymentMethods();
  const { getPaymentData, availablePaymentMethods, paymentMethodsLoading } = useAssociations();
  const { t, i18n } = useTranslation();
  const { checkLPNChangeable } = useBooking();

  const defaultValues = {
    IsAgreeTermsConditions: false,
    PaymentMethodRadio: 2,
    FirstDataTokenId: null,
    ChangeType: 'temporary',
  };
  const methods = useForm({ defaultValues });
  const watchPaymentMethod = methods.watch('PaymentMethodRadio');
  const watchFirstDataToken = methods.watch('FirstDataTokenId');
  const watchChangeType = methods.watch('ChangeType');

  const [step, setStep] = useState(1);
  const [inputs, setInputs] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [VRHError, setVRHError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [startDate, setStartDate] = useState();
  const [LPNChaneable, setLPNChaneable] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!booking.id) return;
    getPaymentData(booking.CarParkId ? [booking.CarParkId] : []);
    setIsLoading(true);
    checkLPNChangeable(booking.id)
      .then((e) => {
        setLPNChaneable(e);
        // monthly present case
        if (e.monthlyVehiclePresent) {
          Swal.fire({
            position: 'center',
            text: t('MyAccount.iMonthly.vehicleIsPresentError'),
            confirmButtonColor: '#fd980f',
            confirmButtonText: t('MyAccount.iMonthly.confirm'),
            allowOutsideClick: false,
          }).then(() => {
            onHide();
          });
        }
      })
      .catch(() => {
        setLPNChaneable({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [booking]);

  const handleCancel = () => {
    if (step === 1) {
      onHide();
    }
    if (step === 2) {
      removeAll();
      setStep(1);
    }
  };

  const handleToStep2 = async () => {
    setInputs({
      ...inputs,
      ...methods.getValues(),
    });

    const step1InputValues = Object.values(methods.getValues());
    if (step1InputValues.includes(undefined)) {
      Swal.fire({
        position: 'center',
        text: t('MyAccount.fillError'),
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('MyAccount.iMonthly.confirm'),
      });
      return;
    }

    if (handelCheckLPN(methods.getValues('newRegistrationMark'))) {
      setVRHError(false);
    } else {
      setVRHError(true);
      return;
    }

    if (methods.getValues('ChangeType') === 'permanent') {
      const response = await changeLPN({
        id: serviceId,
        registrationMark: methods.getValues('newRegistrationMark'),
        type: methods.getValues('ChangeType'),
        startDate,
        bookingID: booking.id,
      });

      if (!response) {
        return;
      }

      book(response);
      setStep(2);
    } else {
      setShowConfirmModal(true);
    }
  };

  useEffect(() => {
    onSelectPaymentMethod(parseInt(watchPaymentMethod, 10));
  }, [watchPaymentMethod]);

  useEffect(() => {
    onSelectToken(watchFirstDataToken);
  }, [watchFirstDataToken]);

  useEffect(() => {
    if (show === true) {
      setStep(1);
    }
  }, [show]);

  useEffect(() => {
    if (creditCards.length === 0) {
      methods.setValue('FirstDataTokenId', 'new');
    } else {
      methods.setValue('FirstDataTokenId', creditCards[0].id.toString());
    }
  }, [creditCards, step]);

  const renderStep1 = () => {
    return (
      <div
        style={{
          height: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              margin: 'auto',
              height: 'fit-content',
              width: 'fit-content',
            }}
          >
            <ReactLoading type="spokes" color="#fd980f" height={30} width={30} />
          </div>
        )}

        <ol style={{ marginTop: 10, marginLeft: 20, marginRight: 20, color: '#666' }}>
          <h3>{t('Cart.IMonthly.step1Title')}</h3>
          <li>{t('Cart.IMonthly.step1Text1')}</li>
          <li>{t('Cart.IMonthly.step1Text2')}</li>
          <li>{t('Cart.IMonthly.step1Text3')}</li>
        </ol>

        <div style={{ background: '#f3f3f3', fontSize: '0.9', padding: 20 }}>
          <SelectRow>
            <span style={{ fontWeight: 'bold', flex: 0.33, fontSize: 13, color: '#666' }}>
              {t('Cart.IMonthly.stlectType')}
            </span>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <RadioGroup name="ChangeType">
                <RadioButton
                  value="temporary"
                  name="ChangeType"
                  checked={methods.getValues('ChangeType') === 'temporary'}
                  disabled={!LPNChaneable.isTempChangeable}
                >
                  <span style={{ fontSize: 14, color: '#666' }}>
                    {t('Cart.IMonthly.temporary')}
                  </span>
                </RadioButton>
                <RadioButton
                  value="permanent"
                  name="ChangeType"
                  checked={methods.getValues('ChangeType') === 'permanent'}
                  disabled={!LPNChaneable.isPermanentChangeable}
                >
                  <span style={{ fontSize: 14, color: '#666' }}>
                    {t('Cart.IMonthly.permanent')}
                  </span>
                </RadioButton>
              </RadioGroup>
            </div>
          </SelectRow>

          {!isLoading && !LPNChaneable.isTempChangeable && !LPNChaneable.isPermanentChangeable && (
            <span style={{ color: 'red' }}>{t('MyAccount.changeMoreThanOne')}</span>
          )}

          <VRHRow>
            <VRGInnerRow>
              <SelectLabel>{t('Cart.IMonthly.VRH')}</SelectLabel>
              <span style={{ flex: 1, color: '#666' }}>{booking.registrationMark}</span>
            </VRGInnerRow>
            <VRGInnerRow>
              <SelectLabel>{t('Cart.IMonthly.newVRH')}</SelectLabel>
              <TextField
                name="newRegistrationMark"
                style={{ paddingLeft: 0, maxWidth: '40%', flex: 1 }}
                VRHError={VRHError}
              />
            </VRGInnerRow>
          </VRHRow>

          <div style={{ display: 'flex' }}>
            <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
              <DickPickLabel>{t('Cart.IMonthly.startDate')}</DickPickLabel>
              <StyledDatePicker
                setStartDate={setStartDate}
                period={{ from: booking.validFrom, to: booking.validTo }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            background: '#f3f3f3',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 15,
            paddingTop: 0,
          }}
        >
          <StyledLink
            type="button"
            label={t('IParkVerifySteps.back')}
            color="grey"
            onClick={handleCancel}
          />
          <StyledButton
            disabled={
              (!LPNChaneable.isTempChangeable && !LPNChaneable.isPermanentChangeable) ||
              (watchChangeType === 'temporary' && !LPNChaneable.isTempChangeable) ||
              (watchChangeType === 'permanent' && !LPNChaneable.isPermanentChangeable) ||
              LPNChaneable?.vehicleIsPresent
            }
            type="button"
            label={t('IParkVerifySteps.next')}
            color="yellow"
            onClick={handleToStep2}
          />
        </div>
      </div>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <div style={{ margin: 20, marginTop: 10 }}>
          <h5 style={{ fontSize: '1rem', fontWeight: 'bold' }}>{t('Cart.IMonthly.step2Title')}</h5>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 20,
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            <span style={{ flex: 1 }}>{t('Cart.IMonthly.originalVRH')}</span>
            <span style={{ flex: 1 }}>{t('Cart.IMonthly.new')}</span>
            <span style={{ flex: 1 }}>{t('Cart.IMonthly.fee')}</span>
          </div>
          <hr style={{ marginTop: 10, marginBottom: 10 }} />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <span style={{ flex: 1 }}>{booking.registrationMark}</span>
            <span style={{ flex: 1 }}>{inputs.newRegistrationMark}</span>
            <span style={{ flex: 1 }}>{inputs.ChangeType === 'temporary' ? 'HK$0' : 'HK$50'}</span>
          </div>
        </div>

        <div style={{ background: '#f3f3f3', fontSize: '0.9', padding: 20 }}>
          <PaymentMethodSelector
            activePaymentMethod={watchPaymentMethod}
            creditCards={creditCards}
            onlinePaymentIds={onlinePaymentIds}
            offlinePaymentIds={offlinePaymentIds}
            setFirstDataTokenId={(id) => methods.setValue('FirstDataTokenId', id)}
            FirstDataTokenId={methods.getValues('FirstDataTokenId')}
            availablePaymentMethods={availablePaymentMethods}
            paymentMethodsLoading={paymentMethodsLoading}
            allPaymentMethods={allPaymentMethods}
            selectedValue={methods.getValues('PaymentMethodRadio')}
          />
          <br />

          <TermsConditions />
          <CheckBoxMargin>
            <div>
              <Controller
                as={<Checkbox />}
                name="termsCondition"
                control={methods.control}
                shape="curve"
                color="warning-o"
                animation="smooth"
                defaultValue={false}
              />
              <span style={{ color: isError ? '#E56E5A' : '#666', fontSize: 13 }}>
                {t('IhourlyCart.isAgree')}
              </span>
            </div>
          </CheckBoxMargin>
        </div>

        <div
          style={{
            background: '#f3f3f3',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 15,
            paddingTop: 0,
          }}
        >
          <StyledLink
            type="button"
            label={t('IParkVerifySteps.back')}
            color="grey"
            onClick={handleCancel}
          />

          <StyledButton
            type="button"
            label={t('IParkVerifySteps.next')}
            color="yellow"
            onClick={() => {
              if (!methods.getValues('termsCondition')) {
                setIsError(true);
                return;
              }
              setIsError(false);
              handleCheckout();
            }}
            to={{
              state: { action: 'ChangeRegMark' },
            }}
          >
            {loading && <ReactLoading type="spin" color="#888" height={30} width={30} />}
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <PopUpModal Popupshow={show} onClose={onHide}>
      <FormContext {...methods}>{step === 1 ? renderStep1() : renderStep2()}</FormContext>
      <ConfirmModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        next={() => {
          const {
            type = 'temporary',
            newRegistrationMark,
            registrationMark,
            ReactDatePicker,
            id,
            CarId,
          } = { ...methods.watch(), ...booking };

          changeLPN({
            type,
            newRegistrationMark,
            registrationMark,
            startDate: ReactDatePicker,
            bookingID: id,
            CarId,
          });
        }}
        isLoading={bookingLoading}
      />
    </PopUpModal>
  );
}
