import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  display: flex;
`;

const Message = styled.span`
  font-size: 12px;
  color: red;
  font-weight: normal;
`;

function FieldError({ message }) {
  return (
    <Container>
      <Message>{message}</Message>
    </Container>
  );
}

export default FieldError;
