import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getOrderDetails } from '../../helpers/api/cart';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media (max-width: 850px) {
    padding: 40px 20px;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 40px 20px 40px 20px;
  background-color: #fff;
  max-width: 1024px;

  p {
    color: #f1a846;
    font-size: 2.5rem;

    @media (max-width: 670px) {
      font-size: 1.5rem;
    }
  }

  span {
    font-size: 15px;
    color: #888;
    margin-top: 20px;
  }
`;

function Step3({ orderId, clearCart }) {
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    getOrderDetails(orderId)
      .then((response) => {
        setOrderDetails(response.data);
        clearCart();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <MasterCardPayment orderDetails={orderDetails} />
    </Container>
  );
}

export default Step3;

const MasterCardPayment = ({ orderDetails }) => {
  const { t } = useTranslation();
  return (
    <Inner>
      <p>
        {t('IhourlyCart.SuccessNumber')}
        {'   '}
        {orderDetails.reference}
      </p>
      <span>{t('IhourlyCart.sevenSuccess')}</span>
    </Inner>
  );
};

// const PpsPayment = () => (
//   <div
//     style={{
//       ...commonStyle.contentContainer,
//       width: '100%',
//       maxWidth: '100%',
//       background: '#fff',
//       paddingTop: 30,
//       paddingBottom: 30,
//     }}
//   >
//     <p>Pay by PPS status: Successful</p>
//   </div>
// );

// const FpsPayment = () => (
//   <div
//     style={{
//       ...commonStyle.contentContainer,
//       width: '100%',
//       maxWidth: '100%',
//       background: '#fff',
//       paddingTop: 30,
//       paddingBottom: 30,
//     }}
//   >
//     <h5>Booking Successful</h5>

//     <h5>Your reference number is: 1534347</h5>

//     <img style={{ marginBottom: 20, marginTop: 30 }} src={qrCode} width="15%" alt="qr_code" />
//   </div>
// );
