import React from 'react';
import PromoCarousel from './PromoCarousel';
import SearchBar from './SearchBar';

function PromotionBar() {
  return (
    <div style={{ position: 'relative' }}>
      <PromoCarousel />
      <SearchBar />
    </div>
  );
}

export default PromotionBar;
