import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RadioGroup, RadioButton } from '../../../components/Cart';

const CreditCardLabel = styled.span`
  margin-left: 10px;
  font-size: 0.8rem;
`;

export default function CardSelector({
  activePaymentMethod,
  creditCards,
  visaId,
  setFirstDataTokenId,
  FirstDataTokenId,
}) {
  const { t } = useTranslation();
  const T = (input) => t(`Cart.PaymentMethod.${input}`);
  return (
    <>
      {creditCards && creditCards.length > 0 && (
        <div hidden={parseInt(activePaymentMethod, 10) !== visaId} className="ml-3 mb-1">
          <CreditCardLabel>{T('SavedCard')}</CreditCardLabel>
          <RadioGroup
            controllerName="FirstDataTokenId"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {creditCards.map((creditCard) => {
              const { id, token } = creditCard;
              const cardType = token.substring(0, 1) === 4 ? 'visa' : 'master';

              return (
                <RadioButton
                  key={`FDT${id}`}
                  value={id.toString()}
                  name="FirstDataTokenId"
                  style={{ marginLeft: 25, marginTop: 5 }}
                  onClick={() => setFirstDataTokenId(id.toString())}
                  checked={FirstDataTokenId === id.toString()}
                >
                  <img
                    src={`https://www.wilsonparking.com.hk/img/logo-flat-${cardType}.png`}
                    alt={cardType}
                  />
                  <CreditCardLabel>
                    &bull;&bull;&bull;&bull;&nbsp;&nbsp;
                    {token.slice(-4)}
                  </CreditCardLabel>
                </RadioButton>
              );
            })}
            <RadioButton
              value="new"
              name="FirstDataTokenId"
              onClick={() => setFirstDataTokenId('new')}
              checked={FirstDataTokenId === 'new'}
            >
              <span>{T('NewCard')}</span>
            </RadioButton>
          </RadioGroup>
        </div>
      )}
    </>
  );
}
