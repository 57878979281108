import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams } from 'react-router-dom';
import { range } from 'lodash';
import AliceCarousel from 'react-alice-carousel';
import { Container, Inner } from './containers/style';
import 'react-alice-carousel/lib/alice-carousel.css';
import ChequePaymentDetail from './containers/ChequePaymentDetail';
import { useBooking } from '../../models/Booking';
import ButtonRow from './containers/ButtonRow';
import { useGetCQMBillNumber } from '../../models/Customer';
import './containers/sweetalert.css';

function ChequePayment() {
  const { orderId } = useParams();

  const { t, i18n } = useTranslation();

  const { GetOneBooking, OrderDetail } = useBooking();

  const [receipts, setReceipts] = useState([]);
  // const accountCode = useMemo(() => bookingDetail?.accountCode || '', [bookingDetail?.accountCode]);

  const { accountCode } = OrderDetail || {};

  const items = range(1);

  useEffect(() => {
    if (orderId) {
      GetOneBooking(orderId, t('chequePayment.alertText1'));
    }
  }, [orderId, i18n.language]);

  return (
    <Container>
      <div style={{ backgroundColor: '#fd980f', height: 250, width: '100%' }} />
      {accountCode && (
        <Inner items={items.length}>
          <AliceCarousel
            mouseTracking={items.length > 1}
            disableDotsControls
            disableButtonsControls
            items={items.map((e, index) => {
              return (
                <ChequePaymentDetail
                  detail={e}
                  key={e}
                  OrderDetail={OrderDetail}
                  index={index}
                  setReceipts={setReceipts}
                  receipts={receipts}
                  billNumber={accountCode || ''}
                  orderId={orderId}
                />
              );
            })}
          />
          <ButtonRow />
        </Inner>
      )}
    </Container>
  );
}

export default withRouter(ChequePayment);
