import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelectOptions } from '../../../models/User';
import Selector from './Selector';
import { HomeselectStyles } from '../../../components/General/SelectorStyle';
import { GooglePlaceAutoComplete } from '../../CarPark/Sidebar';

const Leftarrow = styled.div`
  border-top-width: 0;
  height: 0;
  border-left-width: 0;
  border-right-width: 45px;
  border-bottom-width: 30px;
  border-style: solid;
  background: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  color: rgba(189, 109, 0, 0.8);
  width: 50%;
  position: absolute;
  bottom: 113px;
  left: 0;

  @media (max-width: 950px) {
    display: none;
  }
`;

const Rightarrow = styled.div`
  border-top-width: 0;
  height: 0;
  border-left-width: 45px;
  border-right-width: 0px;
  border-bottom-width: 30px;
  border-style: solid;
  background: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  color: rgba(189, 109, 0, 0.8);
  width: 50%;
  position: absolute;
  bottom: 113px;
  right: 0;

  @media (max-width: 950px) {
    display: none;
  }
`;

const Container = styled.div`
  background: rgba(189, 109, 0, 0.8);
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;

  @media (max-width: 950px) {
    position: relative;
    height: 100%;
    background: #fd980f;
  }
`;

const Width = styled.div`
  width: 1024px;
  display: flex;
  padding: 23px 5px 32px 5px;

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 0px 0px 20px 0px;
  }
`;

const TitleContainer = styled.div`
  -webkit-flex: 1;
  flex: 1;
  display: block;
  //justify-content: center;
  flex-direction: column;
  margin-top: -40px;
  margin-right: ${(props) => props.left && '45px'};
  margin-left: ${(props) => props.right && '45px'};
  @media (max-width: 950px) {
    margin-top: 20px;
    margin-right: ${(props) => (props.left ? '10px' : '10px')};
    margin-left: ${(props) => (props.right ? '10px' : '10px')};
  }
`;

const Title = styled.span`
  color: #fff;
  font-size: 24px;
  text-align: center;
  @media (max-width: 950px) {
    font-size: 16px;
  }
`;

const Divider = styled.div`
  background: #d2d2d2;
  width: 1px;
  height: 54px;
  align-self: center;
  opacity: 0.7;
  @media (max-width: 950px) {
    width: 100%;
    height: 1px;
    margin-top: 20px;
  }
`;

const SelectorRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 550px) {
    flex-direction: column;
    margin-top: 28px;
  }
`;

const RegionSelectorDiv = styled.div`
  -webkit-flex: 1;
  flex: 1;
  margin-top: 9px;
`;

const DistrictSelectorDiv = styled.div`
  margin-left: 7px;
  -webkit-flex: 1;
  margin-top: 9px;
  flex: 1;
  z-index: 1;
  @media (max-width: 950px) {
    margin-left: 7px;
  }
  @media (max-width: 550px) {
    margin-top: 10px;
    margin-left: 0px;
  }
`;

const LeftTitleRow = styled.div`
  align-self: center;
  @media (max-width: 550px) {
    //margin-top: -66px;
  }
`;

const RightTitleRow = styled.div`
  align-self: center;
  @media (max-width: 650px) {
    //margin-bottom: 0px;
  }
`;

function SearchBar() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 100);
  }, [i18n.language]);

  const {
    setSelectedRegion,
    setSelectedDistrict,
    districtOptions,
    regionOptions,
    selectedRegion,
    selectedDistrict,
  } = useSelectOptions('getCarParksInDistrict');

  useEffect(() => {
    if (selectedDistrict) {
      history.push({
        pathname: `/${i18n.language}/car_parks/map`,
        state: { selectedDistrict },
      });
    }
  }, [selectedDistrict]);

  return (
    <div style={{ flex: 1 }}>
      <Leftarrow />
      <Rightarrow />
      <Container>
        <Width>
          <TitleContainer left>
            <LeftTitleRow>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Title>{t('Home.searchbyregion')}</Title>
              </div>
            </LeftTitleRow>
            <SelectorRow>
              <RegionSelectorDiv>
                <Selector
                  options={regionOptions}
                  setSelected={setSelectedRegion}
                  placeholder={t('Home.RegionSelector')}
                  styles={HomeselectStyles}
                />
              </RegionSelectorDiv>

              <DistrictSelectorDiv>
                <Selector
                  options={districtOptions}
                  setSelected={setSelectedDistrict}
                  placeholder={t('Home.DistrictSelector')}
                  isDisabled={!selectedRegion}
                  styles={HomeselectStyles}
                />
              </DistrictSelectorDiv>
            </SelectorRow>
          </TitleContainer>

          <Divider />

          <TitleContainer right>
            <RightTitleRow>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 8 }}>
                <Title>{t('Home.searchbyaddress')}</Title>
              </div>
            </RightTitleRow>
            <SelectorRow>{!reload && <GooglePlaceAutoComplete HomeSearch />}</SelectorRow>
          </TitleContainer>
        </Width>
      </Container>
    </div>
  );
}

export default SearchBar;
