import styled from 'styled-components';

// TODO: responsive?

const MobileNumberContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.forgotpassword ? 'row' : 'column')};
  -webkit-flex: 1;
flex: 1;
  margin-left: ${(props) => (props.forgotpassword ? '0px' : '20px')};
  margin-bottom: ${(props) => (props.forgotpassword ? '20px' : '0px')};

  @media (max-width: 850px) {
    flex-direction: ${(props) => (props.forgotpassword ? 'column' : 'column')};
    margin-left: 0px;
  }
`;

export default MobileNumberContainer;
