import styled from 'styled-components';

const FieldInner = styled.div`
  display: flex;
  -webkit-flex: 1;
flex: 1;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
  margin-top: ${(props) => (props.top ? '3px' : '17px')};

  span {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export default FieldInner;
