const commonStyle = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dedede',
    padding: 40,
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    maxWidth: 1024,
  },
  pageTitle: {
    color: '#444',
    fontSize: '2rem',
    fontWeight: '600',
    marginBottom: 40,
    alignSelf: 'flex-start',
  },
  orangeButton: {
    background: '#fd980f',
    color: 'white',
    border: 'none',
    fontSize: '0.75rem',
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 5,
    paddingBottom: 5,
    outline: 'none',
  },
  borderedButton: {
    background: '#fff',
    border: '1px solid #fd980f',
    fontSize: '0.75rem',
    color: '#fd980f',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    outline: 'none',
  },
  greyButton: {
    background: '#888',
    color: 'white',
    border: 'none',
    fontSize: '0.75rem',
    padding: '7px 35px',
    outline: 'none',
  },
  lightGreyButton: {
    background: '#BBB',
    color: 'white',
    border: 'none',
    fontSize: '0.75rem',
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 5,
    paddingBottom: 5,
    outline: 'none',
  },
  verticalLine: {
    width: 1,
    backgroundColor: '#fff',
    marginLeft: 5,
    marginRight: 10,
  },
};

export default commonStyle;
