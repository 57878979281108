import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router-dom';
import {
  Container,
  TitleRow,
  TitleInner,
  TitleDiv,
  TitleBlock,
  Text,
  FormWidth,
  FormInner,
  FormBlock,
  FormDiv,
  TextRow,
  ButtonRow,
  ButtonDiv,
  Submit,
} from './styles';
import ErrorImg from '../../../media/images/errorIcon.png';

const DeleteAccountFailed = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <Container className="d-block ml-auto mr-auto mt-5">
      <TitleRow>
        <TitleInner>
          <TitleDiv>
            <TitleBlock style={{ display: 'flex' }}>
              <img src={ErrorImg} style={{ width: 70, height: 70 }} />
              <Text>{location?.state}</Text>
            </TitleBlock>
          </TitleDiv>
        </TitleInner>
      </TitleRow>
      <FormWidth>
        <FormInner>
          <FormBlock>
            <FormDiv>
              <TextRow>
                <ButtonRow>
                  <ButtonDiv>
                    <Submit href={`/${i18n.language}`}>{t('IhourlyCart.Back')}</Submit>
                  </ButtonDiv>
                </ButtonRow>
              </TextRow>
            </FormDiv>
          </FormBlock>
        </FormInner>
      </FormWidth>
    </Container>
  );
};

export default withRouter(DeleteAccountFailed);
