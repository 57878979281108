import React from 'react';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';
import DeskTopNews from './DeskTopNews';
import MobileNews from './MobileNews';

const HomeNews = () => {
  const { outerWidth } = ScreenWidthListener();

  return (
    <div>
      {outerWidth > 850 && <DeskTopNews />}
      {outerWidth <= 850 && <MobileNews />}
    </div>
  );
};

export default HomeNews;
