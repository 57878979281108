import styled from 'styled-components';

const FormInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  div {
    -webkit-flex: 1;
flex: 1;
  }

  span {
    color: #fff;
    font-size: 0.725rem;
    margin-top: 5px;
  }

  input {
    width: 60%;
    height: 32px;
  }

  button {
    height: 32px;
    background: #fd980f;
    border: 0;
    font-size: 0.8125rem;
    color: #fff;
    width: 60%;
    margin-top: 20px;
    right: 100%;

    &:hover {
      background: #666;
    }

    @media (max-width: 850px) {
      width: 100%;
    }
  }
`;

export default FormInner;
