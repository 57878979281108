/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import PopUpModal from '../../../components/General/PopUpModal';
import { useCars } from '../../../models/Customer';
import { useCarType } from '../../../models/SelectOptions';

const selectStyles = () => ({
  singleValue: (provided) => ({ ...provided, color: '#666' }),

  indicatorSeparator: () => {},
  dropdownIndicator: () => ({
    marginRight: 10,
    color: 'orange',
    marginTop: -5,
  }),

  control: (base) => ({
    ...base,
    '&:hover': { borderColor: 'orange' },
    '&:focus': { borderColor: 'orange' },
    boxShadow: 'none',
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 6,
    fontSize: 13,
    ...styles,
    backgroundColor: 'transparent',
    color: state.isSelected ? 'black' : '#fd980f',
    fontWeight: state.isFocused ? 'bold' : '',
    '&:hover': { backgroundColor: '#dedede' },
    textDecoration: 'underline',
  }),

  menu: (provided) => ({
    ...provided,
    borderWidth: 10,
    border: '1px solid orange',
    fontSize: 13,
  }),

  container: (base) => ({
    ...base,
    outline: 'none',
  }),

  valueContainer: (base) => ({
    ...base,
    display: 'flex',
  }),
});

const ModalTitle = styled.span`
  font-weight: bold;
  font-size: 1.7rem;
  color: #444444;
  text-transform: uppercase;
`;

const ModalItemLabel = styled.span`
  width: 50%;
  font-size: 0.8125rem;
  color: #666;
`;

const VRHInput = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: #666;
`;

const BookingButton = styled.button`
  background: #fd980f;
  color: white;
  border: none;
  font-size: 18px;
  outline: none;
  padding: 5px 10px;
  min-height: 45px;
  min-width: 80px;
  margin-top: 20px;
  transition: background 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #666;
  }

  &:disabled {
    background: #bbb;
    cursor: not-allowed;
  }

  & > div {
    margin-left: 10px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    &:focus {
      box-shadow: none;
    }
    min-height: auto;
  }
`;

const SelectButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  width: fit-content;
  align-self: flex-end;
  color: rgb(253, 152, 15);
  text-decoration: underline;
`;

function WaitingListModal({ show, setShow, data = {}, onConfirm, waitingList, title, buttonText }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { options: carOptions, createNewCar } = useCars();
  const { options: carTypeOptions } = useCarType();

  const [isLoading, setIsLoading] = useState(false);
  const [isNewCar, setIsNewCar] = useState(false);
  const [selectedCar, setSelectedCar] = useState();
  const [selectedCarType, setSelectedCarType] = useState();
  const [registrationMark, setRegistrationMark] = useState('');
  const { ParkingPlanId, CarTypeId, CarParkId } = data || {};

  function handleClose() {
    setShow({ show: false, data: {} });
    setIsNewCar(false);
    setSelectedCar();
  }

  function errorHandler(error) {
    Swal.fire({
      title: error,
      icon: 'error',
      confirmButtonText: t('MyAccount.iMonthly.confirm'),
      confirmButtonColor: '#fd980f',
    });
  }

  async function onApply() {
    if (!isNewCar && !selectedCar) {
      return;
    }

    if (isNewCar && (!registrationMark || !selectedCarType)) {
      return;
    }

    if (
      isNewCar &&
      (!registrationMark.match(/^(?:([A-HJ-NPR-Z0-9])(?!\1{4,})){1,8}$/) ||
        registrationMark.match(/\s{2,}/g) ||
        registrationMark.match(/\s$/))
    ) {
      errorHandler(t('Registration.registrationMarkError'));
      return;
    }

    if (isNewCar) {
      try {
        setIsLoading(true);
        const newCar = await createNewCar({
          registrationMark,
          CarTypeId: selectedCarType?.value,
        });
        await onConfirm(newCar.id, ParkingPlanId, handleClose);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      setIsLoading(true);
      await onConfirm(selectedCar.value, ParkingPlanId, handleClose);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <PopUpModal Popupshow={show} onClose={handleClose} center>
      <Col>
        <ModalTitle>{title}</ModalTitle>

        {isNewCar ? (
          <Col>
            <ModalItemLabel>{t('Cart.CarRecord.carType')}</ModalItemLabel>
            <Select
              value={selectedCarType}
              onChange={(e) => setSelectedCarType(e)}
              placeholder=" "
              styles={selectStyles()}
              options={carTypeOptions.filter((e) => {
                return e.value === CarTypeId;
              })}
            />

            <ModalItemLabel>{t('Cart.CarRecord.VRH')}</ModalItemLabel>
            <VRHInput
              value={registrationMark}
              onChange={(e) => {
                const { value } = e.target;
                setRegistrationMark(value.toUpperCase().replace(/\s/g, ''));
              }}
            />
          </Col>
        ) : (
          <Col>
            <Select
              value={selectedCar}
              onChange={(e) => {
                setSelectedCar(e);
              }}
              placeholder=" "
              options={carOptions.filter((e) => {
                const isCarWithinConfirmedWaitingList =
                  waitingList.findIndex(
                    (waitinglist) =>
                      waitinglist.CarId === e.value &&
                      (waitinglist.status === 'confirmed' || waitinglist.status === 'processed') &&
                      waitinglist.ParkingPlanId === ParkingPlanId &&
                      waitinglist.CarParkId === Number(CarParkId)
                  ) !== -1;

                return e.CarTypeId === CarTypeId && !isCarWithinConfirmedWaitingList;
              })}
              styles={selectStyles()}
            />
          </Col>
        )}

        <SelectButton
          disabled={isLoading}
          onClick={() => {
            if (!isNewCar) {
              setSelectedCarType();
              setRegistrationMark('');
            }
            setIsNewCar(!isNewCar);
          }}
          type="button"
        >
          {`${
            isNewCar ? t('MyAccount.WaitingList.selectCar') : t('MyAccount.WaitingList.addNewCar')
          }`}
        </SelectButton>

        <BookingButton onClick={onApply} disabled={isLoading}>
          {buttonText}
          {isLoading && <ReactLoading type="spin" color="#888" width={30} height={30} />}
        </BookingButton>
      </Col>
    </PopUpModal>
  );
}

export default WaitingListModal;
