import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Title, Width, Container } from './style';

const BottomRow = ({ data }) => {
  const { t } = useTranslation();

  const { quality_assurance } = data || {};

  return (
    <>
      <Container className="aboutus3__inner">
        <Width dangerouslySetInnerHTML={{ __html: quality_assurance }}>
          {/* <Title>{t('Our_company.QUALITY_ASSURANCE')}</Title>
          <Text subtitle>
            <em>{t('Our_company.h1')}</em>
          </Text>
          <Text>
            <span>{t('Our_company.h2')}</span>
          </Text>
          <Text>
            <span>{t('Our_company.h3')}</span>
          </Text>
          <Text>
            <span>{t('Our_company.h4')}</span>
          </Text>
          <Text bottom>
            <span>{t('Our_company.h5')}</span>
          </Text> */}
        </Width>
      </Container>
    </>
  );
};

export default BottomRow;
