import React, { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import { Text, UploadButton, UploadView, MatchedDiv } from './style';
import StyledButton from '../../../components/General/StyledButton';
import iconpass from '../../../media/icons/greenChecked.png';
import iconcross from '../../../media/icons/redCross.png';

export default function FileUpload({
  setReceipts,
  receipts,
  index,
  finallyAmount,
  orderId,
  checkOCR,
  OCRResult,
  OCRLoading,
  setCount,
  count,
  limitCounttoOCR,
  type,
}) {
  const { t, i18n } = useTranslation();

  const onChange = (imageList) => {
    checkOCR(imageList[index].file, orderId, finallyAmount, type);
    setReceipts(imageList);
  };

  function RemoveImg() {
    const newArray = receipts;
    receipts.splice(index, 1, undefined);
    setReceipts([...newArray]);
  }

  const {
    accountCode,
    amount,
    date,
    isAccountCodeValid,
    isAmountValid,
    isDateValid,
    status,
    time,
  } = OCRResult || {};

  // useEffect(() => {
  //   if (type === 'cheque') return;
  //   if (Object.keys(OCRResult).length < 1) return;

  //   console.log(OCRResult);

  //   if (status === 'error' || !status) {
  //     setCount((e) => e + 1);
  //   }

  //   if (status === 'error' || !status) {
  //     RemoveImg();
  //   }

  //   if (!accountCode && !amount && !date) {
  //     Swal.fire({
  //       focusConfirm: false,
  //       title: t('sevenPayment.errorMsg'),
  //       position: 'center',
  //       confirmButtonColor: '#fd980f',
  //       confirmButtonText: t('sevenPayment.close'),
  //       customClass: {
  //         title: 'sweetAlretTitle',
  //         popup: 'sweetAlretPopup',
  //         header: 'sweetAlretHeader',
  //         actions: 'sweetAlretActions',
  //       },
  //     });
  //     return;
  //   }

  //   Swal.fire({
  //     html: `<div style="display: flex;flex-direction: column;width:100%">
  //             <div style="font-weight: bold;font-size:25px;text-align: left;border-bottom: 1px solid #bbb;padding: 10px 20px;">
  //               ${t('sevenPayment.receipt.title')}
  //             </div>
  //             <div style="text-align: left;border-bottom: 1px solid #bbb;padding: 10px 20px;">
  //               <div style="display:flex;justify-content: space-between;">
  //                 <div>
  //                   ${t('sevenPayment.receipt.billnumber')}
  //                   ${accountCode}
  //                 </div>
  //                 <img style="width: 25px;height: 25px;"
  //                   src=${isAccountCodeValid ? iconpass : iconcross} alt=""
  //                 />
  //               </div>
  //               <div style="margin-top:10px;display:flex;justify-content: space-between;">
  //                 <div>
  //                   ${t('sevenPayment.receipt.amount')}
  //                   $${amount}
  //                 </div>
  //                 <img style="width: 25px;height: 25px;"
  //                 src=${isAmountValid ? iconpass : iconcross} alt=""
  //               />
  //               </div>
  //               <div style="margin-top:10px;display:flex;justify-content: space-between;">
  //                 <div>
  //                   ${t('sevenPayment.receipt.date')}
  //                   ${date}
  //                 </div>
  //                 <img style="width: 25px;height: 25px;"
  //                 src=${isDateValid ? iconpass : iconcross} alt=""
  //               />
  //               </div>
  //               <div style="margin-top:10px">
  //                   ${t('sevenPayment.receipt.time')}
  //                   ${time}
  //               </div>
  //               <div style="margin-top:10px;color:red">
  //               ${t('sevenPayment.receipt.makesure')}
  //               </div>
  //             </div>
  //           </div>`,
  //     customClass: {
  //       popup: 'swal2withoutpadding',
  //       content: 'swal2withoutpadding',
  //       actions: 'sweetAlretActions',
  //       confirmButton: 'OCRbutton',
  //     },
  //     confirmButtonColor: status === 'error' ? '#bbb' : '#fd980f',
  //     confirmButtonText:
  //       status === 'error'
  //         ? t('sevenPayment.receipt.reupload')
  //         : t('sevenPayment.receipt.complete'),
  //     didClose: () => {},
  //   });
  // }, [OCRResult]);

  return (
    <ImageUploading
      multiple
      value={receipts}
      onChange={onChange}
      // maxNumber={1}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpdate }) => {
        return (
          <UploadView>
            {status === 'matched' && (
              <MatchedDiv>
                <img src={iconpass} alt="" />
                <span>{t('sevenPayment.receipt.passed')}</span>
              </MatchedDiv>
            )}
            <UploadButton
              type="button"
              onClick={() => {
                if (OCRLoading) return;
                if (count >= limitCounttoOCR) {
                  Swal.fire({
                    text: t('sevenPayment.threeTimeError'),
                    icon: 'error',
                    position: 'center',
                    confirmButtonColor: '#bbb',
                    confirmButtonText: t('sevenPayment.receipt.complete'),
                    customClass: {
                      title: 'sweetAlretTitle',
                      popup: 'sweetAlretPopup',
                      header: 'sweetAlretHeader',
                      actions: 'sweetAlretActions',
                      confirmButton: 'OCRbutton',
                    },
                  });
                  return;
                }
                onImageUpdate(index);
              }}
            >
              {!receipts[index]?.data_url && (
                <div style={{ opacity: count >= limitCounttoOCR && '0.3' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <path d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z" />
                  </svg>
                  <Text margintop="20px" fontsize="13px">
                    {t('sevenPayment.text9')}
                  </Text>
                </div>
              )}
              {receipts.length > 0 && (
                <div>
                  <img src={receipts[index]?.data_url} alt="" width="100" draggable="false" />
                </div>
              )}
              {OCRLoading && (
                <ReactLoading
                  className="reactLoading"
                  type="spin"
                  color="#fd980f"
                  height={60}
                  width={60}
                />
              )}
            </UploadButton>
            {/* {receipts.length > 0 && receipts[index]?.data_url && (
              <StyledButton
                type="button"
                color="grey"
                onClick={() => {
                  RemoveImg();
                }}
                label={t('sevenPayment.remove')}
                style={{ marginTop: 20 }}
              />
            )} */}
          </UploadView>
        );
      }}
    </ImageUploading>
  );
}
