import styled from 'styled-components';
import React from 'react';

// TODO: responsive?

const Container = styled.div`
  display: flex;
`;

const Note = styled.span`
  font-size: 14px;
  color: #666;
`;

const Star = styled.span`
  color: #fd980f;
  display: flex;
  font-size: 20px;
  margin-top: -5px;
`;

function RequiredNote({ note }) {
  return (
    <Container>
      <Star>*</Star>
      <Note>{note}</Note>
    </Container>
  );
}

export default RequiredNote;
