import { useEffect, useState, createContext } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';

const parkingServicesUrl = `${process.env.REACT_APP_API_DOMAIN}/ParkingService`;
const carParkUrl = `${process.env.REACT_APP_API_DOMAIN}/carPark`;

const CarParkContext = createContext(null);

function useParkingService(carParkId) {
  const {
    i18n: { language },
  } = useTranslation();

  const [services, setServices] = useState([]);
  const [mapOptions, setMapOptions] = useState([]);

  const url = carParkId ? `${carParkUrl}/${carParkId}/ParkingServices` : parkingServicesUrl;

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data || !data.data) return;
    const isEng = language === 'en';

    setServices(
      data.data
        .filter((e) => e.isEnable)
        .map((record) => {
          const { type, chineseType, remark, chineseRemark, ...rest } = record;

          return {
            type: isEng ? type : chineseType,
            englishType: type,
            chineseType,
            remark: isEng ? remark : chineseRemark,
            englishRemark: remark,
            chineseRemark,
            ...rest,
          };
        })
        .sort(
          ({ priority: priorityA }, { priority: priorityB }) =>
            parseInt(priorityA, 10) - parseInt(priorityB, 10)
        )
    );
  }, [data, language]);

  useEffect(() => {
    if (!services || services.length === 0) return;
    setMapOptions(services.filter(({ isSearchParam }) => isSearchParam));
  }, [services]);

  return { services, mapOptions };
}

function useCarParks(LocationId) {
  const {
    i18n: { language },
  } = useTranslation();

  const { mapOptions } = useParkingService();

  const [carParks, setCarParks] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [carParksByTypes, setCarParksByTypes] = useState([]);
  const [carParksDisplay, setCarParksDisplay] = useState([]);
  const [carParksByLocation, setCarParksByLocation] = useState([]);

  const [{ data }] = useAxios({
    url: carParkUrl,
    method: 'GET',
    params: { LocationId },
  });

  useEffect(() => {
    if (!data || !data.data) return;
    const isEng = language === 'en';
    const carParkData = data.data;

    setCarParks(
      carParkData
        .filter(({ onlineShow }) => onlineShow)
        .map((carPark) => {
          const {
            address,
            chineseAddress,
            name,
            chineseName,
            remark,
            chineseRemark,
            freeParking,
            chineseFreeParking,
            ParkingServices = [],
            ...rest
          } = carPark;
          return {
            address: isEng ? address : chineseAddress,
            name: isEng ? name : chineseName,
            remark: isEng ? remark : chineseRemark,
            freeParking: isEng ? remark : chineseFreeParking,
            englishAddress: address,
            chineseAddress,
            englishName: name,
            chineseName,
            englishRemark: remark,
            chineseRemark,
            englishFreeParking: remark,
            chineseFreeParking,
            features: ParkingServices.flatMap(({ id }) => id),
            ParkingServices,
            ...rest,
          };
        })
    );
  }, [data, language]);

  useEffect(() => {
    if (selectedTypes.length === 0) {
      setCarParksDisplay(carParks);
    }
    setCarParksDisplay(
      carParks.filter(
        (carpark) =>
          carpark.ParkingServices.filter((service) => selectedTypes.indexOf(service.id) !== -1)
            .length === selectedTypes.length
      )
    );
  }, [selectedTypes, carParks]);

  return {
    carParksByTypes,
    carParks,
    carParksDisplay,
    setCarParksDisplay,
    serviceTypes: mapOptions,
    selectedTypes,
    setSelectedTypes,
    setCarParksByTypes,
    setCarParksByLocation,
    carParksByLocation,
  };
}

function useRegionalCarParks() {
  const {
    i18n: { language },
  } = useTranslation();
  const [LocationId, setLocationId] = useState(undefined);
  const [carParks, setCarParks] = useState([]);
  const [{ data }, fetchCarParks] = useAxios({ url: carParkUrl, method: 'GET' }, { manual: true });

  useEffect(() => {
    if (!LocationId) return;
    fetchCarParks({ params: { LocationId } });
  }, [LocationId]);

  useEffect(() => {
    if (!data || !data.data) return;
    const isEng = language === 'en';
    setCarParks(
      data.data
        .filter(({ onlineShow }) => onlineShow)
        .map((carpark) => {
          const { address, chineseAddress, name, chineseName, ...rest } = carpark;
          return {
            address: isEng ? address : chineseAddress,
            name: isEng ? name : chineseName,
            ...rest,
          };
        })
    );
  }, [data, language]);

  return {
    setLocationId,
    carParks,
  };
}

function useCarPark(carParkId) {
  const {
    i18n: { language },
  } = useTranslation();
  const [carPark, setCarPark] = useState({});
  const { services } = useParkingService(carParkId);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [parkingPlansMap, setParkingPlansMap] = useState(new Map());
  const [carTypes, setCarTypes] = useState([]);
  const [parkingPlans, setParkingPlans] = useState([]);

  const baseUrl = `${carParkUrl}/${carParkId}`;
  const isEng = language === 'en';

  const [{ data: carParkData }] = useAxios({ url: baseUrl, method: 'GET' });
  const [{ data: paymentMethodData }] = useAxios({
    url: `${baseUrl}/PaymentMethods`,
    method: 'GET',
  });
  const [{ data: planData }] = useAxios({
    url: `${baseUrl}/ParkingPlans`,
    method: 'GET',
  });

  useEffect(() => {
    if (!carParkData || !carParkData.data) return;
    const {
      data: {
        LocationId,
        name,
        chineseName,
        address,
        chineseAddress,
        remark,
        chineseRemark,
        displayPaymentMethods,
        ...rest
      },
    } = carParkData;

    setCarPark({
      LocationId,
      name: isEng ? name : chineseName,
      address: isEng ? address : chineseAddress,
      remark: isEng ? remark : chineseRemark,
      englishName: name,
      chineseName,
      englishAddress: address,
      chineseAddress,
      englishRemark: remark,
      chineseRemark,
      displayPaymentMethods: displayPaymentMethods || [],
      ...rest,
    });
  }, [carParkData, language]);

  useEffect(() => {
    if (!paymentMethodData || !paymentMethodData.data) return;
    setPaymentMethods(paymentMethodData.data);
  }, [paymentMethodData]);

  useEffect(() => {
    if (!planData || !planData.data) return;

    setParkingPlans(planData.data.filter(({ isEnable }) => isEnable));

    const [plans, types] = planData.data
      .filter(({ isEnable }) => isEnable)
      .reduce(
        (output, { CarType, skidataSurname, ...rest }) => {
          const [p, t, j] = output;
          const { id, name, chineseName } = CarType;
          if (name.trim().length === 0) return output;

          const {
            ParkingService: {
              type,
              chineseType,
              remark,
              chineseRemark,
              priority: ParkingServicePriority,
            },
            subTitle,
            chineseSubTitle,
            id: ParkingPlanId,
            isAppliedAtOffice,
            amount,
            isReserved,
            isFull,
            timeOfDay,
            priority,
            isWaitingList,
          } = rest;

          const { data: { carParkSystem } = {} } = carParkData || {};
          const carTypeName = isEng ? name : chineseName;
          const typeName = isEng ? type : chineseType;
          const plan = {
            id: ParkingPlanId,
            type: typeName,
            remark: isEng ? remark : chineseRemark,
            amount: !amount ? 0 : amount,
            isAppliedAtOffice,
            subTitle: isEng ? subTitle : chineseSubTitle,
            isReserved: !!isReserved,
            isBookable: !!skidataSurname || carParkSystem === 'POS',
            isFull: !!isFull,
            timeOfDay,
            priority,
            CarTypeId: id,
            isWaitingList,
          };

          j.set(ParkingServicePriority, typeName);

          t.set(id, { id, name: carTypeName, priority });

          if (!p.has(carTypeName)) p.set(carTypeName, new Map());
          const pMap = p.get(carTypeName);
          if (!pMap.has(typeName)) pMap.set(typeName, []);
          pMap.set(typeName, [...pMap.get(typeName), { ...plan, ParkingServicePriority }]);
          return [p.set(carTypeName, pMap), t, j];
        },
        [new Map(), new Map(), new Map()]
      );
    setParkingPlansMap(plans);
    setCarTypes([...types.values()]);
  }, [planData, carParkData, language, carParkId]);

  return {
    carPark,
    services,
    paymentMethods,
    parkingPlansMap,
    carTypes,
    parkingPlans,
  };
}

export { useCarPark, useCarParks, useParkingService, useRegionalCarParks, CarParkContext };
