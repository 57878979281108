import React from 'react';

import { withRouter } from 'react-router-dom';
import searchicon from '../../media/icons/search.png';

const dimension = {
  flex: 1,
};

function Search({ prompt }) {
  return (
    <li className="search">
      <form method="get" action="">
        <input name="keyword" type="text" autoComplete="off" placeholder={prompt} />
        <div className="search-submit">
          <input type="image" alt="search" src={searchicon} {...dimension} />
        </div>
      </form>
    </li>
  );
}

export default withRouter(Search);
