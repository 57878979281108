import * as axios from 'axios';

function baseAPIService(
  url,
  method,
  data,
  headers = {
    Accept: 'application/json, text/plain, /',
    'Content-Type': 'application/json',
  }
) {
  // if (cookies.get('token')) {
  //   let urlWithToken = new URL(url);
  //   urlWithToken.searchParams.set('access_token', cookies.get('token'));
  //   url = urlWithToken.toString();
  // }

  console.log(url, data);

  const options = JSON.parse(
    JSON.stringify({
      url,
      method,
      headers,
      data,
    })
  );
  return axios(options)
    .then((res) => {
      if (res.status >= 200 && res.status < 300 && res.data) {
        console.log(res);
        return res.data;
      }
      throw res;
      // throw { status: res.status, body: res.data };
    })
    .catch((error) => {
      console.log(error);
      throw error;
      // throw { status: error.response.status, body: error.response.data };
    });
}

export default baseAPIService;
