import styled from 'styled-components';

const OrangeContainer = styled.div`
  padding: 25px 15px 15px 15px;
  background-color: #fd980f;
  color: #fff;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const Label = styled.span`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  margin-top: ${(props) => props.marginTop};
`;

const SubTitle = styled.span`
  font-size: 1.2rem;
`;

const ItemDetailLabel = styled.td`
  width: 15%;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fd980f;

  @media (max-width: 670px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

const WhiteHorizontalLine = styled.div`
  border-bottom: 1px solid #fff;
  margin-top: 15px;
`;

const DiscountView = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-top: 10px;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const PickUpMethodView = styled.div`
  display: 'flex';
  font-size: 0.8rem;
  margin-top: 15px;
  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const PickUpLocationView = styled.div`
  display: flex;
  font-size: 0.8rem;
  margin-top: 15px;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const PickUpInput = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  @media (max-width: 670px) {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

const DiscountInput = styled.input`
  border: none;
  height: 32px;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
`;

const DiscountLabel = styled.p`
  margin: 0;
  padding: 0;
  line-height: 1.5;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  padding-top: 5px;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
`;

const DiscountDiv = styled.div`
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  display: flex;
`;

const TermsConditionsBox = styled.div`
  background: white;
  padding: 5px;
  height: 100px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  color: black;
  font-size: 0.75rem;
`;

const WhiteContainer = styled.div`
  background: white;
  width: 100%;
  padding: 25px 0px;
`;

const PaymentDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-top: 10px;
`;

export {
  Label,
  TermsConditionsBox,
  WhiteContainer,
  SubTitle,
  PickUpMethodView,
  ItemDetailLabel,
  PickUpLocationView,
  PickUpInput,
  OrangeContainer,
  DiscountView,
  DiscountLabel,
  DiscountDiv,
  DiscountInput,
  WhiteHorizontalLine,
  PaymentDisplay,
};
