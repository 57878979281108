import styled from 'styled-components';

const DetailRightDiv = styled.div`
  position: relative;
  left: 66.666667%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;

  @media (max-width: 850px) {
    flex: 0 0 100%;
    max-width: 100%;
    left: 0;
`;

export default DetailRightDiv;
