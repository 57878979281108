import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useState, useEffect } from 'react';
import axios from 'axios';
import img0 from '../media/images/P921-sq.jpeg';
import img1 from '../media/images/TB2022_chi_.jpeg';
import img3 from '../media/images/P648_0.jpeg';
import img4 from '../media/images/DSCN9981_0.jpeg';
import img5 from '../media/images/WP-NEW-APP13.jpg';
import img6 from '../media/images/_p_918_2022-06-30_e.jpeg';
import img7 from '../media/images/P440sq-b_1.jpeg';
import img8 from '../media/images/p209sq.jpeg';
import Appstore from '../media/images/Appstore.png';
import Android from '../media/images/Googleplay.png';
import img9 from '../media/images/P919news.jpg';
import img10 from '../media/images/c565d025-f042-4dcd-be78-fae40bc26d01.jpeg';
import img11 from '../media/images/4d25be1b-1870-44f8-b6a3-16c7e1e103c2.jpeg';
import img12 from '../media/images/9a259b95-6956-4622-ac86-6d11c8d97a57.jpeg';
import img13 from '../media/images/79488e1a-fda7-4b81-b53c-5b4f07733cf5.jpeg';

const url = `${process.env.REACT_APP_API_DOMAIN}/news`;

function useNews() {
  const { t, i18n } = useTranslation();

  const [news, setNews] = useState([]);
  const [announcement, setAnnouncement] = useState({});

  function getData(data, type) {
    return data
      .filter((e) => {
        if (!e.expiryDate && !e.startDate) {
          return e.type === type && e.status === 'published';
        }

        if (!e.expiryDate) {
          return (
            e.type === type &&
            moment().isSameOrAfter(e.startDate ? moment(e.startDate) : moment(), 'days') &&
            e.status === 'published'
          );
        }

        return (
          e.type === type &&
          moment().isBetween(
            e.startDate ? moment(e.startDate) : moment(),
            moment(e.expiryDate),
            'days',
            '[]'
          ) &&
          e.status === 'published'
        );
      })
      .sort((a, b) => a.priority - b.priority)
      .map((e) => ({
        ...e,
        image: `${process.env.REACT_APP_CP_MEDIA_DOMAIN}${e.image}`,
      }));
  }

  useEffect(() => {
    async function getNews() {
      const {
        data: { data },
      } = await axios.get(`${url}`);

      const filteredData = getData(data, 'news');

      setNews(filteredData);

      const filteredAnnouncement = getData(data, 'announcement')[0] || {};

      setAnnouncement(filteredAnnouncement);
    }

    getNews();
  }, []);

  const PromoData = [
    {
      Img: img13,
      id: 13,
      Title: t('News.N13Title'),
      Text: t('News.N13Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p>Please go to below car park detail page for parking rate:</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/413" target="_blank">Honour Industrial Centre, Chai Wan</a></p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/414" target="_blank">New Commerce Centre, Shek Mun, Shatin</a></p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/415" target="_blank">New Trade Plaza, Shek Mun, Shatin</a></p>`
          : `<p>請到以下停車場資料網頁查閱泊車收費：</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/413" target="_blank">柴灣安力工業中心</a></p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/414" target="_blank">沙田石門滙貿中心</a></p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/415" target="_blank">沙田石門新貿中心</a></p>`,
      startDate: '1/1/2023',
      expiryDate: '31/1/2023',
    },
    {
      Img: img12,
      id: 12,
      Title: t('News.N12Title'),
      Text: t('News.N12Text'),
      bottomText:
        i18n.language === 'en'
          ? '<p>Please go to below car park detail page for parking rate:</p> <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/416" target="_blank">Project Site Office (Zone A)</a></p> <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/417" target="_blank">Hong Kong Palace Museum (Zone B)</a></p> <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/418" target="_blank">Art Park (Zone E)</a></p> <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/419" target="_blank">Zone F</a></p> <p>&nbsp;</p>'
          : '<p>請到以下停車場資料網頁<span lang="ZH-TW">查</span><span lang="ZH-HK">閱</span>泊車收費：</p><p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/416" target="_blank">項目辦公室 (A區)</a></p><p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/417" target="_blank">香港故宮文化博物館 (B區)</a></p> <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/418" target="_blank">藝術公園 (E區)</a></p><p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/419" target="_blank">F區</a></p><p>&nbsp;</p> ',
      startDate: '16/12/2022',
      expiryDate: '31/1/2023',
    },
    {
      Img: img11,
      id: 11,
      Title: t('News.N11Title'),
      Text: t('News.N11Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p>- Quick Charge Bay : $30/hr<br />- Medium Charge Bay : $10/hr</p>
          <p>Prevailing hourly parking rate applies to EV-Charge Bay.</p>
          <p>Please refer to Terms &amp; Conditions of Use and relevant signs displayed at Admiralty Car Park.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>`
          : `<p>- 快速充電車位：$30/小時<br />- 中速充電車位：$10/小時</p>
          <p>使用電動車充電車位須另外繳付時租泊車費用。</p>
          <p>詳情請留意金鐘停車場內的使用條款及規則及相關標示。</p> 
        `,
      startDate: '24/11/2022',
      expiryDate: '31/1/2023',
    },
    {
      Img: img10,
      id: 10,
      Title: t('News.N10Title'),
      Text: t('News.N10Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p>Wilson Parking Parkers can enjoy FREE Ozone Sterilization Service x3 (value: $2,964) upon SmarTone Home 5G Broadband subscription! Simply plug in and go online using truly unlimited 5G with no speed cap^ for just $148/mth!</p>
          <p>&nbsp;</p>
          <p>Subscribe to 7-day free trial</p>
          <p>^FUP (Fair Usage Policy) applies. T&amp;Cs apply.</p>     `
          : `<p>Wilson Parking用戶登記SmarTone Home 5G寬頻專享獨家優惠，送3次「車廂臭氧消毒服務」(價值:$2,964) ！ 月費只需$148 ！</p>
          <p>Home 5G寬頻 - 免拉線插電即用，隨你靈活擺位，加個喺房/廳做自己嘅私人寬頻，獨享真無限5G數據不限速^任上網，唔駛同家人爭！</p>
          <p>&nbsp;</p>
          <p>按此登記7日免費試用</p>
          <p>^FUP(公平使用政策)適用。優惠受條款及細則約束。</p>  
        `,
      startDate: '10/10/2022',
      expiryDate: '03/11/2022',
    },
    // {
    //   Img: img9,
    //   id: 9,
    //   Title: t('News.N9Title'),
    //   Text: t('News.N9Text'),
    //   bottomText:
    //     i18n.language === 'en'
    //       ? `
    //       <p><span style="text-decoration: underline;">Private Car/Van</span></p>
    //       <p><span>Hourly Rate&nbsp;</span>: $15</p>
    //       <p>Day Park : $80 (08:00-18:00)</p>
    //       <p>Night Park : $80 (18:00-08:00)</p>
    //       <p>&nbsp;</p>
    //       <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/404" target="_blank">Click here</a>&nbsp;for car park information.</p>
    //       <p>&nbsp;</p>`
    //       : `<p><span style="text-decoration: underline;">私家車/客貨車</span></p>
    //       <p><span>時租&nbsp;</span>: $15</p>
    //       <p><span>日泊 : $80 (08:00-18:00</span>)</p>
    //       <p>夜泊 :&nbsp;$80 (18:00-08:00)&nbsp;</p>
    //       <p>&nbsp;</p>
    //       <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/404" target="_blank">按此</a>&nbsp;查閱停車場資訊。</p>
    //       <p>&nbsp;</p>
    //     `,
    //   startDate: '31/08/2022',
    //   expiryDate: '30/11/2022',
    // },
    {
      Img: img8,
      id: 8,
      Title: t('News.N8Title'),
      Text: t('News.N8Text'),
      bottomText:
        i18n.language === 'en'
          ? `<div class="thread_details__details"><p><span style="text-decoration: underline;">Private Car/Van</span></p><p>Hourly Rate : $13 (M-F, excl. PH)/ $15 (S/S/PH)</p><p>Monthly Parking : $1900</p><p>&nbsp;</p><p><a style="color: #000000;font-size:14px" href="https://web.wilsonparking.com.hk/en/car_parks/detail/410" target="_blank">Click here</a>&nbsp;for car park information.</p><p>&nbsp;</p>`
          : `<div><p>私家車/客貨車</p><p>時租 : $13 (星期一至五，公眾假期除外)/ $15 (星期六/日/公眾假期)</p><p>月租 : $1900</p><p><a style="color: #000000;font-size:14px"; href="https://web.wilsonparking.com.hk/zh/car_parks/detail/410" target="_blank">按此</a>&nbsp;查閱停車場資訊。</p></div>
        `,
      startDate: '01/08/2022',
      expiryDate: '30/08/2022',
    },
    {
      Img: img3,
      id: 2,
      Title: t('News.N3Title'),
      Text: t('News.N3Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p data-pm-slice="1 1 []">During the promotional period, all EV drivers can enjoy up to 4 hours free charging* at Admiralty Car Park!</p><ul  class="ak-ul" style="list-style-type: disc;margin-left:20px"><li><p>First 2 hours free charging on Quick Chargers</p></li><li><p >First 4 hours free charging on Medium Chargers</p></li></ul><p><a style="color: #000000;"  target="_blank" href="https://www2.wilsonparking.com.hk/edm/202207/eDM_202207.html">Click here</a>&nbsp; to Learn More&nbsp;</p><p>*In addition to the prevailing hourly parking rate</p>`
          : `<p data-pm-slice="1 1 []">推廣期內，所有電動車車主於金鐘停車場，均可享高達4小時免費充電*：</p><ul  class="ak-ul" style="list-style-type: disc;margin-left:20px"><li><p>快速充電首2小時免費</p></li><li><p >中速充電首4小時免費</p></li></ul><p><a style="color: #000000;"  target="_blank" href="https://www2.wilsonparking.com.hk/edm/202207/eDM_202207.html">按此</a>了解更多&nbsp;</p><p>*須繳付時租泊車費用。</p>
          `,
      startDate: '15/07/2022',
      expiryDate: '11/11/2022',
    },
    {
      Img: img6,
      id: 7,
      Title: t('News.N6Title'),
      Text: t('News.N6Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p><span style="text-decoration: underline;">Private Car/Van</span></p>
          <p>Hourly Rate : $25</p>
          <p>Day Park : $140 (Mon-Sat 08:00-18:00, excl.PH)</p>
          <p>Monthly Parking : $4500</p>
          <p>&nbsp;</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/406" target="_blank">Click here</a>&nbsp;for car park information.</p>
          <p>&nbsp;</p>`
          : `<p><span style="text-decoration: underline;">私家車/客貨車</span></p>
          <p>時租 : $25</p>
          <p>日泊 :&nbsp;$140 (星期一至六 08:00-18:00, 公眾假期除外)</p>
          <p>月租 : $4500</p>
          <p>&nbsp;</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/406" target="_blank">按此</a>&nbsp;查閱停車場資訊。</p>
          <p>&nbsp;</p>`,
      startDate: '04/07/2022',
      expiryDate: '31/08/2022',
    },
    {
      Img: img7,
      id: 8,
      Title: t('News.N7Title'),
      Text: t('News.N7Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p><span style="text-decoration: underline;">Private Car/Van</span></p>
          <p>Hourly Rate : $19 (M-F, excl. PH)/ $25 (S/S/PH)</p>
          <p>Max Park : $95 (M-F, excl. PH)/ $120 (S/S/PH)</p>
          <p>Monthly Parking : $2600up</p>
          <p>&nbsp;</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/409" target="_blank">Click here</a>&nbsp;for car park information.</p>
          <p>&nbsp;</p>`
          : ` <p><span style="text-decoration: underline;">私家車/客貨車</span></p>
          <p>時租 :&nbsp;&nbsp;$19 (星期一至五，公眾假期除外)/ $25 (星期六/日/公眾假期)</p>
          <p>全日任泊 : $95 (星期一至五，公眾假期除外)/ $120 (星期六/日/公眾假期)</p>
          <p>月租 : $2600起</p>
          <p>&nbsp;</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/409" target="_blank">按此</a>&nbsp;查閱停車場資訊。</p>
          <p>&nbsp;</p>`,
      startDate: '04/07/2022',
      expiryDate: '31/08/2022',
    },

    {
      Img: img0,
      id: 6,
      Title: t('News.N0Title'),
      Text: t('News.N0Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p><span style="text-decoration: underline;">Private Car</span></p>
          <p>Hourly Rate : $15 (M-F, excl. PH)/ $18 (S/S/PH)</p>
          <p>Day Park 08:00-18:00 : $70 (M-F, excl. PH)/ $80 (S/S/PH)</p>
          <p>Night Park 18:00-08:00 : $90 (M-F, excl. PH)/ $100&nbsp;(S/S/PH)</p>
          <p>Monthly Parking : $3300</p>
          <p>&nbsp;</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/en/car_parks/detail/407" target="_blank">Click here</a>&nbsp;for car park information.</p>
          <p>&nbsp;</p>`
          : `<p><span style="text-decoration: underline;">私家車/客貨車</span></p>
          <p>時租 : $25</p>
          <p>日泊 :&nbsp;$140 (星期一至六 08:00-18:00, 公眾假期除外)</p>
          <p>月租 : $3300</p>
          <p>&nbsp;</p>
          <p><a style="color: #000000;" href="https://web.wilsonparking.com.hk/zh/car_parks/detail/407" target="_blank">按此</a>&nbsp;查閱停車場資訊。</p>
          <p>&nbsp;</p>`,
      startDate: '29/06/2022',
      expiryDate: '31/08/2022',
    },
    {
      Img: img1,
      id: 0,
      Title: t('News.N1Title'),
      Text: t('News.N1Text'),
      bottomText: t('News.N1Detailtext1'),
      startDate: '15/06/2022',
      expiryDate: '30/09/2022',
    },
    {
      Img: img4,
      id: 3,
      Title: t('News.N4Title'),
      Text: t('News.N4Text'),
      bottomText:
        i18n.language === 'en'
          ? `<p>You can apply for Transport Department Quarterly Parking Ticket online</p><div style="margin-bottom:5px"><a style="color:black;font-size:0.875rem" href="/${i18n.language}/car_parks/detail/295" target='_blank' style="color:black">Kwai Fong Car Park</a ></div><div style="margin-bottom:5px"><a href="/${i18n.language}/car_parks/detail/8" target='_blank' style="color:black;font-size:0.875rem">Tsuen Wan Car Park</a></div><div style="margin-bottom:5px"><a href="/${i18n.language}/car_parks/detail/7" target='_blank'  style="color:black;font-size:0.875rem">Tin Hau Car Park</a></div><div style="margin-top:20px"><a href="https://www.tdcarpark.com.hk/" target="_blank" style="color:black;font-size:0.875rem;font-weight:bolder;">>> Click here to apply quarterly parking ticket</a></div>`
          : `<p>您可於網上申請運輸署停車場之泊車季票</p><div style="margin-bottom:5px"><a style="color:black;font-size:0.875rem" href="/${i18n.language}/car_parks/detail/295" target='_blank' style="color:black">葵芳停車場</a ></div><div style="margin-bottom:5px"><a href="/${i18n.language}/car_parks/detail/8" target='_blank' style="color:black;font-size:0.875rem">荃灣停車場</a></div><div style="margin-bottom:5px"><a href="/${i18n.language}/car_parks/detail/7" target='_blank'  style="color:black;font-size:0.875rem">天后停車場</a></div><div style="margin-top:20px"><a href="https://www.tdcarpark.com.hk/" target="_blank" style="color:black;font-size:0.875rem;font-weight:bolder;">> 按此申請泊車季票</a></div>`,
      startDate: '16/11/2021',
      expiryDate: '31/12/2025',
    },
    {
      Img: img5,
      id: 4,
      Title: t('News.N5Title'),
      Text: t('News.N5Text'),
      bottomText:
        i18n.language === 'en'
          ? `<div class="thread_details__details">
                <p><span style="text-decoration: underline;"><strong>Download:&nbsp;</strong></span></p>
                <p>&nbsp;</p>
                <table>
                <tbody>
                <tr>
                <td><a href="https://itunes.apple.com/hk/app/wilson-parking-hk/id1234893685?l=zh&amp;mt=8" target="_blank"><img src="${Appstore}"/></a></td>
                <td><a href="https://play.google.com/store/apps/details?id=com.wilsonparking" target="_blank"> <img src="${Android}" /></a></td>
                </tr>
                </tbody>
                </table>
                <p>&nbsp;</p>
                <p><span style="text-decoration: underline;"><strong>Key Features:</strong></span></p>
                <ul>
                <li><strong>- Car Parks:</strong> Instant access to real time parking information</li>
                <li><strong>- Eat &amp; Play:</strong> Explore restaurants and interesting events nearby</li>
                <li><strong>- i-Monthly:</strong> Book and renew monthly parking service with just a couple of taps</li>
                <li><strong>- My Page:</strong> Manage monthly parking service, retrieve payment records and more</li>
                <li><strong>- Park+Jetso:</strong>&nbsp;Book exclusive parking-plus offers instantly&nbsp;</li>
                </ul>                                    
              </div>`
          : `
        <div className="thread_details__details">
          <p>
            <span style="text-decoration: underline;">
              <strong>
                下載
              </strong>
            </span>
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <a
                    href="https://itunes.apple.com/hk/app/wilson-parking-hk/id1234893685?l=zh&amp;mt=8"
                    target="_blank"
                  >
                    <img src=${Appstore} />
                  </a>
                </td>
                <td>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wilsonparking"
                    target="_blank"
                  >
                    <img src="${Android}" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <p>
            <span style="text-decoration: underline;">
              <strong>重點功能：</strong>
              <strong>&nbsp;</strong>
            </span>
          </p>
          <ul>
            <li>
              <strong>- 停車場：</strong>
              獲取即時泊車資訊
            </li>
            <li>
              <strong>- 美食消閒：</strong>
              網羅附近餐廳及有趣活動資訊
              <strong>
              </strong>
            </li>
            <li>
              <strong>- i-Monthly 預訂月租：</strong>
              隨時隨地預訂及續訂月租服務
            </li>
            <li>
              <strong>- 我的專頁：</strong>
              管理月租服務、查閱付款記錄等
            </li>
            <li>
              <strong>- Park+泊賞：</strong>
              即時訂購獨家泊車相關著數獎賞
            </li>
          </ul>
        </div>
      `,
      startDate: '21/06/2019',
      expiryDate: '31/12/2025',
    },
  ].filter((e) =>
    moment().isBetween(
      moment(e.startDate, 'DD/MM/YYYY'),
      moment(e.expiryDate, 'DD/MM/YYYY'),
      'days',
      '[]'
    )
  );

  return {
    PromoData,
    news,
    announcement,
  };
}

export default useNews;
