const profileStyle = {
  subtitle: {
    color: '#444',
    fontSize: '1.3rem',
    fontWeight: '600',
    paddingBottom: 20,
  },
  item: { color: '#666', fontSize: '0.8125rem', fontWeight: '400' },
  itemsmall: { color: '#666', fontSize: '0.7rem', fontWeight: '400' },
  itemflex: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#fff',
    marginTop: 20,
  },
  itemstyle: { display: 'flex', flex: 2, backgroundColor: '#fff' },
  itemflexback: { display: 'flex', flex: 3, backgroundColor: '#fff' },
  separator: {
    height: 1,
    backgroundColor: '#ccc',
    marginTop: 20,
    marginBottom: 20,
  },
  selectstyle: {
    marginRight: 10,
    border: '1px solid #bbb',
    backgroundColor: '#fff',
    fontSize: '0.8125rem',
    height: 20,
  },
  buttonflex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    marginTop: 50,
  },
  buttonstyleRECEIVE: {
    color: '#fff',
    borderWidth: 0,
    fontSize: 13,
    flex: 1,
    whiteSpace: 'nowrap',
    padding: 5,
  },
  buttonstyleVERIFY: {
    color: '#fff',
    borderWidth: 0,
    fontSize: 13,
    flex: 1,
    padding: 5,
  },
  buttonstyleRESET: {
    color: '#fff',
    borderWidth: 0,
    fontSize: 13,
    flex: 1,
    padding: 5,
  },
  buttonstyleUPDATE: {
    color: '#fff',
    borderWidth: 0,
    fontSize: 13,
    marginLeft: 20,
    flex: 1,
    padding: 5,
  },
  checkboxstyle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 2,
    backgroundColor: '#fff',
    marginTop: 10,
  },
  inputstyle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    backgroundColor: '#fff',
  },
  divstyle: { display: 'flex', flex: 1, backgroundColor: '#fff' },
  bigdivstyle: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  InputBar: {
    display: 'flex',
    flex: 1,
    paddingLeft: 5,
    height: 30,
    color: '#666',
    fontSize: 13,
  },
  AdressSelectView: { display: 'flex', marginLeft: 190 },
};

export default profileStyle;
