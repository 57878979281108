import styled from 'styled-components';
import imgback from '../../media/images/news_bg.png';

const AwardBlock = styled.div`
  display: block;
  flex-direction: column;
  flex: 0 0 25%;
  max-width: 25%;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 60px;

  @media (max-width: 850px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const ImgDiv = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
`;

const AwardImg = styled.img`
  width: 100%;
  height: 226px;

  @media (max-width: 550px) {
    width: 150px;
    height: 150px;
  }
`;

const TextDiv = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  text-align: center;
  min-height: 70px;
`;

const AwardTitle = styled.span`
  text-align: center;
  color: #888;
  font-size: 0.875rem;
`;

const AwardSubTitle = styled(AwardTitle)`
  font-size: 1;
`;

const AwardText = styled.span`
  text-align: center;
  color: #888;
  font-size: 12px;
`;

const TitleRow = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  justify-content: center;
  background-image: url(${imgback});
  background-size: cover;
  background-position: center;
`;

const TitleWidth = styled.div`
  width: 1024px;
  padding: 20px;
`;

const PageTitle = styled.span`
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
`;

const PageContainer = styled.div`
  background: #fff;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  justify-content: center;
`;

const Inner = styled.div`
  background-color: #fff;
  display: flex;
  padding-bottom: 80px;
  justify-content: space-around;
  flex-direction: column;
  width: 1024px;
`;

const InnerRow = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

export {
  AwardBlock,
  ImgDiv,
  AwardImg,
  TextDiv,
  TitleDiv,
  AwardTitle,
  AwardSubTitle,
  AwardText,
  TitleRow,
  TitleWidth,
  PageTitle,
  PageContainer,
  Inner,
  InnerRow,
};
