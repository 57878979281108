import styled from 'styled-components';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div {
    &:nth-child(2) {
      margin-top: 40px;
    }
  }
`;

export default FormContainer;
