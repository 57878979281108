import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RadiusCheckbox } from '../../components/FormComponents';
import TermsAndConditions from './TermsAndConditions';
import * as icon from '../../media/icons';
import Card from '../../media/images/payment_visa_master.png';
import PPSImg from '../../media/images/payment_pps.png';
import FPSImg from '../../media/images/payment_fps_color.png';
import mastercard from '../../assets/images/mastercard.png';
import visa from '../../assets/images/visa.png';
import { useFirstDataToken, useGuestCart } from '../../models/Customer';
import { RadioGroup, RadioButton } from '../../components/Cart';
import {
  Container,
  ContentContainer,
  InnerContainer,
  IhourlyTitle,
  HourlySpan,
  DateTimeTitle,
  DateTimeSpan,
  CustomerDetailsDiv,
  PaymentContainer,
  PaymentTitle,
  LeftDiv,
  HandlingFree,
  HandlingFreeText,
  Divider,
  RightDiv,
  Amount,
  UpperDiv,
  MethodsDiv,
  CheckboxDiv,
  PaymentMethodText,
  CardImg,
  LowerDiv,
  SavedPayment,
  TermsContainer,
  Terms,
  TermsDiv,
  CheckDiv,
  Agree,
  ButtonRow,
  Reset,
  Submit,
  CheckboxwithControl,
  CardText,
  CreditCardImg,
  DetailRow,
  PaymenyInner,
  TimeBlock,
  TimeRow,
} from './IhourlyComponents/index';

function Step2({ CustomerData, carParkData, onlinePaymentIds }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const methods = useForm();
  const [paymentMethod, setPaymentMethod] = useState();
  const history = useHistory();
  const { firstDataTokens: creditCards } = useFirstDataToken();
  const [FirstDataTokenId, setFirstDataTokenId] = useState();
  const { guestCartAPI, orderId } = useGuestCart();
  const { visaId = '', ppsId = '', fpsId = '' } = onlinePaymentIds || {};

  const {
    prefix,
    entryHour,
    entryMins,
    EntryDate,
    lastName,
    firstName,
    email,
    phone,
    VehicleRegistrationMark,
  } = CustomerData;

  const {
    address,
    subTitle,
    amount,
    carParkName,
    timeOfDay,
    carParkId,
    activeCarType,
  } = carParkData;

  const onSubmit = () => {
    guestCartAPI({
      prefix,
      lastName,
      firstName,
      email,
      phone,
      CarTypeId: activeCarType,
      registrationMark: VehicleRegistrationMark,
      EntryDate,
      entryHour,
      entryMins,
      ParkingPlanId: carParkId,
      PaymentMethodId: paymentMethod,
      FirstDataTokenId,
      amount,
      language,
    });
  };

  useEffect(() => {
    if (!orderId) return;
    const MethodData = { paymentMethod, orderId };
    if (paymentMethod) {
      history.push({ pathname: `/${language}/IhourlyCart/3`, state: MethodData });
    }
  }, [orderId]);

  return (
    <>
      <Container>
        <ContentContainer>
          <div style={{ textAlign: 'left', width: '100%', padding: '0 10px 0 10px' }}>
            <div style={{ display: 'flex' }}>
              <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
              <p>
                <b>{t('IhourlyCart.DetailTitle')}</b>
              </p>
            </div>

            <p style={{ fontSize: '0.85rem' }}>{t('IhourlyCart.DetailText1')}</p>
            <p style={{ fontSize: '0.85rem' }}>{t('IhourlyCart.DetailText2')}</p>
          </div>

          <InnerContainer>
            <span style={{ color: '#fd980f', fontSize: '2rem' }}>{carParkName}</span>
            <div>
              <img src={icon.location} style={{ height: '1.1rem' }} alt="location" />
              <span style={{ fontSize: ' 0.875rem', marginBottom: 10, color: '#888' }}>
                {address}
              </span>
            </div>
            <br />
            <div>
              <span style={{ color: '#fd980f', fontSize: '1.285rem' }}>
                {t('IhourlyCart.Hourly')}
              </span>
              <p />
              <div style={{ display: 'flex' }}>
                <IhourlyTitle>{t('IhourlyCart.Service')}</IhourlyTitle>
                <IhourlyTitle>{t('IhourlyCart.Time')}</IhourlyTitle>
                <IhourlyTitle>{t('IhourlyCart.ParkingRate')}</IhourlyTitle>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#ccc' }} />
              <div style={{ display: 'flex', padding: '10px 0px' }}>
                <HourlySpan>{subTitle}</HourlySpan>
                <HourlySpan>{timeOfDay}</HourlySpan>
                <HourlySpan>{`HK$${amount}`}</HourlySpan>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#ccc' }} />
            </div>
            <br />
            <span>{t('IhourlyCart.DateTime')}</span>
            <TimeRow>
              <TimeBlock>
                <DateTimeTitle>{t('IhourlyCart.EntryDate')}</DateTimeTitle>
                <DateTimeSpan>{EntryDate}</DateTimeSpan>
              </TimeBlock>
              <TimeBlock>
                <DateTimeTitle>{t('IhourlyCart.EntryTime')}</DateTimeTitle>
                <DateTimeSpan>
                  {entryHour}
:
{entryMins}
                </DateTimeSpan>
              </TimeBlock>
            </TimeRow>

            <br />
            <span>{t('IhourlyCart.CustomerDetails')}</span>
            <DetailRow className="d-flex-polyfill">
              <CustomerDetailsDiv>
                <DateTimeTitle>{t('IhourlyCart.Name')}</DateTimeTitle>
                <DateTimeSpan>
                  {language === 'en' && prefix}
                  &nbsp;
                  {language === 'en' ? firstName : lastName}
                  &nbsp;
                  {language === 'en' ? lastName : firstName}
                  &nbsp;
                  {language === 'zh' && prefix}
                </DateTimeSpan>
              </CustomerDetailsDiv>
              <CustomerDetailsDiv>
                <DateTimeTitle>{t('IhourlyCart.Phone')}</DateTimeTitle>
                <DateTimeSpan>{phone}</DateTimeSpan>
              </CustomerDetailsDiv>
              <CustomerDetailsDiv>
                <DateTimeTitle>{t('IhourlyCart.Email')}</DateTimeTitle>
                <DateTimeSpan>{email}</DateTimeSpan>
              </CustomerDetailsDiv>
              <CustomerDetailsDiv>
                <DateTimeTitle>{t('IhourlyCart.VehicleRegistrationMark')}</DateTimeTitle>
                <DateTimeSpan>{VehicleRegistrationMark}</DateTimeSpan>
              </CustomerDetailsDiv>
            </DetailRow>
          </InnerContainer>
          <PaymentContainer>
            <FormContext {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PaymentTitle>{t('IhourlyCart.PaymentTitle')}</PaymentTitle>
                <PaymenyInner>
                  <LeftDiv>
                    <HandlingFree>{t('IhourlyCart.HandlingFree')}</HandlingFree>
                    <HandlingFreeText>{t('IhourlyCart.HandlingFreeText')}</HandlingFreeText>
                  </LeftDiv>
                  <Divider />
                  <RightDiv>
                    <Amount>{t('IhourlyCart.PaymentMethodText')}</Amount>
                    {paymentMethod === 6 && <Amount>{t('IhourlyCart.VisaText')}</Amount>}
                    <UpperDiv>
                      <MethodsDiv onClick={() => setPaymentMethod(visaId)}>
                        <CheckboxDiv>
                          <RadiusCheckbox
                            shape="round"
                            content={<PaymentMethodText>{t('IhourlyCart.Visa')}</PaymentMethodText>}
                            checked={paymentMethod === visaId}
                          />
                        </CheckboxDiv>
                        <CardImg src={Card} />
                      </MethodsDiv>
                      <MethodsDiv onClick={() => setPaymentMethod(ppsId)}>
                        <CheckboxDiv>
                          <RadiusCheckbox
                            shape="round"
                            content={<PaymentMethodText>{t('IhourlyCart.PPS')}</PaymentMethodText>}
                            checked={paymentMethod === ppsId}
                          />
                        </CheckboxDiv>
                        <CardImg src={PPSImg} />
                      </MethodsDiv>
                    </UpperDiv>
                    <div
                      style={{
                        height: 1,
                        width: '100%',
                        backgroundColor: '#ccc',
                        marginBottom: 10,
                      }}
                    />
                    <LowerDiv>
                      <Amount>{t('IhourlyCart.OtherMethod')}</Amount>
                      <MethodsDiv onClick={() => setPaymentMethod(fpsId)}>
                        <CheckboxDiv>
                          <RadiusCheckbox
                            shape="round"
                            content={<PaymentMethodText>{t('IhourlyCart.FPS')}</PaymentMethodText>}
                            checked={paymentMethod === fpsId}
                          />
                        </CheckboxDiv>
                        <CardImg src={FPSImg} />
                      </MethodsDiv>
                      {paymentMethod === 6 && (
                        <div style={{ marginTop: 20, flexDirection: 'column' }}>
                          <SavedPayment>{t('IhourlyCart.SavedPayment')}</SavedPayment>
                          <RadioGroup controllerName="FirstDataTokenId" mobile>
                            {creditCards.map((creditCard) => (
                              <RadioButton
                                key={`FDT${creditCard.id}`}
                                value={creditCard.id.toString()}
                                name="FirstDataTokenId"
                                style={{ marginRight: 10 }}
                                onClick={() => setFirstDataTokenId(creditCard.id)}
                              >
                                <CreditCardImg
                                  src={creditCard.token[0] === '4' ? visa : mastercard}
                                />
                                <CardText style={{ cursor: 'pointer', color: '#666' }}>
                                  ••••&nbsp;
                                  {creditCard.token.slice(-4)}
                                </CardText>
                              </RadioButton>
                            ))}
                            <RadioButton
                              value="new"
                              name="FirstDataTokenId"
                              onClick={() => setFirstDataTokenId('new')}
                            >
                              <SavedPayment>{t('IhourlyCart.OtherCard')}</SavedPayment>
                            </RadioButton>
                          </RadioGroup>
                        </div>
                      )}
                    </LowerDiv>
                  </RightDiv>
                </PaymenyInner>
                <TermsContainer>
                  <Terms>{t('IhourlyCart.Terms')}</Terms>
                  <TermsDiv>
                    <TermsAndConditions />
                  </TermsDiv>
                </TermsContainer>
                <CheckDiv>
                  <CheckboxwithControl
                    shape="square"
                    name="isAgree"
                    content={<Agree>{t('IhourlyCart.isAgree')}</Agree>}
                    required
                  />
                </CheckDiv>
                <ButtonRow>
                  <Reset type="button" onClick={() => history.push(`/${language}/IhourlyCart/1`)}>
                    {t('IhourlyCart.Back')}
                  </Reset>
                  <Submit type="submit">{t('IhourlyCart.Next')}</Submit>
                </ButtonRow>
              </form>
            </FormContext>
          </PaymentContainer>
        </ContentContainer>
      </Container>
    </>
  );
}

export default Step2;
