import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import closeimg from '../../media/images/icons8-close-window-52.png';

const Container = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: ${(props) => (props.Popupshow ? 'flex' : 'none')};
  z-index: 9999;
  overflow: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 680px) {
    padding: 0;
  }

  &:-webkit-scrollbar {
    display: none;
  }
`;

const MainWidth = styled.div`
  background-color: #fff;
  z-index: 2000;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  box-shadow: 0 0 2px #bbb;
  margin: auto;
  min-width: 400px;

  @media (max-width: 680px) {
    width: 90%;
    min-width: auto;
    //margin: 0px 0px 0px 0px;
  }
`;

const ContentContainer = styled.div`
  background: #dedede;
  display: flex;
  width: 100%;
`;

const ContentWidth = styled.div`
  background: #fff;
  padding: 20px;
  width: 100%;

  @media (max-width: 680px) {
    padding: 10px;
  }
`;

const CloseButton = styled.button`
  background: #fff;
  border-radius: 200px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -15px;
  border: none;
  background: transparent;

  @media (max-width: 570px) {
    top: 5px;
    right: -5px;
  }
`;

const CloseImg = styled.img`
  width: 25px;
  height: 25px;
  z-index: 2000;
  border-radius: 100px;
  border: 2px solid #fff;
  box-shadow: 0 2px 2px #6c6c6c;
`;

const BackDrop = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PopUpModal = ({ onClose, Popupshow, children, center, style, widthStyle, contentStyle }) => {
  const { i18n } = useTranslation();
  const targetRef = useRef();

  useEffect(() => {
    if (Popupshow) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.touchAction = 'auto';
    }
  }, [Popupshow]);

  return (
    <>
      {Popupshow && (
        <Container Popupshow={Popupshow} center={center} style={style}>
          <BackDrop language={i18n.language} onClick={onClose} />

          <MainWidth style={widthStyle}>
            <CloseButton onClick={onClose}>
              <CloseImg src={closeimg} />
            </CloseButton>

            <ContentContainer ref={targetRef}>
              <ContentWidth style={contentStyle}>{children}</ContentWidth>
            </ContentContainer>
          </MainWidth>
        </Container>
      )}
    </>
  );
};

export default PopUpModal;
