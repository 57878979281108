/* eslint-disable no-unused-vars,no-console */
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import useWindowSize from '@rehooks/window-size';
import { useForm, FormContext, ErrorMessage } from 'react-hook-form';
import * as yup from 'yup';
import { useSelectOptions } from '../../models/User';
import {
  useEmailVerifyMyprofile,
  useSMSVerify,
  useRegister,
  TokenContext,
} from '../../models/Customer';
import {
  PageContainer,
  MainContainer,
  Divider,
  ItemMargin,
  CheckBoxMargin,
  RegistrationFormHeader,
  RequiredNote,
  PasswordNote,
  SectionTitle,
  FieldTitle,
  FieldContainer,
  TextField,
  SelectField,
  AddressContainer,
  AddressField,
  RegionSelector,
  DistrictField,
  DistrictSelector,
  Checkbox,
  Button,
  MobileNumerContainer,
  MobileNumberContainer,
  MobileNumberMargin,
  MobileNumberRow,
  MobileNumberItem,
  ButtonContainer,
  ButtonItem,
  RecapchaContainer,
  MainContainerInner,
  FieldInner,
  PhoneButton,
  VerifyButton,
  SubmitButton,
} from '../../components/FormComponents';
import Modal from '../../components/General/PopupPrivacyPolicy';
import { DEFAULT_VALUE } from './constants';
import FieldError from '../../components/FormComponents/FieldError';

const Registration = ({ location }) => {
  const { isEmailValid, VerifyEmail } = useEmailVerifyMyprofile();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const {
    prefixOptions,
    carType,
    setSelectedRegion,
    setSelectedDistrict,
    selectedRegion,
    districtOptions,
    selectedDistrict,
    regionOptions,
    languageOptions,
  } = useSelectOptions(t);
  const { isSMSVerified, verifyMobile, setIsSMSVerified, isPhoneExist, RGCheckPhoneExist } =
    useSMSVerify();
  const { registerAPI } = useRegister();
  const [isLoading, setIsLoading] = useState(false);

  const [Popupshow, setPopupShow] = React.useState();
  const [savedState, setSavedState] = useState({});
  const { state } = location;

  const methods = useForm({
    defaultValues: DEFAULT_VALUE,
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      confirmEmail: yup
        .string()
        .oneOf([yup.ref('email')])
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')])
        .required(),
      email: yup
        .string()
        .required('Registration.EmailEnterError')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Registration.EmailError'),
      password: yup
        .string()
        .matches(/^(?=.*[A-Z])(?=.*\d).{8,15}$/)
        .required(),
      prefix: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      chineseFirstName: yup.string(),
      chineseLastName: yup.string(),
      address: yup.string(),
      LocationId: yup.number().nullable(),
      preferredLanguage: yup.string().required(),
      registrationMark: yup
        .string()
        .max(8)
        .matches(/^(?:([A-HJ-NPR-Z0-9])(?!\1{4,})){1,8}$/, {
          excludeEmptyString: true,
        })
        .test('', '', (e) => {
          if (e.match(/\s{2,}/g) || e.match(/\s$/)) {
            return false;
          }
          return true;
        }),
      VerificationCode: yup.string().required(),
      phone: yup
        .string()
        .required('Registration.phoneEnterError')
        .matches(/^1[0-9]{10}$|^[456789][0-9]{7}$/, t('Registration.phoneError')),
      isAgreedToTerms: yup.boolean().default(false).oneOf([true]),
      isAgreedToPrivacy: yup.boolean().default(false).oneOf([true]),
      isReceivePromotion: yup.boolean().default(true),
      Reaptcha: yup
        .boolean()
        .default(false)
        .test('Reaptcha', 'Reaptcha must be true', (val) => val === true),
    }),
  });

  const onSubmit = (data) => {
    if (isEmailValid) return;
    const prefixdata = methods.getValues('prefix');
    const preferredLanguagedata = methods.getValues('preferredLanguage');
    const typedata = methods.getValues('carType');
    if (!savedState?.phone && isSMSVerified !== true) {
      setIsSMSVerified(false);
      return;
    }

    setIsLoading(true);
    const {
      address,
      firstName,
      lastName,
      chineseFirstName,
      chineseLastName,
      email,
      password,
      phone,
      isAgreedToTerms,
      isAgreedToPrivacy,
      isReceivePromotion,
      registrationMark,
    } = data;

    registerAPI(
      {
        LocationId: selectedDistrict,
        address,
        firstName,
        lastName,
        chineseFirstName,
        chineseLastName,
        language: preferredLanguagedata.value,
        title: prefixdata.value,
        User: { email, password },
        phone,
        isAgreedToPrivacy,
        isAgreedToTerms,
        isReceivePromotion,
        isReceiveSms: true,
        car: { type: typedata ? typedata.value : null, registrationMark },
        email,
      },
      setIsLoading
    );
  };

  useEffect(() => {
    if (savedState) {
      Object.entries(savedState).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
    }
  }, [savedState]);

  const WatchVerificationCode = methods.watch('VerificationCode');

  useEffect(() => {
    if (WatchVerificationCode.length === 4 && !isPhoneExist)
      verifyMobile(methods.getValues('phone'), methods.getValues('VerificationCode'), false);
  }, [WatchVerificationCode, isPhoneExist]);

  useEffect(() => {
    setSavedState(state);
  }, []);

  useEffect(() => {
    if (!savedState) {
      history.push({ pathname: `/${i18n.language}/customers/login`, state: 'registration' });
    }
  }, [savedState]);

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PageContainer>
            <RegistrationFormHeader title={t('Registration.Header')} />

            <MainContainer>
              <MainContainerInner>
                <RequiredNote note={t('Registration.Required')} />
                <SectionTitle title={t('Registration.Title1')} />

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle
                      required
                      title={t('Registration.Email')}
                      name="email"
                      isEmailValid={isEmailValid}
                    />
                    <TextField
                      name="email"
                      placeHolder={t('Registration.EmailPlaceholder')}
                      isEmailValid={isEmailValid}
                      errorMessage={t(`${methods.errors.email?.message}`)}
                      t={t}
                      onBlur={() => VerifyEmail(methods.watch('email'))}
                      disabled={savedState?.email}
                    />
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle required title={t('Registration.ComEmail')} name="confirmEmail" />
                    <TextField
                      name="confirmEmail"
                      errorMessage={t('Registration.ConfirmEmailError')}
                      disabled={savedState?.confirmEmail}
                    />
                  </FieldInner>
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle required title={t('Registration.Password')} name="password" />
                    <TextField
                      name="password"
                      type="password"
                      errorMessage={t('Registration.PasswordError')}
                      disabled={savedState?.password}
                    >
                      <PasswordNote note={t('Registration.PasswordRequest')} />
                    </TextField>
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle
                      required
                      title={t('Registration.ComPassword')}
                      name="confirmPassword"
                    />
                    <TextField
                      name="confirmPassword"
                      type="password"
                      errorMessage={t('Registration.ConfirmPasswordError')}
                      disabled={savedState?.confirmPassword}
                    />
                  </FieldInner>
                </FieldContainer>

                <Divider />

                <MobileNumerContainer>
                  <SectionTitle title={t('Registration.Title4')} note={t('Registration.Note')} />
                  <MobileNumberContainer>
                    <MobileNumberMargin>
                      <FieldTitle required title={t('Registration.Mobile')} name="phone" />
                      <MobileNumberRow>
                        <TextField
                          name="phone"
                          errorMessage={t(`${methods.errors.phone?.message}`)}
                          isPhoneExist={isPhoneExist}
                          t={t}
                          disabled={savedState?.phone}
                          style={{
                            flex: savedState?.phone && 1,
                            maxWidth: savedState?.phone && '100%',
                          }}
                        />
                        {!savedState?.phone && (
                          <MobileNumberItem>
                            <PhoneButton
                              CheckPhoneExistAPI={RGCheckPhoneExist}
                              isPhoneExist={isPhoneExist}
                              language={i18n.language}
                              SMSVerified={isSMSVerified}
                            />
                          </MobileNumberItem>
                        )}
                      </MobileNumberRow>
                    </MobileNumberMargin>
                    <MobileNumberMargin
                      style={{
                        visibility: savedState?.phone && 'hidden',
                        position: savedState?.phone && 'absolute',
                      }}
                    >
                      <FieldTitle
                        required
                        title={t('Registration.VerificationCode')}
                        name="VerificationCode"
                      />
                      <MobileNumberRow>
                        <TextField
                          name="VerificationCode"
                          errorMessage={t('Registration.VerificationCodeError')}
                        />

                        <MobileNumberItem>
                          <VerifyButton
                            Title={t('Registration.Verify')}
                            onClick={() => {
                              if (isSMSVerified) return;
                              verifyMobile(
                                methods.getValues('phone'),
                                methods.getValues('VerificationCode'),
                                true
                              );
                            }}
                            Verified={isSMSVerified}
                          />
                        </MobileNumberItem>
                      </MobileNumberRow>
                    </MobileNumberMargin>
                  </MobileNumberContainer>
                </MobileNumerContainer>

                <Divider />

                <SectionTitle title={t('Registration.Title2')} />

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle required title={t('Registration.Prefix')} name="prefix" />
                    <SelectField
                      name="prefix"
                      options={prefixOptions}
                      errorMessage={t('Registration.prefixError')}
                      placeholder={t('Registration.PleaseSelect')}
                    />
                  </FieldInner>
                  <ItemMargin />
                  <FieldContainer />
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle required title={t('Registration.FirstNameEng')} name="firstName" />
                    <TextField name="firstName" errorMessage={t('Registration.firstNameError')} />
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle required title={t('Registration.LastNameEng')} name="lastName" />
                    <TextField name="lastName" errorMessage={t('Registration.lastNameError')} />
                  </FieldInner>
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle title={t('Registration.FirstNameZh')} />
                    <TextField name="chineseFirstName" />
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle title={t('Registration.LastNameZh')} />
                    <TextField name="chineseLastName" />
                  </FieldInner>
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle title={t('Registration.Address')} />
                    <AddressContainer>
                      <TextField address name="address" />
                      <AddressField>
                        <RegionSelector
                          name="Region"
                          options={regionOptions}
                          setSelectedRegion={setSelectedRegion}
                          placeholder={t('Registration.Region')}
                        />

                        <DistrictField>
                          <DistrictSelector
                            name="LocationId"
                            options={districtOptions}
                            setSelectedDistrict={setSelectedDistrict}
                            placeholder={t('Registration.District')}
                            isDisabled={selectedRegion === null}
                          />
                        </DistrictField>
                      </AddressField>
                    </AddressContainer>
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle
                      required
                      title={t('Registration.PreferredLanguage')}
                      name="preferredLanguage"
                    />
                    <SelectField
                      name="preferredLanguage"
                      options={languageOptions}
                      errorMessage={t('Registration.languageError')}
                      placeholder={t('Registration.PleaseSelect')}
                    />
                  </FieldInner>
                </FieldContainer>

                <Divider />
                <SectionTitle title={t('Registration.Title3')} />

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle title={t('Registration.Type')} />
                    <SelectField
                      name="carType"
                      getOptionLabel={(label) => label}
                      getOptionValue={(value) => value}
                      maxMenuHeight={500}
                      options={carType}
                      placeholder={t('Registration.PleaseSelect')}
                    />
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle
                      title={t('Registration.VehicleRegistrationMark')}
                      name="registrationMark"
                    />
                    <TextField
                      name="registrationMark"
                      errorMessage={t('Registration.registrationMarkError')}
                    />
                  </FieldInner>
                </FieldContainer>

                <Divider />

                <Divider />

                <CheckBoxMargin>
                  <Checkbox
                    name="isAgreedToTerms"
                    Text1={t('Registration.Iagree')}
                    Text2={t('Registration.AgreeTerm')}
                    onclick={() => {
                      setPopupShow('iMONTHLYTerms');
                    }}
                  />
                  <ErrorMessage
                    name="isAgreedToTerms"
                    errors={methods.errors}
                    as={<FieldError message={t('Registration.Required')} />}
                  />
                </CheckBoxMargin>

                <CheckBoxMargin>
                  <Checkbox
                    name="isAgreedToPrivacy"
                    Text1={t('Registration.Iagree')}
                    Text2={t('Registration.AgreePrivacy')}
                    onclick={() => {
                      setPopupShow('PrivacyPolicy');
                    }}
                  />
                  <ErrorMessage
                    name="isAgreedToPrivacy"
                    errors={methods.errors}
                    as={<FieldError message={t('Registration.Required')} />}
                  />
                </CheckBoxMargin>

                <Divider />

                <CheckBoxMargin>
                  <Checkbox name="isReceivePromotion" Text1={t('Registration.WishPro')} checked />
                </CheckBoxMargin>

                {/* <CheckBoxMargin>
                  <Checkbox name="isReceiveSms" Text1={t('Registration.WishSMS')} checked />
                </CheckBoxMargin> */}

                <RecapchaContainer name="Reaptcha" methods={methods} />

                <ButtonContainer>
                  <ButtonItem>
                    <Button
                      type="button"
                      Title={t('Registration.Reset')}
                      bottom
                      gray
                      onClick={() => {
                        methods.reset({ ...DEFAULT_VALUE, ...savedState });
                        setSelectedRegion(null);
                        setIsSMSVerified(undefined);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </ButtonItem>

                  <ButtonItem register>
                    <SubmitButton
                      name="Submit"
                      Title={t('Registration.Register')}
                      bottom
                      isLoading={isLoading}
                    />
                  </ButtonItem>
                </ButtonContainer>
              </MainContainerInner>
            </MainContainer>
          </PageContainer>
        </form>
      </FormContext>
      <Modal onClose={() => setPopupShow(false)} Popupshow={Popupshow} />
    </div>
  );
};

export default withRouter(Registration);
