import moment from 'moment';
import styled, { keyframes } from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { RadioGroup, RadioButton } from '../../../components/Cart';
import CardSelector from './CardSelector';

const twingle = keyframes`
  0%   { opacity:1; }
  25%  { opacity:0; }
  50%  { opacity:1; }
  75%  { opacity:0; }
  100% { opacity:1; }
`;

const PaymentMethodButton = styled.span`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 123px;
  color: #888;
  text-align: center;
  margin-top: 8px;
  padding: 0 20px;
  background: url(${(props) => props.backgroundUrl}) no-repeat center center #fff;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  border: ${({ errors }) => (errors ? '2px solid red' : '1px solid #ccc ')};
  animation: ${twingle} ${({ errors }) => errors && '1s 1s 4 ease-out'};
`;

const PaymentRow = styled.div`
  width: calc(50% - 10px);
  margin-right: 10px;

  @media (max-width: 850px) {
    width: 250px;
  }
`;

const Label = styled.span`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  margin-top: ${(props) => props.marginTop};
`;

export default function PaymentMethodSelector({
  // action,
  activePaymentMethod,
  creditCards,
  offlinePaymentIds,
  onlinePaymentIds,
  setFirstDataTokenId,
  errors,
  FirstDataTokenId,
  availablePaymentMethods,
  selectedValue,
  paymentMethodsLoading,
}) {
  const { t } = useTranslation();
  const T = (input) => t(`Cart.PaymentMethod.${input}`);

  const { chequeId = '', sevenElevenId = '' } = offlinePaymentIds || {};
  const { visaId = '', ppsId = '', fpsId = '' } = onlinePaymentIds || {};

  const Today = moment().format('D');

  const isLast2Day = Today >= moment().endOf('month').format('D') - 2;

  const defaultPaymentMethodId = [
    ...Object.values(offlinePaymentIds),
    ...Object.values(onlinePaymentIds),
  ];

  const isEnablePaymentMethods = availablePaymentMethods.filter(
    ({ id: paymentMethodId, isEnable, isOnlineMethod }) =>
      isEnable &&
      defaultPaymentMethodId.includes(paymentMethodId) &&
      (isOnlineMethod || !isLast2Day)
  );

  return (
    <div>
      <div>
        <Label fontSize="1.1rem" fontWeight="bold">
          {T('ChoosePrompt')}
        </Label>
        <br />
        <Label fontSize="0.9rem" marginTop="20px">
          {T('HKDDisclaimer')}
        </Label>
        <br />
        <br />
        <br />
        {paymentMethodsLoading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color="#dedede" height={50} width={50} />
          </div>
        )}
        {!paymentMethodsLoading && availablePaymentMethods.length > 0 ? (
          <RadioGroup
            controllerName="PaymentMethodRadio"
            mobile
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {isEnablePaymentMethods
              .filter(({ isEnable }) => isEnable)
              .map(({ icon: paymentImg, name, id: paymentMethodId }) => {
                const isDefaultPaymentMethods = defaultPaymentMethodId.includes(paymentMethodId);

                return (
                  <PaymentRow>
                    <RadioButton
                      checked={selectedValue === paymentMethodId.toString()}
                      value={paymentMethodId.toString()}
                      name="PaymentMethodRadio"
                      style={{ width: '100%' }}
                    >
                      <Label fontSize="1rem">{isDefaultPaymentMethods ? T(`${name}`) : name}</Label>
                      <PaymentMethodButton
                        backgroundUrl={`${process.env.REACT_APP_MEDIA_DOMAIN}${paymentImg}`}
                        errors={errors}
                      />
                    </RadioButton>
                    {paymentMethodId === visaId && (
                      <CardSelector
                        activePaymentMethod={activePaymentMethod}
                        creditCards={creditCards}
                        visaId={visaId}
                        setFirstDataTokenId={setFirstDataTokenId}
                        FirstDataTokenId={FirstDataTokenId}
                      />
                    )}
                  </PaymentRow>
                );
              })}
          </RadioGroup>
        ) : (
          <Label fontSize="1.2rem" style={{ color: errors ? 'red' : '#fff' }}>
            {t('Cart.Separate')}
          </Label>
        )}
      </div>
    </div>
  );
}
