export default function useGA() {
  function handleEventTracking(event, data) {
    if (window.gtag) {
      window.gtag('event', event, data);
      return;
    }
    console.error('GA error.');
  }

  return {
    handleEventTracking,
  };
}
