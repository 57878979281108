import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import { CustomerContext } from '../../../../models/User';
import * as icon from '../../../../media/icons';
import { CartContext } from '../../../../models/Cart';
import { useBooking, useBookings } from '../../../../models/Booking';
import Bottombar from '../Bottombar';
import ChangeService from './ChangeService';
import ChangeCar from './ChangeCar';
import BookingItem from '../BookingItem';

const IMonthly = () => {
  const { t, i18n } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const {
    renew,
    terminate,
    changeLPN,
    changeCarPark,
    isLoading: bookingLoading,
    tempChangeTerminate,
  } = useBooking();
  const { bookings, totalPage, bookingsLoading } = useBookings(pageNumber, true);

  const {
    singleCart: { book, removeAll, onSelectPaymentMethod, onSelectToken, checkout },
  } = useContext(CartContext);

  const { token } = useContext(CustomerContext);

  const history = useHistory();

  const [changeCar, setChangeCar] = useState(false);
  const [changeService, setChangeService] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceId, setServiceId] = useState('');
  const [booking, setBooking] = useState({});

  useEffect(() => {
    removeAll();
  }, []);

  const handleTerminate = (id) => {
    terminate(id)
      .then((status) => {
        if (status === 200) {
          Swal.fire({
            position: 'center',
            text: t('MyAccount.iMonthly.successfullyTerminated'),
            didClose: () => window.location.reload(),
            confirmButtonColor: '#fd980f',
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: 'top',
          text: t('MyAccount.iMonthly.SomethingWrong'),
          confirmButtonColor: '#fd980f',
        });
        console.error(err);
      });
  };

  const handleTempChangeTerminate = (id) => {
    tempChangeTerminate(id)
      .then((status) => {
        if (status === 200) {
          Swal.fire({
            position: 'center',
            text: t('MyAccount.iMonthly.successfullyTerminated'),
            didClose: () => window.location.reload(),
            confirmButtonColor: '#fd980f',
          });
        }
      })
      .catch(() => {
        Swal.fire({
          position: 'center',
          text: t('MyAccount.iMonthly.SomethingWrong'),
          confirmButtonColor: '#fd980f',
        });
      });
  };

  const handleCheckout = async () => {
    setLoading(true);
    await checkout(token)
      .then((id) => {
        setLoading(false);
        if (!id) {
          return;
        }
        history.push({
          pathname: `/${i18n.language}/cart/3`,
          state: { action: 'changeCar', spareOrderId: id },
        });
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };

  return (
    <>
      {bookingsLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
          <ReactLoading type="spokes" color="#fd980f" height={30} width={30} />
        </div>
      ) : (
        <>
          <div style={{ backgroundColor: '#f3f3f3', flex: 1, padding: '20px 30px' }}>
            <div style={{ display: 'flex' }}>
              <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
              <h5 style={{ marginLeft: 5, fontWeight: 'bold', fontSize: '1.125rem' }}>
                {t('MyAccount.iMonthly.PleaseRead')}
              </h5>
            </div>
            <span style={{ fontSize: '0.875rem', color: '#666' }}>
              {t('MyAccount.iMonthly.reminderContent')}
            </span>
          </div>

          <div style={{ background: '#fff' }}>
            <>
              {bookings.map((record, index) => {
                return (
                  <BookingItem
                    pageNumber={pageNumber}
                    key={record.id}
                    index={index}
                    booking={record}
                    book={async () => {
                      await book(await renew(record.id));

                      history.push({
                        pathname: `/${i18n.language}/cart/1`,
                        state: { action: 'Renew' },
                      });
                    }}
                    handleTerminate={handleTerminate}
                    changeService={() => {
                      setBooking(record);
                      setChangeService(true);
                    }}
                    changeCar={() => {
                      setBooking(record);
                      setServiceId(record.id);
                      setChangeCar(true);
                    }}
                    handleTempChangeTerminate={handleTempChangeTerminate}
                  />
                );
              })}
              <Bottombar page={pageNumber} setPage={setPageNumber} totalPage={totalPage} />
            </>
          </div>

          <ChangeService
            show={changeService}
            setChangeService={setChangeService}
            book={async (item) => {
              book(await changeCarPark(item));
            }}
            booking={booking}
          />

          <ChangeCar
            booking={booking}
            show={changeCar}
            onHide={() => {
              setBooking({});
              removeAll();
              setChangeCar(false);
            }}
            removeAll={removeAll}
            onSelectPaymentMethod={onSelectPaymentMethod}
            onSelectToken={onSelectToken}
            changeLPN={changeLPN}
            serviceId={serviceId}
            book={book}
            handleCheckout={handleCheckout}
            loading={loading}
            bookingLoading={bookingLoading}
          />
        </>
      )}
    </>
  );
};

export default IMonthly;
