import { useTranslation } from 'react-i18next';
import React from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Column, Text } from './style';
import FileUpload from './ImageUpload';
import useOCR from '../../../models/OCR';
import StyledButton from '../../../components/General/StyledButton';
import yellowline from '../../../media/icons/yellowline.png';
import info from '../../../media/icons/info.png';

export default function ChequePaymentDetail({
  detail,
  OrderDetail,
  setReceipts,
  receipts,
  index,
  billNumber,
  orderId,
}) {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { checkOCR, OCRResult, OCRLoading } = useOCR();

  const { servicePeriod, registrationMark, amount, CarParkName, subTitle, orderReference } =
    OrderDetail || {};

  return (
    <Column className="item" data-value={detail}>
      <Text fontsize="20px" margintop="10px" bold>
        {t('chequePayment.payBy')}
      </Text>
      <Text fontsize="17px" bold style={{ color: 'rgb(231, 76, 60)' }}>
        {t('chequePayment.payable')}
      </Text>
      <Text fontsize="17px" margintop="-10px" bold style={{ color: 'rgb(231, 76, 60)' }}>
        {t('chequePayment.name')}
      </Text>
      <Text fontsize="15px">
        {t('chequePayment.billType')}
        <br />
        {t('chequePayment.billType1')}
      </Text>

      <Text fontsize="20px" margintop="10px" bold>
        {t('chequePayment.billAc')}
        {`[${billNumber}]`}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.text11')}
        {CarParkName}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.text4')}
        {t(`PaymentRecords.renew`)}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.service')}
        {`[${subTitle}]`}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.text5')}
        {servicePeriod}
      </Text>
      <Text bold fontsize="15px">
        {t('sevenPayment.text6')}
        {registrationMark}
      </Text>
      <Text fontsize="36px" bold>
        HK$
        {amount}
      </Text>
      <Text fontsize="13px" margintop="-15px" style={{ color: 'rgb(231, 76, 60)' }}>
        {t('sevenPayment.text7')}
      </Text>
      <FileUpload
        setReceipts={setReceipts}
        receipts={receipts}
        index={index}
        finallyAmount={amount}
        orderId={orderId}
        checkOCR={checkOCR}
        OCRResult={OCRResult}
        type="cheque"
        OCRLoading={OCRLoading}
      />
      {Object.keys(OCRResult).length > 0 && (
        <StyledButton
          type="button"
          label={t('sevenPayment.complete')}
          color="white"
          style={{ maxWidth: 200, minHeight: 50, maxHeight: 50, marginTop: 20 }}
          onClick={() => {
            Swal.fire({
              title: t('sevenPayment.progress'),
              html:
                `<img src=${yellowline} width='100' height='100'></img>` +
                `<div id='PaymentLinkInnerTextContainer'>${t('sevenPayment.no')}</div>` +
                `${orderReference}` +
                `<div id='PaymentLinkInnerInfo'>
                          <div id='PaymentLinkInnerInfoDiv'>
                            <img src=${info} width='30' height='30'></img>
                          </div>
                          <div id='PaymentLinkInnerTextDiv'>
                            ${t('sevenPayment.confirm')}
                          </div>
                        </div>`,
              confirmButtonColor: '#888',
              confirmButtonText: t('sevenPayment.back'),
              didClose: () => history.push({ pathname: `/${i18n.language}` }),
              allowOutsideClick: false,
              customClass: {
                content: 'PaymentLinkPopup',
              },
            });
          }}
        />
      )}
    </Column>
  );
}
