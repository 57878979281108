import styled from 'styled-components';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import icon from '../../../media/images/empty-cart-icon.png';
import arrow from '../../../media/images/quicklinkinner.png';
import { CartContext } from '../../../models/Cart';

const QuickAccessImg = styled.span`
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: contain;
  background-size: contain;
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  color: #000000;
  @media (max-width: 980px) {
    height: 35px;
  }
  height: 50px;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 50px;

  @media (max-width: 980px) {
    height: 35px;
  }
`;

const QuickAccessContainer = styled.div`
  position: relative;
  overflow: visible;
  padding-top: 13px;
  width: 25%;
  vertical-align: middle;
  display: inline-block;
  flex: 0 0 25%;
  max-width: 25%;
  min-height: 100px;

  @media (max-width: 980px) {
    min-height: 84px;
  }

  @media (max-width: 750px) {
    padding-top: 6px;
    padding-bottom: 0px;
    min-height: 73px;
  }

  &:hover {
    ${QuickAccessImg} {
      background-image: url(${({ hoverimg }) => hoverimg});
    }
    background: #fd980f;
    &:after {
      display: ${(props) => (props.dropdown ? 'flex' : 'none')};
      content: url(${arrow});
      z-index: 500;
      width: 26px;
      height: 16px;
      position: absolute;
      top: 100%;
      left: 50%;
      -o-transform: translate(-13px, 0);
      -ms-transform: translate(-13px, 0);
      -webkit-transform: translate(-13px, 0);
      transform: translate(-13px, -2px);
      @media (max-width: 980px) {
        display: none;
      }
    }
  }
`;

const QuickAccessLink = styled(Link)`
  font-weight: bold;
  max-width: 7.5rem;
  font-size: 13px;
  line-height: 15px;
  display: block;
  text-decoration: none;
  color: #fd980f;
  position: relative;
  text-align: center;
  position: relative;
  margin: 0 auto;

  &:before {
    display: ${(props) => (props.dropdown && props.cart > 0 ? 'flex' : 'none')};
    content: '${(props) => (props.cart ? props.cart : null)}';
    position: absolute;
    left: 58%;
    color: #fff;
    background: #fd980f;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 10;
  }
`;

const QuickAccessTextDiv = styled.span`
  font-weight: bold;
  margin: 0 auto;
  font-size: 0.75rem;
  display: inline-block;
  vertical-align: middle;
  color: #333333;
  clear: both;
  width: 100%;
  text-align: center;
  height: 2rem;
  margin-top: 2px;
  position: relative;

  @media (max-width: 750px) {
    vertical-align: middle;
    margin-top: 0px;
  }
`;

const QuickAccessText = styled.span`
  vertical-align: middle;
  display: inline-block;
  width: 90%;
  position: relative;
  top: 50%;
  line-height: 1.1;
  -o-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  font-weight: bold;

  ${QuickAccessContainer}:hover & {
    color: #fff;
  }

  @media (max-width: 750px) {
    vertical-align: middle;
    transform: scale(0.7);
    margin-top: -21px;
    width: 100%;
  }
`;

const DropDownDiv = styled.div`
  padding: 10px 10px;
  border: 1px solid #cccccc;
  display: none;
  z-index: 500;
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  width: auto;
  max-width: 360px;

  ${QuickAccessContainer}:hover & {
    display: flex;
    @media (max-width: 980px) {
      display: none;
    }
  }
`;

const CartRecord = styled.div`
  width: 100%;
  border-bottom: 1px solid #dedede;
  display: flex;
  padding-bottom: 10px;
`;

const DorpDownInner = styled.div`
  padding: 10px 10px;
  width: 100%;
  text-align: center;
  min-width: 300px;
  clear: both;
  font-size: 0.75rem;
  overflow: hidden;
  color: #888;

  & > ${CartRecord}:not(:first-child) {
    margin-top: 10px;
  }
`;

const DorpDownIcon = styled.span`
  background-image: url(${icon});
  height: 80px;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  background-position: center;
  margin: 0 0 10px;
`;

const DropDownLink = styled(Link)`
  color: #ffffff;
  font-weight: bold;
  line-height: 3rem;
  background-color: #fd980f;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border: none;
  vertical-align: middle;
  border-radius: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }
`;

const LeftDiv = styled.div`
  flex-basis: 65%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  span {
    text-align: left;
  }
`;

const RightDiv = styled.div`
  flex-basis: 35%;
  display: flex;
  justify-content: flex-end;
  color: #fd980f;
  font-size: 1.25rem;
  margin-top: 8px;
`;

function QuickAccessBlock({ detail }) {
  const { t, i18n } = useTranslation();
  const {
    generalCart: { cart },
  } = useContext(CartContext);
  const CartNumber = cart?.length;

  return (
    <QuickAccessContainer dropdown={detail.dropdown} hoverimg={detail.hover}>
      <QuickAccessLink
        to={detail.link}
        onClick={detail.onClick}
        dropdown={detail.dropdown}
        cart={CartNumber}
      >
        <QuickAccessImg img={detail.icon} />
        <QuickAccessTextDiv>
          <QuickAccessText>
            {t(detail.title)}
            <br />
            {t(detail.title1)}
          </QuickAccessText>
        </QuickAccessTextDiv>
      </QuickAccessLink>
      {detail.dropdown && (
        <DropDownDiv>
          {CartNumber <= 0 && (
            <DorpDownInner>
              <DorpDownIcon />
              {t('header.CartText')}
              <br />
              <DropDownLink to={`/${i18n.language}/car_parks/map`}>
                {t('header.find_carpark')}
              </DropDownLink>
            </DorpDownInner>
          )}
          {CartNumber > 0 && (
            <DorpDownInner>
              {cart.map(({ service, name, fee, ParkingPlanId, createdDate }) => (
                <CartRecord key={`${service}${ParkingPlanId}${createdDate}`}>
                  <LeftDiv>
                    <span style={{ color: '#333', fontSize: 20 }}>{name}</span>
                    <span style={{ color: '#666', fontSize: 14 }}>{service}</span>
                  </LeftDiv>
                  <RightDiv>{`HK$${fee}`}</RightDiv>
                </CartRecord>
              ))}
              <div style={{ marginTop: 10 }}>
                <DropDownLink to={`/${i18n.language}/cart`}>
                  {t('header.CartCheckout')}
                </DropDownLink>
              </div>
            </DorpDownInner>
          )}
        </DropDownDiv>
      )}
    </QuickAccessContainer>
  );
}

export default QuickAccessBlock;
