import styled from 'styled-components';
import React from 'react';
import { Checkbox } from 'pretty-checkbox-react';

const Container = styled.div`
  &:focus {
    outline: none !important;
  }

  & .pretty {
    &:focus {
      outline: none !important;
    }
  }

  & .pretty input:checked ~ .state.p-primary label:after {
    background-color: #fd980f !important;
  }

  & .p-round {
    background: transparent;
  }

  &:hover {
    & .pretty .state label:before {
      border-color: ${(props) => props.shape === 'square' && '#000'};
    }
  }
`;

function RadiusCheckbox({ content, checked, shape, onchange, disabled, value }) {
  return (
    <Container shape={shape}>
      <Checkbox
        shape={shape}
        color="primary"
        checked={checked}
        onChange={onchange}
        disabled={disabled}
        value={value}
      >
        {content}
      </Checkbox>
    </Container>
  );
}

export default RadiusCheckbox;
