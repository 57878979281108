import styled from 'styled-components';

const DetailRightBox = styled.div`
  min-width: 0;
  margin-left: auto;
  max-width: 236px;
  padding: 15px 15px 15px 30px;
  min-height: 236px;
  background-color: #444;
  position: relative;
  transition: height 0.3s ease-in-out;
  overflow: hidden;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    vertical-align: middle;
    top: 15px;
    position: absolute;
    left: 0;
  }

  &:after {
    border-left-color: #fff;
  }

  @media (max-width: 850px) {
    max-width: 100%;
    min-height: 100%;
    height: ${(props) => (props.open ? '263px' : '70px')};
  }
`;

export default DetailRightBox;
