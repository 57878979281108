import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import commonStyle from '../../../styles/common';
import cartStyle from '../../../styles/cart';
import octopusCardHorizontal from '../../../assets/images/octopusCard_horizontal.png';
import visa from '../../../assets/images/visa.png';
import mastercard from '../../../assets/images/mastercard.png';
import octopusInstruct from '../../../assets/images/octopus_instruct.png';
import activateInstruction from '../../../assets/images/activate_instruction.png';
import { useIPark, useCars } from '../../../models/User';
import ProgressBar from '../../../components/General/ProgressBar';
import { ActionButton, CardInfoDisplay } from './iPark';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';
import StyledLink from '../../../components/General/StyledLink';
import StyledButton from '../../../components/General/StyledButton';

const IParkVerifySteps = () => {
  const { step } = useParams();
  const [method, setMethod] = useState('octopus');
  const { t } = useTranslation();

  const renderSteps = () => {
    switch (step) {
      case '2':
        return <Step2 method={method} />;
      case '3':
        return <Step3 method={method} />;
      default:
        return <Step1 method={method} setMethod={setMethod} />;
    }
  };

  const stepDisplay = [
    { step: '1', label: t('IParkVerifySteps.step1') },
    { step: '2', label: t('IParkVerifySteps.step2') },
    { step: '3', label: t('IParkVerifySteps.step3') },
  ];

  return (
    <div style={{ flex: 1 }}>
      <ProgressBar
        title={t('IParkVerifySteps.title')}
        currentStep={step}
        stepDisplay={stepDisplay}
      />

      <div style={commonStyle.pageContainer}>
        <div style={commonStyle.contentContainer}>
          <div style={{ ...cartStyle.whiteContainer, padding: '40px 30px' }}>{renderSteps()}</div>
        </div>
      </div>
    </div>
  );
};

const Step2Container = styled.div`
  display: flex;

  div:first-child {
    margin-right: 20px;
    @media (max-width: 670px) {
      margin-right: 0px;
    }
  }

  div:last-child {
    @media (max-width: 670px) {
      margin-top: 20px;
    }
  }

  @media (max-width: 670px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const MethodsRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 670px) {
    justify-content: center;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media (max-width: 670px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export default IParkVerifySteps;

const Step1 = ({ method, setMethod }) => {
  const obj = JSON.parse(localStorage.getItem('iPark'));
  const { t } = useTranslation();

  return (
    <div style={{ flex: 1 }}>
      <p style={{ fontWeight: 'bold', fontSize: '0.9rem', marginBottom: 30 }}>
        {t('IParkVerifySteps.select')}
      </p>

      <Step2Container>
        <MethodsRow>
          <h5 style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
            {t('IParkVerifySteps.octopus')}
          </h5>
          <button
            type="button"
            style={{
              border: method === 'octopus' ? '1px solid #fd980f' : '1px solid #ccc',
              padding: 10,
              background: 'transparent',
              width: 140,
            }}
            onClick={() => {
              const updatedObj = { ...obj, verificationMethod: 'octopus' };
              localStorage.setItem('iPark', JSON.stringify(updatedObj));
              setMethod('octopus');
            }}
          >
            <img alt="" src={octopusCardHorizontal} width="120" />
          </button>
        </MethodsRow>

        <MethodsRow>
          <h5 style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
            {t('IParkVerifySteps.creditCard')}
          </h5>
          <button
            type="button"
            style={{
              border: method === 'creditCard' ? '1px solid #fd980f' : '1px solid #ccc',
              padding: 10,
              display: 'flex',
              background: 'transparent',
              width: 140,
            }}
            onClick={() => {
              const updatedObj = { ...obj, verificationMethod: 'creditCard' };
              localStorage.setItem('iPark', JSON.stringify(updatedObj));
              setMethod('creditCard');
            }}
          >
            <img alt="" src={visa} width="45" />
            <div style={{ ...commonStyle.verticalLine, background: '#ccc' }} />
            <img alt="" src={mastercard} width="45" />
          </button>
        </MethodsRow>
      </Step2Container>

      <ButtonRow>
        <StyledLink
          widthoutMargin
          label={t('IParkVerifySteps.back')}
          color="grey"
          to="/customers/account"
          style={{ minHeight: 40 }}
        />
        <StyledLink
          widthoutMargin
          to="/customers/activate/2"
          label={t('IParkVerifySteps.next')}
          color="yellow"
          style={{ minHeight: 40 }}
        />
      </ButtonRow>
    </div>
  );
};

const Step2 = ({ method }) => {
  const { cardNumber, setCardNumber, validateOctopusCardNumber, cardNumberValidated } = useIPark();

  const { t } = useTranslation();

  const [toStep3, setToStep3] = useState(false);

  const { outerWidth, outerHeight } = ScreenWidthListener();

  const popupWidth = useMemo(() => Math.max((outerWidth / 100) * 50, 530), [outerWidth]);

  const handlePopUp = () => {
    window.open(
      '/customers/validate_credit_card',
      'Secure Payment',
      `height=${(outerHeight / 100) * 50},width=${popupWidth}`
    );
    window.setToStep3 = setToStep3;
  };

  useEffect(() => {
    if (method === 'creditCard') {
      handlePopUp();
    }
  }, []);

  useEffect(() => {
    if (toStep3 === true) {
      window.location = '/customers/activate/3';
    }
  }, [toStep3]);

  useEffect(() => {
    if (cardNumberValidated === true) {
      const obj = JSON.parse(localStorage.getItem('iPark'));
      const updatedObj = { ...obj, cardNumber };
      localStorage.setItem('iPark', JSON.stringify(updatedObj));
      window.location = '/customers/activate/3';
    }
  }, [cardNumberValidated]);

  const validatedStyle = cardNumberValidated === false ? '#E56E5A' : '#888';

  return (
    <>
      {method === 'octopus' ? (
        <>
          <p>{t('IPark.step2Title')}</p>
          <img alt="" src={octopusInstruct} />
          <p style={{ fontSize: '0.8rem', marginTop: 30, color: validatedStyle }}>
            {t('IPark.stepText')}
          </p>
          <p
            style={{
              fontSize: '0.8rem',
              marginTop: -15,
              color: validatedStyle,
              margin: 0,
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              maxLength="8"
              style={{
                width: 220,
                paddingLeft: 10,
                border: `1px solid ${validatedStyle}`,
                marginTop: 10,
              }}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            {cardNumberValidated === false && (
              <p
                style={{
                  fontSize: '0.8rem',
                  marginLeft: 15,
                  color: '#E56E5A',
                  margin: 0,
                }}
              >
                {t('IPark.octopusError')}
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <p style={{ margin: 0 }}>{t('IPark.step2CardTitle')}</p>
          <button
            type="button"
            style={{
              fontSize: '0.9rem',
              color: '#fd980f',
              border: 'none',
              background: 'transparent',
              padding: 0,
            }}
            onClick={handlePopUp}
          >
            {t('IPark.step2CardText')}
          </button>
        </>
      )}

      <ButtonRow>
        <StyledLink
          widthoutMargin
          label={t('IParkVerifySteps.back')}
          color="grey"
          to="/customers/activate/1"
          style={{ minHeight: 40 }}
        />
        {method === 'octopus' && (
          <StyledLink
            widthoutMargin
            onClick={validateOctopusCardNumber}
            label={t('IParkVerifySteps.next')}
            color="yellow"
            style={{ minHeight: 40 }}
          />
        )}
      </ButtonRow>
    </>
  );
};

const ValidateCreditCard = () => {
  const { cardNumber, setCardNumber, validateCreditCardNumber, cardNumberValidated } = useIPark();

  const [showAlert, setShowAlert] = useState('none');

  const { t } = useTranslation();

  useEffect(() => {
    if (cardNumberValidated === true) {
      const obj = JSON.parse(localStorage.getItem('iPark'));
      const updatedObj = { ...obj, cardNumber };
      localStorage.setItem('iPark', JSON.stringify(updatedObj));
      window.close();
      window.opener.setToStep3(true);
    }
    if (cardNumberValidated === false) {
      setShowAlert('block');
    }
  }, [cardNumberValidated]);

  const handleAuthorizeCreditCard = () => {
    validateCreditCardNumber();
  };

  return (
    <div
      style={{
        background: '#fff',
        height: '100vh',
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>{t('IPark.name')}</h1>
        <p>{t('IPark.secure')}</p>
        <Alert variant="danger" style={{ display: showAlert }}>
          {t('IPark.cardError')}
        </Alert>
        <label>{t('IPark.card')}</label>
        <input
          type="text"
          maxLength="19"
          className="flex-polyfill-1"
          style={{ flex: 1, padding: 5 }}
          onChange={(e) => {
            setShowAlert('none');
            setCardNumber(e.target.value);
          }}
          value={cardNumber}
          required
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: 25,
            }}
          >
            <label>{t('IPark.expiration')}</label>
            <div className="d-flex-polyfill" style={{ display: 'flex' }}>
              <input
                type="text"
                placeholder="MM"
                maxLength="2"
                className="flex-polyfill-1"
                style={{ flex: 1, padding: 5 }}
                required
              />
              <input
                type="text"
                placeholder="YY"
                maxLength="2"
                className="flex-polyfill-1"
                style={{ flex: 1, padding: 5 }}
                required
              />
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <label>{t('IPark.cvv')}</label>
            <input type="text" style={{ padding: 5 }} maxLength="3" required />
          </div>
        </div>

        <button
          type="button"
          style={{
            marginTop: 20,
            marginBottom: 10,
            padding: 5,
            background: '#007bff',
            border: 'none',
            color: '#fff',
          }}
          onClick={handleAuthorizeCreditCard}
        >
          {t('IPark.authorize')}
        </button>
        <button
          type="button"
          style={{
            padding: 5,
            background: '#F0F0F0',
            border: 'none',
          }}
          onClick={() => window.close()}
        >
          {t('IPark.cancel')}
        </button>
      </div>
    </div>
  );
};

export { ValidateCreditCard };

const Steop3Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 670px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const WidthView = styled.div`
  width: 65%;

  @media (max-width: 670px) {
    width: 100%;
  }
`;

const Img = styled.img`
  height: 25%;
  width: 25%;
  @media (max-width: 670px) {
    height: 60%;
    width: 60%;
  }
`;

const Step3 = () => {
  const { updateVerificationMethod } = useCars(1);
  const { cardNumber, registrationMark, verificationMethod } = JSON.parse(
    localStorage.getItem('iPark')
  );
  const { t } = useTranslation();

  const cardType = verificationMethod === 'octopus' ? 'octopus' : 'creditCard';
  const [displayCardNumber, setDisplayCardNumber] = useState('');

  useEffect(() => {
    if (verificationMethod === 'octopus') {
      setDisplayCardNumber(cardNumber.replace(/\s/g, ''));
    } else {
      const completeNumber = cardNumber;
      setDisplayCardNumber(completeNumber.slice(completeNumber.length - 5));
    }
  }, []);

  return (
    <Steop3Container>
      <Img src={activateInstruction} alt="activateInstruction" />

      <WidthView>
        <p style={{ fontSize: '0.9rem', paddingTop: 20 }}>
          {cardType === 'octopus' ? t('IPark.pleaceUseOctopus') : t('IPark.pleaceUseCredit')}
          <Link to="customers/ipark_list" style={{ textDecoration: 'none', color: '#fd980f' }}>
            &nbsp;
            {t('IPark.iParkSupport')}
            &nbsp;
          </Link>
          {t('IPark.once')}
        </p>
        <br />

        <span style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>{t('IPark.VRH')}</span>
        <h4>{registrationMark}</h4>

        <span style={{ fontSize: '0.8rem', fontWeight: 'bold', marginBottom: 30 }}>
          {t(`IParkVerifySteps.${cardType}`)}
        </span>

        <CardInfoDisplay cardType={verificationMethod} cardNumber={displayCardNumber} />

        <StyledButton
          onClick={() => updateVerificationMethod()}
          label={t('Cart.Confirm')}
          color="yellow"
          style={{ margin: 0, width: '100%', marginTop: 20, minHeight: 40, maxHeight: 40 }}
        />
      </WidthView>
    </Steop3Container>
  );
};
