import React, { useState, useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as icon from '../../../media/icons';
import CartItem from '../CartItem';
import { useCars } from '../../../models/Customer';
import StyledLink from '../../../components/General/StyledLink';
import StyledButton from '../../../components/General/StyledButton';
import { CarSelector } from '../CartFormComponents';
import CarRecord from './CarRecord';
import { CartItemContainer, ButtonRow, InnerRow, BackLink } from './style';

function Step1({ cart, onSelectCar, action, redirectToStep2 }) {
  const { t, i18n } = useTranslation();
  const methods = useForm();
  const [showModal, setShowModal] = useState(false);
  const [selectedCarType, setSelectedCarType] = useState();
  const { options: carOptions, createNewCars } = useCars();

  function handleChangeSelectedCarType(e) {
    setSelectedCarType(e);
  }

  const onSubmit = () => {
    redirectToStep2();
  };

  useEffect(() => {
    if (carOptions.length > 0) {
      cart.forEach((item, index) => {
        const filteredCarOptions = carOptions.filter((e) => e.CarTypeId === item.CarTypeId);

        if (filteredCarOptions.length === 0) {
          return;
        }

        const { value, label } = filteredCarOptions[0];

        if (item.registrationMark) {
          methods.setValue(`${index}`, {
            value: item.registrationMark,
            label: item.registrationMark,
          });
          onSelectCar(index, {
            id: item.CarId,
            registrationMark: item.registrationMark,
          });
        } else {
          methods.setValue(`${index}`, { value, label });
          onSelectCar(index, { id: value, registrationMark: label });
        }
      });
    }
  }, [carOptions]);

  return (
    <div>
      <Prompt
        when={action === 'Change'}
        message={(location) => {
          if (location?.pathname === '/cart/2') {
            return true;
          }

          return t('Cart.BackMessage');
        }}
      />

      <div style={{ display: 'flex', alignSelf: 'flex-start', margin: '15px 0 0 0' }}>
        <img src={icon.alert} width="21px" height="21px" alt="read_carefully" />
        &nbsp;&nbsp;
        <p style={{ fontSize: '1.125rem', fontWeight: 'bold', marginBottom: 10 }}>
          {`${t('Cart.Instructions.Header')}:`}
        </p>
      </div>

      <ol style={{ fontSize: '0.87rem', margin: '0 20px 25px 20px' }}>
        <li style={{ marginBottom: 5 }}>{t('Cart.Instructions.1-1')}</li>
        <li style={{ marginBottom: 5 }}>{t('Cart.Instructions.1-2')}</li>
      </ol>

      <CartItemContainer>
        {cart.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{ fontSize: '1.15rem' }}>{t('Cart.EmptyMessage')}</span>
          </div>
        ) : (
          <>
            <span style={{ marginLeft: 15, fontSize: '1.2rem' }}>{t('Cart.DetailHeader')}</span>

            <FormContext {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {cart.map((item, index) => {
                  return (
                    <CartItem
                      key={`${item.ParkingPlanId}${item.service}${index}`}
                      index={index}
                      details={item}
                      action={action}
                      showModal={() => {
                        setShowModal(true);
                        handleChangeSelectedCarType(item.CarTypeId);
                      }}
                      handleChangeSelectedCarType={handleChangeSelectedCarType}
                      carSelectField={
                        <CarSelector
                          placeholder="  "
                          name={`${index}`}
                          maxMenuHeight={500}
                          options={carOptions.filter((e) => e.CarTypeId === item.CarTypeId)}
                          rules={{ required: true }}
                          onChange={([selected]) => {
                            const { value: id, label: registrationMark } = selected;
                            onSelectCar(index, { id, registrationMark });
                            return selected;
                          }}
                        />
                      }
                    />
                  );
                })}

                <ButtonRow>
                  <BackLink to={`/${i18n.language}/customers/account`}>
                    {t('Cart.BackToService')}
                  </BackLink>

                  <InnerRow>
                    <StyledLink
                      color="white"
                      label={t('Cart.BookMore')}
                      to={`/${i18n.language}/car_parks/map`}
                    />

                    <StyledButton
                      disabled={carOptions.length === 0}
                      color="yellow"
                      label={t('Cart.Next')}
                      type="submit"
                      // to={{
                      //   pathname: '/cart/2',
                      //   state: { action },
                      // }}
                    />
                  </InnerRow>
                </ButtonRow>
              </form>
            </FormContext>
          </>
        )}
        <CarRecord
          showModal={showModal}
          setShowModal={setShowModal}
          handleChangeSelectedCarType={handleChangeSelectedCarType}
          selectedCarType={selectedCarType}
          createNewCars={createNewCars}
        />
      </CartItemContainer>
    </div>
  );
}

export default Step1;
