import React from 'react';
import styled from 'styled-components';
import '../../../components/General/Checkbox/style.scss';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  background: rgba(253, 152, 15, 0.93);
  padding: 15px;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 25px;

  @media (max-width: 850px) {
    flex-direction: column;
    background: #fd980f;
    padding: 0px;
  }
`;

const ContentBarWidth = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 850px) {
    flex-direction: column;
    padding-left: 0px;
    padding-bottom: 20px;
    padding-right: 0px;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  -webkit-flex: 1;
flex: 1;
  flex-direction: column;
  align-self: flex-start;

  @media (max-width: 850px) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 5px;
  }
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
  border-left: 1px solid #fff;
  -webkit-flex: 1;
flex: 1;

  @media (max-width: 850px) {
    border-top: 1px solid #b9710f;
    border-left: none;
    padding-left: 15px;
    padding-bottom: 5px;
  }
`;

const RightDivRow = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const RightDivRowTextDiv = styled.div`
  display: flex;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 0px;
  }
`;

const RightDivRowText = styled.span`
  color: #fff;
  font-size: 14px;
`;

const RightDivRowA = styled.a`
  color: #fff;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
`;

const RightDivFlex = styled.div`
  display: flex;
  -webkit-flex: 1;
flex: 1;
  padding-left: 20px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 5px;
  }
`;

const OfficeHourDiv = styled.div`
  display: flex;
  -webkit-flex: 1;
flex: 1;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 5px;
  }
`;
const ContentBar = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <ContentBarWidth>
          <LeftDiv />

          <RightDiv>
            <RightDivRow>
              <RightDivRowTextDiv>
                <RightDivRowText>{t('CsList.OfficeHour')}</RightDivRowText>
              </RightDivRowTextDiv>

              <OfficeHourDiv>
                <RightDivRowText>
                  {t('CsList.OfficeHour1')}
                  <br />
                </RightDivRowText>
                <RightDivRowText>{t('CsList.Except')}</RightDivRowText>
              </OfficeHourDiv>
            </RightDivRow>

            <RightDivRow>
              <RightDivRowTextDiv>
                <RightDivRowText>{t('CsList.EmailAddress')}</RightDivRowText>
              </RightDivRowTextDiv>

              <RightDivFlex>
                <RightDivRowA href="mailto:business@wilsonparking.com.hk">
                  business@wilsonparking.com.hk
                </RightDivRowA>
              </RightDivFlex>
            </RightDivRow>
          </RightDiv>
        </ContentBarWidth>
      </Container>
    </>
  );
};

export default ContentBar;
