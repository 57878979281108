/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import iconpass from '../../media/images/iconpass.png';
import iconcross from '../../media/images/icon-cross.png';

const StyledButton = styled.button`
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  margin-top: 3px;
  height: 32px;
  border: 0;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ isCardVerified, gray }) =>
      (isCardVerified === undefined && gray && '#6C6C6C') ||
      (isCardVerified === undefined && '#888') ||
      (isCardVerified === true && '#83c150') ||
      (isCardVerified === false && '#CD0510')};
    opacity: ${({ isCardVerified }) =>
      (isCardVerified === undefined && 1) ||
      (isCardVerified === true && 0.8) ||
      (isCardVerified === false && 0.8)};
    color: #fff;
    opacity: ${({ Exist }) => (Exist === undefined ? 1 : 0.8)};
  }
  background: ${({ Exist }) => {
    if (Exist === false) return '#CD0510';
    if (Exist === true) return '#83c150';
    return '#FD980F';
  }};
`;

const SMSPassImg = styled.img`
  margin-right: ${({ Exist }) => (Exist === undefined ? '0px' : '10px')};
  display: ${({ Exist }) => (Exist === undefined ? 'none' : 'initial')};
`;

function ReceiveButton({
  Title,
  bottom,
  submit,
  language,
  Exist,
  CheckExistAPI,
  userphone,
  SMSVerified,
  name,
}) {
  const [canSend, setCanSend] = useState(true);
  const { t } = useTranslation();
  const { control, getValues } = useFormContext();

  function callExist() {
    console.log({ name });
    if (SMSVerified === true) return;
    if (getValues(name) === userphone) return;
    if (!getValues(name)) return;
    if (!canSend) alert(t('Registration.TryLater'));
    if (canSend) {
      CheckExistAPI(getValues(name), language);
    }
  }

  useEffect(() => {
    if (!getValues(name)) return;
    if (Exist === true) {
      setCanSend(false);
      setTimeout(() => {
        setCanSend(true);
      }, 30000);
    }
  }, [Exist]);

  return (
    <StyledButton
      type={submit ? 'submit' : 'button'}
      value={Title}
      bottom={bottom}
      control={control}
      Exist={Exist}
      onClick={callExist}
    >
      <SMSPassImg src={Exist ? iconpass : iconcross} Exist={Exist} />
      {Exist === true ? t('ForgotPassword.ReSend') : t('ForgotPassword.ReceiveSMS')}
    </StyledButton>
  );
}

export default ReceiveButton;
