import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as icon from '../../media/icons';
import { CartContext, useServicePeriodDisplay } from '../../models/Cart';
import StyledLink from '../../components/General/StyledLink';

export const ItemContainer = styled.div`
  display: flex;
  padding: 30px 0;
  background: ${(props) => props.background};
  width: 100%;
  //overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const ItemNumberLabel = styled.div`
  background: #fd980f;
  display: inline-block;
  height: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 9px;
  margin-right: 15px;
`;

export const ItemNumber = styled.span`
  color: #fff;
  font-size: 1.3rem;
  padding: 0 10px;
`;

export const CarParkName = styled.span`
  height: auto;
  color: #fd980f;
  font-size: 1.5rem;
  line-height: 1;
`;

export const CarParkAddress = styled.span`
  color: #888888;
  margin-left: 7px;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: normal;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const DetailContainer = styled.tr`
  display: flex;
  flex-direction: row;
  //align-items: center;
  align-items: flex-start;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const Label = styled.td`
  width: ${(props) => (props.width ? props.width : '28%')};
  font-size: 0.875rem;
  font-weight: bold;
  color: #fd980f;

  @media (max-width: 760px) {
    width: 50%;
  }
`;

export const Detail = styled.td`
  width: ${(props) => (props.width ? props.width : '70%')};
  font-size: 0.875rem;

  @media (max-width: 760px) {
    margin-top: -10px;
    width: 100%;
  }
`;

export const DollarSign = styled.span`
  font-weight: 800;
  font-size: 1.2rem;
`;

export const Fee = styled.span`
  margin-top: -5px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 2.2rem;
`;

export const ChangeCarButton = styled.button`
  color: #fd980f;
  margin-left: 10px;
  align-self: center;
  background: none;
  border: none;
  outline: none;
  font-size: 0.875rem;
`;

const CarParkInfo = styled.div`
  flex: 73%;
  display: flex;
  padding-right: 10px;

  @media (max-width: 760px) {
    flex: 100%;
    flex-direction: column;
    padding-right: 0px;
  }
`;

const CarParkDetail = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 760px) {
    margin-left: 0px;
    flex-direction: column;
  }
`;

const TableLeft = styled.table`
  flex: 50%;

  @media (max-width: 570px) {
    flex: 100%;
  }
`;

const TableRight = styled.table`
  flex: 50%;

  @media (max-width: 570px) {
    flex: 100%;
  }
`;

const BreakLineContainer = styled.div`
  flex: 4%;
  display: flex;
  justify-content: center;

  @media (max-width: 760px) {
    flex: 100%;
    padding: 15px 0;
    margin: 0 10px;
  }
`;

const BreakLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: #dedede;

  @media (max-width: 760px) {
    width: 100%;
    height: 1px;
  }
`;

const AmountContainer = styled.div`
  flex: 23%;
  margin-top: 20px;
  padding: 0 35px 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 760px) {
    flex: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
  }
`;

const FeeContainer = styled.div`
  display: flex;

  @media (max-width: 760px) {
    flex: 55%;
    justify-content: center;
  }
`;

const RemoveButtonContainer = styled.div`
  @media (max-width: 760px) {
    flex: 45%;
    padding-right: 10px;
  }
`;

const DetailInner = styled.div`
  width: 100%;

  @media (max-width: 760px) {
    padding: 15px 15px 0px 15px;
  }
`;

const CartItem = ({ index, details, carSelectField, action }) => {
  const { t, i18n } = useTranslation();
  const {
    singleCart: { remove: singleCartRemove },
  } = useContext(CartContext);

  const handleRemoveItem = () => {
    if (window.confirm(t('Cart.ConfirmRemove'))) {
      singleCartRemove(index);
    }
  };

  return (
    <ItemContainer background={index % 2 === 0 ? '#fff' : '#fef1e5'}>
      <CarParkInfo>
        <ItemNumberLabel>
          <ItemNumber />
        </ItemNumberLabel>

        <DetailInner>
          <CarParkName>{details.name}</CarParkName>
          <div style={{ margin: '4px 0 20px 0', display: 'flex' }}>
            <img src={icon.location} width="15" height="20" alt="location" />
            <CarParkAddress>{details.address}</CarParkAddress>
          </div>

          <Step1Summary details={details} action={action} />
        </DetailInner>
      </CarParkInfo>
      <BreakLineContainer>
        <BreakLine />
      </BreakLineContainer>
      <AmountContainer>
        <FeeContainer>
          <DollarSign>HK$</DollarSign>
          <Fee>{details.fee}</Fee>
        </FeeContainer>

        {carSelectField && (
          <RemoveButtonContainer>
            <StyledLink
              type="button"
              onClick={handleRemoveItem}
              color="grey"
              label={t('Cart.Remove').toUpperCase()}
              style={{
                padding: '7px 35px',
                minHeight: 'auto',
                maxWidth: '100%',
                fontSize: '0.75rem',
              }}
            />
          </RemoveButtonContainer>
        )}
      </AmountContainer>
    </ItemContainer>
  );
};

export default CartItem;

const Step2RightTable = styled.table`
  width: 55%;
  font-size: 0.8rem;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

const Step2LeftTable = styled.table`
  width: 45%;
  font-size: 0.8rem;

  @media (max-width: 760px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const Step1Summary = ({ details, action }) => {
  const { t } = useTranslation();

  const servicePeriodDisplay = useServicePeriodDisplay();

  return (
    <CarParkDetail>
      <Step2RightTable cellPadding="5">
        <tbody>
          <DetailContainer>
            <Label>{t('Cart.BookingTypeTitle')}</Label>
            <Detail>{t(`Cart.BookingTypes.${action || 'New'}`)}</Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.ServiceTitle')}</Label>
            <Detail>{details.service}</Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.monthlyPeriodTitle')}</Label>
            <Detail>{servicePeriodDisplay(details.servicePeriod)}</Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.CarTitle')}</Label>
            <Detail>{details.registrationMark}</Detail>
          </DetailContainer>
        </tbody>
      </Step2RightTable>

      <Step2LeftTable cellPadding="5">
        <tbody>
          <DetailContainer>
            <Detail style={{ fontSize: '1.3rem' }}>
              <b>{t('Cart.Price')}</b>
            </Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.ServiceFee')}</Label>
            <Detail>
              HK$
              {details.fee}
            </Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.HandlingFee')}</Label>
            <Detail>
              HK$
              {details.handlingFee ? details.handlingFee : 0}
            </Detail>
          </DetailContainer>
        </tbody>
      </Step2LeftTable>
    </CarParkDetail>
  );
};
