import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ItemBlock = styled.div`
  line-height: 40px;
  font-size: 0;
  display: inline-block;
`;

const ItemA = styled(Link)`
  white-space: nowrap;
  padding: 5px 0.5rem;
  vertical-align: middle;
  font-size: 0.625rem;
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase !important;

  &:hover {
    color: #fff;
    text-decoration-line: none;
  }
`;

function UpperItem({ detail }) {
  const { t } = useTranslation();

  return (
    <ItemBlock>
      <ItemA to={detail.link} onClick={detail.onClick}>
        {t(detail.title)}
      </ItemA>
    </ItemBlock>
  );
}

export default UpperItem;
