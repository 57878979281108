import React, { useState } from 'react';
import { useForm, FormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import commonStyle from '../../../styles/common';
import { SelectField } from '../../../components/FormComponents';
import { useCars } from '../../../models/Customer';
import { useCarType } from '../../../models/SelectOptions';
import PopUpModal from '../../../components/General/PopUpModal';

const ModalTitle = styled.span`
  font-size: 1.75rem;
  color: #666;
`;

const ModalItemLabel = styled.span`
  width: 50%;
  font-size: 0.8125rem;
  color: #666;
`;

const CarRecordContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #bbb;

  &:not(:first-child) {
    padding-bottom: 10px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const VRHInput = styled.input`
  margin-left: 25px;
  max-height: 30px;
  border: ${({ errors }) => errors && `1px solid red`};

  @media (max-width: 760px) {
    margin-left: 0px;
  }
`;

const RemoveButton = styled.button`
  margin-left: 10px;
  background: none;
  border: none;
  outline: none;
  color: rgb(253, 152, 15);
  visibility: visible;
  font-size: 0.85rem;
  margin-top: 10px;
  display: flex;

  @media (max-width: 760px) {
    margin-left: 0px;
  }
`;

const MainConatiner = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
`;

const CarRecordRow = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const RemoveRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export default function CarRecord({
  showModal,
  setShowModal,
  selectedCarType,
  handleChangeSelectedCarType,
  createNewCars,
}) {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      cars: [
        {
          CarTypeId: '',
          registrationMark: '',
        },
      ],
    },
  });
  const { register, control, handleSubmit, getValues, errors, setValue } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cars',
  });
  const { options: carTypeOptions } = useCarType();
  const [hasError, setHasError] = useState(false);

  return (
    <PopUpModal
      Popupshow={showModal}
      onClose={() => {
        methods.reset();
        setShowModal(false);
        handleChangeSelectedCarType();
      }}
      widthStyle={{ maxWidth: 650, width: '90%' }}
    >
      <ModalTitle>{t('Cart.CarRecord.title')}</ModalTitle>
      {hasError && <p style={{ color: 'red' }}>{t('Cart.CarRecord.fillError')}</p>}

      <FormContext {...methods}>
        <form onSubmit={handleSubmit((data) => createNewCars(data.cars))}>
          <MainConatiner>
            <ul style={{ listStyle: 'none', marginTop: 25, marginBottom: 0 }}>
              {fields.map((item, index) => {
                return (
                  <CarRecordContainer key={item.id}>
                    <CarRecordRow>
                      <ModalItemLabel>
                        {t('Cart.CarRecord.carType') + parseInt(index + 1, 10)}
                      </ModalItemLabel>
                      <div style={{ marginRight: 30, width: '100%' }}>
                        <SelectField
                          name={`cars.[${index}].CarTypeId`}
                          maxMenuHeight={500}
                          options={carTypeOptions.filter((e) => e.value === selectedCarType)}
                          rules={{ required: true }}
                          customError={
                            errors.cars && errors.cars[index] && errors.cars[index].CarTypeId
                          }
                        />
                      </div>
                      {errors.cars &&
                        errors.cars[index] &&
                        errors.cars[index].CarTypeId &&
                        hasError === false &&
                        setHasError(true)}

                      <ModalItemLabel>
                        {t('Cart.CarRecord.VRH') + parseInt(index + 1, 10)}
                      </ModalItemLabel>
                      <VRHInput
                        name={`cars[${index}].registrationMark`}
                        defaultValue={`${item.registrationMark}`}
                        ref={register({
                          validate: {
                            required: (value) => value !== '' || 'Required field',
                            pattern: (value) =>
                              /^(?:([A-HJ-NPR-Z0-9])(?!\1{4,})){1,8}$/.test(value) ||
                              'Please input valid LPN',
                          },
                        })}
                        onChange={(e) => {
                          const { value } = e.target;
                          setValue(
                            `cars[${index}].registrationMark`,
                            value.toUpperCase().replace(/\s/g, '')
                          );
                        }}
                        errors={
                          errors.cars && errors.cars[index] && errors.cars[index].registrationMark
                        }
                      />

                      {errors.cars &&
                        errors.cars[index] &&
                        errors.cars[index].registrationMark &&
                        hasError === false &&
                        setHasError(true)}
                    </CarRecordRow>

                    <RemoveRow>
                      {index !== 0 && (
                        <RemoveButton type="button" onClick={() => remove(index)} index={index}>
                          {t('Cart.RemoveCar')}
                        </RemoveButton>
                      )}
                    </RemoveRow>
                    {errors.cars &&
                      errors.cars[index] &&
                      errors.cars[index].registrationMark?.message && (
                        <p style={{ color: 'red', marginTop: 5, fontSize: 14 }}>
                          {t('Cart.CarRecord.invalidLPN')}
                        </p>
                      )}
                  </CarRecordContainer>
                );
              })}
            </ul>

            <div style={{ flexDirection: 'column', display: 'flex', marginTop: 20 }}>
              <section>
                <button
                  type="button"
                  onClick={() => {
                    append({ CarTypeId: '', registrationMark: '' });
                  }}
                  style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    color: '#fd980f',
                    fontSize: '0.875rem',
                  }}
                >
                  {t('Cart.CarRecord.addCar')}
                </button>
              </section>
              <input
                value={t('Cart.CarRecord.create')}
                type="submit"
                onChange={(e) => {
                  e.preventDefault();
                  getValues();
                }}
                style={{ ...commonStyle.orangeButton, float: 'right', marginTop: 20 }}
              />
            </div>
          </MainConatiner>
        </form>
      </FormContext>
    </PopUpModal>
  );
}
