import { useEffect } from 'react';

const onNetworkError = (err) => {
  console.log(err);
  console.error(`Network error ${JSON.stringify(err)}. Please try again.`);
};

export default { onNetworkError };

export function capitalize(string) {
  if (string !== null && string !== undefined) {
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized.toString();
  }
  return string;
}

export const getMultiLangProperty = (item, lang) => {
  if (typeof item === 'string') return item;
  if (!item) return '';
  if (typeof item !== 'object') return '';
  if (item.hasOwnProperty(lang)) return item[lang];
  // if (item.hasOwnProperty("en")) return item.en;
  return '';
};

export function handelCheckLPN(input) {
  if (input.match(/\s{2,}/g)) {
    return false;
  }

  if (!input.match(/^(?:([A-HJ-NPR-Z0-9])(?!\1{4,})){1,8}$/g)) {
    return false;
  }

  return true;
}

export const useDebouncedEffect = (effect, delay, deps) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};
