import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Step1 from './PaymentLinkStep1';
import Step2 from './PaymentLinkStep2';
import Step3 from './PaymentLinkStep3';
import ProgressBar from '../../components/General/ProgressBar';
import { CartContext, useAssociations } from '../../models/Cart';
import { useOfflinePaymentMethods, useOnlinePaymentMethods } from '../../models/SelectOptions';
import { getQueryStringValue } from '../../components/General/QueryString';
import { useBooking } from '../../models/Booking';
import { CustomerContext } from '../../models/User';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  padding: 20px ${(props) => props.paddingHorizontal};
  -webkit-flex: 1;
  flex: 1;
`;

const ContentContainer = styled.div`
  max-width: 1024px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

function PaymentLinkCart() {
  const { t, i18n } = useTranslation();
  const { singleCart } = useContext(CartContext);
  const { renew } = useBooking();
  const location = useLocation();
  const { search, hash } = location;
  const history = useHistory();
  const { offlinePaymentIds, allPaymentMethods } = useOfflinePaymentMethods();
  const { onlinePaymentIds } = useOnlinePaymentMethods();
  const { step } = useParams();
  const [bookingDetail, setBookingDetail] = useState();
  const action = 'Renew';
  const [OrderId, setOrderId] = useState();
  const {
    orderId,
    paymentMethodId,
    checkout,
    onSelectPaymentMethod,
    onSelectToken,
    clearCart,
    removeAll,
  } = singleCart;
  const { getPaymentData, availablePaymentMethods, paymentMethodsLoading } = useAssociations();
  const {
    user: { id: customerId },
  } = useContext(CustomerContext);

  function redirectToHomePage() {
    Swal.fire({
      icon: 'error',
      text: t('PaymentLinkCart.hrefError'),
      position: 'center',
      confirmButtonColor: '#fd980f',
      confirmButtonText: t('sevenPayment.close'),
      didClose: () => history.push({ pathname: `/${i18n.language}` }),
    });
  }

  useEffect(() => {
    if (step === '1' && !search && !hash) {
      redirectToHomePage();
      return;
    }
    if (hash) {
      setOrderId(hash.replace('#', ''));
    }
  }, [step, hash, search]);

  useEffect(() => {
    if (!OrderId) return;
    renew(OrderId)
      .then((convertedItem) => {
        const orderRef = search.replace('?ref=', '');

        const {
          reference,
          carPark: { id },
          CustomerId: bookingCustomerID,
        } = convertedItem;

        if (customerId && customerId !== bookingCustomerID) {
          throw 'User not match';
        }

        getPaymentData([id]);

        if (reference !== orderRef) {
          redirectToHomePage();
          return;
        }

        setBookingDetail(convertedItem);
      })
      .catch((error) => {
        redirectToHomePage();
      });
    // redirectToHomePage();
  }, [OrderId, i18n.language, customerId]);

  const renderSteps = () => {
    switch (step) {
      case '2':
        return (
          <PageContainer paddingHorizontal="0">
            <ContentContainer>
              <Step2
                paymentMethodId={parseInt(paymentMethodId, 10)}
                onlinePaymentIds={onlinePaymentIds}
                offlinePaymentIds={offlinePaymentIds}
                orderId={orderId}
                redirectToStep3={() =>
                  history.push({
                    pathname: `/${i18n.language}/PaymentLink/cart/3`,
                    state: { action },
                  })
                }
                action={action}
                bookingDetail={bookingDetail}
              />
            </ContentContainer>
          </PageContainer>
        );
      case '3':
        return <Step3 orderId={getQueryStringValue('orderId') || orderId} clearCart={clearCart} />;
      case '1':
      default:
        return (
          <PageContainer paddingHorizontal="15px">
            <ContentContainer>
              <Step1
                paymentMethodsLoading={paymentMethodsLoading}
                availablePaymentMethods={availablePaymentMethods}
                allPaymentMethods={allPaymentMethods}
                bookingDetail={bookingDetail}
                onSelectPaymentMethod={onSelectPaymentMethod}
                onSelectToken={onSelectToken}
                checkout={checkout}
                removeAll={removeAll}
                action={action}
                offlinePaymentIds={offlinePaymentIds}
                onlinePaymentIds={onlinePaymentIds}
                history={history}
                redirectToStep2={() =>
                  history.push({
                    pathname: `/${i18n.language}/PaymentLink/cart/2`,
                    state: { action },
                  })
                }
              />
            </ContentContainer>
          </PageContainer>
        );
    }
  };

  const { fpsId } = onlinePaymentIds;

  const stepDisplay =
    paymentMethodId === fpsId
      ? [
          { step: '1', label: t('PaymentLinkCart.Step1') },
          { step: '2', label: t('PaymentLinkCart.Step2') },
        ]
      : [
          { step: '1', label: t('PaymentLinkCart.Step1') },
          { step: '2', label: t('PaymentLinkCart.Step2') },
          { step: '3', label: t('PaymentLinkCart.Step3') },
        ];

  return (
    <div style={{ flex: 1 }}>
      <ProgressBar
        title={t('PaymentLinkCart.title')}
        currentStep={step?.length > 1 || step === undefined ? '1' : step}
        stepDisplay={stepDisplay}
      />
      {renderSteps()}
    </div>
  );
}

export default withRouter(PaymentLinkCart);
