import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';

const Container = styled.div`
  background: ${({ color }) => color};
  padding: 10px;
  display: flex;
  justify-content: center;
  opacity: ${({ opacity }) => opacity};
`;

const Width = styled.div`
  display: flex;
  width: 1000px;
  padding-top: 35px;
  padding-bottom: 20px;
  flex-direction: row;

  @media (max-width: 850px) {
    flex-direction: column;
    padding-top: 10px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Imgdiv = styled.div``;

const TextContainer = styled.div`
  max-width: 500px;
  margin-left: 60px;
  @media (max-width: 850px) {
    margin-left: 10px;
  }
`;

const Titlediv = styled.div`
  padding-top: 10px;
  padding-bottom: 7px;
`;

const Title = styled.span`
  font-size: 30px;
  line-height: 1.3;
  text-transform: uppercase !important;
`;

const TextWidth = styled.div`
  width: 100%;
  max-width: 350px;

  p {
    margin: 0;
  }
`;

const SubTitle = styled.span`
  font-size: 19px;
  color: #555;
  font-weight: bold;
`;

const Text = styled.span`
  font-size: 16px;
  color: #555;
`;

const TextFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
`;

const TextSpace = styled.div`
  width: 15px;
  height: 45px;
  display: flex;
  align-self: center;
`;

const FPSTestingButton = styled.a`
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffffff;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  transition: all 200ms ease;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  background-clip: padding-box;
  border-radius: 0px;
  width: auto !important;
  margin-top: 40px;
  min-width: 150px;
  line-height: 1.375rem;
`;

const url = `${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}`;

const FPS = ({ data }) => {
  const { t } = useTranslation();
  const { outerWidth } = ScreenWidthListener();

  const {
    promotion_image,
    promotion_title,
    promotion_content,
    promotion_button_title,
    promotion_overlay_opacity,
    promotion_overlay_color,
    promotion_button_url,
  } = data || {};

  return (
    <Container color={promotion_overlay_color} opacity={promotion_overlay_opacity}>
      <Width>
        {/* DeskTop Screen */}
        {outerWidth > 850 && (
          <ImgContainer>
            <Imgdiv>
              <img src={`${url}${promotion_image}`} alt="" />
            </Imgdiv>
          </ImgContainer>
        )}

        <TextContainer>
          <Titlediv>
            <Title dangerouslySetInnerHTML={{ __html: promotion_title }}>
              {/* {t('Home.layer1Title')}
              <br /> */}
            </Title>
          </Titlediv>

          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <TextWidth dangerouslySetInnerHTML={{ __html: promotion_content }}>
              {/* <SubTitle>
              {t('Home.layer1subTitle')}
              <br />
            </SubTitle>
            <Text>
              {t('Home.layer1text1')}
              <br />
            </Text>
            <TextFlex>
              <TextSpace /> <Text>{t('Home.layer1text2')}</Text>
            </TextFlex>
            <TextFlex>
              <TextSpace /> <Text>{t('Home.layer1text3')}</Text>
            </TextFlex>
            <Text>
              {t('Home.layer1text4')}
              <br />
            </Text>
            <TextFlex>
              <Text>3.</Text>
              <Text>{t('Home.layer1text5')}</Text>
            </TextFlex>
            <TextFlex>
              <Text>4.</Text>
              <TextFlex column>
                <Text>{t('Home.layer1text6')} </Text>
                <Text>{t('Home.layer1text7')}</Text>
              </TextFlex>
            </TextFlex> */}
            </TextWidth>

            {promotion_button_title && promotion_button_url && (
              <FPSTestingButton href={promotion_button_url}>
                {promotion_button_title}
              </FPSTestingButton>
            )}
          </div>
        </TextContainer>

        {/* Mobile Screen */}
        {outerWidth <= 850 && (
          <div className="d-flex-polyfill" style={{ display: 'flex' }}>
            <div>
              <img src={`${url}${promotion_image}`} style={{ width: '90%' }} alt="" />
            </div>
          </div>
        )}
      </Width>
    </Container>
  );
};

export default FPS;
