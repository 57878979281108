import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const CustomerServiceUrl = `${process.env.REACT_APP_API_DOMAIN}/customer-service`;
const BusinessEnquiryUrl = `${process.env.REACT_APP_API_DOMAIN}/business-enquiry`;

function useCsForm() {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  async function CustomerServiceAPI(input) {
    const { name, phone, email, subject, content } = input || {};

    setIsLoading(true);
    try {
      const { data } = await axios.post(CustomerServiceUrl, {
        name,
        phone,
        email,
        subject,
        content,
      });
      history.push(`/${i18n.language}/customers/message/info%3Aenquiry_success`);
      return data;
    } catch (error) {
      alert('Error!Please Try again later');
    } finally {
      setIsLoading(false);
    }
  }

  async function BusinessEnquiryAPI(input) {
    const { name, phone, email, companyName, subject, content } = input || {};
    setIsLoading(true);
    try {
      const { data } = await axios.post(BusinessEnquiryUrl, {
        name,
        phone,
        email,
        companyName,
        subject,
        content,
      });
      history.push(`/${i18n.language}/customers/message/info%3Aenquiry_success`);
      return data;
    } catch (error) {
      alert('Error!Please Try again later');
    } finally {
      setIsLoading(false);
    }
  }

  return {
    CustomerServiceAPI,
    BusinessEnquiryAPI,
    isLoading,
  };
}

export { useCsForm };
