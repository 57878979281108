import styled from 'styled-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import iconpass from '../../media/images/iconpass.png';
import iconcross from '../../media/images/icon-cross.png';

const StyledButton = styled.button`
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  background: ${({ Verified }) => {
    if (Verified === false) return '#CD0510';
    if (Verified === true) return '#83c150';
    return '#888';
  }};
  border: 0;
  padding: 5px;
  height: 32px;
  margin-top: 3px;
  transition: all 0.3s ease-in-out;
  height: 32px;
  &:hover {
    background: ${({ isParkCardSMSVerified, disabled }) =>
      (disabled === true && '#888') ||
      (disabled === false && '#6C6C6C') ||
      (isParkCardSMSVerified === undefined && '#6C6C6C') ||
      (isParkCardSMSVerified === undefined && '#888') ||
      (isParkCardSMSVerified === true && '#83c150') ||
      (isParkCardSMSVerified === false && '#CD0510')};
    opacity: ${({ isParkCardSMSVerified }) =>
      (isParkCardSMSVerified === undefined && 1) ||
      (isParkCardSMSVerified === true && 0.8) ||
      (isParkCardSMSVerified === false && 0.8)};
    color: #fff;
  }

  @media (max-width: 850px) {
    height: 32px;
    margin-bottom: 0px;
  }
`;

const Img = styled.img`
  margin-right: ${({ Verified }) => (Verified === undefined ? '0px' : '10px')};
  display: ${({ Verified }) => (Verified === undefined ? 'none' : 'initial')};
`;

function VerifyButton({ Title, bottom, submit, onClick, Verified }) {
  const { control } = useFormContext();
  return (
    <StyledButton
      name={Title}
      type={submit ? 'submit' : 'button'}
      bottom={bottom}
      control={control}
      onClick={onClick}
      Verified={Verified}
    >
      <Img src={Verified === true ? iconpass : iconcross} Verified={Verified} />
      <span>{Title}</span>
    </StyledButton>
  );
}

export default VerifyButton;
