import styled from 'styled-components';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FieldError from './FieldError';

const selectStyles = (error, address, withoutBorder) => ({
  singleValue: (provided) => ({ ...provided, color: '#666' }),

  indicatorSeparator: () => {},
  dropdownIndicator: () => ({
    marginRight: 10,
    color: 'orange',
    marginTop: withoutBorder && -5,
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    minHeight: withoutBorder ? 25 : 32,
    height: withoutBorder ? 25 : 32,
    '&:hover': { borderColor: 'orange' },
    border: (error && '1px solid red') || (withoutBorder && 'none') || '1px solid #bbbbbb',
    borderRadius: 0,
    fontSize: withoutBorder ? 15 : 12,
    width: address ? 120 : '100%',
    background: withoutBorder && 'transparent',
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 6,
    fontSize: 12,
    ...styles,
    backgroundColor: state.isFocused ? '' : null,
    color: state.isSelected ? 'black' : '',
    fontWeight: state.isFocused ? 'bold' : '',
    '&:active': { backgroundColor: 'white' },
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderwidth: 10,
    border: '1px solid orange',
    fontSize: 12,
    zIndex: 999,
  }),

  valueContainer: (base) => ({
    ...base,
    padding: withoutBorder ? '0px' : '0px 8px',
  }),
});

const Container = styled.div`
  display: flex;
  flex: ${(props) => (props.prefix ? ' 0 0 50%' : '1')};
  max-width: ${(props) => (props.prefix && ' 50%') || (props.withoutBorder && '60%') || '100%'};
  flex-direction: column;
  padding-left: ${(props) => (props.withoutBorder ? 0 : '13px')};
  margin-top: ${(props) => props.withoutBorder && '-5px'};

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
    padding-left: 0px;
  }
`;

const Star = styled.span`
  color: #fd980f;
  display: flex;
  font-size: 75%;
  margin-top: -0.25rem;
`;

const aaa = 'cars[0]';

function SelectField({
  errorMessage,
  name,
  options,
  onChange = ([selected]) => selected,
  children,
  address,
  prefix,
  required,
  placeholder,
  defaultValue,
  withoutBorder,
  isDisabled,
  rules,
  customError = false,
}) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const Ref = useRef();
  const { t } = useTranslation();
  return (
    <Container prefix={prefix} withoutBorder={withoutBorder}>
      <Controller
        as={<Select ref={Ref} />}
        name={name}
        control={control}
        options={options}
        styles={selectStyles(error || customError, address, withoutBorder)}
        onChange={onChange}
        isSearchable={false}
        placeholder={placeholder || t('Registration.PleaseSelect')}
        defaultValue={defaultValue}
        menuPlacement="auto"
        onFocus={() => Ref.current.focus()}
        isDisabled={isDisabled}
        rules={rules}
        // defaultMenuIsOpen
      />
      {required && <Star>*</Star>}
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
      {children}
    </Container>
  );
}

export default SelectField;

// <div style={RegistrationStyle.InputBar}>
//   <Select
//     value={[Prefix]}
//     options={PrefixData}
//     maxMenuHeight={500}
//     onChange={(option) => OnChangePrefix(option)}
//     getOptionLabel={(label) => label}
//     getOptionValue={(value) => value}
//     styles={{
//       ...Mobileselectstyle,
//       control: (base, state) => ({
//         ...base,
//         boxShadow: 'none',
//         height: 25,
//         minHeight: 30,
//         '&:hover': { borderColor: 'orange' },
//         border: Prefix_border_color,
//         boxShadow: 'none',
//         borderRadius: 0,
//         fontSize: 12,
//       }),
//     }}
//   />
// </div>;
