import styled from 'styled-components';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import infoimg from '../../../media/images/icon-info.png';

// TODO: responsive?

const Container = styled.div`
  display: flex;
  width: 25%;
  flex-direction: row;
  font-weight: bold;
  padding-top: 5px;
  flex: ${(props) => (props.detail ? '50%' : 'none')};

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const Title = styled.span`
  font-size: 13px;
  display: flex;
  color: ${({ error, isEmailValid }) => (error || isEmailValid ? '#E56E5A' : '#666')};
`;

const InfoImg = styled.img`
  height: 20px;
  width: auto;
  margin-top: -4px;
  cursor: pointer;
`;

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      background: '#fff',
      color: '#666',
      border: '1px solid #666',
      borderRadius: 0,
      fontWeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
      padding: 20,
      fontSize: '0.6rem',
      fontFamily: 'Lato,Microsoft JhengHei,Helvetica,Arial,sans-serif',
      maxWidth: 350,
    },
    arrow: {
      fontSize: 16,
      width: 17,
      '&::before': {
        border: '1px solid #666',
        backgroundColor: '#fff',
        boxSizing: 'border-box',
      },
    },
  })
);

function FieldTitle({ title, name, detail, prefix, info, tooltipMsg }) {
  const { errors } = useFormContext();
  const error = errors[name];
  const classes = useStyles();

  return (
    <Container detail={detail} prefix={prefix}>
      <Title error={error} name={name}>
        {title}
      </Title>

      {info && (
        <Tooltip
          title={tooltipMsg}
          arrow
          placement="top"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
        >
          <InfoImg src={infoimg} />
        </Tooltip>
      )}
    </Container>
  );
}

export default FieldTitle;
