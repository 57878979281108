import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAward } from './Award';
import { useCarParks } from './CarPark';

function useSearch() {
  const { t, i18n } = useTranslation();
  const { awards } = useAward();
  const carParksHook = useCarParks();
  const { carParks } = carParksHook;
  const [SearchDataBase, setSearchDataBase] = useState([]);

  useEffect(() => {
    if (carParks.length > 1 && awards.length > 1) {
      const carParkData = carParks.map(
        ({ name, address, id, englishName, englishAddress, chineseName, chineseAddress }) => {
          return {
            title: `${t('Search.carPark')} - ${name}`,
            subTitle: `${englishName}${englishAddress}${chineseName}${chineseAddress}`,
            link: `/${i18n.language}/car_parks/detail/${id}`,
            content: address || '',
          };
        }
      );
      const AwardData = awards.map(({ title, subTitle, grantedBy }) => {
        return {
          title: t('Search.award'),
          subTitle: `${title}${subTitle}`,
          link: `/${i18n.language}/awards/index`,
          content: `${title}<br/>${subTitle}<br/><br/><br/>${grantedBy}` || '',
        };
      });

      if (AwardData && carParkData) {
        setSearchDataBase([...AwardData, ...carParkData]);
      }
    }
  }, [carParks, awards]);

  return {
    SearchDataBase,
  };
}

// eslint-disable-next-line import/prefer-default-export
export { useSearch };
