/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useGoogleMap, Autocomplete } from '@react-google-maps/api';
import useWindowSize from '@rehooks/window-size';
import Checkbox from '../../components/General/Checkbox';
import { useSelectOptions } from '../../models/User';
import sidebarExpand from '../../media/images/icon_sidebar_expand.png';
import findCarParkStyle from '../../styles/findcarpark';
import { useCarParks } from '../../models/CarPark';
import Selector from '../Home/HomepageComponents/Selector';
import { FindCarParkSelectStyles } from '../../components/General/SelectorStyle';
import SearchIcon from '../../media/images/icon-searchinput.jpg';

const url = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-next-white.png`;

const SideBarContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  transform: ${(props) => (props.open ? 'translateX(-99%)' : 'translateX(0%)')};
  transition: all 0.3s ease-in-out;
  border-right: ${(props) => (props.open ? '3px solid #fd980F' : 'none')};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 570px) {
    width: 85%;
  }
`;

const ToggleBarButton = styled.button`
  right: -18px;
  height: 40px;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  border: none;
  width: 35px;
  position: absolute;
  outline: none;
  background-color: #fd980f;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Sidebar = ({
  radius,
  setCarParksDisplay,
  selectedMarker,
  setZoom,
  setCenter,
  serviceTypes,
  selectedTypes,
  setSelectedTypes,
  state,
  carParksByLocation,
  setCarParksByLocation,
  mapRef,
  isSearchByRegion,
  handelChangeIsSearchByRegion,
}) => {
  const MapRef = useGoogleMap();

  const {
    location,
    regionOptions,
    districtOptions,
    setSelectedRegion,
    setSelectedDistrict,
    selectedDistrict,
    carParksInDistrict,
    setCarParksInDistrict,
    selectedRegion,
  } = useSelectOptions('getCarParksInDistrict');

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const defaultValues = {
    Region: { value: '', label: t('Map.Region') },
    District: { value: '', label: t('Map.District') },
  };

  const { carParks } = useCarParks();
  const methods = useForm({ defaultValues });
  const { setValue } = methods;

  const history = useHistory();

  const [open, setOpen] = useState();

  const [region, setRegion] = useState({ label: t('Map.Region') });
  const [district, setDistrict] = useState({ label: t('Map.District') });

  const [resetSearch, setResetSearch] = useState(false);
  const [noResult, setNoResult] = useState(false);

  function toggleMenu() {
    setOpen(!open);
  }

  useEffect(() => {
    setValue([
      { Region: { value: '', label: t('Map.Region') } },
      { District: { value: '', label: t('Map.District') } },
    ]);
    setRegion({ label: t('Map.Region') });
    setDistrict({ label: t('Map.District') });
  }, [language]);

  useEffect(() => {
    if (carParksInDistrict.length > 0) {
      setCarParksDisplay(carParksInDistrict);
      setZoom(15);

      if (carParksByLocation.length > 0) {
        return;
      }
      setCenter({
        lat: parseFloat(carParksInDistrict[0].coordinates.latitude),
        lng: parseFloat(carParksInDistrict[0].coordinates.longitude),
      });
    }
    if (carParksInDistrict.length === 0 && selectedDistrict) {
      setNoResult(true);
    }
  }, [carParksInDistrict, selectedDistrict, carParksByLocation]);

  useEffect(() => {
    if (selectedDistrict) {
      setResetSearch(true);
      setNoResult(false);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (carParksByLocation.length === 0) return;
    setCarParksDisplay(carParksByLocation);

    if (carParksByLocation.length > 0) {
      setNoResult(false);
      setZoom(15);
    }
  }, [carParksByLocation]);

  useEffect(() => {
    if (!state) return;
    if (state.selectedDistrict) {
      const userLocation = location.find((item) => item.id === state.selectedDistrict);
      const totalCarParks = carParks.filter(
        ({ LocationId }) => LocationId === state.selectedDistrict
      );
      setRegion(
        userLocation
          ? {
              value: userLocation.region,
              label: language === 'en' ? userLocation.region : userLocation.chineseRegion,
            }
          : regionOptions[0] || ''
      );
      setDistrict(
        userLocation
          ? {
              value: userLocation.district,
              label:
                language === 'en'
                  ? `${userLocation.district}(${totalCarParks.length})`
                  : `${userLocation.chineseDistrict}(${totalCarParks.length})`,
            }
          : regionOptions[0] || ''
      );

      if (userLocation) {
        setSelectedRegion(userLocation.region);
        setSelectedDistrict(userLocation.id);
      }
    }
    if (state.Homelng) handelChangeIsSearchByRegion(false);
    if (state.placeId) handelChangeIsSearchByRegion(false);
    if (state.bookIhourly) setSelectedTypes(state.bookIhourly);
  }, [state, carParks, location]);

  return (
    <SideBarContainer open={open}>
      <ToggleBarButton type="button" onClick={() => toggleMenu()} open={open}>
        <img
          src={sidebarExpand}
          alt="sidebarExpand"
          style={{ height: 25, width: 25, marginRight: 3 }}
        />
      </ToggleBarButton>

      <div
        className="content"
        // style={{ height: '105px' }}
      >
        <div style={findCarParkStyle.locationInputContainer}>
          {isSearchByRegion ? (
            <div
              // className="d-flex-polyfill"
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <Selector
                options={regionOptions}
                setSelected={setSelectedRegion}
                styles={FindCarParkSelectStyles}
                onchange={setRegion}
                selectedValue={region}
                placeholder={t('Map.Region')}
              />
              <Selector
                options={districtOptions}
                setSelected={setSelectedDistrict}
                isDisabled={!selectedRegion}
                styles={FindCarParkSelectStyles}
                onchange={setDistrict}
                selectedValue={district}
                placeholder={t('Map.District')}
              />
            </div>
          ) : (
            <GooglePlaceAutoComplete
              setNoResult={setNoResult}
              setCenter={setCenter}
              radius={radius}
              carParks={carParks}
              setCarParksByLocation={setCarParksByLocation}
              resetSearch={resetSearch}
              setResetSearch={setResetSearch}
              setCarParksInDistrict={setCarParksInDistrict}
              state={state}
              MapRef={MapRef}
              setZoom={setZoom}
            />
          )}

          <div
            className="d-flex-polyfill"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <button
              type="button"
              style={findCarParkStyle.searchByButton}
              onClick={() => handelChangeIsSearchByRegion(!isSearchByRegion)}
            >
              {isSearchByRegion ? t('Map.Search by Address') : t('Map.Search by Region')}
            </button>
            {resetSearch && (
              <button
                type="button"
                style={findCarParkStyle.searchByButton}
                onClick={() => {
                  setZoom(13);
                  setCarParksByLocation([]);
                  setCarParksInDistrict([]);
                  setResetSearch(false);
                  setSelectedRegion(undefined);
                  setSelectedDistrict(undefined);
                  setRegion({ label: t('Map.Region') });
                  setDistrict({ label: t('Map.District') });
                  setNoResult(false);
                  history.replace(location, null);
                  setCarParksDisplay(carParks);
                  selectedMarker(undefined);
                }}
              >
                {t('Map.Reset')}
              </button>
            )}
          </div>
        </div>
      </div>

      <RefineSearch
        setCarParksDisplay={setCarParksDisplay}
        carParksInDistrict={carParksInDistrict}
        selectedMarker={selectedMarker}
        noResult={noResult}
        serviceTypes={serviceTypes}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        toggleMenu={toggleMenu}
        mapRef={mapRef}
      />
    </SideBarContainer>
  );
};

export default Sidebar;

const Addressinput = styled.input`
  width: 100%;
  height: 53px;
  -webkit-flex: 1;
  flex: 1;
  padding: 16px;
  background-repeat: no-repeat;
  background-image: url(${SearchIcon});
  background-position: right 10px center;
  -webkit-background-size: 25px auto;
  background-size: 25px auto;
  border: none;

  ::placeholder {
    color: gray;
    opacity: 0.7;
    font-size: 1rem;
  }
  @media (max-width: 950px) {
    margin-left: 0px;
  }
`;

const SearchInput = styled.input`
  height: 42px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border: 2px solid #fff;

  ::placeholder {
    color: gray;
    opacity: 0.7;
    font-size: 1rem;
  }

  &:hover {
    border: 2px solid #cd7805;
  }

  &:focus {
    border: 2px solid #cd7805;
  }
`;

const GooglePlaceAutoComplete = ({
  setNoResult,
  setCenter,
  radius,
  carParks,
  setCarParksByLocation,
  resetSearch,
  setResetSearch,
  setCarParksInDistrict,
  HomeSearch,
  state,
  MapRef,
  setZoom,
}) => {
  const { google } = window;
  const [address, setAddress] = useState('');
  const autoCompleteRef = useRef(null);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const history = useHistory();
  const [placeId, setPlaceId] = useState();
  const { t, i18n } = useTranslation();
  const [autoComplete, setAutoComplete] = useState();
  let autoCompleteWithoutMap;

  useEffect(() => {
    if (resetSearch === false) {
      setAddress('');
      setLat(undefined);
      setLng(undefined);
    }
  }, [resetSearch]);

  useEffect(() => {
    if (state) {
      setLat(state.Homelat);
      setLng(state.Homelng);
      setAddress(state.HomenewAddress);
      setPlaceId(state.placeId);
    }
  }, [state]);

  useEffect(() => {
    if (!address) return;
    if (carParks.length <= 0) return;
    if (!lng) return;

    const filtered = carParks.filter(({ coordinates }) => {
      const latitude = coordinates === null ? 0 : coordinates.latitude;
      const longitude = coordinates === null ? 0 : coordinates.longitude;
      const distance =
        google.maps.geometry.spherical.computeDistanceBetween(
          // filled-in address in auto-complete
          new google.maps.LatLng(lat, lng),
          // existing car park
          new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude))
        ) <= radius;
      return distance === true;
    });

    if (filtered.length === 0) {
      setNoResult(true);
    }

    setZoom(15);
    setCenter({ lat, lng });
    setResetSearch(true);
    setCarParksInDistrict(filtered);
    setCarParksByLocation(filtered);
  }, [carParks, lng]);

  const handleOnPressSearch = (e, Home) => {
    if (e !== 'Enter') return;

    const service = new window.google.maps.places.AutocompleteService();
    const request = {
      input: address,
      componentRestrictions: { country: 'hk' },
    };

    service.getPlacePredictions(request, (results, status) => {
      if (status !== 'OK') return;
      setPlaceId(results[0].place_id);
      if (Home) {
        history.push({
          pathname: `/${i18n.language}/car_parks/map`,
          state: { placeId: results[0].place_id },
        });
      }
    });
  };

  useEffect(() => {
    if (!google) return;
    if (!autoCompleteRef) return;

    if (HomeSearch) {
      autoCompleteWithoutMap = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        componentRestrictions: { country: 'hk' },
        fields: ['formatted_address', 'geometry', 'name'],
      });

      autoCompleteWithoutMap.addListener('place_changed', () => {
        const addressObject = autoCompleteWithoutMap.getPlace();
        if (!addressObject.formatted_address) return;
        const HomenewAddress = addressObject.formatted_address;
        const Homelat = addressObject.geometry.location.lat();
        const Homelng = addressObject.geometry.location.lng();

        history.push({
          pathname: `/${i18n.language}/car_parks/map`,
          state: { Homelat, Homelng, HomenewAddress },
        });
      });
    }
  }, [google, autoCompleteRef]);

  useEffect(() => {
    if (!MapRef) return;
    if (!placeId) return;

    const SearchService = new window.google.maps.places.PlacesService(MapRef);
    const SearchRequest = {
      placeId,
      fields: ['geometry', 'formatted_address'],
    };

    SearchService.getDetails(SearchRequest, (Searchresults) => {
      const Homelat = Searchresults.geometry.location.lat();
      const Homelng = Searchresults.geometry.location.lng();
      const HomenewAddress = Searchresults.formatted_address;
      setAddress(HomenewAddress);
      setLat(Homelat);
      setLng(Homelng);
    });
  }, [placeId, MapRef]);

  return (
    <div style={{ width: '100%' }}>
      {HomeSearch ? (
        <Addressinput
          ref={autoCompleteRef}
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          onKeyPress={(e) => handleOnPressSearch(e.key, true)}
          placeholder={t('Map.search')}
        />
      ) : (
        <Autocomplete
          restrictions={{ country: 'hk' }}
          onLoad={(e) => setAutoComplete(e)}
          onPlaceChanged={() => {
            const addressObject = autoComplete.getPlace();
            if (!addressObject?.place_id) {
              return;
            }

            setAddress(addressObject.formatted_address);
            setLat(addressObject.geometry.location.lat());
            setLng(addressObject.geometry.location.lng());
          }}
        >
          <SearchInput
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            onKeyPress={(e) => handleOnPressSearch(e.key)}
            placeholder={t('Map.search')}
          />
        </Autocomplete>
      )}
    </div>
  );
};

export { GooglePlaceAutoComplete };

const RefineToggleButton = styled.button`
  border: none;
  outline: none;
  background-color: #666;
  color: white;
  text-align: left;
  padding: 8px;
  padding-left: 15px;
  font-size: 0.84375rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: #444;
  }

  &:after {
    color: #fff;
    background-color: #444;
    position: absolute;
    transition: all 0.3s ease-out;
    left: auto;
    right: 4px;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
    color: #fff;
    font-size: 0rem;
    line-height: 24px;
    text-align: center;
    background-repeat: no-repeat;
    content: '';
    background-image: url(${url});
    background-position: center;
    background-size: auto 55%;
    vertical-align: text-bottom;
    font-weight: bold;
    border-radius: 50px;
    background-clip: padding-box;
    transform: ${(props) => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
  }
`;

const RefineInner = styled.div`
  width: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0px 5px 0px;
  &:hover {
    & .makeStyles-icon-2 {
      border: 1px solid #000;
    }
  }
`;

const ToggleRefineSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${(props) => (props.open ? '200px' : '0')};
  transition: max-height 0.7s;
  overflow: hidden;
`;

const RefineSearchContainer = styled.div`
  background-color: #f3f3f3;
  height: 200px;
  overflow-y: auto;
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  color: #666;
`;

const ServiceTypeLabel = styled.label`
  font-size: 0.85rem;
  width: 80%;
  cursor: pointer;
  margin-bottom: 0px;
`;

const ResultButton = styled.button`
  width: 100%;
  border: ${(props) => (props.clicked ? '2px solid #fd980f' : 'none')};
  background-color: ${(props) => (props.hovered ? '#f3f3f3' : '#fff')};
  text-align: left;
  outline: none;
`;

const RefineSearch = ({
  carParksInDistrict,
  selectedMarker,
  noResult,
  serviceTypes,
  selectedTypes,
  setSelectedTypes,
  toggleMenu: tooggleSearchSlideBar,
  mapRef,
}) => {
  const width = 100;
  // const height = 200;
  const { t } = useTranslation();
  const { innerWidth } = useWindowSize();

  const [open, setOpen] = useState(false);
  const [clickedResultId, setclickedResultId] = useState('');
  const [hoveredResultId, setHoveredResultId] = useState('');

  const toggleMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedTypes.length >= 1) {
      setOpen(true);
    }
  }, [selectedTypes]);

  return (
    <>
      <RefineToggleButton type="button" onClick={() => toggleMenu()} open={open}>
        {t('Map.REFINE SEARCH')}
      </RefineToggleButton>

      <ToggleRefineSearch open={open}>
        <div className="content">
          <RefineSearchContainer>
            {serviceTypes.map((item) => {
              const { id, type } = item;
              return (
                <RefineInner key={id}>
                  <ServiceTypeLabel dangerouslySetInnerHTML={{ __html: type }} />
                  <Checkbox
                    checked={selectedTypes.includes(id)}
                    onChange={() => {
                      const update = [...selectedTypes];
                      const index = update.indexOf(id);

                      if (update.includes(id)) {
                        update.splice(index, 1);
                      } else {
                        update.push(id);
                      }

                      setSelectedTypes(update);
                    }}
                  />
                </RefineInner>
              );
            })}
          </RefineSearchContainer>
        </div>
      </ToggleRefineSearch>

      <div
        style={{
          width,
          ...findCarParkStyle.refineToggleBarStyle,
        }}
      >
        <div
          className="content"
          style={{
            overflowY: serviceTypes.length === 0 ? 'visible' : 'auto',
          }}
        >
          {noResult && (
            <div style={{ padding: 20, color: '#666', fontSize: 16 }}>No car parks found.</div>
          )}
          {carParksInDistrict.map((carpark) => {
            const { id, coordinates } = carpark;

            const { latitude, longitude } = coordinates || {};
            return (
              <ResultButton
                key={carpark.id}
                onMouseOver={() => setHoveredResultId(id)}
                onFocus={() => setHoveredResultId(id)}
                onMouseOut={() => setHoveredResultId('')}
                onBlur={() => setHoveredResultId('')}
                onClick={() => {
                  setclickedResultId(id);
                  selectedMarker(carpark);
                  if (mapRef) {
                    mapRef.panTo({
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    });
                  }
                  if (innerWidth < 770) {
                    tooggleSearchSlideBar();
                  }
                }}
                clicked={clickedResultId === id}
                hovered={hoveredResultId === id}
              >
                <div style={{ margin: 15 }}>
                  <h5 style={{ fontSize: '1.125rem', color: '#FD980F' }}>{carpark.name}</h5>
                  <span style={{ fontSize: '0.875rem', color: '#444444' }}>{carpark.address}</span>
                </div>
                <hr style={{ margin: 0 }} />
              </ResultButton>
            );
          })}
        </div>
      </div>
    </>
  );
};
