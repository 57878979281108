/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuIcon from './Icon';
import { CartContext } from '../../../models/Cart';
import { CustomerContext } from '../../../models/User';
import emptycart from '../../../media/images/empty_cart.png';

const CartIcon = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-left: 3px;

  @media (max-width: 980px) {
    margin-top: 2px;
    margin-left: 0px;
    height: 1rem;
    margin-bottom: 8px;
    margin-right: 5px;
  }
`;

const DropDownMenu = styled.div`
  background: #fff;
  width: 300px;
  min-height: 180px;
  border: 1px solid #ccc;
  top: 100px;
  color: #000;
  align-self: flex-end;
  padding: 20px;
  z-index: -2;
  position: absolute;
  display: ${(props) => (props.isHoverCartBox ? 'block' : 'none')};

  @media (max-width: 980px) {
    top: 73px;
  }
`;

function MenuItem(props) {
  const { title, link = '/', icon, hover, dropdown, ...rest } = props;
  const [image, setImage] = useState(icon);
  const { t, i18n } = useTranslation();
  const {
    generalCart: { cart },
  } = useContext(CartContext);
  const { token } = useContext(CustomerContext);

  const [isHoverCart, setIsHoverCart] = useState(false);
  const [isHoverCartBox, setIsHoverCartBox] = useState(false);
  const cartDisplay = isHoverCart
    ? {
        backgroundColor: '#fff',
        fontColor: '#fd980f',
      }
    : {
        backgroundColor: '#fd980f',
        fontColor: '#fff',
      };

  const arrowStyle = {
    transform: 'rotate(45deg)',
  };

  const cartItems = () =>
    title === 'header.cart' && (
      <div
        style={{
          width: 20,
          height: 20,
          background: cartDisplay.backgroundColor,
          position: 'absolute',
          borderRadius: '50%',
          alignSelf: 'flex-end',
          marginLeft: -30,
          marginTop: -34,
        }}
      >
        <span
          style={{
            color: cartDisplay.fontColor,
            alignSelf: 'center',
            marginTop: 1,
          }}
        >
          {cart.length}
        </span>
      </div>
    );

  if (!icon) {
    return (
      <li className="menu-item">
        <Link className="menu-link" to={link} {...rest}>
          <span className="menu-item-label"> {t(title)} </span>
        </Link>
      </li>
    );
  }

  if (!hover) {
    return (
      <li className="menu-item">
        <Link className="menu-link" to={link} {...rest}>
          <MenuIcon icon={icon} />
          <span className="menu-item-label"> {t(title)} </span>
        </Link>
      </li>
    );
  }

  return (
    <li
      className="menu-item"
      style={{
        display: 'flex',
        flexDirection: dropdown && 'column',
        position: 'relative',
        background: isHoverCartBox && '#fd980f',
        color: isHoverCartBox && '#fff', // doesnt work yet
      }}
    >
      <Link
        className="menu-link"
        to={link}
        onMouseOver={() => {
          setImage(hover);
          setIsHoverCart(!!props.dropdown);
          setIsHoverCartBox(!!props.dropdown);
        }}
        onMouseOut={() => {
          setImage(icon);
          setIsHoverCart(false);
          setIsHoverCartBox(false);
        }}
        onFocus={() => {
          setImage(hover);
          setIsHoverCart(!!props.dropdown);
          setIsHoverCartBox(!!props.dropdown);
        }}
        onBlur={() => {
          setImage(icon);
          setIsHoverCart(false);
          setIsHoverCartBox(false);
        }}
        {...rest}
      >
        <MenuIcon icon={image} />
        {token && cart.length > 0 && cartItems()}
        <CartIcon>
          <div>
            <span className="menu-item-label">{t(title)}</span>
          </div>
        </CartIcon>
      </Link>

      {isHoverCartBox && (
        <div
          style={{
            background: '#fd980f',
            width: 20,
            height: 20,
            position: 'absolute',
            alignSelf: 'center',
            bottom: -5,
            zIndex: -1,
            ...arrowStyle,
          }}
          onMouseOver={() => {
            setIsHoverCart(true);
            setIsHoverCartBox(true);
          }}
          onFocus={() => {
            setIsHoverCart(true);
            setIsHoverCartBox(true);
          }}
        />
      )}

      <DropDownMenu
        isHoverCartBox={isHoverCartBox}
        onMouseOver={() => {
          setImage(hover);
          setIsHoverCart(true);
          setIsHoverCartBox(true);
        }}
        onMouseOut={() => {
          setImage(icon);
          setIsHoverCart(false);
          setIsHoverCartBox(false);
        }}
        onFocus={() => {
          setImage(hover);
          setIsHoverCart(true);
          setIsHoverCartBox(true);
        }}
        onBlur={() => {
          setImage(icon);
          setIsHoverCart(false);
          setIsHoverCartBox(false);
        }}
      >
        {/* item names display in pop up container should be linked to page of actual item */}
        {token &&
          cart.length > 0 &&
          cart.map((item) => (
            <div key={item.id}>
              <div
                className="d-flex-polyfill"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Link
                    to={`/${i18n.language}/car_parks/detail/${item.id}`}
                    style={{
                      fontSize: '1rem',
                      padding: 0,
                      fontWeight: 'normal',
                      background: 'transparent',
                      color: '#000',
                    }}
                  >
                    {item.name}
                  </Link>
                  <span style={{ justifyContent: 'flex-start' }}>{item.service}</span>
                </div>
                <span
                  style={{
                    color: '#fd980f',
                    alignSelf: 'center',
                    fontSize: '1rem',
                  }}
                >
                  HK$ {item.fee}
                </span>
              </div>
              <hr />
            </div>
          ))}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {(!token || cart.length === 0) && (
            <>
              <img
                alt=""
                src={emptycart}
                style={{
                  minWidth: '80px',
                  minHeight: '80px',
                  alignSelf: 'center',
                }}
              />
              <span style={{ marginTop: 10, marginBottom: 10, fontSize: '0.8rem' }}>
                Your cart is currently empty
              </span>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 0,
              justifyContent: 'center',
            }}
          >
            <Link
              to={token ? (cart.length === 0 ? '/car_parks/map' : '/cart') : '/login'}
              style={{
                flexGrow: 0,
                background: '#fd980f',
                color: '#fff',
                width: '50%',
                padding: 10,
                fontSize: '0.8rem',
              }}
            >
              {cart.length === 0 ? 'FIND A CAR PARK' : 'CHECK OUT'}
            </Link>
          </div>
        </div>
      </DropDownMenu>
    </li>
  );
}

MenuItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hover: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withRouter(MenuItem);
