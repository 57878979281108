import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, MaxWidth, ImageCol, FormCol, LoginTitle } from './Login.styles';
import { TokenContext } from '../../../models/Customer';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';

const Login = () => {
  const { token } = useContext(TokenContext);
  const history = useHistory();
  const [formType, setFormType] = useState('login');
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      const {
        location: { state: locationState = {}, pathname },
      } = history;

      // eslint-disable-next-line prefer-const
      let { redirectedFrom = '/', ...rest } = locationState;
      if (redirectedFrom === pathname) redirectedFrom = '/';
      history.replace(redirectedFrom, rest);
    }
  }, [token]);

  useEffect(() => {
    if (location.state === 'registration') {
      setFormType('registration');
      return;
    }
    setFormType('login');
  }, [location.state]);

  return (
    <Container>
      <MaxWidth>
        <ImageCol />
        <FormCol>
          <LoginTitle>{t('Login.signInTitle')}</LoginTitle>
          {formType === 'login' && <LoginForm setFormType={setFormType} />}
          {formType === 'registration' && <RegistrationForm setFormType={setFormType} />}
        </FormCol>
      </MaxWidth>
    </Container>
  );
};

export default Login;
