import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withRouter, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useResetPassword } from '../../models/Customer';
import useQueryString from '../../components/General/useQueryString';

const Container = styled.div`
  background: #dedede;
  height: 100%;
  flex: 1;
`;

const TitleRow = styled.div`
  padding: 5px 0;
`;

const TitleInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
  @media (max-width: 850px) {
    margin-top: 4px;
    margin-bottom: 3px;
  }
`;

const TitleDiv = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

const TitleBlock = styled.div`
  flex-grow: 1;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const Title = styled.span`
  color: #444;
  font-size: 2rem;
  font-weight: 400;
  margin-left: -3px;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 850px) {
    line-height: inherit;
  }
`;

const FormWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const FormInner = styled.div`
  font-size: 0.875rem;
  display: block;
  box-shadow: 0 0 2px #bbb;
  background-color: #fff;
`;

const FormDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
`;

const FormPadding = styled.div`
  padding-top: 30px !important;
  padding-bottom: 0px;
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
  @media (max-width: 850px) {
    padding-bottom: 5px;
  }
`;

const FormBlock = styled.div``;

const Flex = styled.div`
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const ItemRow = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const ItemFlex = styled.div`
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const ItemDiv = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;

  @media (max-width: 850px) {
    flex: 1;
    max-width: 100%;
  }
`;

const Item = styled.span`
  font-weight: inherit;
  font-size: 0.8125rem;
  line-height: 1;
  vertical-align: top;
  display: inline-block;
  margin-bottom: 5px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};
  max-width: 100%;
`;

const InputDiv = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const Input = styled.input`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 6px !important;
  height: 32px !important;
  min-height: auto !important;
  width: 100%;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
`;

const ButtonRow = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  margin-top: 61px;

  @media (max-width: 850px) {
    margin-top: 6px;
  }
`;

const ButtonInner = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 850px) {
    justify-content: flex-start;
  }
`;

const ButtonDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 11px;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  padding-right: 16px;
  padding-left: 16px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const Reset = styled.button`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 6px !important;
  height: 30px !important;
  min-height: auto !important;
  background: #888;
  border: none;
  color: #fff;
  width: 100%;

  &:hover {
    background: #6c6c6c;
  }
`;

const Submit = styled.button`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 6px !important;
  height: 30px !important;
  min-height: auto !important;
  background: #fd980f;
  border: none;
  color: #fff;
  width: 100%;

  &:hover {
    background: #6c6c6c;
  }
`;

const ErrorMsg = styled.span`
  color: #e56e5a;
  font-size: 13px;
`;

const ResetPassword = () => {
  const { ResetPasswordApi, isLoading } = useResetPassword();
  const { t } = useTranslation();
  const { handleSubmit, register, errors, getValues, reset } = useForm({
    mode: 'onBlur',
  });
  const { token } = useParams();
  const onSubmit = () => ResetPasswordApi(token, getValues('password'));

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleRow>
          <TitleInner>
            <TitleDiv>
              <TitleBlock>
                <Title>{t('ResetPassword.Title')}</Title>
              </TitleBlock>
            </TitleDiv>
          </TitleInner>
        </TitleRow>
        <FormWidth>
          <FormInner>
            <FormBlock>
              <FormDiv>
                <FormPadding>
                  <Flex>
                    <ItemRow>
                      <ItemFlex>
                        <ItemDiv>
                          <Item error={errors.password}>{t('ResetPassword.Password')}</Item>
                        </ItemDiv>
                        <InputDiv>
                          <Input
                            name="password"
                            type="password"
                            ref={register({
                              required: true,
                              pattern: /^(?=.*[A-Z])(?=.*\d).{8,15}$/,
                            })}
                            error={errors.password}
                          />
                          {errors.password && (
                            <ErrorMsg>{t('ResetPassword.PasswordError')}</ErrorMsg>
                          )}
                        </InputDiv>
                      </ItemFlex>
                    </ItemRow>
                    <ItemRow>
                      <ItemFlex>
                        <ItemDiv>
                          <Item error={errors.Confirmpassword}>
                            {t('ResetPassword.ComPassword')}
                          </Item>
                        </ItemDiv>
                        <InputDiv>
                          <Input
                            name="Confirmpassword"
                            type="password"
                            ref={register({
                              required: true,
                              validate: (value) => value === getValues('password'),
                            })}
                            error={errors.Confirmpassword}
                          />
                          {errors.Confirmpassword && (
                            <ErrorMsg>{t('ResetPassword.PasswordError')}</ErrorMsg>
                          )}
                        </InputDiv>
                      </ItemFlex>
                    </ItemRow>
                  </Flex>

                  <ButtonRow>
                    <ButtonInner>
                      <ButtonDiv>
                        <Reset onClick={() => reset()}>{t('ResetPassword.Reset')}</Reset>
                      </ButtonDiv>
                      <ButtonDiv>
                        <Submit disabled={isLoading} type="submit">
                          {t('ResetPassword.Submit')}
                        </Submit>
                      </ButtonDiv>
                    </ButtonInner>
                  </ButtonRow>
                </FormPadding>
              </FormDiv>
            </FormBlock>
          </FormInner>
        </FormWidth>
      </form>
    </Container>
  );
};

export default withRouter(ResetPassword);
