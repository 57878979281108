export const DEFAULT_VALUE = {
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  prefix: null,
  firstName: '',
  lastName: '',
  chineseFirstName: '',
  chineseLastName: '',
  address: '',
  Region: null,
  LocationId: null,
  preferredLanguage: null,
  registrationMark: '',
  VerificationCode: '',
  phone: '',
  carType: null,
};
