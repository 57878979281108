import styled from 'styled-components';
import { Controller, useFormContext, ErrorMessage } from 'react-hook-form';
import React, { useRef } from 'react';
import Select from 'react-select';
import FieldError from './FieldError';

const selectStyles = (error, address) => ({
  singleValue: (provided) => ({ ...provided, color: '#666' }),

  indicatorSeparator: () => {},
  dropdownIndicator: () => ({
    marginRight: 10,
    color: 'orange',
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    minHeight: 32,
    height: 32,
    '&:hover': { borderColor: 'orange' },
    border: error ? '1px solid red' : '1px solid #bbbbbb',
    borderRadius: 0,
    fontSize: 12,
    width: address ? 120 : '100%',
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 6,
    fontSize: 12,
    ...styles,
    backgroundColor: state.isFocused ? '' : null,
    color: state.isSelected ? 'black' : '',
    fontWeight: state.isFocused ? 'bold' : '',
    '&:active': { backgroundColor: 'white' },
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderwidth: 10,
    border: '1px solid orange',
    fontSize: 12,
  }),

  valueContainer: (base) => ({
    ...base,
    padding: '0px 8px',
  }),
});

const Container = styled.div`
  display: flex;
  -webkit-flex: 1;
flex: 1;
  flex-direction: column;
  padding-top: 3px;
  max-width: 60%;
  margin-right: ${(props) => (props.prefix ? '24px' : '0px')};
  width: 100%;
  @media (max-width: 850px) {
    -webkit-flex: 1;
flex: 1;
    max-width: 100%;
    padding-left: 0px;
  }
`;

function SelectField({
  errorMessage,
  name,
  options,
  onChange = ([selected]) => selected,
  children,
  address,
  prefix,
  placeholder,
  defaultValue,
}) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const Ref = useRef();
  return (
    <Container prefix={prefix}>
      <Controller
        as={<Select ref={Ref} />}
        name={name}
        control={control}
        options={options}
        styles={selectStyles(error, address)}
        onChange={onChange}
        isSearchable={false}
        placeholder={placeholder}
        defaultValue={defaultValue}
        menuPlacement="auto"
        onFocus={() => Ref.current.focus()}
      />
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
      {children}
    </Container>
  );
}
export default SelectField;
