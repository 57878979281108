import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MenuItem from './Item';

function MenuBody(props) {
  const { items, children, staticContext, ...rest } = props;
  return (
    <div className="menu-block" {...rest}>
      {(items || children) && (
        <ul className="menu-list" style={{ margin: 0 }}>
          {items &&
            items.map((item) => {
              const key = Array.isArray(item.title)
                ? item.title.filter((t) => typeof t === 'string').join(' ')
                : item.title;
              return <MenuItem {...item} key={key} />;
            })}
          {children}
        </ul>
      )}
    </div>
  );
}

MenuBody.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      hover: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
};

export default withRouter(MenuBody);
