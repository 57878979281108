/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const libraries = ['places', 'geometry'];
const region = 'hk';
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY;

const Map = ({ zoom, center, children, options, containerStyle }) => {
  const { i18n } = useTranslation();
  const params = useParams();

  const language = params.zh || params.en;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    libraries,
    language: language === 'en' ? 'en' : 'zh-hk',
    region,
  });

  const [touched, setTouched] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    return () => {
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 500);
    };
  }, [i18n.language]);

  return (
    <>
      {!reload && isLoaded && (
        <GoogleMap
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            gestureHandling: 'greedy',
            scrollwheel: touched,
            ...options,
          }}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onDrag={() => setTouched(true)}
          onClick={() => setTouched(true)}
          onMouseOut={() => setTouched(false)}
          onBlur={() => {}}
        >
          {children}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
