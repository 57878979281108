import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cartStyle from '../../../styles/cart';

const Timer = ({ timesUp, expireDate = moment().startOf('minute').add(15, 'minutes') }) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(expireDate ? moment(expireDate).diff(currentTime) : 0);
  const [isTimeOut, setIsTimeOut] = useState(false);

  const minutes = useMemo(() => {
    const v = timeBetween.minutes();
    return `${v < 0 ? '00' : v.toString().padStart(2, '0')}`;
  }, [timeBetween]);

  const second = useMemo(() => {
    const v = timeBetween.seconds();
    return `${v < 0 ? '00' : v.toString().padStart(2, '0')}`;
  }, [timeBetween]);

  useEffect(() => {
    if (isTimeOut) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimeOut]);

  useEffect(() => {
    if (expireDate && moment(expireDate).diff(currentTime) <= 0) {
      timesUp();
      setIsTimeOut(true);
    }
  }, [currentTime, expireDate]);

  return (
    <div className="d-flex-polyfill" style={{ alignSelf: 'center', display: 'flex' }}>
      <b>{t('Timer.TimeLeft')}</b>
      <span style={{ marginRight: 10 }}>:</span>
      <b style={cartStyle.timer}>{minutes}</b>
      <span style={{ marginLeft: 10, marginRight: 10 }}>:</span>
      <b style={cartStyle.timer}>{second}</b>
    </div>
  );
};

export default Timer;
