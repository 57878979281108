import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import * as icon from '../../media/icons';
import { getPaymentConfig } from '../../helpers/api/cart';
import { usePpsPaymentConfig, useFpsPayment, CartContext } from '../../models/Cart';
import Modal from '../../components/General/PopupPrivacyPolicy';

const WhiteContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #666;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
  padding-left: 40px;

  @media (max-width: 760px) {
    padding-left: 20px;
  }
`;

function Step2({ paymentMethodId, orderId, onlinePaymentIds, redirectToStep3, offlinePaymentIds }) {
  const { visaId, ppsId, fpsId } = onlinePaymentIds;
  const { chequeId, sevenElevenId } = offlinePaymentIds;

  return (
    <MainContainer>
      {paymentMethodId === visaId && (
        <>
          <MasterCardPayment orderId={orderId} redirectToStep3={redirectToStep3} />
          <Modal Popupshow="Custom">
            <div
              id="embedded-checkout-modal"
              style={{ width: '80vw', height: '90vh', maxWidth: 850 }}
            />
          </Modal>
        </>
      )}
      {paymentMethodId === ppsId && (
        <PpsPayment orderId={orderId} redirectToStep3={redirectToStep3} />
      )}
      {paymentMethodId === fpsId && <FpsPayment orderId={orderId} />}
      {paymentMethodId === chequeId && <ChequePayment orderId={orderId} />}
      {paymentMethodId === sevenElevenId && <SevenElevenPayment orderId={orderId} />}
    </MainContainer>
  );
}

export default Step2;

const MasterCardPayment = ({ orderId, redirectToStep3 }) => {
  const { t, i18n } = useTranslation();

  const scriptLoaded = (config) => {
    // config.interaction.locale = i18n.language === 'en' ? 'en_US' : 'zh_HK';
    window.Checkout.configure(config);
    window.Checkout.showEmbeddedPage('#embedded-checkout-modal');
    // window.Checkout.showLightbox();
    // window.Checkout.showPaymentPage();
  };

  // useEffect(() => {
  //   if (spareOrderId) {
  //     localStorage.setItem('spareOrderId', spareOrderId);
  //   }
  // }, [spareOrderId]);

  function handelNewTokenWithCheckoutJS(response) {
    const script = document.createElement('script');
    script.setAttribute('data-error', `${process.env.REACT_APP_API_DOMAIN}${response.error}`);
    script.setAttribute(
      'data-complete',
      'completeCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.complete}`
    );
    script.setAttribute(
      'data-cancel',
      'cancelCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.cancel}`
    );
    script.setAttribute(
      'data-timeout',
      'timeoutCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`
    );
    script.setAttribute(
      'data-error',
      'errorCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`
    );
    script.src =
      process.env.REACT_APP_CHECKOUT_JS_SRC ||
      'https://fdhk.gateway.mastercard.com/checkout/version/55/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    window.completeCallback = (results) => {
      const { resultIndicator, sessionVersion } = results || {};
      console.log({ resultIndicator, sessionVersion });
      let completeURL = new URL(`${process.env.REACT_APP_API_DOMAIN}${response.complete}`);
      completeURL.searchParams.set('resultIndicator', resultIndicator);
      completeURL.searchParams.set('sessionVersion', sessionVersion);
      completeURL = completeURL.toString();
      window.location.href = completeURL;
    };
    window.cancelCallback = (resultIndicator, sessionVersion) => {
      console.log({ resultIndicator, sessionVersion });
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${response.cancel}`;
    };
    window.timeoutCallback = (resultIndicator, sessionVersion) => {
      console.log({ resultIndicator, sessionVersion });
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`;
    };
    window.errorCallback = (error) => {
      console.log(error);
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${response.error}`;
    };
    script.onload = () => scriptLoaded(response.config);
  }

  useEffect(() => {
    if (orderId !== null) {
      const locale = i18n.language === 'en' ? 'en_US' : 'zh_HK';
      getPaymentConfig(orderId, locale)
        .then((response) => {
          if (response.next === 'checkout') {
            handelNewTokenWithCheckoutJS(response);
            return;
          }

          if (response.next === 'success') {
            redirectToStep3();
          }
        })
        .catch((error) => {
          Swal.fire({
            focusConfirm: false,
            titleText: 'Invaild credit card,Please try again',
            position: 'center',
            confirmButtonColor: '#fd980f',
            confirmButtonText: t('sevenPayment.close'),
          });
          console.error(error);
        });
    }
  }, [orderId]);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const PpsPayment = ({ orderId }) => {
  const { form } = usePpsPaymentConfig(orderId);
  const { t } = useTranslation();

  const openNewWindow = () => {
    const newWindow = window.open('about:blank', '_self');

    newWindow.document.write(form);
    newWindow.onbeforeunload = (e) => {
      e.preventDefault();
      window.location = '/';
    };
  };

  useEffect(() => {
    if (form) {
      openNewWindow();
    }
  }, [form]);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const FpsPayment = ({ orderId }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  setTimeout(() => {
    history.push({ pathname: `/${i18n.language}/PaymentLink/fps/${orderId}` });
  }, 1500);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const Label = styled.p`
  margin-bottom: 10px;
  font-size: 0.85rem;
  color: #666;
`;

const ChequePayment = ({ orderId }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  setTimeout(() => {
    history.push({ pathname: `/${i18n.language}/PaymentLink/cheque/${orderId}` });
  }, 1500);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const SevenElevenPayment = ({ orderId }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  setTimeout(() => {
    history.push({ pathname: `/${i18n.language}/PaymentLink/seven/${orderId}` });
  }, 1500);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};
