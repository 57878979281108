import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import ReactLoading from 'react-loading';
import { useForm, Controller } from 'react-hook-form';
import LockIcon from '@mui/icons-material/Lock';
import Reaptcha from 'reaptcha';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useHistory } from 'react-router-dom';
import {
  ErrorMsg,
  PasswordInput,
  PasswordRow,
  UsernameInput,
  UsernameRow,
  RecaptchaRow,
  InputCol,
  PasswordCol,
  BackButton,
} from './Login.styles';
import StyledButton from '../../../components/General/StyledButton';
import { TokenContext, useEmailVerifyMyprofile, useSMSVerify } from '../../../models/Customer';

const RegistrationForm = ({ setFormType }) => {
  const { t, i18n } = useTranslation();
  const { loading } = useContext(TokenContext);
  const { isEmailValid, VerifyEmail } = useEmailVerifyMyprofile();
  const { fetchPhone, isPhoneExist } = useSMSVerify();
  const history = useHistory();

  const { handleSubmit, register, errors, watch, setError, setValue, clearError } = useForm({
    validateCriteriaMode: 'all',
  });

  function handelFetchEmail() {
    if (!watch('email')) return;

    setValue('email', watch('email').trim());

    VerifyEmail(watch('email'), 'active', true)
      .then(() => {
        setError('email', {
          exist: t('Registration.EmailExistError'),
        });
      })
      .catch((e) => {
        if (e === 'formatError') {
          setError('email', {
            exist: t('Registration.PasswordError'),
          });
          return;
        }
        clearError('email');
      });
  }

  function handelFetchPhone() {
    if (!watch('phone')) return;
    if (!watch('phone').match(/^1[0-9]{10}$|^[456789][0-9]{7}$/)) {
      setError('phone', {
        exist: t('CsList.PhoneError'),
      });
      return;
    }
    fetchPhone(watch('phone'), 'active', true)
      .then(() => {
        setError('phone', {
          exist: t('Registration.PhoneExistError'),
        });
      })
      .catch(() => {
        clearError('phone');
      });
  }

  const onSubmit = (data) => {
    delete data.Reaptcha;

    if (isEmailValid) {
      setError('email', {
        exist: t('Registration.EmailExistError'),
      });
      return;
    }

    if (isPhoneExist) {
      setError('phone', {
        exist: t('Registration.PhoneExistError'),
      });
      return;
    }

    history.push({
      pathname: `/${i18n.language}/OTP`,
      state: { ...data, confirmEmail: data.email },
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputCol>
        <UsernameRow>
          <UsernameInput
            name="email"
            onChange={(e) => {
              if (!e) {
                setValue('email', '');
                return;
              }
              setValue('email', e.target.value.trim());
            }}
            onBlur={handelFetchEmail}
            ref={register({
              required: t('CsList.PasswordError'),
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('CsList.PasswordError'),
              },
            })}
            error={errors.email}
            placeholder={t('Login.Email')}
          />
          <EmailIcon style={{ position: 'absolute', left: 10, top: 10 }} />
          {errors.email && (
            <ErrorMsg style={{ top: 45, left: 0 }}>
              {errors?.email?.message}
              {errors?.email?.types.exist}
            </ErrorMsg>
          )}
        </UsernameRow>

        <UsernameRow style={{ marginTop: 25 }}>
          <UsernameInput
            onBlur={handelFetchPhone}
            name="phone"
            ref={register({
              required: t('CsList.PhoneError'),
              pattern: {
                value: /^1[0-9]{10}$|^[456789][0-9]{7}$/,
                message: t('CsList.PhoneError'),
              },
            })}
            error={errors.phone}
            placeholder={t('Myprofile.MobileNo')}
          />
          <LocalPhoneIcon style={{ position: 'absolute', left: 10, top: 10 }} />
          {errors.phone && (
            <ErrorMsg style={{ top: 75, left: 0 }}>
              {errors?.phone?.message}
              {errors?.phone?.types.exist}
            </ErrorMsg>
          )}
        </UsernameRow>

        <div
          style={{
            // position: 'absolute',
            lineHeight: 1.1,
            fontSize: 12,
            marginTop: 5,
            color: '#888',
          }}
        >
          {t('Login.pleaseEnter')}
        </div>

        <PasswordCol>
          <PasswordRow isEmailValid>
            <PasswordInput
              error={errors.password}
              ref={register({
                required: t('CsList.PasswordError'),
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*\d).{8,15}$/,
                  message: t('ResetPassword.PasswordError'),
                },
              })}
              type="password"
              name="password"
              placeholder={t('Registration.Password')}
            />
            {errors.password && <ErrorMsg style={{}}>{t('ResetPassword.PasswordError')}</ErrorMsg>}
            <LockIcon style={{ position: 'absolute', left: 10, top: 10 }} />
          </PasswordRow>

          <PasswordRow isEmailValid style={{ marginLeft: 10 }}>
            <PasswordInput
              error={errors.confirmPassword}
              ref={register({
                required: t('CsList.PasswordError'),
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*\d).{8,15}$/,
                  message: t('ResetPassword.PasswordError'),
                },
                validate: {
                  confirmPassword: (v) =>
                    v === watch('password') || t('ResetPassword.ConfirmPasswordError'),
                },
              })}
              type="password"
              name="confirmPassword"
              placeholder={t('Registration.ComPassword')}
            />
            {errors.confirmPassword && (
              <ErrorMsg style={{}}>{t('ResetPassword.PasswordError')}</ErrorMsg>
            )}
            <LockIcon style={{ position: 'absolute', left: 10, top: 10 }} />
          </PasswordRow>
        </PasswordCol>

        {/* <RecaptchaRow error={errors.Reaptcha} isEmailValid style={{ marginTop: 25 }}>
          {show && (
            <Controller
              as={<Reaptcha />}
              name="Reaptcha"
              control={control}
              sitekey="6LfPFksUAAAAAIcM2UKLjQ6X7mJOHIhGBUY1_PHf"
              onVerify={() => setValue('Reaptcha', true, { shouldValidate: true })}
              onExpire={() => setValue('Reaptcha', false, { shouldValidate: true })}
              defaultValue={false}
              hl={i18n.language === 'zh' ? 'zh-hk' : 'en'}
              rules={{ required: true }}
            />
          )}
        </RecaptchaRow> */}
      </InputCol>

      <StyledButton
        disabled={loading}
        type="submit"
        color="yellow"
        style={{
          margin: 0,
          fontSize: '0.8125rem',
          padding: '10px 40px',
          flex: 0,
          marginTop: 20,
          maxWidth: 200,
          zIndex: 10,
        }}
      >
        {t('Login.signUp')}
        {loading && <ReactLoading type="spin" color="#888" width={20} height={20} />}
      </StyledButton>

      <BackButton onClick={() => setFormType('login')} type="button">
        {`< ${t('IhourlyCart.Back')}`}
      </BackButton>
    </form>
  );
};

export default RegistrationForm;
