import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const url = `${process.env.REACT_APP_API_DOMAIN}/award`;

function useAward() {
  const {
    i18n: { language },
  } = useTranslation();

  const [awardData, setAwardData] = useState([]);
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    async function getAward() {
      const {
        data: { data },
      } = await axios.get(`${url}`);
      setAwardData(data);
    }

    getAward();
  }, []);

  useEffect(() => {
    if (awardData.length === 0) return;
    const isEng = language === 'en';
    setAwards(
      awardData.map(
        ({
          title,
          chineseTitle,
          subTitle,
          chineseSubTitle,
          grantedBy,
          chineseGrantedBy,
          ...rest
        }) => ({
          ...rest,
          title: isEng ? title : chineseTitle,
          subTitle: isEng ? subTitle : chineseSubTitle,
          grantedBy: isEng ? grantedBy : chineseGrantedBy,
        })
      )
    );
  }, [awardData, language]);

  return { awards };
}

// eslint-disable-next-line import/prefer-default-export
export { useAward };
