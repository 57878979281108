import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGuestCart } from '../../models/Customer';
import { Container, ContentContainer, InnerContainer } from './IhourlyComponents/index';
import { getOrderDetails } from '../../helpers/api/cart';

const Title = styled.p`
  color: #444;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
`;

const OrangeText = styled.p`
  color: #fd980f;
  font-size: 2rem;
  margin: 0;
`;

const BlackText = styled.p`
  color: #444;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
  margin-top: ${(props) => props.top && '10px'};
`;

const RemarkText = styled.p`
  margin: 0;
  font-size: 0.75rem;
  color: #666;
`;

const RemarkDiv = styled.div`
  background-color: #f3f3f3;
  padding: 15px 25px 25px;
  width: 100%;
  & ${RemarkText} {
    margin-top: 10px;
  }

  span {
    display: list-item;
    list-style: decimal;
    margin-left: 20px;
    font-size: 0.875rem;
    color: #666;
  }
`;

const Email = styled.a`
  color: #666;
  &:hover {
    color: #666;
  }
`;

function Step4({ OrderData }) {
  const { t } = useTranslation();
  const { orderId } = OrderData;
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    getOrderDetails(orderId)
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <Container>
      <ContentContainer>
        <InnerContainer>
          <Title>{t('IhourlyCart.SuccessTitle')}</Title>
          <OrangeText>
            {t('IhourlyCart.SuccessNumber')}
            {orderDetails.reference}
          </OrangeText>
          <OrangeText>
            {t('IhourlyCart.SuccessFee')}
            {t('IhourlyCart.HKD')}
            {orderDetails.Payment?.amount}
            {t('IhourlyCart.HKD1')}
          </OrangeText>
          <BlackText top>{t('IhourlyCart.SuccessT1')}</BlackText>
          <BlackText>{t('IhourlyCart.SuccessT2')}</BlackText>
        </InnerContainer>
        <RemarkDiv>
          <RemarkText>{t('IhourlyCart.SuccessRemark')}</RemarkText>
          <span>
            <RemarkText>{t('IhourlyCart.SuccessR1')}</RemarkText>
          </span>
          <span>
            <RemarkText>{t('IhourlyCart.SuccessR2')}</RemarkText>
          </span>
          <span>
            <RemarkText>{t('IhourlyCart.SuccessR3')}</RemarkText>
          </span>
          <span>
            <RemarkText>{t('IhourlyCart.SuccessR4')}</RemarkText>
          </span>
          <span>
            <RemarkText>
              {t('IhourlyCart.SuccessR5')}
              <Email href="mailto:ihourly@wilsonparking.com.hk">ihourly@wilsonparking.com.hk</Email>
            </RemarkText>
          </span>
        </RemarkDiv>
      </ContentContainer>
    </Container>
  );
}

export default Step4;
