import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import { useAward } from '../../../models/Award';
import '../Carousel.css';

const AwardImg = styled.img`
  height: 100%;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
`;

const AwardBox = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  background: #f6f6f6;
  padding-top: 40px;
  padding-bottom: 40px;
`;

function AwardsItem({ award: { image } }) {
  return (
    <AwardBox href="/awards/index">
      <AwardImg
        src={`${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}${
          image.includes('static') ? '' : 'static'
        }${image}`}
      />
    </AwardBox>
  );
}
const MobileAwards = () => {
  const [index, setIndex] = React.useState(0);
  const { awards } = useAward();

  const MobileNewshandleSelect = (selectedMobileNewsIndex) => {
    setIndex(selectedMobileNewsIndex);
  };

  return (
    <Container>
      <Carousel
        activeIndex={index}
        onSelect={MobileNewshandleSelect}
        interval={99999999}
        id="MobileAwards1"
      >
        {awards.map((award) => (
          <Carousel.Item>
            <AwardsItem award={award} />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default MobileAwards;
