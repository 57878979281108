import styled from 'styled-components';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FieldError from './FieldError';

const selectStyles = (error) => ({
  singleValue: (provided) => ({ ...provided, color: '#666' }),

  indicatorSeparator: () => {},
  dropdownIndicator: () => ({
    marginRight: 10,
    color: 'orange',
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    height: 32,
    minHeight: 32,
    '&:hover': { borderColor: 'orange' },
    border: error ? '1px solid red' : '1px solid #bbbbbb',
    borderRadius: 0,
    fontSize: 12,
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 2,
    fontSize: 12,
    paddingLeft: 5,
    ...styles,
    backgroundColor: state.isFocused ? '' : null,
    color: state.isSelected ? 'black' : '',
    fontWeight: state.isFocused ? 'bold' : '',
    '&:active': { backgroundColor: 'white' },
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderWidth: 10,
    border: '1px solid orange',
    zIndex: 100,
  }),

  valueContainer: (base) => ({
    ...base,
    padding: '0px 8px',
  }),
});

const Container = styled.div`
  -webkit-flex: 1;
  flex: 1;
  margin-right: 31px;
  margin-left: 14px;
  @media (max-width: 850px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

function RegionSelector({ errorMessage, name, options, setSelectedRegion, placeholder }) {
  const { control, errors, setValue } = useFormContext();
  const error = errors[name];
  const { t } = useTranslation();
  return (
    <Container>
      <Controller
        as={Select}
        name={name}
        options={options}
        control={control}
        onChange={([{ value }]) => {
          setValue('LocationId', null);
          setSelectedRegion(value);
        }}
        styles={selectStyles(error)}
        isSearchable={false}
        placeholder={placeholder || t('Registration.PleaseSelect')}
        menuPlacement="auto"
      />
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
    </Container>
  );
}

export default RegionSelector;
