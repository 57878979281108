import React, { useEffect, useState } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Step1 from './IhourlyStep1';
import Step2 from './IhourlyStep2';
import Step3 from './IhourlyStep3';
import Step4 from './IhourlyStep4';
import ProgressBar from '../../components/General/ProgressBar';
import { useOnlinePaymentMethods } from '../../models/SelectOptions';

function IhourlyCart() {
  const { state } = useLocation();
  const { paymentMethod: statePaymentMethod } = state || {};
  const { t, i18n } = useTranslation();
  const { step } = useParams();
  const { onlinePaymentIds } = useOnlinePaymentMethods();
  const { fpsId } = onlinePaymentIds;
  const [stepDisplay, setStepDisplay] = useState([
    { step: '1', label: t('Cart.Steps.Step1') },
    { step: '2', label: t('Cart.Steps.Step2') },
    { step: '3', label: t('Cart.Steps.Step3') },
    { step: '4', label: t('Cart.Steps.Step4') },
  ]);

  if (state && step === '1') {
    window.localStorage.setItem('IhourlyCartData', JSON.stringify(state));
  }
  if (state && step === '2') {
    window.localStorage.setItem('CustomerData', JSON.stringify(state));
  }
  if (state && step === '3') {
    window.localStorage.setItem('OrderData', JSON.stringify(state));
  }

  const Data = JSON.parse(localStorage.getItem('IhourlyCartData'));
  const CustomerData = JSON.parse(localStorage.getItem('CustomerData'));
  const OrderData = JSON.parse(localStorage.getItem('OrderData'));

  // const paymentMethod = OrderData ? OrderData.paymentMethod : statePaymentMethod;

  const renderSteps = () => {
    switch (step) {
      case '2':
        return (
          <Step2
            CustomerData={CustomerData}
            carParkData={Data}
            onlinePaymentIds={onlinePaymentIds}
          />
        );

      case '3':
        return <Step3 onlinePaymentIds={onlinePaymentIds} carParkData={Data} />;
      case '4':
        return <Step4 OrderData={OrderData} />;
      case '1':
      default:
        return <Step1 Data={Data} />;
    }
  };

  useEffect(() => {
    if (step === '3' && OrderData.paymentMethod === fpsId) {
      setStepDisplay([
        { step: '1', label: t('Cart.Steps.Step1') },
        { step: '2', label: t('Cart.Steps.Step2') },
        { step: '3', label: t('Cart.Steps.Step3') },
      ]);
    }
  }, [OrderData, step]);

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <ProgressBar
        title={t('IhourlyCart.Title')}
        currentStep={step === undefined ? '1' : step}
        stepDisplay={stepDisplay}
      />
      {renderSteps()}
    </div>
  );
}

export default withRouter(IhourlyCart);
