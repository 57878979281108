import styled from 'styled-components';

const SigninTitle = styled.p`
  color: #fff;
  font-size: 1rem;
  line-height: 1.1;
  margin: 0;
`;

export default SigninTitle;
