import styled from 'styled-components';

const AddressField = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  justify-content: space-between;
  margin-top: 10px;
  flex-direction: row;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export default AddressField;
