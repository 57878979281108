import gradient from '../media/images/gradient.png';
import { back } from '../media/icons';

const findCarParkStyle = {
  mainToggleBar: {
    height: '100% !important',
    flexDirection: 'column',
    backgroundColor: '#fff',
    transition: '0.8s ease',
    position: 'absolute',
    zIndex: 1,
  },
  mainToggleButton: {
    height: '40px',
    borderTopRightRadius: '10rem',
    borderBottomRightRadius: '10rem',
    border: 'none',
    width: '35px',
    position: 'absolute',
    outline: 'none',
    backgroundColor: '#fd980f',
    marginTop: 18,
  },
  locationInputContainer: {
    backgroundColor: '#fd980f',
    height: 100,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  locationDropDown: {
    height: 40,
    flexGrow: 1,
    marginRight: 10,
    paddingLeft: 5,
    border: 'none',
  },
  searchByButton: {
    textDecoration: 'underline',
    color: '#fff',
    fontSize: '0.85rem',
    border: 'none',
    background: 'none',
    outline: 'none',
    marginTop: 5,
    alignSelf: 'flex-start',
  },
  refineToggleBarStyle: {
    width: '100%',
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    transition: '0.8s ease',
    position: 'relative',
    overflowY: 'auto',
  },
  refineToggleButtonStyle: {
    // height: '40px',
    border: 'none',
    outline: 'none',
    backgroundColor: '#666',
    color: 'white',
    textAlign: 'left',
    padding: 5,
    paddingLeft: 15,
    width: '100%',
  },
  refineSearchContainer: {
    backgroundColor: '#f3f3f3',
    height: 200,
    overflowY: 'auto',
    padding: '10px 15px 10px 15px',
    display: 'flex',
    flexWrap: 'wrap',
    color: '#666',
  },
  checkbox: {
    marginTop: 5,
    marginRight: 10,
    backgroundColor: '#fff',
    float: 'right',
    alignSelf: 'center',
  },

  // car park detail style
  pageLayout: {
    display: 'flex',
    width: '85%',
    justifyContent: 'space-between',
  },
  moreCarParksTitle: {
    background: '#444',
    fontSize: '0.7rem',
    textAlign: 'center',
    color: '#fff',
    padding: 10,
  },
  whiteBorderButton: {
    background: 'transparent',
    color: '#fff',
    borderColor: '#',
    border: '1px solid',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    outline: 'none',
    textDecoration: 'none',
    fontSize: '0.85rem',
  },
  nearbyCarParkBox: {
    color: '#808080',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nearbyCarParkClick: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    textDecoration: 'none',
    padding: 15,
    paddingTop: 20,
    paddingBottom: 20,
  },
  nearbyCarParkArrow: {
    background: '#f3f3f3',
    width: 20,
    height: 20,
    marginRight: -10,
    float: 'right',
    alignSelf: 'center',
    transform: 'rotate(45deg)',
  },
  tabMenuBar: {
    backgroundSize: '100% 100%',
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'top-center',
    outline: 'none',
    border: '1px solid #dedede',
    height: '50px',
  },
  remarksContainer: {
    backgroundColor: '#f9b73c',
    padding: '0px 15px',
    color: '#fff',
    display: 'flex',
    flexWrap: 'wrap',
  },
  remarkDrawer: {
    width: '100%',
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#dedede',
    transition: '0.8s ease',
    // position: 'relative',
  },
  remarkDrawerButton: {
    background: '#f9b73c',
    width: 20,
    height: 30,
    outline: 'none',
    border: 'none',
    borderRadius: '50%',
    alignSelf: 'center',
    marginTop: -15,
  },
  tutorialLinkContainer: {
    background: '#d8d8d8',
    padding: 50,
    display: 'flex',
    justifyContent: 'space-around',
  },
  tutorialLinkButton: {
    background: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    textDecoration: 'none',
    color: '#666',
  },

  // parking plans (car park detail page) style
  carouselButtons: {
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid #ccc',
    borderBottom: 'none',
    fontSize: '1.5rem',
    color: '#fd980f',
  },
  carouselItemTitle: {
    fontSize: '1.1rem',
    flexGrow: 1,
    textAlign: 'center',
    padding: 10,
    border: '1px solid #ccc',
  },
  parkingPlanDetails: {
    fontSize: '0.85rem',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  parkingPlanFee: {
    textAlign: 'right',
    alignSelf: 'center',
    flexBasis: '25%',
    marginRight: 10,
  },
};

export default findCarParkStyle;
