import styled from 'styled-components';
import * as icon from '../../../media/icons';
import CheckboxwithControl from './CheckboxwithControl';

const Container = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  padding-top: 40px;
  -webkit-flex: 1;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  flex-direction: column;
  -webkit-flex: 1;
  flex: 1;
  align-items: center;
  max-width: 1024px;
  width: 100%;
`;

const InnerContainer = styled.div`
  background: white;
  width: 100%;
  max-width: 1024px;
  padding: 15px 25px 25px 25px;

  @media (max-width: 670px) {
    padding: 0px 10px 20px 10px;
  }
`;

const HourlySpan = styled.div`
  color: #333;
  font-size: 0.875rem;
  flex: 0 0 30%;
  max-width: 30%;
`;

const IhourlyTitle = styled.div`
  color: #444;
  font-size: 0.875rem;
  font-weight: bold;
  flex: 0 0 30%;
  max-width: 30%;
`;

const DateTimeTitle = styled.div`
  color: #666;
  font-size: 0.875rem;
  font-weight: bold;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
`;

const DateTimeSpan = styled.div`
  color: #333;
  font-size: 0.875rem;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  align-items: flex-start;

  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
`;

const CustomerDetailsDiv = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  justify-content: flex-start;
  &:last-child {
    margin-top: 10px;
    & ${DateTimeSpan} {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
  }

  @media (max-width: 670px) {
    margin-top: 20px;
    flex: 1;
    min-width: 100%;
  }
`;

const PaymentContainer = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  padding: 15px 25px 25px;

  @media (max-width: 670px) {
    padding: 0px 10px 20px 10px;
  }
`;

const PaymentTitle = styled.div`
  color: #222;
  font-size: 1.125rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const LeftDiv = styled.div`
  flex: 0 0 30%;
  max-width: 30%;

  @media (max-width: 670px) {
    flex: 1;
    max-width: 100%;
  }
`;

const RightDiv = styled.div`
  flex: 0 0 65%;
  max-width: 65%;
  padding: 0px 20px;

  @media (max-width: 670px) {
    padding: 0px;
  }
`;

const HandlingFree = styled.div`
  font-size: 0.9375rem;
  color: #fd980f;
  font-weight: bold;
`;

const HandlingFreeText = styled.div`
  color: #888;
  font-size: 12px;
  margin-top: 10px;
  line-height: 1.5;
`;

const Divider = styled.div`
  width: 20px;
  background-image: url(${icon.plus});
  background-position: center;
  background-repeat: no-repeat;
`;

const Amount = styled.div`
  font-size: 12px;
  color: #888;
`;

const PaymentMethodText = styled.div`
  color: #fd980f;
  font-size: 1rem;
  font-weight: 500;
`;

const MethodsDiv = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  cursor: pointer;
  & .pretty input:checked ~ .state.p-primary label:after {
    background-color: #fd980f !important;
  }

  @media (max-width: 670px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const UpperDiv = styled.div`
  padding: 20px 0px;
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;

  & > ${MethodsDiv}:not(:first-child) {
    padding-left: 20px;

    @media (max-width: 670px) {
      padding-left: 0px;
      margin-top: 20px;
    }
  }

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const LowerDiv = styled.div`
  & > ${MethodsDiv} {
    margin-top: 25px;
  }
`;

const CardImg = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  background: url(${(props) => props.src}) no-repeat center center #fff;
  position: relative;
  width: 100%;
  height: 123px;
  border: 1px solid #ccc;
  color: #888;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);
`;

const TermsContainer = styled.div`
  margin-top: 40px;
`;

const Terms = styled.div`
  color: #888;
  font-size: 12px;
  margin-bottom: 10px;
`;

const TermsDiv = styled.div`
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #bbbbbb;
  font-size: 0.8125rem;
  line-height: 1.3;
  height: auto;
  max-height: 150px;
  min-height: 100px;
  color: #333;
  overflow-y: scroll;
`;

const Agree = styled.div`
  color: #888888;
  font-size: 0.8125rem;
  font-weight: 400;
  cursor: pointer;
`;

const CheckDiv = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
`;

const ButtonRow = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  justify-content: flex-end;

  @media (max-width: 670px) {
    margin-top: 40px;
    justify-content: space-between;
  }
`;

const Reset = styled.button`
  min-width: 130px;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 6px !important;
  height: 32px !important;
  background-color: #888;
  border: 0;
  color: #fff;
  transition: all 200ms ease;

  &:hover {
    background-color: #666;
  }
`;

const Submit = styled.button`
  min-width: 130px;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 6px !important;
  height: 32px !important;
  background-color: #fd980f;
  border: 0;
  color: #fff;
  margin-left: 40px;
  transition: all 200ms ease;
  &:hover {
    background-color: #888;
  }
`;

const CheckboxDiv = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  align-items: center;
`;

const SavedPayment = styled.span`
  font-size: 0.8125rem;
  font-weight: 400;
  color: #666;
  margin: 0;
  cursor: pointer;
`;

const CardText = styled.span`
  font-size: 0.8125rem;
  font-weight: 400;
  color: #666;
  cursor: pointer;
`;

const CreditCardImg = styled.img`
  height: 40px;
`;

const DetailRow = styled.div`
  margin-top: 10px;
  flex-wrap: wrap;

  @media (max-width: 670px) {
    flex-direction: column;
    margin-top: -10px;
  }
`;

const PaymenyInner = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  flex-direction: row;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const TimeBlock = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  flex-basis: 25%;
`;

const TimeRow = styled.div`
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
  margin-top: 10px;

  @media (max-width: 670px) {
    flex-direction: column;
  }

  & > div:not(:first-child) {
    @media (max-width: 670px) {
      margin-top: 20px;
    }
  }
`;

export {
  Container,
  ContentContainer,
  InnerContainer,
  IhourlyTitle,
  HourlySpan,
  DateTimeTitle,
  DateTimeSpan,
  CustomerDetailsDiv,
  PaymentContainer,
  PaymentTitle,
  LeftDiv,
  HandlingFree,
  HandlingFreeText,
  Divider,
  RightDiv,
  Amount,
  UpperDiv,
  MethodsDiv,
  CheckboxDiv,
  PaymentMethodText,
  CardImg,
  LowerDiv,
  SavedPayment,
  TermsContainer,
  Terms,
  TermsDiv,
  CheckDiv,
  Agree,
  ButtonRow,
  Reset,
  Submit,
  CheckboxwithControl,
  CardText,
  CreditCardImg,
  DetailRow,
  PaymenyInner,
  TimeBlock,
  TimeRow,
};
