import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, FormContext } from 'react-hook-form';
import * as yup from 'yup';
import '../../../../components/General/Checkbox/style.scss';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import { CustomerContext, useSelectOptions } from '../../../../models/User';
import {
  ItemMargin,
  CheckBoxMargin,
  Checkbox,
  PasswordNote,
  SectionTitle,
  FieldTitle,
  FieldContainer,
  TextField,
  SelectField,
  AddressContainer,
  AddressField,
  RegionSelector,
  DistrictField,
  DistrictSelector,
  Button,
  MobileNumberContainer,
  MobileNumberMargin,
  MobileNumberRow,
  MobileNumberItem,
  FieldInner,
  ReceiveButton,
  VerifyButton,
  PhoneButton,
} from '../../../../components/FormComponents';
import {
  useCustomer,
  useFirstDataToken,
  useSMSVerify,
  useActiveParkCard,
  TokenContext,
  UseUserDeletionRequest,
} from '../../../../models/Customer';
import mastercard from '../../../../assets/images/mastercard.png';
import visa from '../../../../assets/images/visa.png';
import profileStyle from '../../../../styles/Account/profile';
import StyledButton from '../../../../components/General/StyledButton';
import LoadingSprinner from '../../../../components/Loading';

const HeaderTextDiv = styled.div`
  background: #f3f3f3;
  padding-top: 18px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
`;

const HeaderText = styled.span`
  color: #888;
  font-size: 14px;
  line-height: 1.5;
`;

const Inner = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.span`
  color: #444;
  font-size: 1.3rem;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 10px;
`;

const Divider = styled.div`
  height: 1px;
  background: #ccc;
  margin-top: 38px;
  margin-bottom: 3px;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  margin-top: 50px;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const ButtonRow = styled.div`
  display: flex;
`;

const ButtonMargin = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const AddParkContainer = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
`;

const DeleteAccountContainer = styled.div`
  width: 100%;
  padding: 20px 30px 20px 30px;
  display: flex;
  align-items: center;
`;

const Space = styled.div`
  background-color: rgb(222, 222, 222);
  margin-top: 20px;
  flex: 1 1 0%;
  display: flex;
  height: 20px;
`;

const SavedContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
`;

const SavedTitle = styled.div`
  color: rgb(68, 68, 68);
  font-size: 1.3rem;
  font-weight: 600;
  padding: 18px;
`;

const SavedInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(255, 255, 255);
  margin-top: 0px;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0px;
`;

const CardRow = styled.div`
  color: rgb(102, 102, 102);
  font-size: 0.8125rem;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  place-content: center start;
  margin-right: 20px;
`;

const CardNo = styled.p`
  color: rgb(102, 102, 102);
  font-size: 0.8125rem;
  font-weight: 400;
  flex: 4 1 0%;
  padding-top: 11px;
  margin-left: 20px;
`;

const DeleteButton = styled.button`
  height: 32px;
  border: 0px;
  background-color: transparent;
  font-size: 20.8px;
  font-weight: bold;

  @media (max-width: 650px) {
    margin-bottom: 40px;
  }
`;

const MyProfileTab = () => {
  const { /* user,  /* creditCards, */ removeCreditCard } = useContext(CustomerContext);
  const { token } = useContext(TokenContext);
  const { customer: user, updateCustomer } = useCustomer();
  const { firstDataTokens: creditCards } = useFirstDataToken();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    location,
    prefixOptions,
    setSelectedRegion,
    setSelectedDistrict,
    districtOptions,
    regionOptions,
    languageOptions,
    selectedDistrict,
  } = useSelectOptions();
  const { isSMSVerified, verifyMobile, isPhoneExist, setIsSMSVerified, RGCheckPhoneExist } =
    useSMSVerify();
  const { isActivePPcard, isParkCardSMSVerified, fetchPPcard, ProfileTabVerifySms, ppcardExist } =
    useActiveParkCard();
  const { handelCreateUserDeletionRequest, isUserDeletionLoading } = UseUserDeletionRequest();
  const history = useHistory();

  const methods = useForm({
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      User: yup.object({
        password: yup
          .string()
          .matches(/^(?=.*[A-Z])(?=.*\d).{8,15}$/, { excludeEmptyString: true }),
      }),
      confirmPassword: yup.string().when('User.password', {
        is: (val) => !val,
        then: yup.string(),
        otherwise: yup
          .string()
          .oneOf([yup.ref('User.password')])
          .required(),
      }),
      prefix: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      chineseFirstName: yup.string().notRequired().nullable(),
      chineseLastName: yup.string().notRequired().nullable(),
      address: yup.string().nullable(),
      preferredLanguage: yup.string().required(),
      carType: yup.number(),
      registrationMark: yup.string(),
      VerificationCode: yup.string().when('phone', {
        is: (val) => val !== user.phone,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      phone: yup.string().matches(/^1[0-9]{10}$|^[456789][0-9]{7}$/),
      isReceivePromotion: yup.boolean().nullable(),
      isReceiveSms: yup.boolean().nullable(),
    }),
  });

  useEffect(() => {
    if (user.id) {
      const userLocation = location.find((item) => item.id === user.LocationId);
      const defaultValues = {
        ...user,
        preferredLanguage:
          languageOptions.find((option) => option.value === user.language) ||
          (languageOptions.length > 0 ? languageOptions[0] : ''),
        Region: userLocation
          ? {
              value: userLocation.region,
              label: language === 'en' ? userLocation.region : userLocation.chineseRegion,
            }
          : regionOptions[0] || '',
        LocationId: userLocation
          ? {
              value: userLocation.district,
              label: language === 'en' ? userLocation.district : userLocation.chineseDistrict,
            }
          : districtOptions[0] || '',
        prefix:
          prefixOptions.find((option) => option.value === user.title) ||
          (prefixOptions.length > 0 ? prefixOptions[0] : ''),
        'User.password': '',
        confirmPassword: '',
        VerificationCode: '',
      };
      methods.reset(defaultValues);
      if (userLocation) {
        setSelectedRegion(userLocation.region);
        setSelectedDistrict(userLocation.id);
      }
    }
  }, [user, location]);

  const onSubmit = (data) => {
    if (!isSMSVerified && user.phone !== data.phone) {
      setIsSMSVerified(false);
      return;
    }
    const prefixdata = methods.getValues('prefix');
    const preferredLanguagedata = methods.getValues('preferredLanguage');
    const {
      address,
      firstName,
      lastName,
      chineseFirstName,
      chineseLastName,
      User,
      phone,
      isReceivePromotion,
    } = data;

    const body = {
      address,
      LocationId: selectedDistrict,
      firstName,
      lastName,
      chineseFirstName,
      chineseLastName,
      language: preferredLanguagedata.value,
      title: prefixdata.value,
      phone,
      isReceivePromotion,
      isReceiveSms: true,
    };

    if (User.password) body.User = User;
    updateCustomer(body);
  };

  const WatchVerificationCode = methods.watch('VerificationCode');

  useEffect(() => {
    if (!WatchVerificationCode) return;
    if (WatchVerificationCode.length === 4 && isPhoneExist === false)
      verifyMobile(methods.getValues('phone'), methods.getValues('VerificationCode'), false);
  }, [WatchVerificationCode, isPhoneExist]);

  return (
    <>
      {isUserDeletionLoading && <LoadingSprinner loading={isUserDeletionLoading} />}
      {Object.keys(user).length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
          <ReactLoading type="spokes" color="#fd980f" height={30} width={30} />
        </div>
      ) : (
        <div>
          <HeaderTextDiv>
            <HeaderText>{t('Myprofile.Headertext')}</HeaderText>
          </HeaderTextDiv>
          <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Inner>
                <SubTitle>{t('Myprofile.CustomerInf')}</SubTitle>
                <FieldContainer>
                  <FieldInner top>
                    <FieldTitle required name="prefix" title={t('Registration.Prefix')} />
                    <SelectField
                      prefix
                      name="prefix"
                      maxMenuHeight={500}
                      options={prefixOptions}
                      errorMessage={t('Registration.prefixError')}
                      isSearchable={false}
                      placeholder={t('Registration.PleaseSelect')}
                    />
                  </FieldInner>
                  <ItemMargin />
                  <FieldContainer />
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle name="firstName" required title={t('Registration.FirstNameEng')} />
                    <TextField name="firstName" errorMessage={t('Registration.firstNameError')} />
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle name="lastName" required title={t('Registration.LastNameEng')} />
                    <TextField name="lastName" errorMessage={t('Registration.lastNameError')} />
                  </FieldInner>
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle name="chineseFirstName" title={t('Registration.FirstNameZh')} />
                    <TextField name="chineseFirstName" />
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle name="chineseLastName" title={t('Registration.LastNameZh')} />
                    <TextField name="chineseLastName" />
                  </FieldInner>
                </FieldContainer>

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle title={t('Registration.Address')} />
                    <AddressContainer>
                      <TextField address name="address" />
                      <AddressField>
                        <RegionSelector
                          name="Region"
                          options={regionOptions}
                          setSelectedRegion={setSelectedRegion}
                          isSearchable={false}
                          placeholder={t('Registration.Region')}
                        />

                        <DistrictField>
                          <DistrictSelector
                            name="LocationId"
                            options={districtOptions}
                            setSelectedDistrict={setSelectedDistrict}
                            isSearchable={false}
                            placeholder={t('Registration.District')}
                          />
                        </DistrictField>
                      </AddressField>
                    </AddressContainer>
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle
                      required
                      name="preferredLanguage"
                      title={t('Registration.PreferredLanguage')}
                    />
                    <SelectField
                      name="preferredLanguage"
                      options={languageOptions}
                      errorMessage={t('Registration.languageError')}
                      isSearchable={false}
                      placeholder={t('Registration.PleaseSelect')}
                    />
                  </FieldInner>
                </FieldContainer>

                <Divider />

                <SectionTitle title={t('Myprofile.ChangePassword')} />

                <FieldContainer>
                  <FieldInner>
                    <FieldTitle required name="User" title={t('Myprofile.NewPassword')} />
                    <TextField
                      password="User"
                      name="User.password"
                      type="password"
                      errorMessage={t('ResetPassword.PasswordError')}
                    >
                      <PasswordNote note={t('Registration.PasswordRequest')} />
                    </TextField>
                  </FieldInner>

                  <ItemMargin />

                  <FieldInner>
                    <FieldTitle
                      required
                      name="confirmPassword"
                      title={t('Registration.ComPassword')}
                    />
                    <TextField
                      name="confirmPassword"
                      type="password"
                      errorMessage={t('ResetPassword.ConfirmPasswordError')}
                    />
                  </FieldInner>
                </FieldContainer>

                <Divider />

                <SectionTitle title={t('Myprofile.ChangeMobileNo')} />

                <MobileNumberContainer forgotpassword>
                  <MobileNumberMargin>
                    <FieldTitle title={t('Registration.Mobile')} />
                    <MobileNumberRow>
                      <TextField
                        name="phone"
                        errorMessage={t('Registration.phoneError')}
                        isPhoneExist={isPhoneExist}
                        t={t}
                      />
                      <MobileNumberItem>
                        <PhoneButton
                          Title={t('Registration.ReceiveSMS')}
                          CheckPhoneExistAPI={RGCheckPhoneExist}
                          isPhoneExist={isPhoneExist}
                          language={user.language}
                          userphone={user.phone}
                          SMSVerified={isSMSVerified}
                        />
                      </MobileNumberItem>
                    </MobileNumberRow>
                  </MobileNumberMargin>

                  <ButtonMargin />

                  <MobileNumberMargin>
                    <FieldTitle
                      title={t('Registration.VerificationCode')}
                      name="VerificationCode"
                    />
                    <MobileNumberRow>
                      <TextField
                        name="VerificationCode"
                        errorMessage={t('Registration.VerificationCodeError')}
                      />
                      <MobileNumberItem>
                        <Button
                          style={{ marginTop: 3 }}
                          gray
                          Title={t('Registration.Verify')}
                          onClick={() => {
                            if (isSMSVerified) return;
                            verifyMobile(
                              methods.getValues('phone'),
                              methods.getValues('VerificationCode')
                            );
                          }}
                          isSMSVerified={isSMSVerified}
                        />
                      </MobileNumberItem>
                    </MobileNumberRow>
                  </MobileNumberMargin>
                </MobileNumberContainer>

                <CheckBoxMargin>
                  <Checkbox name="isReceivePromotion" Text1={t('Registration.WishPro')} checked />
                </CheckBoxMargin>

                {/* <CheckBoxMargin>
                  <Checkbox name="isReceiveSms" Text1={t('Registration.WishSMS')} checked />
                </CheckBoxMargin> */}

                <ButtonDiv>
                  <ButtonRow>
                    <Button
                      gray
                      bottom
                      Title={t('Myprofile.Reset')}
                      onClick={() => {
                        methods.reset();
                        setIsSMSVerified(undefined);
                        const userLocation = location.find((item) => item.id === user.LocationId);
                        if (userLocation) {
                          setSelectedRegion(userLocation.region);
                          setSelectedDistrict(userLocation.id);
                        }
                      }}
                    />
                    <ButtonMargin />
                    <Button bottom submit Title={t('Myprofile.Update')} />
                  </ButtonRow>
                </ButtonDiv>
              </Inner>
              <Space />

              <SavedContainer>
                <SavedTitle>{t('Myprofile.SavedPayment')}</SavedTitle>

                {Array.isArray(creditCards) && creditCards.length > 0 ? (
                  <SavedInner>
                    {creditCards.map((card) => (
                      <CardRow key={`vm${card.id}`}>
                        <img alt="" height={40} src={card.token[0] === '4' ? visa : mastercard} />
                        <CardNo>{`●●●●${card.token.slice(-4)}`}</CardNo>
                        <StyledButton
                          type="button"
                          label={t('Myprofile.remove')}
                          color="grey"
                          style={{ marginTop: 10, fontSize: 12 }}
                          onClick={() => {
                            Swal.fire({
                              position: 'top',
                              text: t('Myprofile.confirmRemoveCard', {
                                token: `●●●●${card.token.slice(-4)}`,
                              }),
                              confirmButtonColor: '#fd980f',
                              confirmButtonText: t('MyAccount.iMonthly.confirm'),
                              showCancelButton: true,
                              cancelButtonText: t('MyAccount.iMonthly.cancel'),
                              cancelButtonColor: '#888',
                              reverseButtons: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                removeCreditCard(card.id, () => {
                                  window.location.reload();
                                });
                              } else if (result.isDenied) {
                                Swal.close();
                              }
                            });
                          }}
                        />
                      </CardRow>
                    ))}
                  </SavedInner>
                ) : (
                  <div
                    style={{ ...profileStyle.itemflex, justifyContent: 'center', paddingBottom: 0 }}
                  >
                    <span style={{ ...profileStyle.item }}>{t('Myprofile.NoSaved')}</span>
                  </div>
                )}
              </SavedContainer>

              <Space />

              <DeleteAccountContainer>
                <SectionTitle
                  titleStyle={{ marginTop: 0, cursor: 'pointer', width: 'fit-content' }}
                  title={t('Myprofile.deleteAccount')}
                  onClick={() => {
                    handelCreateUserDeletionRequest(
                      token,
                      user,
                      (e) => {
                        history.push({
                          pathname: `/${language}/customers/DeleteAccountTnc/${e.data.data.token}`,
                        });
                      },
                      () => {
                        Swal.fire({
                          position: 'center',
                          text: t('error'),
                        });
                      }
                    );
                  }}
                />
              </DeleteAccountContainer>
            </form>
          </FormContext>
        </div>
      )}
    </>
  );
};

export default withRouter(MyProfileTab);
