import styled from 'styled-components';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldError from './FieldError';

// TODO: responsive?

const Container = styled.div`
  display: flex;
  flex: 0 0 58.333333%;
  max-width: ${(props) => (props.address ? '100%' : '58.333333%')};
  padding-left: 13px;
  padding-top: 3px;
  flex-direction: column;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
    padding-left: 0;
  }
`;

const FieldContainer = styled.div`
  display: flex;
`;

const Input = styled.input`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding: 4px 10px;
  font-size: 13px;
  color: #666;
  height: 32px;
  border: 1px solid
    ${({ error, passworderror, isEmailValid, VRHError }) =>
      error || passworderror || isEmailValid || VRHError ? '#E56E5A' : '#bbb'};
  width: 100%;

  &:disabled {
    background-color: #dedede;
  }
`;

function TextField({
  errorMessage,
  name,
  placeHolder,
  type = 'text',
  children,
  onChange,
  address,
  disabled,
  password,
  onBlur,
  isEmailValid,
  isPhoneExist,
  style,
  VRHError,
}) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const passworderror = errors[password];
  const Ref = useRef();
  const { t } = useTranslation();

  return (
    <Container address={address} style={{ ...style }}>
      <FieldContainer>
        <Controller
          as={<Input ref={Ref} />}
          name={name}
          control={control}
          placeholder={placeHolder}
          type={type}
          onChange={onChange}
          disabled={disabled}
          error={error}
          passworderror={passworderror}
          VRHError={VRHError}
          onBlur={onBlur}
          isEmailValid={isEmailValid}
          onFocus={() => Ref.current.focus()}
          t={t}
        />
      </FieldContainer>
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
      {isEmailValid && <FieldError message={t('Registration.EmailExistError')} />}
      {isPhoneExist && <FieldError message={t('Registration.PhoneExistError')} />}
      {VRHError && <FieldError message={t('Registration.registrationMarkError')} />}
      {children}
    </Container>
  );
}

export default TextField;
