import styled from 'styled-components';
import backgroundimage from '../../../media/images/OURCOMPANY.png';

const Container = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  background-image: url(${backgroundimage});
  background-size: cover;
  background-position: center;
  justify-content: center;
  padding-bottom: 80px;
  background-color: #000000;
  padding: 15px;
`;

const Width = styled.div`
  width: 1024px;
  padding: 100px 0;

  @media (max-width: 850px) {
    padding: 20px 0;
  }
`;

const TitleDiv = styled.div`
  justify-content: center;
  display: flex;
`;

const Title = styled.span`
  color: #fff;
  font-size: 42px;

  @media (max-width: 850px) {
    font-size: 28px;
  }
`;

const SubTextDiv = styled.div`
  padding: 30px 15px;
  text-align: center;

  @media (max-width: 850px) {
    padding: 15px;
  }
`;

const SubText = styled.span`
  color: #fff;
  font-size: 18px;

  @media (max-width: 850px) {
    font-size: 16px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

const ImgBoxFlex = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 580px) {
    width: 100%;
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const ImgBoxWidth = styled.div`
  width: 100%;
  text-align: center;
`;

const ImgBox1 = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 30%;
  margin-right: 0;
  margin-left: 0;

  @media (max-width: 850px) {
    padding: 0 15px !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 580px) {
    flex-direction: row;
  }
`;

const ImgBox2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-right: 0;
  margin-left: 0;

  @media (max-width: 850px) {
    padding: 0 15px !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 580px) {
    flex-direction: row;
  }
`;

const ImgBox3 = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30%;
  padding-right: 10%;
  margin-right: 0;
  margin-left: 0;

  @media (max-width: 850px) {
    padding: 0 15px !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 580px) {
    flex-direction: row;
  }
`;

const PromoImg = styled.img`
  @media (max-width: 850px) {
    padding: 0 5px;
    max-width: 100%;
  }
`;

const ImgTextDiv = styled.div`
  @media (max-width: 580px) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    display: flex;
    align-items: center;
  }
`;

const ImgText1 = styled.span`
  display: inline-block;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 2px;
  vertical-align: middle;
`;

const ImgText2 = styled.span`
  color: #fff;
  display: inline-block;
  padding: 0.3rem 0;
  color: #fff;
  font-size: 2.0625rem;
  text-align: center;
  letter-spacing: 2px;
`;

export {
  Container,
  Width,
  TitleDiv,
  Title,
  SubTextDiv,
  SubText,
  ImgContainer,
  ImgBoxFlex,
  ImgBox1,
  ImgBoxWidth,
  PromoImg,
  ImgTextDiv,
  ImgText1,
  ImgText2,
  ImgBox2,
  ImgBox3,
};
