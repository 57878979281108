import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const BottomWidth = styled.div`
  width: 1000px;
  display: flex;
  transition: opacity 300ms ease-out, position 250ms ease-out, z-index 500ms ease-out;
  padding-bottom: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: flex-start;
  margin-top: 20px;

  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 850px) {
    position: relative;
    left: auto;
    transform: translateX(0px);
    align-items: flex-start;
  }
`;

const BottomImgDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  @media (max-width: 850px) {
    display: none;
  }
`;

const BottomImg = styled.img`
  height: 250px;
  width: 250px;
  border-radius: 200px;
`;

const BottomTextDiv = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 850px) {
    padding: 0px 20px 0px 20px;
  }

  a {
    color: #000;
    font-size: 14px;
  }
`;

const BottomTitle = styled.span`
  color: #fff;
  font-size: 26px;

  @media (max-width: 850px) {
    display: none;
  }
`;

const BottomText = styled.div`
  color: #fff;
  font-size: 1rem;
  margin-top: 1rem;
`;

const BottomTextRow = ({ detail }) => {
  const { t, i18n } = useTranslation();
  return (
    <BottomWidth>
      <BottomImgDiv>
        <BottomImg src={detail.image} />
      </BottomImgDiv>
      <BottomTextDiv>
        <BottomTitle>{i18n.language === 'en' ? detail.title : detail.chineseTitle} </BottomTitle>
        <BottomText
          dangerouslySetInnerHTML={{
            __html: i18n.language === 'en' ? detail.content : detail.chineseContent,
          }}
        />
      </BottomTextDiv>
    </BottomWidth>
  );
};

export default BottomTextRow;
