import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as icon from '../../media/icons';

import { usePpsPaymentConfig, useFpsPayment } from '../../models/Cart';
import { getPaymentConfig } from '../../helpers/api/cart';
import Modal from '../../components/General/PopupPrivacyPolicy';

const FpsPaymentButtons = styled(Link)`
  background-color: #fd980f;
  color: #fff;
  border: none;
  font-size: 0.75rem;
  margin: 12px 0;
  padding: 10px 20px;
  outline: none;
  transition: all 0.1s ease-out;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 580px) {
    width: 70%;
  }
`;

const WhiteContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #666;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

function Step3({ onlinePaymentIds }) {
  const { state } = useLocation();
  const { visaId, ppsId, fpsId } = onlinePaymentIds;
  const { paymentMethod, orderId } = state || JSON.parse(localStorage.getItem('hourlyItems'));

  useEffect(() => {
    if (!paymentMethod || !orderId) return;
    localStorage.setItem('hourlyItems', JSON.stringify({ paymentMethod, orderId }));
  }, [paymentMethod, orderId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 50,
      }}
    >
      {paymentMethod === fpsId && <FpsPayment orderId={orderId} />}
      {paymentMethod === visaId && (
        <>
          <MasterCardPayment orderId={orderId} />
          <Modal Popupshow="Custom">
            <div
              id="embedded-checkout-modal"
              style={{ width: '80vw', height: '90vh', maxWidth: 850 }}
            />
          </Modal>
        </>
      )}
      {paymentMethod === ppsId && <PpsPayment orderId={orderId} />}
    </div>
  );
}

export default Step3;

const MasterCardPayment = ({ orderId }) => {
  const scriptLoaded = (config) => {
    window.Checkout.configure(config);
    window.Checkout.showEmbeddedPage('#embedded-checkout-modal');
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    if (orderId !== null) {
      const locale = i18n.language === 'en' ? 'en_US' : 'zh_HK';
      getPaymentConfig(orderId, locale)
        .then((response) => {
          script.setAttribute('data-error', `${process.env.REACT_APP_API_DOMAIN}${response.error}`);
          script.setAttribute(
            'data-complete',
            `${process.env.REACT_APP_API_DOMAIN}${response.complete}`
          );
          script.setAttribute(
            'data-cancel',
            `${process.env.REACT_APP_API_DOMAIN}${response.cancel}`
          );
          script.setAttribute(
            'data-timeout',
            `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`
          );
          script.setAttribute('data-error', `${process.env.REACT_APP_API_DOMAIN}${response.error}`);
          script.src =
            process.env.REACT_APP_CHECKOUT_JS_SRC ||
            'https://fdhk.gateway.mastercard.com/checkout/version/55/checkout.js';
          script.async = true;
          document.body.appendChild(script);
          script.onload = () => scriptLoaded(response.config);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [orderId]);

  return (
    <div style={{ textAlign: 'left', width: '70%' }}>
      <div style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <p style={{ fontSize: '0.85rem' }}>{t('Cart.PPS.text1')}</p>
      {/* <p style={{ fontSize: '0.85rem' }}>2. Please wait...</p> */}
    </div>
  );
};

const PpsPayment = ({ orderId }) => {
  const { form } = usePpsPaymentConfig(orderId);
  const { t } = useTranslation();
  const openNewWindow = () => {
    const newWindow = window.open('about:blank', '_self');

    newWindow.document.write(form);
    newWindow.onbeforeunload = (e) => {
      e.preventDefault();
      window.location = '/';
    };
  };

  useEffect(() => {
    if (form) {
      openNewWindow();
    }
  }, [form]);

  return (
    <div style={{ textAlign: 'left', width: '70%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <ol>
        <li>{t('Cart.PPS.text1')}</li>
        <li>{t('Cart.PPS.text2')}</li>
        <li>{t('Cart.PPS.text3')}</li>
      </ol>
    </div>
  );
};

const FpsPayment = ({ orderId }) => {
  const { amount, qrCode } = useFpsPayment(orderId);
  const { t } = useTranslation();

  return (
    <WhiteContainer>
      <div
        style={{
          width: 600,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p style={{ color: '#888' }}>{t('Cart.PaymentMethod.ByFPS')}</p>

        <img
          style={{ marginBottom: 20, width: 180, height: 180 }}
          src={qrCode}
          width="15%"
          alt="qr_code"
        />

        <p style={{ fontSize: '0.9rem', color: '#888' }}>{t('Cart.FPS.forMobile')}</p>

        <br />
        <p style={{ fontSize: '2rem' }}>
          HK$
          {amount}
        </p>

        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <ol style={{ fontSize: '0.9rem', padding: '0 20px' }}>
            <li>{t('Cart.FPS.text1')}</li>
            <li>{t('Cart.FPS.text2')}</li>
            <li>
              {t('Cart.FPS.text3')}
              <br />
              {t('Cart.FPS.text4')}
            </li>
          </ol>
        </div>

        <FpsPaymentButtons
          onClick={() => {
            window.location = '/customers/account';
          }}
        >
          {t('Cart.FPS.complete')}
        </FpsPaymentButtons>

        <FpsPaymentButtons to="/" target="_top">
          {t('Cart.FPS.back')}
        </FpsPaymentButtons>
      </div>
    </WhiteContainer>
  );
};
