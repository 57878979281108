import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import useStaticInfo from '../../models/StaticInfo';

const Title = styled.h3`
  font-size: 1.75rem;
  color: #666;
  line-height: 1.5;
`;

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const MainWidth = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 20px 20px 104px 20px;
  width: 100%;
  box-shadow: 0 0 2px #bbb;

  p {
    font-size: 0.875rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }
`;

const MobileAppProblem = () => {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo(
    'Pages-Wilson Parking Mobile App - problem'
  );

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <div>
      <MainContainer>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }}>
          {/* <SubTitle>{t('iHourlyFAQ.h1Q')}</SubTitle>
          <Answer>{t('iHourlyFAQ.h1T')}</Answer>

          <PromoImg src={i18n.language === 'en' ? imageEng : imageZh} />
          <Margin top="-2px" />
          <SubTitle left>{t('iHourlyFAQ.h2Q')}</SubTitle>
          <Answer>{t('iHourlyFAQ.h2A1')}</Answer>
          <Answer>{t('iHourlyFAQ.h2A2')}</Answer>
          <Answer>{t('iHourlyFAQ.h2A3')}</Answer>
          <Answer>
            {t('iHourlyFAQ.h2A4')}
            <br />
            <br />
          </Answer>
          <Answer>
            <i>
              <strong>{t('iHourlyFAQ.h2T1')}</strong>
              <br />
              <br />
            </i>
          </Answer>
          <Answer>
            {t('iHourlyFAQ.h2T2')}
            <Href href="mailto:ihourly@wilsonparking.com.hk."> ihourly@wilsonparking.com.hk.</Href>
            {t('iHourlyFAQ.h2T3')}
          </Answer>
          <SubTitle>{t('iHourlyFAQ.FAQ')}</SubTitle>
          <Qusetion>{t('iHourlyFAQ.Q1')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A1')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q2')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A2')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q3')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A3')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q4')}</Qusetion>
          <Answer>
            {t('iHourlyFAQ.A4')}
            <Href href="mailto:ihourly@wilsonparking.com.hk."> ihourly@wilsonparking.com.hk.</Href>
            {t('iHourlyFAQ.A41')}
          </Answer>
          <Qusetion>{t('iHourlyFAQ.Q5')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A5')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q6')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A6')} </Answer>
          <Qusetion>{t('iHourlyFAQ.Q7')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A7')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q8')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A8')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q9')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A9')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q10')}</Qusetion>
          <Answer>
            {t('iHourlyFAQ.A10')}
            <Href href="mailto:ihourly@wilsonparking.com.hk."> ihourly@wilsonparking.com.hk.</Href>
            {t('iHourlyFAQ.A101')}
          </Answer> */}
        </MainWidth>
        <Title>{name}</Title>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }} />
      </MainContainer>
    </div>
  );
};
export default withRouter(MobileAppProblem);
