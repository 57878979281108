import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import ReactLoading from 'react-loading';
import { useForm, Controller } from 'react-hook-form';
import Cookies from 'universal-cookie';
import LockIcon from '@mui/icons-material/Lock';
import Reaptcha from 'reaptcha';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import {
  ErrorMsg,
  PasswordInput,
  PasswordRow,
  UsernameInput,
  UsernameRow,
  RecaptchaRow,
  InputCol,
  ForgotDiv,
  Forget,
} from './Login.styles';
import StyledButton from '../../../components/General/StyledButton';
import { TokenContext, useEmailVerifyMyprofile } from '../../../models/Customer';

const cookies = new Cookies();

const LoginForm = ({ setFormType }) => {
  const { t, i18n } = useTranslation();
  const { login, loading, loginWithToken } = useContext(TokenContext);
  const { isEmailValid, VerifyEmail } = useEmailVerifyMyprofile();
  const { search } = useLocation();

  const parsedQs = qs.parse(search);
  const { from, token } = parsedQs || {};

  const [show, setShow] = useState(false);

  const { control, handleSubmit, register, errors, watch, setError, setValue } = useForm();

  useEffect(() => {
    if (token) {
      loginWithToken(token);
    }
  }, [token]);

  const {
    username: storedUserName = '',
    password: storedPassword = '',
    remember: storedRememberMe = false,
  } = cookies.get('credentials') || {};

  const username = watch('username', storedUserName);
  const password = watch('password', storedPassword);
  const remember = watch('rememberMe', storedRememberMe);

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    });
  }, [i18n.language]);

  const onSubmit = (data) => {
    if (!isEmailValid) {
      VerifyEmail(username)
        .then((e) => {
          console.log(e);
        })
        .catch(() => {
          setError('username', {
            message: t('Login.notExist'),
          });
        });
      return;
    }

    if (!data.Reaptcha) {
      setError('Reaptcha');
      return;
    }

    if (data.password && !data.password.match(/^(?=.*[A-Z])(?=.*\d).{8,15}$/)) {
      setError('password', {
        message: t('Login.passwordError'),
      });
      return;
    }

    if (remember) {
      cookies.set('credentials', { username, password, remember });
    }

    login(username, password, from);
  };

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputCol>
        <UsernameRow>
          <UsernameInput
            name="username"
            ref={register({
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              },
            })}
            error={errors.username}
            placeholder={t('Login.Email')}
            onChange={(e) => {
              if (!e) {
                setValue('username', '');
                return;
              }
              setValue('username', e.target.value.trim());
            }}
          />
          <EmailIcon style={{ position: 'absolute', left: 10, top: 10 }} />
          {errors.username && (
            <ErrorMsg style={{ position: 'absolute', top: 45, left: 0 }}>
              {t('Login.emailError')}
            </ErrorMsg>
          )}
        </UsernameRow>

        <PasswordRow isEmailValid={isEmailValid}>
          <PasswordInput
            error={errors.password}
            ref={register({
              validate: {
                required: (v) => {
                  if (!v && isEmailValid) {
                    return false;
                  }

                  return true;
                },
              },
            })}
            type="password"
            name="password"
          />
          <LockIcon style={{ position: 'absolute', left: 10, top: 10 }} />
          {errors.password && (
            <ErrorMsg style={{ position: 'absolute', top: 75, left: 0 }}>
              {t('Login.passwordError')}
            </ErrorMsg>
          )}
        </PasswordRow>

        <RecaptchaRow error={errors.Reaptcha} isEmailValid={isEmailValid}>
          {show && (
            <Controller
              as={<Reaptcha />}
              name="Reaptcha"
              control={control}
              onExpire={() => setValue('Reaptcha', false, { shouldValidate: true })}
              sitekey="6LfPFksUAAAAAIcM2UKLjQ6X7mJOHIhGBUY1_PHf"
              onVerify={() => setValue('Reaptcha', true, { shouldValidate: true })}
              defaultValue={false}
              hl={i18n.language === 'zh' ? 'zh-hk' : 'en'}
            />
          )}
        </RecaptchaRow>
      </InputCol>

      <ForgotDiv>
        <Forget href={`/${i18n.language}/customers/forgot-password`}>
          {t('Login.Forgot_Password')}
        </Forget>
      </ForgotDiv>

      <StyledButton
        disabled={loading}
        type="submit"
        color="yellow"
        style={{
          margin: 0,
          fontSize: '0.8125rem',
          padding: '10px 20px',
          flex: 0,
          marginTop: isEmailValid ? 25 : 20,
          maxWidth: 200,
          zIndex: 10,
        }}
      >
        {t('Login.Login')}
        {loading && <ReactLoading type="spin" color="#888" width={20} height={20} />}
      </StyledButton>

      <StyledButton
        onClick={() => setFormType('registration')}
        // onClick={() => {
        //   if (i18n.language === 'en') {
        //     window.open('https://www.wilsonparking.com.hk/en-us/customers/registration', '_self');
        //     return;
        //   }
        //   window.open('https://www.wilsonparking.com.hk/zh-hk/customers/registration', '_self');
        // }}
        type="button"
        color="white"
        style={{
          margin: 0,
          fontSize: '0.8125rem',
          padding: '10px 30px',
          flex: 0,
          marginTop: 20,
          maxWidth: 200,
          zIndex: 10,
        }}
      >
        {t('Login.signUp')}
      </StyledButton>
    </form>
  );
};

export default LoginForm;
