import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { useCustomer, TokenContext } from './Customer';
import useEncrypt from '../hooks/useEncrypt';

const chechkUserRegistreredUrl = `${process.env.REACT_APP_API_DOMAIN}/wilRegistration/checkRegistered`;
const getWilInfoUrl = `${process.env.REACT_APP_API_DOMAIN}/wilRegistration/getStatus`;
const updateWilPreferencesUrl = `${process.env.REACT_APP_API_DOMAIN}/wilRegistration/customer`;

export default function useWilRegistration() {
  const { t, i18n } = useTranslation();
  const [dataFromWil, setDataFromWil] = useState();
  const { parseUrl } = useEncrypt();
  const { customer, fetchInfo } = useCustomer();
  const { token: userToken } = useContext(TokenContext);

  async function updateWilPreferences(customerId, isShowWilRegister) {
    try {
      const {
        data: { success },
      } = await axios.put(`${updateWilPreferencesUrl}/${customerId}`, {
        isShowWilRegister,
      });

      if (userToken) {
        fetchInfo({ headers: { token: userToken } });
      }

      return Promise.resolve(success);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getWilInfo(bookingID, reference, timestamp) {
    try {
      const {
        data: { hash, token, isShowWilRegister },
      } = await axios.get(
        `${getWilInfoUrl}/${bookingID}?reference=${reference}&timestamp=${timestamp}`
      );

      setDataFromWil({ hash, token, timestamp });

      return Promise.resolve({ hash, token, isShowWilRegister });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function checkUserRegistered(
    phone,
    bookingId,
    reference,
    CustomerId,
    timestamp,
    handelCheckout,
    setLoading
  ) {
    try {
      const {
        data: { registered },
      } = await axios.post(`${chechkUserRegistreredUrl}`, {
        phone: customer?.phone || phone,
      });

      let ISSHOWWILREGISTER = false;
      let URL = '';
      let CUSTOMERID = '';

      if (userToken) {
        ISSHOWWILREGISTER = customer?.isShowWilRegister;
        URL = `${parseUrl(
          `${process.env.REACT_APP_WIL_DOMAIN}/register?source=wp_web&lang=${i18n.language}&timestamp={{timestamp}}&token={{token}}&hash={{hash:worhashing256.\${token}|\${timestamp}:endhash}}`,
          {
            token: userToken,
            timestamp,
          },
          process.env.REACT_APP_SECRET
        )}`;
        CUSTOMERID = customer?.id;
      }

      if (!userToken) {
        const data = await getWilInfo(bookingId, reference, timestamp);
        const { hash, token, isShowWilRegister } = data || {};
        ISSHOWWILREGISTER = isShowWilRegister;
        URL = `${process.env.REACT_APP_WIL_DOMAIN}/register?source=wp_web&lang=${i18n.language}&timestamp=${timestamp}&token=${token}&hash=${hash}`;
        CUSTOMERID = CustomerId;
      }

      if (!registered) {
        if (ISSHOWWILREGISTER) {
          Swal.fire({
            input: 'checkbox',
            inputValue: 0,
            inputPlaceholder: t('PaymentLinkCart.showAgain'),
            text: t('PaymentLinkCart.WILMemberReminder'),
            position: 'center',
            confirmButtonColor: '#fd980f',
            confirmButtonText: t('PaymentLinkCart.regNow'),
            denyButtonText: t('PaymentLinkCart.skip'),
            denyButtonColor: '#888',
            reverseButtons: true,
            showDenyButton: true,
            returnInputValueOnDeny: true,
          }).then(async (result) => {
            if (result.value === 1) {
              await updateWilPreferences(CUSTOMERID, false);
            }
            if (result.isConfirmed) {
              window.open(URL, '_blank');
            }
            if (result.isDenied) {
              handelCheckout();
            }
          });

          return Promise.resolve(true);
        }

        handelCheckout();
        return Promise.resolve(false);
      }

      handelCheckout();
      return Promise.resolve(false);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  }

  return {
    checkUserRegistered,
    dataFromWil,
    getWilInfo,
  };
}
