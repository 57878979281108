import styled from 'styled-components';
import React from 'react';

const PageTitle = styled.span`
  color: #444;
  font-size: 32px;
  margin-bottom: 40px;
  align-self: flex-start;
`;

function RegistrationFormHeader({ title }) {
  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <div style={{ width: 1000, marginBottom: 20, marginTop: 20 }}>
        <PageTitle>{title}</PageTitle>
      </div>
    </div>
  );
}

export default RegistrationFormHeader;
