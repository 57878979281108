import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UpperItemContainter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: ${({ index, i }) => (index === i ? '90px' : '165px')};

  @media (max-width: 850px) {
    padding-top: 0px;
  }
`;

const NewsDiv = styled.div`
  background: ${({ index, i }) => (index === i ? 'rgba(0,0,0,0.7)' : '#F8F1E8')};
  max-width: 275px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: ${({ index, i }) => (index === i ? '250px' : '115px')};
  padding-bottom: ${({ index, i }) => (index === i ? '20px' : '0px')};

  @media (max-width: 850px) {
    max-width: 100%;
    background: transparent;
    height: 500px;
    margin-top: 0px;
    padding-top: 0px;
    justify-content: center;
    padding-bottom: 20px;
  }
`;

const ImgDiv = styled.div`
  margin-top: 10px;
  z-index: 10;
  position: absolute;
  top: ${({ index, i }) => (index === i ? '95px' : '0px')};
  @media (max-width: 850px) {
    display: flex;
    position: relative;
    top: 0px;
  }
`;

const ProImg = styled.img`
  border-radius: 50%;
  border: 3px solid #fff;
  max-width: 230px;
  max-height: 230px;
  width: 100%;
  height: 100%;
`;

const TextDiv = styled.div`
  padding: 1.5rem 0rem 2.5rem 0rem;
  text-align: center;
  min-height: 250px;
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    display: flex;
    position: relative;
    top: 0px;
    padding: 20px 0rem 20px 0rem;
    min-height: auto;
  }
`;

const Title = styled.div`
  font-size: 32px;
  color: ${({ index, i }) => (index === i ? '#FD980F' : '#888')};
  line-height: 1.2;

  @media (max-width: 850px) {
    color: #fd980f;
  }
`;

const TextMargin = styled.div`
  margin-top: 10px;
`;

const PromoText = styled.span`
  font-size: 14px;
  color: ${({ index, i }) => (index === i ? '#fff' : '#888')};
  text-align: center;

  @media (max-width: 850px) {
    color: #fff;
  }
`;

const UpperItemRow = ({ detail, index, innerIndex }) => {
  const { t, i18n } = useTranslation();
  return (
    <UpperItemContainter index={index} i={innerIndex}>
      <NewsDiv index={index} i={innerIndex}>
        <ImgDiv index={index} i={innerIndex}>
          <ProImg src={detail.image} />
        </ImgDiv>
        <TextDiv>
          <Title index={index} i={innerIndex}>
            {i18n.language === 'en' ? detail.title : detail.chineseTitle}
            <br />
          </Title>
          <TextMargin />
          <PromoText index={index} i={innerIndex}>
            {i18n.language === 'en' ? detail.abstract : detail.chineseAbstract}
          </PromoText>
        </TextDiv>
      </NewsDiv>
    </UpperItemContainter>
  );
};

export default UpperItemRow;
