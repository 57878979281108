import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router-dom';
import {
  Container,
  TitleRow,
  TitleInner,
  TitleDiv,
  TitleBlock,
  Text,
  FormWidth,
  FormInner,
  FormBlock,
  FormDiv,
  TextRow,
  ButtonRow,
  ButtonDiv,
  Submit,
} from './styles';

const DeleteAccountSuccess = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container className="d-block ml-auto mr-auto mt-5">
      <TitleRow>
        <TitleInner>
          <TitleDiv>
            <TitleBlock>
              {/* <img src={ErrorImg} style={{ width: 70, height: 70 }} /> */}
              <Text style={{ textTransform: 'uppercase' }}>{t('Myprofile.successText')}</Text>
              <Text>{t('Myprofile.successText1')}</Text>
            </TitleBlock>
          </TitleDiv>
        </TitleInner>
      </TitleRow>
      <FormWidth>
        <FormInner>
          <FormBlock>
            <FormDiv>
              <TextRow style={{ justifyContent: 'flex-start' }}>
                <ButtonRow>
                  <ButtonDiv style={{ justifyContent: 'flex-start' }}>
                    <Submit href={`/${i18n.language}`} style={{ marginLeft: 0 }}>
                      {t('sevenPayment.back')}
                    </Submit>
                  </ButtonDiv>
                </ButtonRow>
              </TextRow>
            </FormDiv>
          </FormBlock>
        </FormInner>
      </FormWidth>
    </Container>
  );
};

export default withRouter(DeleteAccountSuccess);
