import styled from 'styled-components';

const FieldContainer = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  flex-direction: row;
  @media (max-width: 850px) {
    flex-direction: column;
  }
  margin-top: ${(props) => (props.top ? '3px' : '0px')};
`;

export default FieldContainer;
