import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import { useAward } from '../../../models/Award';
import '../Carousel.css';

const AwardImg = styled.img`
  height: 100%;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
`;

const AwardBox = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  background: #f6f6f6;
  padding-bottom: 60px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

function AwardsItem({ award: { image } }) {
  const { i18n } = useTranslation();
  return (
    <AwardBox href={`/${i18n.language}/awards/index`}>
      <AwardImg
        src={`${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}${
          image.includes('static') ? '' : 'static'
        }${image}`}
      />
    </AwardBox>
  );
}
const DeskTopAwards = () => {
  const { awards } = useAward();

  const [index, setIndex] = useState(1);
  const totalAwards = useMemo(
    () => Math.floor(awards.length / 4 + (awards.length % 4 > 0 ? 1 : 0)),
    [awards]
  );
  const displayAwards = useMemo(
    () => awards.slice((index - 1) * 4, index * 4) || [],
    [index, awards]
  );

  const MobileNewshandleSelect = (selectedMobileNewsIndex) => {
    if (selectedMobileNewsIndex === totalAwards + 1) {
      setIndex(1);
      return;
    }

    if (selectedMobileNewsIndex === 0) {
      setIndex(totalAwards);
      return;
    }

    setIndex(selectedMobileNewsIndex);
  };

  return (
    <Container>
      <Carousel activeIndex={index} onSelect={MobileNewshandleSelect} id="DeskAwards">
        {awards.map(({ id }) => (
          <Carousel.Item key={id}>
            {displayAwards.map((award) => (
              <AwardsItem key={award.id} award={award} />
            ))}
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default DeskTopAwards;
