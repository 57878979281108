import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { UseUserDeletionRequest, TokenContext } from '../../../models/Customer';
import {
  Container,
  TitleRow,
  TitleInner,
  TitleDiv,
  TitleBlock,
  Text,
  FormWidth,
  FormInner,
  FormBlock,
  FormDiv,
  TextRow,
  ButtonRow,
  ButtonDiv,
  Back,
  LoadingContainer,
  SubmitButton,
} from './styles';
import WarningImg from '../../../media/images/warningIcon.png';

const DeleteAccountConfirm = () => {
  const { t, i18n } = useTranslation();
  const { handelDeleteDeletionRequest } = UseUserDeletionRequest();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const { logout } = useContext(TokenContext);

  useEffect(() => {
    if (!state || Object.values(state).length === 0) {
      setIsLoading(false);
      history.push({
        pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
        state: t('error'),
      });
    }
  }, [state]);

  function handelSubmit() {
    setIsLoading(true);
    handelDeleteDeletionRequest(
      state.token,
      () => {
        setIsLoading(false);
        logout();
        history.push({
          pathname: `/${i18n.language}/customers/DeleteAccount/success`,
        });
      },
      (err) => {
        setIsLoading(false);
        history.push({
          pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
          state: t('error'),
        });
      }
    );
  }

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <ReactLoading type="spokes" color="#fd980f" height={80} width={80} />
        </LoadingContainer>
      )}
      {!isLoading && Object.values(state).length > 0 && (
        <Container>
          <TitleRow style={{ height: '80%' }}>
            <TitleInner>
              <TitleDiv>
                <TitleBlock>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <img src={WarningImg} style={{ width: 100, height: 100 }} alt="" />
                  </div>

                  <br />
                  <Text style={{ fontWeight: 'bold' }}>{t('Myprofile.confirmText')}</Text>
                  <br />
                  <Text style={{ fontWeight: 'bold' }}>{t('Myprofile.confirmText1')}</Text>
                </TitleBlock>
              </TitleDiv>
            </TitleInner>
          </TitleRow>
          <FormWidth>
            <FormInner>
              <FormBlock>
                <FormDiv>
                  <TextRow>
                    <ButtonRow>
                      <ButtonDiv>
                        <Back href={`/${i18n.language}`}>{t('MyAccount.iMonthly.cancel')}</Back>
                        <SubmitButton onClick={handelSubmit}>
                          {t('MyAccount.iMonthly.confirm')}
                        </SubmitButton>
                      </ButtonDiv>
                    </ButtonRow>
                  </TextRow>
                </FormDiv>
              </FormBlock>
            </FormInner>
          </FormWidth>
        </Container>
      )}
      {!isLoading && Object.values(state) === 0 && <Container />}
    </>
  );
};

export default withRouter(DeleteAccountConfirm);
