import React, { useState } from 'react';
import { Link as routerLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  flex-basis: 30%;
`;

const HeaderContainer = styled.div`
  background: #444;
  font-size: 0.7rem;
  text-align: center;
  color: #fff;
  padding: 7px 10px;
  box-shadow: 2px 0 5px #bbb;
`;

const HeaderIcon = styled.img`
  width: 20px;
`;

const HeaderTitle = styled.span`
  align-self: center;
  margin: 0px 0 0 10px;
  font-size: 0.75rem;
`;

const EntryContent = styled.div`
  box-shadow: 2px 0 5px #bbb;
`;

const EntryContainer = styled.div`
  background: ${({ isActive }) => (isActive ? '#f3f3f3' : '#fff')};
  color: #808080;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 1.21rem;
  color: ${({ isActive }) => (isActive ? '#fd980f' : '#888')};
  margin: 0;

  ${EntryContainer}:hover & {
    color: #fd980f;
    text-decoration: underline;
  }
`;

const Address = styled.span`
  color: #888;
  font-size: 0.85rem;
  text-decoration: none;
`;

const BrowseButton = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: #fd980f;
  visibility: ${({ isActive }) => (isActive ? 'hidden' : 'visible')};
  padding-top: 7px;
`;

const Arrow = styled.div`
  background: #f3f3f3;
  width: 20px;
  height: 20px;
  margin-right: -8px;
  float: right;
  align-self: center;
  transform: rotate(45deg);
`;

const Link = styled(routerLink)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 23px 15px;

  &:hover {
    text-decoration: none;
  }
`;

function NearBy({ isHidden, carParks = [], carParkName, setActiveToZero }) {
  const { t, i18n } = useTranslation();
  // const [hoveredCarPark, setHoveredCarPark] = useState('');

  const nearByCarIcon = 'https://www.wilsonparking.com.hk/img/carpark-detail/icon-morecarpark.png';
  const browseIcon = 'https://www.wilsonparking.com.hk/img/carpark-detail/icon-arrow.png';

  return (
    <Container hidden={isHidden}>
      <HeaderContainer>
        <HeaderIcon src={nearByCarIcon} alt="location" />
        <HeaderTitle>{t('CarParkDetail.DistrictCarParks')}</HeaderTitle>
      </HeaderContainer>

      <EntryContent>
        {carParks.map(({ id, name, address }) => {
          const isActive = carParkName === name;
          // const isHover = hoveredCarPark === id;
          return (
            <EntryContainer className="carparks-nearby" key={`cpn${id}`} isActive={isActive}>
              <Link
                to={`/${i18n.language}/car_parks/detail/${id}`}
                onClick={setActiveToZero}
                // onMouseEnter={() => setHoveredCarPark(id)}
                // onMouseLeave={() => setHoveredCarPark('')}
              >
                <div style={{ flexBasis: '80%' }}>
                  <Title isActive={isActive}>{name}</Title>
                  <Address>{address}</Address>
                </div>
                <BrowseButton isActive={isActive}>
                  <img src={browseIcon} width="9" height="15" />
                </BrowseButton>
              </Link>
              {isActive && <Arrow />}
            </EntryContainer>
          );
        })}
      </EntryContent>
    </Container>
  );
}

export default NearBy;
