import { useTranslation } from 'react-i18next';
import React from 'react';
import moment from 'moment';
import { Column, Text, FPSRemarkDiv } from './style';
import FPSIconEn from '../../../media/icons/fpsEn.svg';
import FPSIconZh from '../../../media/icons/fpsZh.svg';

export default function FpsPaymentDetail({ qrCode, amount, bookingDetail }) {
  const { t, i18n } = useTranslation();

  // function finallyFee() {
  //   if (last === 0) {
  //     return fee;
  //   }
  //   if (fee % limit === 0) {
  //     return limit;
  //   }
  //   if (fee % limit > 0 && index === last) {
  //     return fee % limit;
  //   }
  //   return limit;
  // }

  const { validTo, Car: { registrationMark = '' } = {} } = bookingDetail || {};

  return (
    <Column className="item">
      <img
        draggable="false"
        alt=""
        src={i18n.language === 'en' ? FPSIconEn : FPSIconZh}
        height="100"
        width="100"
      />
      <Text fontsize="20px" margintop="10px" bold>
        {t('fpsPayment.payBy')}
      </Text>
      {qrCode && <img alt="" src={qrCode} style={{ height: 150, width: 150 }} draggable="false" />}

      <FPSRemarkDiv>
        <Text bold fontsize="30px">
          HK$
          {amount}
        </Text>
        <br />
        <Text bold fontsize="28px">
          {registrationMark}
        </Text>
        <Text bold fontsize="20px">
          {t('Cart.FPS.onlyPeriod', {
            period: validTo ? moment(validTo).format('YYYY - MM') : '-',
          })}
        </Text>
      </FPSRemarkDiv>

      <Text fontsize="13px" style={{ color: 'rgb(231, 76, 60)' }}>
        {t('fpsPayment.useCap')}
      </Text>
    </Column>
  );
}
