import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Column, Text } from './style';
import sevenIcon from '../../../assets/images/sevenIcon.jpg';
import FileUpload from './ImageUpload';
import yellowline from '../../../media/icons/yellowline.png';
import successImg from '../../../media/icons/success.png';
import info from '../../../media/icons/info.png';
import useOCR from '../../../models/OCR';
import StyledButton from '../../../components/General/StyledButton';

export default function SevenPaymentDetail({
  detail,
  QrCodeData,
  setReceipts,
  receipts,
  index,
  OrderDetail,
  limit,
  length,
  orderId,
}) {
  const { t, i18n } = useTranslation();

  const { img } = QrCodeData || {};

  const { checkOCR, OCRResult, OCRLoading } = useOCR();

  const { status } = OCRResult || {};

  const history = useHistory();

  const { servicePeriod, registrationMark, amount, CarParkName, subTitle, orderReference } =
    OrderDetail || {};

  const [finallyAmount, setFinallyAmount] = useState();

  const [count, setCount] = useState(0);

  const limitCounttoOCR = 3;

  useEffect(() => {
    if (length === 1) {
      setFinallyAmount(amount);
      return;
    }
    if (amount % limit > 0 && index === length - 1) {
      const regex = /^[0-9]*[1-9][0-9]*$/;
      if (regex.test(amount % limit)) {
        setFinallyAmount(amount % limit);
        return;
      }
      setFinallyAmount((amount % limit).toFixed(1));
      return;
    }
    setFinallyAmount(limit);
  }, [amount]);

  const success = {
    title: t('sevenPayment.success'),
    html:
      `<img src=${successImg} width='100' height='100'></img>` +
      `<div id='PaymentLinkInnerTextContainer'>${t('sevenPayment.no')}</div>` +
      `${orderReference}` +
      `<div id='PaymentLinkInnerInfo'>
                  <div id='PaymentLinkInnerInfoDiv'>
                    <img src=${info} width='30' height='30'></img>
                  </div>
                  <div id='PaymentLinkInnerTextDiv'>
                    ${t('sevenPayment.confirm')}
                  </div>
                </div>`,
    confirmButtonColor: '#888',
    confirmButtonText: t('sevenPayment.back'),
    didClose: () => history.push({ pathname: `/${i18n.language}` }),
    allowOutsideClick: false,
    customClass: {
      content: 'PaymentLinkPopup',
    },
  };

  const fail = {
    title: t('sevenPayment.progress'),
    html:
      `<img src=${yellowline} width='100' height='100'></img>` +
      `<div id='PaymentLinkInnerTextContainer'>${t('sevenPayment.no')}</div>` +
      `${orderReference}` +
      `<div id='PaymentLinkInnerInfo'>
                  <div id='PaymentLinkInnerInfoDiv'>
                    <img src=${info} width='30' height='30'></img>
                  </div>
                  <div id='PaymentLinkInnerTextDiv'>
                    ${t('sevenPayment.confirm')}
                  </div>
                </div>`,
    confirmButtonColor: '#888',
    confirmButtonText: t('sevenPayment.back'),
    didClose: () => history.push({ pathname: `/${i18n.language}` }),
    allowOutsideClick: false,
    customClass: {
      content: 'PaymentLinkPopup',
    },
  };

  return (
    <Column className="item" data-value={detail}>
      <img draggable="false" alt="" src={sevenIcon} style={{ height: 30, width: 30 }} />
      <Text fontsize="20px" margintop="10px" bold>
        {t('sevenPayment.text1')}
      </Text>
      <Text fontsize="13px">{t('sevenPayment.text2')}</Text>
      <Text fontsize="13px">{t('sevenPayment.text3')}</Text>
      <Text fontsize="13px" style={{ color: 'rgb(231, 76, 60)' }}>
        {t('sevenPayment.valid')}
      </Text>
      {QrCodeData && (
        <img alt="" src={img?.src} style={{ height: 150, width: 150 }} draggable="false" />
      )}
      <Text fontsize="15px" margintop="10px">
        {i18n.language === 'zh' && `[${moment().add(1, 'M').format('MM/YYYY')}]`}
        {t('sevenPayment.applicable')}
        {i18n.language === 'en' && `[${moment().add(1, 'M').format('MM/YYYY')}]`}
        <br />
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.text11')}
        {CarParkName}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.text4')}
        {t(`PaymentRecords.renew`)}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.service')}
        {`[${subTitle}]`}
      </Text>
      <Text fontsize="13px">
        {t('sevenPayment.text5')}
        {servicePeriod}
      </Text>
      <Text bold fontsize="15px">
        {t('sevenPayment.text6')}
        {registrationMark}
      </Text>
      <Text fontsize="36px" bold>
        HK$
        {finallyAmount}
      </Text>
      <Text fontsize="13px" margintop="-15px" style={{ color: 'rgb(231, 76, 60)' }}>
        {t('sevenPayment.text7')}
      </Text>
      <FileUpload
        setReceipts={setReceipts}
        receipts={receipts}
        index={index}
        finallyAmount={finallyAmount}
        orderId={orderId}
        checkOCR={checkOCR}
        OCRResult={OCRResult}
        OCRLoading={OCRLoading}
        count={count}
        setCount={setCount}
        limitCounttoOCR={limitCounttoOCR}
        type="seven"
      />
      {/* {count >= 3 && status === 'error' && (
        <StyledButton
          type="button"
          label={t('sevenPayment.complete')}
          color="white"
          style={{ maxWidth: 200, minHeight: 50, maxHeight: 50, marginTop: 20 }}
          onClick={() => {
            Swal.fire(fail);
          }}
        />
      )}
      {status === 'matched' && (
        <StyledButton
          type="button"
          label={t('sevenPayment.complete')}
          color="white"
          style={{ maxWidth: 200, minHeight: 50, maxHeight: 50, marginTop: 20 }}
          onClick={() => {
            Swal.fire(success);
          }}
        />
      )} */}

      {Object.keys(OCRResult).length > 0 && (
        <StyledButton
          type="button"
          label={t('sevenPayment.complete')}
          color="white"
          style={{ maxWidth: 200, minHeight: 50, maxHeight: 50, marginTop: 20 }}
          onClick={() => {
            Swal.fire({
              title: t('sevenPayment.progress'),
              html:
                `<img src=${yellowline} width='100' height='100'></img>` +
                `<div id='PaymentLinkInnerTextContainer'>${t('sevenPayment.no')}</div>` +
                `${orderReference}` +
                `<div id='PaymentLinkInnerInfo'>
                          <div id='PaymentLinkInnerInfoDiv'>
                            <img src=${info} width='30' height='30'></img>
                          </div>
                          <div id='PaymentLinkInnerTextDiv'>
                            ${t('sevenPayment.confirm')}
                          </div>
                        </div>`,
              confirmButtonColor: '#888',
              confirmButtonText: t('sevenPayment.back'),
              didClose: () => history.push({ pathname: `/${i18n.language}` }),
              allowOutsideClick: false,
              customClass: {
                content: 'PaymentLinkPopup',
              },
            });
          }}
        />
      )}
    </Column>
  );
}
