import { useState, useEffect, createContext, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useOfflinePaymentMethods, useOnlinePaymentMethods } from './SelectOptions';

const cartUrl = `${process.env.REACT_APP_API_DOMAIN}/cart`;
const guestCartUrl = `${process.env.REACT_APP_API_DOMAIN}/guestCart`;
const pickupLocationUrl = `${process.env.REACT_APP_API_DOMAIN}/pick-up-location?isEnable=1`;
const getOneOrder = (id) => `${process.env.REACT_APP_API_DOMAIN}/Order/${id}`;

const CartContext = createContext(null);

function useCart(cartType) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const localStoragePaymentMethod = localStorage.getItem('paymentMethod');
  const [cart, setCart] = useState([]);
  const [orderId, setOrderId] = useState(localStorage.getItem('orderId'));
  const [PickUpLocationId, setPickUpLocationId] = useState(null);
  const [PaymentMethodId, setPaymentMethodId] = useState(localStoragePaymentMethod);
  const [FirstDataTokenId, setFirstDataTokenId] = useState(null);

  async function book(order) {
    const newCartItems = [...cart, order];

    setCart(newCartItems);
    localStorage.setItem(cartType, JSON.stringify(newCartItems));
    return newCartItems;
  }

  useEffect(() => {
    const init = JSON.parse(localStorage.getItem(cartType) || '[]').filter(
      ({ ParkingPlanId }) => ParkingPlanId
    );
    const isEng = language === 'en';

    setCart(
      init.map((item) => {
        const { carPark, type, servicePeriod, service } = item;

        return {
          ...item,
          carPark: { PaymentMethods: [], ...carPark },
          name: isEng ? carPark?.englishName : carPark?.chineseName,
          address: isEng ? carPark?.englishAddress : carPark?.chineseAddress,
          displayType: t(`BookingType.${type}`),
          servicePeriod: servicePeriod.replace(/to|至/g, isEng ? 'to' : '至'),
          service,
        };
      })
    );
  }, [localStorage.getItem(cartType), language]);

  // useEffect(() => {
  //   const isEng = language === 'en';
  //   setCart(
  //     cart.map((item) => {
  //       const { carPark, type, servicePeriod, isReserved } = item;
  //       return {
  //         ...item,
  //         name: isEng ? carPark?.englishName : carPark?.chineseName,
  //         address: isEng ? carPark?.englishAddress : carPark?.chineseAddress,
  //         displayType: t(`BookingType.${type}`),
  //         servicePeriod: servicePeriod.replace(/to|至/g, isEng ? 'to' : '至'),
  //         service: isReserved ? t('BookingService.isReserved') : t('BookingService.isNotReserved'),
  //       };
  //     })
  //   );
  // }, [language]);

  function remove(orderIndex) {
    console.log({ orderIndex, length: cart.length, cart });
    // if (orderIndex >= cart.length) {
    //   // throw new Error(`Order Index ${orderIndex} out of bound`);
    //   console.log(`Order Index ${orderIndex} out of bound`);
    //   return;
    // }
    cart.splice(orderIndex, 1);
    setCart([...cart]);
    localStorage.setItem(cartType, JSON.stringify([...cart]));
  }

  function replace(array) {
    setCart(array);
    localStorage.setItem(cartType, JSON.stringify(array));
  }

  function removeAll() {
    // setCart([]);
    localStorage.setItem(cartType, JSON.stringify([]));
  }

  function onSelectCar(orderIndex, carObj) {
    if (orderIndex >= cart.length) {
      // throw new Error(`Order Index ${orderIndex} out of bound`);
      console.error(`Order Index ${orderIndex} out of bound`);
      return;
    }
    const order = cart[orderIndex];
    Object.assign(order, {
      CarId: carObj.id,
      registrationMark: carObj.registrationMark,
    });
    cart.splice(orderIndex, 1, order);
    setCart([...cart]);
  }

  function onSelectPickUp(pickUpId) {
    setPickUpLocationId(pickUpId);
    setCart(cart.map((item) => Object.assign(item, { PickUpLocationId: pickUpId })));
  }

  function onSelectToken(tokenId) {
    setFirstDataTokenId(tokenId);
  }

  function onSelectPaymentMethod(methodId) {
    setPaymentMethodId(methodId);
    setCart(cart.map((item) => Object.assign(item, { Payment: { PaymentMethodId: methodId } })));
  }

  async function checkout(token, isPaymentLink = false) {
    try {
      let headers = {};
      if (token) headers = { ...headers, token };
      const response = await axios({
        method: 'post',
        headers,
        url: cartUrl,
        data: {
          cart,
          PaymentMethodId,
          FirstDataTokenId:
            PaymentMethodId !== 6 || FirstDataTokenId === 'new' ? null : FirstDataTokenId,
          isPaymentLink,
          origin: 'web',
        },
      });

      const {
        data: {
          data: { id },
        },
      } = response;

      setOrderId(id);
      localStorage.setItem('orderId', id);
      localStorage.setItem('paymentMethod', PaymentMethodId);
      return id;
    } catch (error) {
      const {
        data: { error: errorMessage },
      } = error.response || {};

      if (errorMessage === 'Existing Booking Found') {
        Swal.fire({
          title: t('ErrorCode.CART_005'),
          icon: 'error',
          confirmButtonText: t('MyAccount.iMonthly.confirm'),
          confirmButtonColor: '#fd980f',
        });
        return false;
      }

      alert(errorMessage);
      return false;
    }
  }

  function clearCart() {
    // console.log('clear cart', cartType);
    setCart([]);
    localStorage.removeItem(cartType);
    localStorage.removeItem('paymentMethod');
    localStorage.removeItem('orderId');
    localStorage.removeItem('spareOrderId');
  }

  function clearTemporaryCart() {
    setCart([]);
    localStorage.removeItem(cartType);
    localStorage.removeItem('paymentMethod');
    localStorage.removeItem('orderId');
  }

  return {
    cart,
    checkout,
    paymentMethodId: PaymentMethodId,
    book,
    remove,
    removeAll,
    onSelectCar,
    onSelectPickUp,
    onSelectToken,
    onSelectPaymentMethod,
    orderId,
    clearCart,
    PickUpLocationId,
    clearTemporaryCart,
    replace,
  };
}

function usePpsPaymentConfig(orderId) {
  const { i18n } = useTranslation();

  const ppsConfigUrl = `${process.env.REACT_APP_API_DOMAIN}/pps?orderId=${orderId}&locale=${i18n.language}`;

  const [inputs, setInputs] = useState(undefined);
  const [form, setForm] = useState(undefined);

  useEffect(() => {
    async function getPpsConfig() {
      console.log('getting PPS config...');
      try {
        if (!orderId) return;

        const {
          data: { data },
        } = await axios.get(ppsConfigUrl);
        const { createdAt, id, updatedAt, ...rest } = data;

        console.log({ data });

        const formInputs = {
          ...rest,
        };

        setInputs(formInputs);
      } catch (e) {
        alert({ e });
      }
    }

    getPpsConfig();
  }, []);

  async function getForm(formData) {
    console.log('sending config form......');
    console.log({ url: process.env.REACT_APP_PPS_PATH, formData });
    try {
      const query = Object.entries(formData)
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join('&');
      console.log(query);
      const { data } = await axios.get(`${process.env.REACT_APP_PPS_PATH}?${query}`);
      setForm(data);
    } catch (e) {
      alert(e);
      console.log(e);
    }
  }

  useEffect(() => {
    if (inputs) {
      getForm(inputs);
    }
  }, [inputs]);

  return { form };
}

function useFpsPayment(orderId) {
  const fpsPaymentUrl = `${process.env.REACT_APP_API_DOMAIN}/fps/qrCode?orderId=${orderId}`;

  const [amount, setAmount] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [orderReference, setOrderReference] = useState();
  const [bookingDetail, setBookingDetail] = useState({});

  useEffect(() => {
    if (!orderId) return;
    async function getPaymentData() {
      const {
        data: { data },
      } = await axios.get(fpsPaymentUrl);

      const {
        data: { data: order = {} },
      } = await axios.get(getOneOrder(orderId));

      const { Bookings = [] } = order || {};

      const pendingBookings = Bookings.filter((e) => e.status === 'pending') || [];
      const latestBooking = pendingBookings[pendingBookings.length - 1];

      const { amount: amountData, qrCode: qrCodeData, orderReference: refcrence } = data;

      setBookingDetail(latestBooking);
      setAmount(amountData);
      setQrCode(qrCodeData);
      setOrderReference(refcrence);
    }

    getPaymentData();
  }, []);

  return { amount, qrCode, orderReference, bookingDetail };
}

function usePickupLocations() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // setLocations([
    //   { label: t('Cart.contantYou'), address: t('Cart.contantAddress'), description: '' },
    // ]);
    async function getLocations() {
      const { data } = await axios.get(pickupLocationUrl);
      const allLocations = data.data.reduce((output, { id, name, address, description }) => {
        output.push({
          value: id,
          label: name,
          address,
          description,
        });

        return output;
      }, []);

      setLocations(allLocations);
    }
    getLocations();
  }, [language]);

  return { locations };
}

function useServicePeriodDisplay() {
  const { i18n } = useTranslation();

  const servicePeriodDisplay = useCallback(
    (servicePeriod) => {
      const periodRegex = /(?<periodStart>.+) (to|至|-) (?<periodEnd>.+)/g;
      const [match, periodStart, to, periodEnd] = periodRegex.exec(servicePeriod);
      if (!periodStart || !periodEnd) return '-';
      const periodStartMoment = moment(periodStart, ['D/MM/YYYY', 'D MMM YYYY', 'YYYY年 MMM D日']);
      const periodEndMoment = moment(periodEnd, ['D/MM/YYYY', 'D MMM YYYY', 'YYYY年 MMM D日']);
      if (!periodStartMoment.isValid() || !periodEndMoment.isValid()) return '-';

      let toWording = '至';
      if (i18n.language === 'en') toWording = 'to';
      const dateFormat = i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年 MMM D日';
      return `${periodStartMoment
        .locale(i18n.language)
        .format(dateFormat)} ${toWording} ${periodEndMoment
        .locale(i18n.language)
        .format(dateFormat)}`;
    },
    [i18n.language]
  );

  return servicePeriodDisplay;
}

function useAssociations() {
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
  const [allAvailablePaymentMethods, setAllAvailablePaymentMethods] = useState([]);
  // const [isSettingAvailableMethods, setIsSettingAvailableMethods] = useState(false);

  // useEffect(() => {
  //   if (paymentMethodsLoading && isSettingAvailableMethods) {
  //     setPaymentMethodsLoading(false);
  //     setIsSettingAvailableMethods(false);
  //   }
  // }, [availablePaymentMethods]);

  async function getPaymentData(carParkId) {
    setPaymentMethodsLoading(true);
    Promise.all(
      carParkId.map(async (e) => {
        const getAssociationsURL = `${process.env.REACT_APP_API_DOMAIN}/carpark/${e}/PaymentMethods`;
        const {
          data: { data },
        } = await axios.get(getAssociationsURL);

        setAllAvailablePaymentMethods((prev) => ({ ...prev, [e]: data }));
        return data;
      })
    )
      .then((e) => {
        // setIsSettingAvailableMethods(true);
        const comparedPaymentMethods = e.reduce((acc, currentValue) =>
          acc.filter(
            (x) =>
              currentValue.filter((r) => {
                return r.id === x.id;
              }).length > 0
          )
        );

        setAvailablePaymentMethods(comparedPaymentMethods);
        setPaymentMethodsLoading(false);
      })
      .catch(() => {
        setAvailablePaymentMethods([]);
        setPaymentMethodsLoading(false);
      });
  }

  return {
    getPaymentData,
    availablePaymentMethods,
    paymentMethodsLoading,
    allAvailablePaymentMethods,
  };
}

function useBuyNewCart() {
  const [carts, setCarts] = useState([]);
  const [buyNewOrderId, setBuyNewOrderId] = useState(localStorage.getItem('buyNewOrderId'));
  const { t } = useTranslation();

  function clearBuyNewCart() {
    setCarts([]);
    setBuyNewOrderId(null);
    localStorage.removeItem('buyNewOrderId');
  }

  function getCodeString(string, error) {
    switch (string) {
      case 'CART_001':
        return 'Customer not found';
      case 'CART_002':
        return 'Invalid Valid From/To';
      case 'CART_003':
        return 'Missing Compulsory Parameters';
      case 'CART_004':
        return 'Duplicate Registration Mark in same order';
      case 'CART_005':
        return t('ErrorCode.CART_005');
      case 'CART_006':
        return 'Mismatched Car.CarType with ParkingPlan.CarType';
      case 'CART_007':
        return 'Mismatched Car Park';
      case 'CART_008':
        return 'Mismatched Car and Owner';
      case 'CART_009':
        return t('ErrorCode.CART_009');
      case 'CART_010':
        return 'Cart not found';
      case 'CART_011':
        return 'Cart expired';
      case 'CART_012':
        return 'Payment Method not found';
      case 'CART_013':
        return 'Cart already checked out';

      default:
        return error;
    }
  }

  function getErrorMessage(errorBody) {
    const { data = {} } = errorBody?.response || {};
    const { error = '' } = data || {};

    if (!error) {
      if (typeof errorBody === 'string') {
        return { title: errorBody };
      }
      return { title: t('error') };
    }

    const arrayString = error.replace(/\s/g, '').split(',') || [];

    if (arrayString.length > 1) {
      return {
        title: arrayString
          .map((e, i) => {
            const [code = ''] = e.split('-');
            return `${i + 1}. ${getCodeString(code)}`;
          })
          .toString()
          .replace(/\,/g, '\n'),
      };
    }

    const [errorString = ''] = arrayString || [];
    const [code] = errorString.replace('UnexpectedError:', '').split('-');
    const [title = '', text = ''] = getCodeString(code, error).split('\n') || [];

    return { title, text };
  }

  async function toCart(items, token) {
    try {
      if (!token) {
        throw 'No token';
      }

      const response = await axios({
        method: 'post',
        headers: { token },
        url: `${cartUrl}/toCart`,
        data: {
          cart: items,
        },
      });
      return response.data.data;
    } catch (error) {
      const { title = '', text = '' } = getErrorMessage(error);

      Swal.fire({
        focusConfirm: false,
        title,
        text,
        position: 'center',
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('sevenPayment.close'),
      });
      return false;
    }
  }

  async function toCheckout(data, token) {
    try {
      if (!token) {
        throw 'No token';
      }

      const response = await axios({
        method: 'post',
        headers: { token },
        url: `${cartUrl}/toCheckout`,
        data,
      });

      const {
        data: {
          data: { id },
        },
      } = response;

      setBuyNewOrderId(id);
      localStorage.setItem('buyNewOrderId', id);
      localStorage.setItem('paymentMethod', data.PaymentMethodId);

      return id;
    } catch (error) {
      const { title = '', text = '' } = getErrorMessage(error);

      Swal.fire({
        focusConfirm: false,
        title,
        text,
        position: 'center',
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('sevenPayment.close'),
      });
      return false;
    }
  }

  async function getCarts(token) {
    try {
      if (!token) {
        return;
      }

      const response = await axios({
        method: 'get',
        headers: { token },
        url: cartUrl,
      });
      setCarts(response.data.data);
    } catch (error) {
      setCarts([]);
    }
  }

  async function toCartVoid(cartId, token) {
    try {
      await axios({
        method: 'post',
        headers: { token },
        url: `${cartUrl}/toCartVoid`,
        data: { cartId },
      });
    } catch (error) {
      const { title = '', text = '' } = getErrorMessage(error);

      Swal.fire({
        focusConfirm: false,
        title,
        text,
        position: 'center',
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('sevenPayment.close'),
      });
    }
  }

  return {
    getCarts,
    carts,
    toCart,
    toCheckout,
    getErrorMessage,
    buyNewOrderId,
    clearBuyNewCart,
    toCartVoid,
  };
}

export {
  useCart,
  CartContext,
  usePpsPaymentConfig,
  useFpsPayment,
  usePickupLocations,
  useServicePeriodDisplay,
  useAssociations,
  useBuyNewCart,
};
