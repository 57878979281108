import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  background: #dedede;
  height: 100%;
  flex: 1;
`;

const TitleRow = styled.div`
  padding: 5px 0;

  @media (max-width: 850px) {
    padding: 11px 0 5px 0;
  }
`;

const TitleInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const TitleDiv = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

const TitleBlock = styled.div`
  flex-grow: 1;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: -3px;
`;

const Title = styled.span`
  color: #444;
  font-size: 2rem;
  font-weight: 400;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 850px) {
    line-height: 1.4;
  }
`;

const FormWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const FormInner = styled.div`
  font-size: 0.875rem;
  display: block;

  @media (max-width: 850px) {
    margin-top: -15px;
  }
`;

const FormDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

const FormBlock = styled.div``;

const TextRow = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 850px) {
    margin-top: 19px;
  }
`;

const TextPadding = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const Text = styled.span`
  color: #444;
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.5;
`;

const ButtonRow = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 10px 15px;
  margin-top: 25px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const ButtonDiv = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
`;

const Submit = styled.a`
  width: 100%;
  line-height: 1.375rem;
  color: #fff;
  background-color: #fd980f;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;

  @media (max-width: 850px) {
    line-height: 1.1rem;
    font-size: 0.9rem;
  }

  &:hover {
    color: #fff;
    background-color: #6c6c6c;
    text-decoration-line: none;
  }
`;

const ActiveAlready = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <TitleRow>
        <TitleInner>
          <TitleDiv>
            <TitleBlock>
              <Title>{t('Myprofile.ActiveSuccessTitle')}</Title>
            </TitleBlock>
          </TitleDiv>
        </TitleInner>
      </TitleRow>
      <FormWidth>
        <FormInner>
          <FormBlock>
            <FormDiv>
              <TextRow>
                <TextPadding>
                  <Text>{t('Myprofile.ActiveSuccessText')}</Text>
                </TextPadding>
              </TextRow>
              <TextRow>
                <ButtonRow>
                  <ButtonDiv>
                    <Submit href={`/${i18n.language}`}>{t('CsList.SentOutButton')}</Submit>
                  </ButtonDiv>
                </ButtonRow>
              </TextRow>
            </FormDiv>
          </FormBlock>
        </FormInner>
      </FormWidth>
    </Container>
  );
};

export default withRouter(ActiveAlready);
