import styled from 'styled-components';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../helpers/generalHelper';
import * as icon from '../../../../media/icons';
import {
  ItemContainer,
  ItemNumberLabel,
  ItemNumber,
  CarParkName,
  CarParkAddress,
  Label,
  Detail,
  DollarSign,
  Fee,
  StatusDetail,
  DetailInnerDiv,
  ChangeCarButton,
} from '../../../Cart/CartItem';
import StyledLink from '../../../../components/General/StyledLink';
import Alarm from '../../../../assets/images/icons8-time-96.png';
import Process from '../../../../assets/images/icons8-process-30.png';

const BookingItemView = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 15px;
  padding-top: 10px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const ItemBlock = styled.div`
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const ItemTabel = styled.table`
  width: 50%;
  margin-right: 20px;
  @media (max-width: 850px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const DetailContainer = styled.div`
  flex-basis: 70%;
  border-right: 1px solid #dedede;

  @media (max-width: 850px) {
    border-right: none;
  }
`;

const DetailInner = styled.div`
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  padding-top: 20px;

  @media (max-width: 850px) {
    margin-right: 0px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dedede;
    margin-top: 20px;
  }

  & > button {
    width: 150px;
    max-width: 150px;
    max-height: 32px;

    @media (max-width: 850px) {
      width: 90%;
      max-width: 90%;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
  }

  button {
    margin-top: 10px;
    width: 150px;
    max-width: 150px;
    min-height: 32px;

    @media (max-width: 850px) {
      &:not(:first-child) {
        margin-left: 20px;
      }
      width: 100%;
      max-width: 100%;
      margin: 10px 0px 0px 0px;
    }
  }
`;

export default function WaitingListItem({
  index,
  record = {},
  pageNumber,
  cancelWaitingListStatus,
  changeLPN,
}) {
  const {
    status,
    CarPark = {},
    reference,
    createdAt,
    ParkingPlan = {},
    Car = {},
    id,
    ParkingPlanId,
    customerLpnChangeCount,
    CarParkId,
  } = record || {};

  const { t, i18n } = useTranslation();

  const locale = i18n.language === 'en' ? 'en' : 'zh-hk';
  const dateFormat = i18n.language === 'en' ? 'DD MMM YYYY' : 'YYYY年 MMM DD';

  const statusIcon = useMemo(() => {
    if (status === 'confirmed') {
      return Alarm;
    }
    if (status === 'processed') {
      return Process;
    }

    if (status === 'expired' || status === 'cancelled') {
      return icon.cross;
    }

    if (status === 'paid') {
      return icon.check;
    }
    return '';
  }, [status]);

  const displayStatus = useMemo(() => {
    if (status === 'confirmed') {
      return t('MyAccount.WaitingList.confirmed');
    }

    return t(`MyAccount.iMonthly.status.${status}`);
  }, [status, i18n.language]);

  const serviceName = useMemo(
    () => (i18n.language === 'en' ? ParkingPlan?.subTitle : ParkingPlan?.chineseSubTitle),
    [ParkingPlan, i18n.language]
  );

  return (
    <ItemContainer background={index % 2 === 0 ? '#fff' : '#fef1e5'}>
      <ItemNumberLabel>
        <ItemNumber>{index + 1 + (pageNumber * 20 - 20)}</ItemNumber>
      </ItemNumberLabel>

      <BookingItemView>
        <DetailContainer>
          <CarParkName>{i18n.language === 'en' ? CarPark?.name : CarPark?.chineseName}</CarParkName>
          <div style={{ margin: '4px 0 20px 0', display: 'flex' }}>
            <img
              src="https://www.wilsonparking.com.hk/img/icon-pin-info.png"
              width="14"
              height="17"
              alt="location"
            />
            <CarParkAddress>
              {i18n.language === 'en' ? CarPark?.address : CarPark?.chineseAddress}
            </CarParkAddress>
          </div>

          <ItemBlock className="d-flex-polyfill">
            <ItemTabel cellPadding="5">
              <tbody>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.ServiceStatus')}</Label>
                  <Detail>
                    <StatusDetail>
                      <DetailInnerDiv>
                        <img
                          style={{ marginRight: 10, width: 15, height: 15, border: 'none' }}
                          src={statusIcon}
                          alt=""
                        />
                        {status ? capitalize(displayStatus) : '-'}
                      </DetailInnerDiv>
                    </StatusDetail>
                  </Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.Service')}</Label>
                  <Detail width="65%">{serviceName}</Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.WaitingList.createdAt')}</Label>
                  <Detail width="65%">{moment(createdAt).locale(locale).format(dateFormat)}</Detail>
                </tr>
              </tbody>
            </ItemTabel>

            <ItemTabel cellPadding="5">
              <tbody>
                <tr>
                  <Label width="35%">{t('MyAccount.WaitingList.refNo')}</Label>
                  <Detail width="65%">{reference}</Detail>
                </tr>
                <tr>
                  <Label width="35%">{t('MyAccount.iMonthly.AssignedCar')}</Label>
                  <Detail width="65%">{Car?.registrationMark}</Detail>
                  {customerLpnChangeCount < 1 && (
                    <ChangeCarButton
                      onClick={() =>
                        changeLPN(ParkingPlanId, Car?.CarTypeId, id, status, CarParkId)
                      }
                    >
                      {`(${t('MyAccount.iMonthly.ChangeCar')})`}
                    </ChangeCarButton>
                  )}
                </tr>
              </tbody>
            </ItemTabel>
          </ItemBlock>
        </DetailContainer>

        <DetailInner>
          {status === 'confirmed' && (
            <ButtonRow>
              <StyledLink
                type="button"
                color="yellow"
                onClick={() => cancelWaitingListStatus(id)}
                label={t('MyAccount.WaitingList.cancelWaiting')}
              />
            </ButtonRow>
          )}
        </DetailInner>
      </BookingItemView>
    </ItemContainer>
  );
}
