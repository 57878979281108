import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import {
  Container,
  MaxWidth,
  Record,
  RecordRow,
  LogoCol,
  ButtonRow,
  RecordCol,
} from './SkiDataMonthlyParkerRecord.styles';
import Logo from '../../media/icons/logo.png';
import StyledButton from '../../components/General/StyledButton';

function SkiDataMonthlyParkerRecord() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state } = useLocation();

  const [savedState, setSavedState] = useState({});

  const { skiDataMonthlyParkerRecords = [], ...rest } = savedState || {};

  // useEffect(() => {
  //   if (!state) {
  //     history.push(`/:${i18n.language}`);
  //   }
  // }, [state]);

  useEffect(() => {
    setSavedState(state);
  }, []);

  return (
    <Container>
      <MaxWidth>
        <p style={{ fontSize: 28 }}>{t('SkiDataMonthlyParkerRecord.import')}</p>

        <p style={{ fontSize: 16, color: '#666' }}>{t('SkiDataMonthlyParkerRecord.found')}</p>

        {skiDataMonthlyParkerRecords.map((record) => {
          return (
            <Record key={record?.CarPark?.id}>
              {/* <LogoCol>
                <img src={Logo} alt="" />
                <p style={{ textAlign: 'center', marginTop: 5, color: '#666' }}>
                  {t('SkiDataMonthlyParkerRecord.wp')}
                </p>
              </LogoCol> */}
              <RecordCol>
                <RecordRow>
                  <p style={{ color: '#666', whiteSpace: 'nowrap' }}>
                    {t('SkiDataMonthlyParkerRecord.carPark')}
                  </p>
                  <p style={{ color: '#666' }}>
                    {i18n.language === 'en' ? record?.CarPark?.name : record?.CarPark?.chineseName}
                    {`(${record?.CarPark?.id})`}
                  </p>
                </RecordRow>
                <RecordRow>
                  <p style={{ color: '#666' }}>
                    {t('SkiDataMonthlyParkerRecord.registrationMark')}
                  </p>
                  <p style={{ color: '#666' }}>{record?.registrationMark}</p>
                </RecordRow>
                <RecordRow>
                  <p>{t('SkiDataMonthlyParkerRecord.ppcard')}</p>
                  <p>{record?.CardNumber}</p>
                </RecordRow>
              </RecordCol>
            </Record>
          );
        })}

        <ButtonRow>
          <StyledButton
            onClick={() => {
              history.push({
                pathname: `/${i18n.language}/customers/login`,
              });
            }}
            color="grey"
          >
            {t('SkiDataMonthlyParkerRecord.back')}
          </StyledButton>
          <div style={{ flex: 1 }} />
          <StyledButton
            onClick={() => {
              if (!savedState) {
                return;
              }
              history.push({
                pathname: `/${i18n.language}/customers/registration`,
                state: rest,
              });
            }}
            color="yellow"
          >
            {t('SkiDataMonthlyParkerRecord.confirm')}
          </StyledButton>
        </ButtonRow>

        {/* <p style={{ marginTop: 20, color: '#666' }}>{t('SkiDataMonthlyParkerRecord.remind')}</p> */}
      </MaxWidth>
    </Container>
  );
}

export default withRouter(SkiDataMonthlyParkerRecord);
