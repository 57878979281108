import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import iconpass from '../../media/images/iconpass.png';
import iconcross from '../../media/images/icon-cross.png';
import { useSMSVerify, useEmailVerify } from '../../models/Customer';

const Container = styled.div`
  background: #dedede;
  padding-left: 20px;
  padding-right: 20px;
  height: auto;
  flex: 1;

  @media (max-width: 980px) {
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 850px) {
    padding-bottom: 30px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleRow = styled.div`
  width: 1000px;
  margin-bottom: 21px;
  margin-top: 21px;

  @media (max-width: 980px) {
    margin-bottom: 21px;
    margin-top: 22px;
    margin-left: -3px;
  }

  @media (max-width: 850px) {
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 0;
  }
`;

const Title = styled.div`
  color: #444;
  font-size: 2rem;
`;

const Background = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
  padding-bottom: 30px;

  @media (max-width: 750px) {
    padding-bottom: 0;
  }
`;

const FormBoxPadding = styled.div`
  @media (max-width: 850px) {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const FormBoxWidth = styled.div`
  background: #fff;
  width: 994px;
  min-width: 320px;
  flex-direction: row;
  display: flex;
  padding: 30px 35px 30px 35px;
  @media (max-width: 850px) {
    flex-direction: column;
    padding: 15px 15px 20px 15px;
  }
`;

const LeftViewContainer = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 35px;

  @media (max-width: 850px) {
    max-width: 100%;
    -webkit-flex: 1;
    flex: 1;
    padding-right: 0;
  }

  &:after {
    position: absolute;
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 40px;
    height: 40px;
    border: 5px solid #fff;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    content: '${(props) => props.or}';
    background-color: #bbb;
    font-weight: bold;
    vertical-align: middle;
    border-radius: 50%;
    left: 50.1%;
    margin-top: -34px;
    transform: translateX(-50%) translateY(-50%);
    @media (max-width: 850px) {
      margin-top: 45px;
      left: 50%;
    }
  }
`;

const SubTitle = styled.span`
  color: #fd980f;
  font-size: 18px;
  font-weight: bold;
`;

const LeftViewText = styled.span`
  font-size: 14px;
  margin-top: 15px;
  display: flex;
  color: #666;
`;

const LeftViewInputRow = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const LeftViewItemDiv = styled.div`
  display: flex;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
`;

const LeftViewItem = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};
`;

const LeftViewInputDiv = styled.div`
  display: flex;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding-left: 10px;
  padding-top: 3px;
  flex-direction: column;

  @media (max-width: 850px) {
    padding-left: 0;
    max-width: 100%;
    padding-top: 5px;
  }
`;

const LeftViewInput = styled.input`
  -webkit-flex: 1;
  flex: 1;
  padding: 6px;
  color: #666;
  height: 32px;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
`;

const LeftViewSpace = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LeftViewButtonDiv = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 20px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
    padding-left: 0;
  }
`;

const LeftViewButtonRow = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  justify-content: flex-end;
  margin-top: 15px;

  @media (max-width: 850px) {
    margin-top: 16px;
  }
`;

const SubmitButton = styled.button`
  border: 0;
  color: #fff;
  font-size: 0.75rem;
  height: 32px;
  padding: 7px;
  background: #fd980f;
  width: 100%;
  &:hover {
    background: #888;
    padding: 6px;
  }

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const RightViewContainer = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  border-left: 1px solid #dedede;
  padding-bottom: 10px;

  @media (max-width: 850px) {
    max-width: 100%;
    -webkit-flex: 1;
    flex: 1;
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
  }

  @media (max-width: 850px) {
    border-top: 1px solid #dedede;
    border-left: none;
    margin-top: 45px;
    padding-top: 45px;
    padding-left: 0;
  }
`;

const RightViewInner = styled.div`
  padding-left: 35px;

  @media (max-width: 850px) {
    padding-left: 0;
  }
`;

const RightViewText = styled.span`
  font-size: 14px;
  display: list-item;
  list-style-type: decimal;
  margin-top: ${(props) => (props.bottom ? '16px' : '15px')};
  margin-left: 19px;
  color: #666;
  font-weight: 500;
`;

const RightViewItemRow = styled.div`
  display: flex;
  margin-top: ${(props) => (props.bottom ? '20px' : '10px')};
  -webkit-flex: 1;
  flex: 1;
  flex-direction: row;

  @media (max-width: 850px) {
    flex-direction: column;
    margin-top: ${(props) => (props.bottom ? '20px' : '5px')};
  }
`;

const RightViewItemDiv = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
`;

const RightViewItem = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};

  @media (max-width: 850px) {
    margin-bottom: ${(props) => (props.bottom ? '5px' : '8px')};
  }
`;

const RightViewInputRow = styled.div`
  display: flex;
  -webkit-flex: 2;
  flex: 2;
`;

const RightViewInputDiv = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  flex-direction: column;
`;

const RightViewInput = styled.input`
  padding: 6px;
  color: #666;
  height: 32px;
  width: 100%;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
`;

const RightViewButtonDiv = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
`;

const RightViewButton = styled.button`
  border: 0;
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  padding: 6px;
  font-weight: 500;
  height: 32px;
  background: ${({ isPhoneVerified }) =>
    (isPhoneVerified === undefined && '#FD980F') ||
    (isPhoneVerified === true && '#83c150') ||
    (isPhoneVerified === false && '#CD0510')};

  &:disabled {
    background: #888;
  }

  &:hover {
    background: ${({ isPhoneVerified }) =>
      (isPhoneVerified === undefined && '#888') ||
      (isPhoneVerified === true && '#83c150') ||
      (isPhoneVerified === false && '#CD0510')};
    opacity: ${({ isPhoneVerified }) =>
      (isPhoneVerified === undefined && 1) ||
      (isPhoneVerified === true && 0.8) ||
      (isPhoneVerified === false && 0.8)};
  }
  background: ${({ isPhoneExist }) => {
    if (isPhoneExist === false) return '#CD0510';
    if (isPhoneExist === true) return '#83c150';
    return '#FD980F';
  }};
`;

const VerifyButton = styled.button`
  border: 0;
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  padding: 6px;
  font-weight: 500;
  height: 32px;
  background: ${({ isSMSVerified, disabled }) =>
    (isSMSVerified === true && '#83c150') ||
    (isSMSVerified === false && '#CD0510') ||
    (disabled === true && '#888') ||
    (disabled === false && '#6C6C6C')};
  &:hover {
    background: ${({ disabled }) =>
      (disabled === true && '#888') || (disabled === false && '#888')};
  }
`;

const SMSPassImg = styled.img`
  margin-right: ${({ isPhoneExist }) => (isPhoneExist === undefined ? '0px' : '10px')};
  display: ${({ isPhoneExist }) => (isPhoneExist === undefined ? 'none' : 'initial')};
`;

const CodePassImg = styled.img`
  margin-right: ${({ isSMSVerified }) => (isSMSVerified === undefined ? '0px' : '10px')};
  display: ${({ isSMSVerified }) => (isSMSVerified === undefined ? 'none' : 'initial')};
`;

const ErrorMsg = styled.span`
  color: #e56e5a;
  font-size: 13px;
`;

const countDownTime = 120;

const ForgotPassword = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { FGCheckPhoneExist, isSMSVerified, isPhoneExist, verifyMobile, usertoken } =
    useSMSVerify();
  const { handleSubmit, register, errors, getValues, setError } = useForm();
  const { emailVerify, error } = useEmailVerify();
  const [timeLeft, setTimeLeft] = useState(countDownTime);
  const [startTimer, setStartTimer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    errors: errors2,
    getValues: getValues2,
    watch: watch2,
  } = useForm();
  const history = useHistory();
  useEffect(() => {
    if (isSMSVerified)
      history.push({ pathname: `/${language}/customers/reset-password/${usertoken}` });
  }, [isSMSVerified]);

  const onSubmitEmail = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await emailVerify(getValues('email'));
    history.push(`/${language}/customers/message/info%3Asendout_forgot_password_success`);
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setError('email', { exist: t('Login.notExist') });
    }
  }, [error]);

  const onSubmitPhone = async () => {
    if (!errors.phone) {
      setIsLoading(true);
      try {
        await FGCheckPhoneExist(getValues2('phone'), language, setError);
        setTimeLeft(countDownTime);
        setStartTimer(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!startTimer) return;
    if (!timeLeft) {
      setStartTimer(false);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, startTimer]);

  const WatchResetPasswordCode = watch2('ResetPasswordCode');

  useEffect(() => {
    if (!WatchResetPasswordCode) return;
    if (WatchResetPasswordCode.length === 4 && isPhoneExist)
      verifyMobile(getValues2('phone'), getValues2('ResetPasswordCode'), true);
  }, [WatchResetPasswordCode, isPhoneExist]);

  return (
    <Container>
      <TitleContainer>
        <TitleRow>
          <Title>{t('ForgotPassword.Header')}</Title>
        </TitleRow>
      </TitleContainer>

      <FormBoxPadding>
        <Background>
          <FormBoxWidth>
            <LeftViewContainer or={t('ForgotPassword.Or')}>
              <form onSubmit={handleSubmit(onSubmitEmail)} ket={1}>
                <SubTitle>
                  {t('ForgotPassword.ResetByEmail')}
                  <br />
                </SubTitle>
                <LeftViewText>{t('ForgotPassword.Text')}</LeftViewText>
                <LeftViewInputRow>
                  <LeftViewItemDiv>
                    <LeftViewItem error={errors.email}>{t('ForgotPassword.Email')}</LeftViewItem>
                  </LeftViewItemDiv>
                  <LeftViewInputDiv>
                    <LeftViewInput
                      name="email"
                      ref={register({
                        required: 'Required',
                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      })}
                      error={errors.email}
                    />
                    {errors.email?.type && <ErrorMsg>{t('CsList.EmailError')}</ErrorMsg>}
                    {errors.email?.types?.exist && <ErrorMsg>{errors.email.types.exist}</ErrorMsg>}
                  </LeftViewInputDiv>
                </LeftViewInputRow>

                <LeftViewButtonRow>
                  <LeftViewSpace />
                  <LeftViewButtonDiv>
                    <SubmitButton disabled={isLoading} type="submit">
                      {t('ForgotPassword.Submit')}
                    </SubmitButton>
                  </LeftViewButtonDiv>
                </LeftViewButtonRow>
              </form>
            </LeftViewContainer>
            <RightViewContainer>
              <RightViewInner>
                <form onSubmit={handleSubmit2(onSubmitPhone)} ket={2}>
                  <SubTitle>
                    {t('ForgotPassword.ResetByMob')}
                    <br />
                  </SubTitle>
                  <RightViewText>{t('ForgotPassword.Step1')}</RightViewText>
                  <RightViewText bottom>{t('ForgotPassword.Step2')}</RightViewText>

                  <RightViewItemRow>
                    <RightViewItemDiv>
                      <RightViewItem error={errors2.phone}>
                        {t('ForgotPassword.MobileNo')}
                      </RightViewItem>
                    </RightViewItemDiv>

                    <RightViewInputRow>
                      <RightViewInputDiv>
                        <RightViewInput
                          name="phone"
                          ref={register2({
                            required: 'Required',
                            pattern: /^1[0-9]{10}$|^[456789][0-9]{7}$/,
                          })}
                          error={errors2.phone}
                        />
                        {errors2.phone && <ErrorMsg>{t('CsList.PhoneError')}</ErrorMsg>}
                      </RightViewInputDiv>
                      <RightViewButtonDiv>
                        <RightViewButton
                          type="submit"
                          isPhoneExist={isPhoneExist}
                          disabled={
                            (timeLeft < countDownTime && timeLeft !== 0) || startTimer || isLoading
                          }
                        >
                          <SMSPassImg
                            src={
                              (isPhoneExist === true && iconpass) ||
                              (isPhoneExist === false && iconcross)
                            }
                            isPhoneExist={isPhoneExist}
                          />
                          {timeLeft < countDownTime || startTimer
                            ? `${t('OTP.resend')} (${timeLeft})`
                            : `${t('OTP.send')} `}
                        </RightViewButton>
                      </RightViewButtonDiv>
                    </RightViewInputRow>
                  </RightViewItemRow>

                  <RightViewItemRow bottom>
                    <RightViewItemDiv>
                      <RightViewItem bottom>{t('ForgotPassword.ResetPasswordCode')}</RightViewItem>
                    </RightViewItemDiv>

                    <RightViewInputRow>
                      <RightViewInputDiv>
                        <RightViewInput name="ResetPasswordCode" ref={register2({})} />
                      </RightViewInputDiv>
                      <RightViewButtonDiv>
                        <VerifyButton
                          type="button"
                          onClick={() => {
                            if (isSMSVerified) return;
                            verifyMobile(
                              getValues2('phone'),
                              getValues2('ResetPasswordCode'),
                              true
                            );
                          }}
                          disabled={!isPhoneExist}
                          isSMSVerified={isSMSVerified}
                        >
                          <CodePassImg
                            src={
                              (isSMSVerified === true && iconpass) ||
                              (isSMSVerified === false && iconcross)
                            }
                            isSMSVerified={isSMSVerified}
                          />
                          {t('ForgotPassword.Verify')}
                        </VerifyButton>
                      </RightViewButtonDiv>
                    </RightViewInputRow>
                  </RightViewItemRow>
                </form>
              </RightViewInner>
            </RightViewContainer>
          </FormBoxWidth>
        </Background>
      </FormBoxPadding>
    </Container>
  );
};

export default withRouter(ForgotPassword);
