import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const MaxWidth = styled.div`
  max-width: 1024px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  background-color: #fff;

  justify-content: center;
`;

export const From = styled.form`
  padding: 20px;
`;

export const InputRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const InputCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const SendSMSRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 760px) {
    flex-direction: column;
  }

  button {
    margin: 0;
    min-height: 20px;
    margin-top: 20px;
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
`;

export const ErrorMsg = styled.p`
  color: #e56e5a;
  font-size: 13px;
  line-height: 1.1;
  margin-bottom: 0px;
`;

export const Input = styled.input`
  border: ${({ error }) => (error ? '1px solid #E56E5A' : ' 1px solid rgb(222, 222, 222)')};
`;
