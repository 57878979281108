import React, {
  useContext,
  useEffect,
  useState,
  // useContext, useEffect
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import visa from '../../media/images/visa.jpg';
import mastercard from '../../assets/images/mastercard.png';
import octopusCard from '../../assets/images/octopusCard.png';
import gradient from '../../media/images/gradient.png';
import { ServiceDetail, MonthlyServiceDetail } from './Components/ServiceDetail';
import unionPayImg from '../../media/images/CUP-logo300.png';
import applePayImg from '../../media/images/applePayImg.png';
import googlePayImg from '../../media/images/googlePayImg.png';
import aliPayImg from '../../media/images/aliPayImg.png';
import weChatPay from '../../media/images/weChatPay.png';
import { TokenContext } from '../../models/Customer';

const PaymentMethodsDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin-top: 5px;
    margin-right: 10px;
    width: auto;
    height: 30px;
  }
`;

const CarouselButton = styled.button`
  background-size: 100% 100%;
  background-image: url(${gradient});
  background-repeat: repeat-x;
  background-position: top center;
  outline: none;
  border: 1px solid #ccc;
`;

const CarTypeArrow = styled(CarouselButton)`
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: none;
  font-size: 1.5rem;
  color: #fd980f;
`;

const CarTypeButton = styled(CarouselButton)`
  border-bottom: ${({ isActive }) => (isActive ? '3px solid #fd980f' : 'none')};
  color: ${({ isActive }) => (isActive ? '#fd980f' : '#666')};
  font-size: 1.1rem;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 670px) {
    font-size: 12px;
  }
`;

function CarTypes({ active, setActive, list = [] }) {
  const sortArray = list.sort(function handelSort(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  const { length } = sortArray;
  const index = sortArray.findIndex(({ name }) => active === name);
  const setPrevious = () => setActive(sortArray[Math.max(index - 1, 0)].name);
  const setNext = () => setActive(sortArray[Math.min(index + 1, sortArray.length - 1)].name);

  const displayList =
    length < 4
      ? sortArray
      : sortArray.slice(
          index === 0 ? 0 : Math.min(index - 1, length - 3),
          index === length - 1 ? length : Math.max(index + 2, 3)
        );

  return (
    <div style={{ display: 'flex' }}>
      <CarTypeArrow type="button" onClick={setPrevious}>
        {'<'}
      </CarTypeArrow>
      {displayList.map(({ name }) => {
        const isActive = active === name;
        return (
          <CarTypeButton
            type="button"
            key={`ctl-${name}`}
            isActive={isActive}
            onClick={() => setActive(name)}
          >
            {name.toUpperCase()}
          </CarTypeButton>
        );
      })}
      <CarTypeArrow type="button" onClick={setNext}>
        {'>'}
      </CarTypeArrow>
    </div>
  );
}

function PaymentMethodList({ methods, displayPaymentMethods = [] }) {
  const { t } = useTranslation();
  const methodNames = methods.reduce((output, { name, isEnable }) => {
    if (!isEnable) return output;
    output.push(name);
    return output;
  }, []);

  return (
    <div className="d-flex-polyfill" style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontSize: '0.875rem', color: '#666' }}>
        {t('CarParkDetail.PaymentMethod')}
      </span>
      <PaymentMethodsDiv>
        {displayPaymentMethods.length === 0 ? (
          <img
            src={octopusCard}
            width="auto"
            style={{ marginRight: 20 }}
            height="24"
            alt="octopusCard"
          />
        ) : (
          displayPaymentMethods.map((e) => {
            switch (e) {
              case 'creditCard':
                return (
                  <>
                    <img src={visa} width="auto" alt="visa" />
                    <img src={mastercard} width="auto" alt="mastercard" />
                  </>
                );
              case 'octopus':
                return <img src={octopusCard} width="auto" alt="octopusCard" />;

              case 'cash':
                return <span style={{ marginRight: 20 }}>{t('cash')}</span>;

              case 'unionPay':
                return <img src={unionPayImg} width="auto" alt="unionPayImg" />;

              case 'weChat':
                return <img src={weChatPay} width="auto" alt="unionPayImg" />;

              case 'aliPay':
                return <img src={aliPayImg} width="auto" alt="unionPayImg" />;

              case 'applePay':
                return <img src={applePayImg} width="auto" alt="unionPayImg" />;

              case 'googlePay':
                return <img src={googlePayImg} width="auto" alt="unionPayImg" />;

              default:
                return <></>;
            }
          })
        )}
      </PaymentMethodsDiv>
    </div>
  );
}

const PlanContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const ServiceName = styled.h5`
  color: #fd980f;
  font-size: 1.1rem;
`;

function PlanList({
  plans = new Map(),
  paymentMethods,
  setActivePlan,
  activeCarType,
  displayPaymentMethods,
  waitingList,
  applyWaitingList,
}) {
  const { token } = useContext(TokenContext);
  const isLogin = !!token;
  const { t } = useTranslation();

  const sortedPlan = new Map(
    [...plans].sort((a, b) => {
      const aValue = a[1][0].ParkingServicePriority;
      const bValue = b[1][0].ParkingServicePriority;
      if (aValue < bValue) {
        return -1;
      }

      if (aValue > bValue) {
        return 1;
      }

      return 0;
    })
  );

  const services = [...sortedPlan.keys()];
  const monthlyServices = services.filter((service) =>
    /monthly|月租|Quarterly|季租/gi.test(service)
  );
  const nonMonthlyServices = services.filter(
    (service) => !/monthly|月租|Quarterly|季租/gi.test(service)
  );

  const nonMonthlysortArray = nonMonthlyServices.sort(function handelSort(a) {
    if (a === '時租 (每小時)' || a === 'Hourly (per hour)') {
      return -1;
    }
    if (a !== '時租 (每小時)' || a !== 'Hourly (per hour)') {
      return 1;
    }
    return 0;
  });

  return (
    <PlanContainer>
      <div style={{ flexBasis: '50%', background: 'white', padding: 15 }}>
        {nonMonthlysortArray.map((name) => (
          <div key={name} style={{ fontSize: '0.9rem' }}>
            <ServiceDetail
              key={`details${name}`}
              plans={plans.get(name)}
              service={name}
              activeCarType={activeCarType}
            />
          </div>
        ))}

        {nonMonthlysortArray.length > 0 && (
          <PaymentMethodList
            methods={paymentMethods}
            displayPaymentMethods={displayPaymentMethods}
          />
        )}
      </div>
      <div style={{ flexBasis: '50%', background: '#F0F0F0', padding: 15 }}>
        {monthlyServices.length > 0 && <ServiceName>{t('CarParkDetail.monthly')}</ServiceName>}
        {monthlyServices.map((name) => (
          <div key={name} style={{ fontSize: '0.9rem' }}>
            <MonthlyServiceDetail
              key={`details${name}`}
              plans={plans.get(name)}
              service={name}
              activate={setActivePlan}
              waitingList={waitingList}
              isLogin={isLogin}
              applyWaitingList={applyWaitingList}
            />
          </div>
        ))}
      </div>
    </PlanContainer>
  );
}

const ParkingPlans = ({
  carTypeList = [],
  plans,
  paymentMethod,
  setActivePlan,
  setShowModal,
  priorityArray,
  displayPaymentMethods,
  waitingList,
  applyWaitingList,
}) => {
  const [activeCarType, setActiveCarType] = useState(undefined);
  const [activePlanList, setActivePlanList] = useState(new Map());

  useEffect(() => {
    if (carTypeList.length === 0) return;
    setActiveCarType(carTypeList[0].name);
  }, [carTypeList]);

  useEffect(() => {
    if (!activeCarType) return;
    setActivePlanList(plans.get(activeCarType));
  }, [activeCarType, plans]);

  return (
    <div style={{ marginTop: 20 }}>
      <CarTypes active={activeCarType} setActive={setActiveCarType} list={carTypeList} />
      <PlanList
        plans={activePlanList}
        setActivePlan={(plan) => {
          setActivePlan(plan);
          setShowModal(true);
        }}
        paymentMethods={paymentMethod}
        activeCarType={activeCarType}
        priorityArray={priorityArray}
        displayPaymentMethods={displayPaymentMethods}
        waitingList={waitingList}
        applyWaitingList={applyWaitingList}
      />
    </div>
  );
};

export default ParkingPlans;
