import styled from 'styled-components';

// TODO: responsive?

const MobileNumberItem = styled.div`
  display: flex;
  -webkit-flex: 1;
flex: 1;

  @media (max-width: 850px) {
    -webkit-flex: 1;
flex: 1;
    max-width: 100%;
    margin-top: 20px;
  }
`;

export default MobileNumberItem;
