import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as icon from '../../media/icons';
import { getPaymentConfig } from '../../helpers/api/cart';
import { usePpsPaymentConfig, useFpsPayment, CartContext } from '../../models/Cart';
import Modal from '../../components/General/PopupPrivacyPolicy';

const WhiteContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #666;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;

  @media (max-width: 980px) {
    padding: 15px;
  }

  .embedded-checkout-modal {
    width: 80vw;
    height: 90vh;
    max-width: 850px;
  }
`;

function Step3({
  paymentMethodId,
  orderId,
  onlinePaymentIds,
  redirectToStep4,
  action,
  offlinePaymentIds,
  spareOrderId,
}) {
  const { visaId, ppsId, fpsId } = onlinePaymentIds;
  const { chequeId, sevenElevenId } = offlinePaymentIds;

  return (
    <MainContainer>
      {paymentMethodId === visaId && (
        <>
          <MasterCardPayment
            orderId={orderId}
            spareOrderId={spareOrderId}
            redirectToStep4={redirectToStep4}
          />
          <Modal Popupshow="Custom">
            <div id="embedded-checkout-modal" />
          </Modal>
        </>
      )}
      {paymentMethodId === ppsId && (
        <PpsPayment orderId={orderId} redirectToStep4={redirectToStep4} />
      )}
      {paymentMethodId === fpsId && <FpsPayment orderId={orderId} action={action} />}
      {paymentMethodId === chequeId && <ChequePayment redirectToStep4={redirectToStep4} />}
      {paymentMethodId === sevenElevenId && (
        <SevenElevenPayment redirectToStep4={redirectToStep4} />
      )}
    </MainContainer>
  );
}

export default Step3;

const MasterCardPayment = ({ orderId, spareOrderId, redirectToStep4 }) => {
  const { t, i18n } = useTranslation();
  const scriptLoaded = (config) => {
    // config.interaction.locale = i18n.language === 'en' ? 'en_US' : 'zh_HK';
    window.Checkout.configure(config);
    window.Checkout.showEmbeddedPage('#embedded-checkout-modal');
    // window.Checkout.showPaymentPage();
  };

  useEffect(() => {
    if (spareOrderId) {
      localStorage.setItem('spareOrderId', spareOrderId);
    }
  }, [spareOrderId]);

  function handelNewTokenWithCheckoutJS(response) {
    const script = document.createElement('script');
    script.setAttribute('data-error', `${process.env.REACT_APP_API_DOMAIN}${response.error}`);
    script.setAttribute(
      'data-complete',
      'completeCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.complete}`
    );
    script.setAttribute(
      'data-cancel',
      'cancelCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.cancel}`
    );
    script.setAttribute(
      'data-timeout',
      'timeoutCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`
    );
    script.setAttribute(
      'data-error',
      'errorCallback'
      // `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`
    );
    script.src =
      process.env.REACT_APP_CHECKOUT_JS_SRC ||
      'https://fdhk.gateway.mastercard.com/static/checkout/checkout.min.js';
    script.async = true;
    document.body.appendChild(script);
    window.completeCallback = (results) => {
      const { resultIndicator, sessionVersion } = results || {};
      console.log({ resultIndicator, sessionVersion });
      let completeURL = new URL(`${process.env.REACT_APP_API_DOMAIN}${response.complete}`);
      completeURL.searchParams.set('resultIndicator', resultIndicator);
      completeURL.searchParams.set('sessionVersion', sessionVersion);
      completeURL = completeURL.toString();
      window.location.href = completeURL;
    };
    window.cancelCallback = (resultIndicator, sessionVersion) => {
      console.log({ resultIndicator, sessionVersion });
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${response.cancel}`;
    };
    window.timeoutCallback = (resultIndicator, sessionVersion) => {
      console.log({ resultIndicator, sessionVersion });
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${response.timeout}`;
    };
    window.errorCallback = (error) => {
      console.log(error);
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${response.error}`;
    };
    script.onload = () => scriptLoaded(response.config);
  }

  useEffect(() => {
    if (orderId !== null) {
      const locale = i18n.language === 'en' ? 'en_US' : 'zh_HK';
      getPaymentConfig(orderId, locale)
        .then((response) => {
          if (response.next === 'checkout') {
            handelNewTokenWithCheckoutJS(response);
            return;
          }

          if (response.next === 'success') {
            redirectToStep4();
          }
        })
        .catch((error) => {
          Swal.fire({
            focusConfirm: false,
            titleText: 'Invaild credit card,Please try again',
            position: 'center',
            confirmButtonColor: '#fd980f',
            confirmButtonText: t('sevenPayment.close'),
          });
          // window.location = '/';
          console.error(error);
        });
    }
  }, [orderId]);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const PpsPayment = ({ orderId, redirectToStep4 }) => {
  const { form } = usePpsPaymentConfig(orderId);
  const { t } = useTranslation();

  const openNewWindow = () => {
    const newWindow = window.open('about:blank', '_self');

    newWindow.document.write(form);
    newWindow.onbeforeunload = (e) => {
      e.preventDefault();
      window.location = '/';
    };
  };

  useEffect(() => {
    if (form) {
      openNewWindow();
    }
  }, [form]);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const FpsPaymentButtons = styled(Link)`
  background-color: #fd980f;
  color: #fff;
  border: none;
  font-size: 0.75rem;
  margin: 12px 0;
  padding: 10px 20px;
  outline: none;
  transition: all 0.1s ease-out;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 580px) {
    width: 70%;
  }
`;

const FpsPayment = ({ orderId, action }) => {
  const { amount, qrCode, bookingDetail } = useFpsPayment(orderId);
  const { t } = useTranslation();
  const { generalCart, singleCart } = useContext(CartContext);

  const { clearCart } = action ? singleCart : generalCart;

  const { validTo, Car: { registrationMark = '' } = {} } = bookingDetail || {};

  useEffect(() => {
    clearCart();
  }, []);

  return (
    <WhiteContainer>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p style={{ color: '#888' }}>{t('Cart.PaymentMethod.FPS')}</p>

        <img
          style={{ marginBottom: 0, width: 180, height: 180 }}
          src={qrCode}
          width="15%"
          alt="qr_code"
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            // background: '#dedede',
            padding: '0px 0px',
          }}
        >
          <p style={{ fontSize: '30px', margin: 0, lineHeight: 1.3 }}>
            HK$
            {amount}
          </p>
          <br />
          <p style={{ fontSize: '28px', margin: 0, lineHeight: 1.3 }}>{registrationMark}</p>
          <p style={{ fontSize: '20px', margin: 0, lineHeight: 1.3 }}>
            {t('Cart.FPS.onlyPeriod', {
              period: validTo ? moment(validTo).format('YYYY - MM') : '-',
            })}
          </p>
        </div>

        <p style={{ fontSize: '0.9rem', color: '#888', marginTop: 20, marginBottom: 0 }}>
          {t('Cart.FPS.forMobile')}
        </p>

        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <ol style={{ fontSize: '0.9rem', padding: '0 20px' }}>
            <li>{t('Cart.FPS.text1')}</li>
            <li>{t('Cart.FPS.text2')}</li>
            <li>
              {t('Cart.FPS.text3')}
              <br />
              {t('Cart.FPS.text4')}
            </li>
          </ol>
        </div>

        <FpsPaymentButtons
          onClick={() => {
            window.location = '/customers/account';
          }}
        >
          {t('Cart.FPS.complete')}
        </FpsPaymentButtons>

        <FpsPaymentButtons to="/" target="_top">
          {t('Cart.FPS.back')}
        </FpsPaymentButtons>
      </div>
    </WhiteContainer>
  );
};

const Label = styled.p`
  margin-bottom: 10px;
  font-size: 0.85rem;
  color: #666;
`;

const ChequePayment = ({ redirectToStep4 }) => {
  const { t } = useTranslation();

  setTimeout(() => {
    redirectToStep4();
  }, 3000);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};

const SevenElevenPayment = ({ redirectToStep4 }) => {
  const { t } = useTranslation();

  setTimeout(() => {
    redirectToStep4();
  }, 3000);

  return (
    <div style={{ textAlign: 'left', width: '100%' }}>
      <div className="d-flex-polyfill" style={{ display: 'flex' }}>
        <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
        <p style={{ marginLeft: 10 }}>
          <b>{t('Cart.PPS.read')}</b>
        </p>
      </div>

      <Label>
        1.
        {'  '}
        {t('Cart.PPS.text1')}
      </Label>
      <Label>
        2.
        {'  '}
        {t('Cart.PPS.text2')}
      </Label>
      <Label>
        3.
        {'  '}
        {t('Cart.PPS.text3')}
      </Label>
    </div>
  );
};
