/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import iconpass from '../../media/images/iconpass.png';
import iconcross from '../../media/images/icon-cross.png';

const StyledButton = styled.button`
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  margin-top: 3px;
  transition: none;
  height: 32px;
  white-space: nowrap;
  border: 0;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ isPhoneExist }) => isPhoneExist === undefined && '#888'};
    color: #fff;
    opacity: ${({ isPhoneExist }) => (isPhoneExist === undefined ? 1 : 0.8)};
  }
  background: ${({ isPhoneExist }) => {
    if (isPhoneExist === true) return '#CD0510';
    if (isPhoneExist === false) return '#83c150';
    return '#FD980F';
  }};
`;

const SMSPassImg = styled.img`
  margin-right: ${({ isPhoneExist }) => (isPhoneExist === undefined ? '0px' : '10px')};
  display: ${({ isPhoneExist }) => (isPhoneExist === undefined ? 'none' : 'initial')};
`;

function PhoneButton({
  Title,
  bottom,
  submit,
  language,
  isPhoneExist,
  CheckPhoneExistAPI,
  userphone,
  SMSVerified,
}) {
  const [canSend, setCanSend] = useState(true);
  const { t } = useTranslation();
  const { control, errors, getValues } = useFormContext();

  function callPhoneExist() {
    if (SMSVerified === true) return;
    if (getValues('phone') === userphone) return;
    if (!getValues('phone')) return;
    if (!canSend) alert(t('Registration.TryLater'));
    if (canSend && !errors.phone) {
      CheckPhoneExistAPI(getValues('phone'), language);
    }
  }

  useEffect(() => {
    if (!getValues('phone')) return;
    if (!isPhoneExist) {
      setCanSend(false);
      setTimeout(() => {
        setCanSend(true);
      }, 30000);
    }
  }, [isPhoneExist]);

  return (
    <StyledButton
      type={submit ? 'submit' : 'button'}
      value={Title}
      bottom={bottom}
      control={control}
      isPhoneExist={isPhoneExist}
      onClick={callPhoneExist}
    >
      <SMSPassImg src={!isPhoneExist ? iconpass : iconcross} isPhoneExist={isPhoneExist} />
      {isPhoneExist === false ? t('ForgotPassword.ReSend') : t('ForgotPassword.ReceiveSMS')}
    </StyledButton>
  );
}

export default PhoneButton;
