import styled from 'styled-components';

const Submit = styled.button`
  background: #fd980f;
  height: 32px;
  color: #fff;
  font-size: 13px;
  border: none;
  width: 30%;

  &:hover {
    background: #888;
  }
`;

export default Submit;
