import React, { useState, useEffect, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddThis from '../../components/General/AddThis';
import commonStyle from '../../styles/common';
import findCarParkStyle from '../../styles/findcarpark';
import TabContainer from '../../components/General/TabContainer';
import { Info, Photo, CarParkMapView, NearBy, ShareLinks, Tutorial } from './Components';
import ScreenWidthListener from '../../components/General/ScreenWidthListener';
import BookingModal from './Components/BookingModal';
import ParkingPlans from './ParkingPlans';
import { useRegionalCarParks, useCarPark, CarParkContext } from '../../models/CarPark';
import freeparking from '../../media/icons/icon-freeparking.png';
import useWaitingList from '../../models/WaitingList';
import WaitingListModal from './Components/WaitingListModal';

function CarPark() {
  const { t, i18n } = useTranslation();
  const { outerWidth } = ScreenWidthListener();
  const { carParkId } = useParams();
  const hook = useCarPark(carParkId);
  const { waitingList, createWaitingList, cancelWaitingListStatus } = useWaitingList('noLimited');
  const { carPark, paymentMethods, services, carTypes: carTypeList, parkingPlansMap } = hook;
  const {
    name: carParkName,
    address: carParkAddress,
    phone: carParkPhone,
    remark: carParkRemark,
    coordinates,
    LocationId,
    image: carParkImage,
    freeParking,
    chineseFreeParking,
    isIMonthly,
    displayPaymentMethods,
  } = carPark;
  const { title: discountTitle, link: discountLink } =
    i18n.language === 'en' ? freeParking || {} : chineseFreeParking || {};
  const [activePlan, setActivePlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showWaitingListModal, setShowWaitingModal] = useState({ show: false, data: {} });

  const { carParks: districtCarPark, setLocationId } = useRegionalCarParks();

  const ref = useRef();

  useEffect(() => {
    setLocationId(LocationId);
  }, [LocationId]);

  const [displayServiceTypes, setDisplayServiceTypes] = useState(false);
  const [activeTab, setActiveTab] = useState('photo');

  useEffect(() => {
    setDisplayServiceTypes(services.some(({ image }) => image !== null && image.length > 0));
  }, [services]);

  const ShowIcon = services.some(({ isShowIcon }) => isShowIcon === true);

  const tabs = [
    {
      title: t('CarParkDetail.PHOTO'),
      key: 'photo',
      component: Photo(carParkImage),
    },
    {
      title: t('CarParkDetail.MAP'),
      key: 'map',
      component: (
        <CarParkMapView coordinates={coordinates} isIMonthly={isIMonthly} activeTab={activeTab} />
      ),
    },
    {
      title: t('CarParkDetail.STREET VIEW'),
      key: 'street_view',
      component: <CarParkMapView coordinates={coordinates} activeTab={activeTab} />,
    },
  ];

  const toggleMenu = () => {
    setShowContent(!showContent);
  };

  function applyWaitingList(ParkingPlanId, CarTypeId) {
    setShowWaitingModal({
      show: true,
      data: {
        ParkingPlanId,
        CarTypeId,
        CarParkId: carParkId,
      },
    });
  }

  const containerStyle = outerWidth <= 850 && { padding: 0 };
  const contentStyle =
    outerWidth > 850
      ? {
          display: 'flex',
          width: 1024,
          justifyContent: 'space-between',
        }
      : { width: '100vw' };
  const hideNearBySection = outerWidth <= 850;

  return (
    <div style={{ flex: 1 }}>
      <div style={{ ...commonStyle.pageContainer, ...containerStyle }}>
        <CarParkContext.Provider value={hook}>
          <div style={contentStyle}>
            <NearBy
              isHidden={hideNearBySection}
              carParks={districtCarPark}
              carParkName={carParkName}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexBasis: '68%',
                width: '100%',
                minWidth: '68%',
              }}
            >
              <Info
                name={carParkName}
                address={carParkAddress}
                phone={carParkPhone}
                remark={carParkRemark}
              />

              {ShowIcon && (
                <div
                  className="content"
                  style={{
                    visibility: displayServiceTypes ? 'visible' : 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    maxHeight: showContent ? ref?.current?.offsetHeight : 0,
                    transition: 'all 800ms ease 0s',
                  }}
                >
                  <div style={findCarParkStyle.remarksContainer} ref={ref}>
                    {services.length > 0 &&
                      services.map(({ id, type, image, isShowIcon }) => {
                        const serviceType = type === 'Monthly' ? 'iMonthly' : type;

                        return (
                          isShowIcon && (
                            <div
                              key={id}
                              style={{
                                marginRight: 15,
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {image && (
                                <img
                                  src={`${process.env.REACT_APP_CP_MEDIA_DOMAIN}${image}`}
                                  alt={type}
                                  width="35px"
                                  height="35px"
                                />
                              )}
                              <div
                                dangerouslySetInnerHTML={{ __html: serviceType }}
                                style={{
                                  marginLeft: 3,
                                  alignSelf: 'center',
                                  fontSize: '0.75rem',
                                  lineHeight: 1.2,
                                }}
                              />
                            </div>
                          )
                        );
                      })}
                  </div>
                </div>
              )}

              {ShowIcon && (
                <button
                  type="button"
                  className="refine-search"
                  onClick={toggleMenu}
                  style={{
                    visibility: displayServiceTypes ? 'visible' : 'hidden',
                    ...findCarParkStyle.remarkDrawerButton,
                    backgroundImage: `url("${process.env.REACT_APP_MEDIA_DOMAIN}/img/carpark-detail/icon-toggleOpen.png")`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '10px 12px',
                    width: '26px',
                    height: '26px',
                  }}
                  aria-label="expend"
                />
              )}

              {discountTitle && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: outerWidth > 850 ? 0 : 10,
                  }}
                >
                  <img src={freeparking} alt="" />
                  <span
                    style={{
                      color: '#000',
                      fontSize: '0.875rem',
                      paddingLeft: 10,
                      paddingRight: 5,
                    }}
                  >
                    {t('CarParkDetail.freeCarParking')}
                  </span>
                  <a
                    style={{ color: '#000', textDecoration: 'underline', opacity: '0.8' }}
                    target="_blank"
                    href={discountLink}
                    rel="noreferrer"
                  >
                    {discountTitle}
                  </a>
                </div>
              )}

              <div
                style={{
                  ...findCarParkStyle.remarkDrawer,
                }}
              >
                <div className="content" style={{ paddingTop: 15 }}>
                  <TabContainer
                    tabs={tabs}
                    activeKey={activeTab}
                    onActiveKeyChange={(key) => setActiveTab(key)}
                    buttonStyle={findCarParkStyle.tabMenuBar}
                    link={`/${i18n.language}/car_parks/detail/${carParkId}`}
                  />

                  <ParkingPlans
                    carTypeList={carTypeList}
                    plans={parkingPlansMap}
                    paymentMethod={paymentMethods}
                    setShowModal={setShowModal}
                    setActivePlan={setActivePlan}
                    displayPaymentMethods={displayPaymentMethods}
                    waitingList={waitingList}
                    applyWaitingList={(ParkingPlanId, CarTypeId) =>
                      applyWaitingList(ParkingPlanId, CarTypeId)
                    }
                  />
                </div>
              </div>
              <BookingModal
                show={showModal}
                setShow={setShowModal}
                plan={activePlan}
                carParkInfo={{ name: carParkName, address: carParkAddress }}
              />

              <WaitingListModal
                show={showWaitingListModal.show}
                setShow={setShowWaitingModal}
                data={showWaitingListModal.data}
                onConfirm={createWaitingList}
                waitingList={waitingList}
                title={t('MyAccount.WaitingList.selectVehicle')}
                buttonText={t('MyAccount.WaitingList.addWaiting')}
              />
            </div>
          </div>
        </CarParkContext.Provider>
      </div>
      <AddThis />
      <ShareLinks carParkId={carParkId} />
      <Tutorial />
    </div>
  );
}

export default withRouter(CarPark);
