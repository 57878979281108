/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import iconpass from '../../media/images/iconpass.png';
import iconcross from '../../media/images/icon-cross.png';

const StyledButton = styled.button`
  color: #fff;
  font-size: 12px;
  -webkit-flex: 1;
  flex: 1;
  transition: all 0.3s ease-in-out;
  background: ${({ disabled, gray, isPhoneVerified, isSMSVerified }) =>
    isPhoneVerified === false
      ? '#CD0510'
      : isPhoneVerified === true
      ? '#83c150'
      : isSMSVerified === false
      ? '#CD0510'
      : isSMSVerified === true
      ? '#83c150'
      : disabled
      ? '#AAA'
      : gray
      ? '#888'
      : '#FD980F'};
  border: 0;
  padding: ${(props) => (props.bottom ? '5px' : null)};
  width: ${(props) => (props.bottom ? '120px' : null)};
  height: ${(props) => (props.bottom ? '32px' : null)};
  //margin-top: 3px;
  height: 32px;
  white-space: nowrap;
  &:hover {
    background: ${(props) => (props.gray ? '#6C6C6C' : '#888')};
    color: #fff;
  }

  &:focus {
    background-color: #888;
  }

  @media (max-width: 850px) {
    height: ${(props) => (props.bottom ? '32px' : '32px')};
    margin-bottom: ${(props) => (props.gray ? '30px' : null)};
    margin-bottom: ${(props) => (props.bottom ? '0px' : null)};
  }
`;

const SMSPassImg = styled.img`
  margin-right: ${({ isPhoneVerified }) => (isPhoneVerified === undefined ? '0px' : '10px')};
  display: ${({ isPhoneVerified }) => (isPhoneVerified === undefined ? 'none' : 'initial')};
`;

const CodePassImg = styled.img`
  margin-right: ${({ isSMSVerified }) => (isSMSVerified === undefined ? '0px' : '10px')};
  display: ${({ isSMSVerified }) => (isSMSVerified === undefined ? 'none' : 'initial')};
`;

function Button({
  Title,
  gray,
  bottom,
  submit,
  onClick,
  disabled,
  isPhoneVerified,
  isSMSVerified,
  style,
}) {
  const { control } = useFormContext();
  return (
    <StyledButton
      type={submit ? 'submit' : 'button'}
      value={Title}
      gray={gray}
      bottom={bottom}
      control={control}
      onClick={onClick}
      disabled={disabled}
      isPhoneVerified={isPhoneVerified}
      isSMSVerified={isSMSVerified}
      style={style}
    >
      {isPhoneVerified !== undefined && (
        <SMSPassImg
          src={(isPhoneVerified === true && iconpass) || (isPhoneVerified === false && iconcross)}
          isPhoneVerified={isPhoneVerified}
        />
      )}

      {isSMSVerified !== undefined && (
        <CodePassImg
          src={(isSMSVerified === true && iconpass) || (isSMSVerified === false && iconcross)}
          isSMSVerified={isSMSVerified}
        />
      )}

      {Title}
    </StyledButton>
  );
}

export default Button;
