import styled from 'styled-components';
import backgroundimage from '../../../media/images/QUALITYASSURANCE.png';

const Container = styled.div`
  padding: 60px 0;
  background-color: #000000;
  background-image: url(${backgroundimage});
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding: 15px;
    min-height: 600px;
  }

  @media (max-width: 550px) {
    min-height: auto;
  }
`;

const Width = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: inherit;

  .aboutus3__title {
    color: #ffffff;
    font-size: 1.625rem;
    padding-right: 55%;
    text-shadow: 2px 2px 3px rgba(17, 65, 135, 0.3);
    margin-top: 0px;
  }

  .aboutus3__detail1 {
    color: #ffffff;
    font-size: 1.125rem;
    text-shadow: 2px 2px 3px rgba(17, 65, 135, 0.3);
    padding-right: 55%;
  }

  p {
    color: #ffffff;
    font-size: 0.875rem;
    padding-right: 55%;
    margin-top: 10px;
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  color: #fff;
  font-size: 1.625rem;
  padding-right: 55%;
  text-shadow: 2px 2px 3px rgba(17, 65, 135, 0.3);

  @media (max-width: 850px) {
    padding-right: 0px;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: ${(props) => (props.subtitle ? ' 1.125rem;' : '0.875rem')};
  text-shadow: 2px 2px 3px rgba(17, 65, 135, 0.3);
  padding-right: 55%;
  margin-top: 10px;
  line-height: 1.5;
  margin-bottom: ${(props) => (props.bottom ? ' 2rem' : '1.2rem')};

  @media (max-width: 850px) {
    padding-right: 0px;
  }
`;

export { Text, Title, Width, Container };
