import React, { useState, useEffect, useContext } from 'react';
import {
  Prompt,
  useHistory,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ReactLoading from 'react-loading';
import moment from 'moment';
import Swal from 'sweetalert2';
import commonStyle from '../../../styles/common';
import CartItem from '../CartItem';
import Timer from './Timer';
import * as icon from '../../../media/icons';
import TimesUpModal from './TimesUpModal';
import { usePickupLocations, useAssociations } from '../../../models/Cart';
import { useFirstDataToken, TokenContext } from '../../../models/Customer';
import { SelectField, Checkbox, CheckBoxMargin } from '../../../components/FormComponents';
import { RadioGroup, RadioButton } from '../../../components/Cart';
import StyledLink from '../../../components/General/StyledLink';
import StyledButton from '../../../components/General/StyledButton';
import PaymentMethodSelector from './PaymentMethodSelector';
import {
  Label,
  TermsConditionsBox,
  WhiteContainer,
  SubTitle,
  PickUpMethodView,
  ItemDetailLabel,
  PickUpLocationView,
  PickUpInput,
  OrangeContainer,
  DiscountView,
  DiscountLabel,
  DiscountDiv,
  DiscountInput,
  WhiteHorizontalLine,
  PaymentDisplay,
} from './style';

export const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Label fontSize="0.9rem" marginTop="20px">
        {t('Cart.TnC.Title')}
      </Label>
      <TermsConditionsBox dangerouslySetInnerHTML={{ __html: t('Cart.TnC.Content') }}>
        {/* <p style={{ color: 'black', fontSize: '0.75rem' }}>{t('Cart.TnC.Content')}</p> */}
      </TermsConditionsBox>
    </>
  );
};

function Step2({
  cart,
  onSelectPickUp,
  onSelectPaymentMethod,
  onSelectToken,
  checkout,
  removeAll,
  action,
  offlinePaymentIds,
  onlinePaymentIds,
  redirectToStep3,
  getSubTotalFee,
  getHandlingFee,
  allPaymentMethods,
  toCheckout,
  user,
  toCartVoid,
}) {
  const { t, i18n } = useTranslation();
  const { token } = useContext(TokenContext);
  const { firstDataTokens: creditCards } = useFirstDataToken();
  const { location } = useHistory();
  const history = useHistory();

  const isBuyNew = !action && location?.state?.buyNewCartData;

  const {
    getPaymentData,
    availablePaymentMethods,
    paymentMethodsLoading,
    allAvailablePaymentMethods,
  } = useAssociations();

  // const defaultPickupLocation =
  //   cart[0] && cart[0].PickUpLocatioObj
  //     ? { value: cart[0].PickUpLocatioObj.value, label: cart[0].PickUpLocatioObj.label }
  //     : '';

  const defaultValues = {
    pickup: 'self',
    IsAgreeTermsConditions: false,
    PaymentMethodRadio: null,
    FirstDataTokenId: null,
    // PickupLocation: defaultPickupLocation,
  };

  const methods = useForm({
    defaultValues,
    validationSchema: yup.object().shape({
      IsAgreeTermsConditions: yup.boolean().oneOf([true], 'IhourlyCart.ErrorMsg'),
      PaymentMethodRadio: yup.string().required(),
      // PickupLocation: yup.string().when('pickup', {
      //   is: (val) => val === 'self',
      //   then: yup.string().required('Cart.locationPickupError'),
      //   otherwise: yup.string(),
      // }),
    }),
  });
  const watchPickupMethod = methods.watch('pickup');
  const watchPickupLocation = methods.watch('PickupLocation');
  const watchPaymentMethod = methods.watch('PaymentMethodRadio');
  const watchFirstDataToken = methods.watch('FirstDataTokenId');
  const { locations } = usePickupLocations();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (creditCards.length === 0) {
      methods.setValue('FirstDataTokenId', 'new');
    } else {
      methods.setValue('FirstDataTokenId', creditCards[0].id.toString());
    }
  }, [creditCards]);

  useEffect(() => {
    const methodId = parseInt(watchPaymentMethod, 10);

    if (methodId === 6 && creditCards.length === 0) {
      onSelectToken('new');
    }
    onSelectPaymentMethod(methodId);
  }, [watchPaymentMethod]);

  useEffect(() => {
    onSelectToken(watchFirstDataToken);
  }, [watchFirstDataToken]);

  // useEffect(() => {
  //   onSelectPickUp(watchPickupLocation.value);
  // }, [watchPickupLocation]);

  useEffect(() => {
    if (cart.length === 0) return;
    const carParkId = cart.map(({ carPark: { id } }) => id);
    getPaymentData(carParkId);
  }, [cart.length]);

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);

    if (isBuyNew) {
      const numberPaymentMethodId = parseInt(watchPaymentMethod, 10);
      const data = {
        FirstDataTokenId:
          numberPaymentMethodId !== 6 || watchFirstDataToken === 'new' ? null : watchFirstDataToken,
        PaymentMethodId: numberPaymentMethodId,
        isPaymentLink: false,
        origin: 'web',
        CustomerId: user.id,
        cartId: location?.state?.buyNewCartData?.id,
      };

      const id = await toCheckout(data, token);

      setLoading(false);

      if (id) {
        window.history.replaceState({}, '');
        setLoading(false);
        redirectToStep3();
      }
      return;
    }

    if (await checkout(token)) {
      setLoading(false);
      redirectToStep3();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const beforeunloadHandle = () => {
      if (isBuyNew) {
        window.history.replaceState({}, '');
      }

      return true;
    };

    window.addEventListener('beforeunload', beforeunloadHandle);
    window.addEventListener('popstate', beforeunloadHandle);
    return () => {
      window.removeEventListener('removeEventListener', beforeunloadHandle);
      window.removeEventListener('popstate', beforeunloadHandle);
    };
  }, []);

  useEffect(() => {
    if (!action && !location?.state?.buyNewCartData) {
      history.replace({ pathname: `/${i18n.language}/cart/1` });
    }
  }, [action, location.state]);

  useEffect(() => {
    const unblock = history.block((location, actions) => {
      if (!action && (actions === 'PUSH' || actions === 'POP')) {
        window.history.replaceState({}, '');
        return true;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, []);

  console.log(location?.state?.buyNewCartData);

  return (
    <div>
      <Prompt
        when={action === 'Change'}
        message={(location, actions) => {
          if (location?.pathname === `/${i18n.language}/cart/3`) {
            return true;
          }

          return t('Cart.BackMessage');
        }}
      />
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', alignSelf: 'flex-start', margin: '15px 0 0 0' }}>
            <img src={icon.alert} width="21px" height="21px" alt="read_carefully" />
            &nbsp;&nbsp;
            <p style={{ fontSize: '1.125rem', marginBottom: 10 }}>
              <b>{t('Cart.Instructions.Header')}</b>
            </p>
          </div>
          <ol style={{ fontSize: '0.87rem', margin: '0 20px 25px 20px' }}>
            <li style={{ marginBottom: 5 }}>{t('Cart.Instructions.2-1')}</li>
            <li style={{ marginBottom: 5 }}>{t('Cart.BackMessage')}</li>
          </ol>

          <WhiteContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <SubTitle>{t('Cart.Summary')}</SubTitle>

              <Timer
                expireDate={location?.state?.buyNewCartData?.cartValidUntil}
                timesUp={() => {
                  Swal.fire({
                    focusConfirm: false,
                    text: t('Cart.retry'),
                    title: t('Cart.paymentExpired'),
                    position: 'center',
                    confirmButtonColor: '#fd980f',
                    confirmButtonText: t('Cart.Back'),
                    preConfirm: () => {
                      window.location = '/cart/1';
                      removeAll();
                    },
                  });
                }}
              />
            </div>

            {cart.length > 0 &&
              cart.map((item, index) => (
                <CartItem
                  allAvailablePaymentMethods={allAvailablePaymentMethods}
                  index={index}
                  details={item}
                  key={item}
                  action={action}
                  totalCart={cart.length}
                  offlinePaymentIds={offlinePaymentIds}
                  onlinePaymentIds={onlinePaymentIds}
                />
              ))}

            {action !== 'Renew' && (
              <div
                style={{
                  margin: '0px 25px 0px 25px',
                  marginBottom: 10,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* <SubTitle>{t('Cart.PickUpMethod.Prompt')}</SubTitle> */}
                <SubTitle style={{ marginTop: 10 }}>{t('Cart.locationText1')}</SubTitle>
                <Label style={{ marginTop: 10 }} fontSize="1rem">
                  {t('Cart.locationText2')}
                </Label>
                {/* <PickUpMethodView>
                  <ItemDetailLabel>{t('Cart.PickUpMethod.Title')}</ItemDetailLabel>
                  <RadioGroup controllerName="pickup">
                    <RadioButton
                      checked={methods.getValues('pickup') === 'self'}
                      style={{ marginRight: 15 }}
                      value="self"
                      name="pickup"
                    >
                      <span>{t('Cart.PickUpMethod.Self')}</span>
                    </RadioButton>

                    <RadioButton
                      checked={methods.getValues('pickup') === 'courier'}
                      value="courier"
                      name="pickup"
                    >
                      <span>{t('Cart.PickUpMethod.Courier')}</span>
                    </RadioButton>
                  </RadioGroup>
                </PickUpMethodView>

                {watchPickupMethod !== 'courier' && (
                  <PickUpLocationView>
                    <ItemDetailLabel>{t('Cart.PickUpMethod.LocationPrompt')}</ItemDetailLabel>
                    <SelectField
                      name="PickupLocation"
                      getOptionLabel={(label) => label}
                      getOptionValue={(value) => value}
                      maxMenuHeight={500}
                      options={locations}
                      errorMessage={t(`${methods.errors.PickupLocation?.message}`)}
                    />
                    <PickUpInput>
                      <input
                        style={{ padding: 5 }}
                        value={
                          cart[0] && cart[0].PickUpLocatioObj
                            ? cart[0].PickUpLocatioObj.address
                            : watchPickupLocation.address
                        }
                        readOnly
                      />
                      <span>
                        {cart[0] && cart[0].PickUpLocatioObj
                          ? cart[0].PickUpLocatioObj.description
                          : watchPickupLocation.description}
                      </span>
                    </PickUpInput>
                  </PickUpLocationView> */}
                {/* )} */}
              </div>
            )}
          </WhiteContainer>

          <OrangeContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexBasis: '55%',
                fontSize: '0.75rem',
              }}
            >
              <PaymentMethodSelector
                paymentMethodsLoading={paymentMethodsLoading}
                allPaymentMethods={allPaymentMethods}
                availablePaymentMethods={availablePaymentMethods.filter(
                  ({ isOnlineMethod }) => action === 'Renew' || isOnlineMethod === true
                )}
                action={action}
                activePaymentMethod={watchPaymentMethod}
                creditCards={creditCards}
                offlinePaymentIds={offlinePaymentIds}
                onlinePaymentIds={onlinePaymentIds}
                setFirstDataTokenId={(id) => methods.setValue('FirstDataTokenId', id)}
                errors={methods.errors.PaymentMethodRadio}
                FirstDataTokenId={methods.getValues('FirstDataTokenId')}
                selectedValue={methods.getValues('PaymentMethodRadio')}
              />
              <TermsConditions />
              <CheckBoxMargin>
                <Checkbox
                  style={{ background: '#fff', borderRadius: '2px' }}
                  textStyle={{ color: '#fff' }}
                  name="IsAgreeTermsConditions"
                  Text1={t('Cart.TnC.Agree')}
                  defaultValues={false}
                  rules={{
                    validate: () => methods.getValues('IsAgreeTermsConditions'),
                  }}
                />
                {methods.errors.IsAgreeTermsConditions && (
                  <span style={{ color: 'red', marginLeft: 25 }}>
                    {t(`${methods.errors.IsAgreeTermsConditions?.message}`)}
                  </span>
                )}
              </CheckBoxMargin>
            </div>

            <div style={commonStyle.verticalLine} />

            <div
              style={{
                flexBasis: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <DiscountView>
                  <DiscountLabel>{t('Cart.Discount')}</DiscountLabel>

                  <DiscountDiv>
                    <DiscountInput />
                    <StyledButton
                      type="button"
                      color="grey"
                      style={{ margin: 0, flex: '0 0 41.666667%', maxWidth: '41.666667%' }}
                    >
                      {t('Cart.Apply')}
                    </StyledButton>
                  </DiscountDiv>
                </DiscountView>

                <WhiteHorizontalLine />

                <PaymentDisplay>
                  <Label fontSize="0.875rem" fontWeight="bold">
                    {t('Cart.SubTotal')}
                  </Label>
                  <Label fontSize="0.875rem">
                    HK$
                    {!action ? location?.state?.buyNewCartData?.total : getSubTotalFee()}
                  </Label>
                </PaymentDisplay>

                <PaymentDisplay>
                  <Label fontSize="0.875rem" fontWeight="bold">
                    {t('Cart.HandlingFee')}
                  </Label>
                  <Label fontSize="0.875rem">
                    HK$
                    {getHandlingFee()}
                  </Label>
                </PaymentDisplay>

                <WhiteHorizontalLine />

                <PaymentDisplay>
                  <Label fontSize="1.3rem" fontWeight="bold">
                    {t('Cart.TotalPrice')}
                  </Label>
                  <Label fontSize="2.4rem">
                    HK$
                    {!action
                      ? location?.state?.buyNewCartData?.total
                      : getSubTotalFee() + getHandlingFee()}
                  </Label>
                </PaymentDisplay>
              </div>

              <div style={{ display: 'flex' }}>
                <StyledButton
                  type="button"
                  color="grey"
                  label={t('Cart.Back')}
                  style={{ maxWidth: '100%', minHeight: 35 }}
                  onClick={async () => {
                    if (location?.state?.buyNewCartData?.id) {
                      await toCartVoid(location?.state?.buyNewCartData?.id, token);
                    }

                    history.replace({ pathname: `/${i18n.language}/cart/1` });
                  }}
                />
                <StyledButton
                  disabled={loading}
                  type="submit"
                  color="white"
                  label={t('Cart.Confirm')}
                  style={{ maxWidth: '100%', minHeight: 35 }}
                >
                  {loading && <ReactLoading type="spin" color="#fd980f" height={30} width={30} />}
                </StyledButton>
              </div>
            </div>
          </OrangeContainer>
        </form>
      </FormContext>
    </div>
  );
}

export default Step2;
