import React from 'react';
import '../../../components/General/Checkbox/style.scss';
import { useTranslation } from 'react-i18next';
import {
  ContentBarContainer,
  ContentBarWidth,
  LeftDiv,
  LeftDivTitle,
  LeftDivText,
  RightDiv,
  RightDivRow,
  RightDivRowTextDiv,
  RightDivRowText,
  OfficeHourDiv,
  RightDivFlex,
  RightDivRowA,
} from './style';

const ContentBar = () => {
  const { t } = useTranslation();

  return (
    <ContentBarContainer>
      <ContentBarWidth>
        <LeftDiv>
          <LeftDivTitle>
            {t('CsList.Hotline')}
            <br />
          </LeftDivTitle>
          <LeftDivText>{t('CsList.HotlineText')}</LeftDivText>
        </LeftDiv>

        <RightDiv>
          <RightDivRow>
            <RightDivRowTextDiv>
              <RightDivRowText>{t('CsList.OfficeHour')}</RightDivRowText>
            </RightDivRowTextDiv>

            <OfficeHourDiv>
              <RightDivRowText>
                {t('CsList.OfficeHour1')}
                <br />
              </RightDivRowText>
              <RightDivRowText>{t('CsList.Except')}</RightDivRowText>
            </OfficeHourDiv>
          </RightDivRow>

          <RightDivRow>
            <RightDivRowTextDiv>
              <RightDivRowText>{t('CsList.Tel')}</RightDivRowText>
            </RightDivRowTextDiv>
            <RightDivFlex>
              <RightDivRowText>+852 2830-3845</RightDivRowText>
            </RightDivFlex>
          </RightDivRow>

          <RightDivRow>
            <RightDivRowTextDiv>
              <RightDivRowText>{t('CsList.EmailAddress')}</RightDivRowText>
            </RightDivRowTextDiv>

            <RightDivFlex>
              <RightDivRowA href="mailto:info@wilsonparking.com.hk">
                info@wilsonparking.com.hk
              </RightDivRowA>
            </RightDivFlex>
          </RightDivRow>
        </RightDiv>
      </ContentBarWidth>
    </ContentBarContainer>
  );
};

export default ContentBar;
