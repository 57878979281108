import styled from 'styled-components';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import FieldError from './FieldError';

// TODO: responsive?

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 3px;
  flex-direction: column;
  position: relative;

  @media (max-width: 850px) {
    -webkit-flex: 1;
flex: 1;
    max-width: 100%;
    padding-left: 0;
  }
`;

const FieldContainer = styled.div`
  display: flex;
`;

const Input = styled.input`
  display: flex;
  -webkit-flex: 1;
flex: 1;
  padding: 4px 4px 4px 10px;
  font-size: 13px;
  color: #666;
  height: 32px;
  border: 1px solid
    ${({ error, passworderror, isEmailValid }) =>
      error || passworderror || isEmailValid ? '#E56E5A' : '#bbb'};
  width: 100%;
`;

function TextField({
  name,
  placeHolder,
  type = 'text',
  children,
  onChange,
  address,
  errorMessage,
  readonly,
}) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const Ref = useRef();

  return (
    <Container address={address}>
      <FieldContainer>
        <Controller
          as={<Input ref={Ref} />}
          name={name}
          control={control}
          placeholder={placeHolder}
          type={type}
          onChange={onChange}
          error={error}
          onFocus={() => Ref.current.focus()}
          readOnly={!!readonly}
        />
      </FieldContainer>
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
      {children}
    </Container>
  );
}

export default TextField;
