import styled from 'styled-components';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useStaticInfo from '../../../models/StaticInfo';

const Container = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  vertical-align: middle;
  background-color: #333333;
  z-index: 100;
  position: relative;
  font-size: 0.75rem;

  @media (max-width: 950px) {
    padding-bottom: 14px;
    padding-top: 4px;
  }
`;

const Width = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const Inner = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  justify-content: space-between !important;

  @media (max-width: 980px) {
    flex-direction: column;
  }

  @media (max-width: 550px) {
    padding-bottom: 1px;
  }
`;

const LeftDiv = styled.div`
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 950px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const CopyRightDiv = styled.div`
  /* height: 2rem;

  @media (max-width: 980px) {
    line-height: 1.5rem;
  } */
`;

const CopyRight = styled.p`
  font-size: 0.625rem;
  /* line-height: 1.5rem; */
  color: #888;
  margin: 0;

  @media (max-width: 980px) {
    font-size: 0.825rem;
    line-height: 1.8rem;
  }
`;

const OfficeAddressDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;

  @media (max-width: 980px) {
    margin-bottom: 3px;
  }

  @media (max-width: 550px) {
    margin-bottom: 10px;
  }
`;

const OfficeAddress = styled.p`
  font-size: 0.625rem;
  line-height: 1.3;
  color: #888;
  margin: 0;

  @media (max-width: 980px) {
    font-size: 0.825rem;
  }
`;

const EmailA = styled.a`
  font-size: 0.625rem;
  line-height: 1.3;
  color: #fd980f;
  &:hover {
    color: #fd980f;
  }
  @media (max-width: 980px) {
    font-size: 0.825rem;
  }
`;

const RightDiv = styled.div`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const RightDivRow = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const CsItem = styled.div`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const CsItemInner = styled.div`
  line-height: 1.5rem;
  text-align: left;
  margin: 0px -15px;
`;

const CsItemTextDiv = styled.div`
  padding: 0 1rem;
  font-size: 0.625rem;
  display: inline-block;
  text-transform: uppercase;
  color: #bbb;
  text-decoration: none;
  vertical-align: middle;
  line-height: 1;
  border-left: ${(props) => (props.left ? '1px solid #bbb' : '0px')};
  @media (max-width: 980px) {
    margin-top: 3px;
  }

  @media (max-width: 550px) {
    margin-top: -5px;
  }
`;

const CsItemA = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  font: inherit;
  text-overflow: inherit;
  transition: all 200ms ease;

  &:hover {
    color: #bbb;
    text-decoration-line: none;
  }
`;

const WilsonParkingDiv = styled.div`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const WilsonParkingInner = styled.div`
  height: 24px;
  @media (max-width: 980px) {
    margin-top: 2px;
    height: auto;
  }

  @media (max-width: 550px) {
    margin-top: 1px;
  }
`;

const WilsonParkingA = styled.a`
  color: #fd980f;
  transition: all 200ms ease;
  font-size: 0.875rem;

  &:hover {
    color: #bbb;
    text-decoration-line: none;
  }
`;

function LowerFooter() {
  const { t } = useTranslation();
  const { StaticInfoContext, getStaticValue } = useStaticInfo();
  const staticInfo = useContext(StaticInfoContext);

  const pathNameData = getStaticValue(['Pages-Privacy Policy', 'Pages-Disclaimer'], staticInfo);

  return (
    <Container>
      <Width>
        <Inner>
          <LeftDiv>
            <CopyRightDiv>
              <CopyRight>{t('footer.CopyRight')}</CopyRight>
              <CopyRight>
                {t('footer.wp')}
                {'\xa0'}
                {'\xa0'}
                {'\xa0'}
                {t('footer.licence')}
              </CopyRight>
            </CopyRightDiv>
            <OfficeAddressDiv>
              <OfficeAddress>
                {t('footer.Office')}
                <br />
                <span>
                  {t('footer.Email')}
                  <EmailA href="mailto:info@wilsonparking.com.hk">
                    {' '}
                    info@wilsonparking.com.hk
                  </EmailA>
                </span>
              </OfficeAddress>
            </OfficeAddressDiv>
          </LeftDiv>

          <RightDiv>
            <RightDivRow>
              <CsItem>
                <CsItemInner>
                  <CsItemTextDiv>
                    <CsItemA to={`/${pathNameData['Pages-Privacy Policy']}`}>
                      {t('footer.PRIVACYPOLICY')}
                    </CsItemA>
                  </CsItemTextDiv>
                  <CsItemTextDiv left>
                    <CsItemA to={`/${pathNameData['Pages-Disclaimer']}`}>
                      {t('footer.DISCLAIMER')}
                    </CsItemA>
                  </CsItemTextDiv>
                </CsItemInner>
              </CsItem>
              <WilsonParkingDiv>
                <WilsonParkingInner>
                  <WilsonParkingA href="http://www.wilsonparking.com.cn/" target="_blank">
                    {t('footer.wilsonparking')}
                  </WilsonParkingA>
                </WilsonParkingInner>
              </WilsonParkingDiv>
            </RightDivRow>
          </RightDiv>
        </Inner>
      </Width>
    </Container>
  );
}

export default LowerFooter;
