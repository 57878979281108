import React from 'react';
import Select from 'react-select';

function Selector({
  options,
  setSelected,
  placeholder,
  isDisabled,
  styles,
  defaultValue,
  onchange,
  selectedValue,
}) {
  const [opened, setOpened] = React.useState(false);

  return (
    <Select
      options={options}
      onChange={({ value, label }) => {
        setSelected(value);
        if (onchange) {
          onchange({ value, label });
        }
      }}
      styles={styles(isDisabled, opened)}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isSearchable={false}
      menuPlacement="auto"
      onMenuClose={() => setOpened(false)}
      onMenuOpen={() => setOpened(true)}
      defaultValue={defaultValue}
      value={selectedValue}
    />
  );
}

export default Selector;
