import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const url = `${process.env.REACT_APP_API_DOMAIN}/Banner`;

function useBanner({ bannerType = '' }) {
  const {
    i18n: { language },
  } = useTranslation();

  const [banners, setBanners] = useState([]);

  function handelEffectiveDate(effectiveFrom, effectiveTo) {
    const today = moment();
    const effectiveFromTZ = effectiveFrom ? moment(effectiveFrom) : null;
    const effectiveToTZ = effectiveTo ? moment(effectiveTo) : null;

    if (effectiveFromTZ && effectiveToTZ) {
      return today.isBetween(effectiveFromTZ, effectiveToTZ, 'day', '[]');
    }

    if (!effectiveFromTZ) {
      return today.isSameOrBefore(effectiveToTZ, 'day');
    }

    if (!effectiveToTZ) {
      return today.isSameOrAfter(effectiveFromTZ, 'day');
    }

    return false;
  }

  async function fetchBanner() {
    try {
      const {
        data: { data = [] },
      } = await axios.get(`${url}`);

      const cleanData = data
        .filter(
          ({ status, type, effectiveFrom, effectiveTo }) =>
            status === 'published' &&
            type === bannerType &&
            handelEffectiveDate(effectiveFrom, effectiveTo)
        )
        .sort((a, b) => a.priority - b.priority)
        .map(
          ({
            chineseImage,
            chineseTitle,
            chineseUrlLink,
            image,
            id,
            title,
            urlLink,
            chineseContent,
            content,
          }) => {
            return {
              chineseImage,
              chineseTitle,
              chineseUrlLink,
              image,
              id,
              title,
              urlLink,
              chineseContent,
              content,
            };
          }
        );

      setBanners(cleanData);
    } catch (error) {
      setBanners([]);
    }
  }

  useEffect(() => {
    fetchBanner();
  }, []);

  return { banners };
}

// eslint-disable-next-line import/prefer-default-export
export default useBanner;
