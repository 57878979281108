import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cartStyle from '../../styles/cart';

const Timer = ({ timesUp }) => {
  const { t } = useTranslation();
  const [secRemaining, setSecRemaining] = useState(15 * 60);
  const [min, setMin] = useState(15);
  const [sec, setSec] = useState('00');

  const countDown = () => {
    let newMin = Math.floor(secRemaining / 60);
    let newSec = secRemaining - min * 60;
    if (newMin < 10) {
      newMin = `0${newMin}`;
    }

    if (newSec < 10) {
      newSec = `0${newSec}`;
    }

    // something wrong when sec = 0
    // extra second showing weird number, between min:00 and min:59
    // temporary solution...
    if (newSec === -1 || newSec.toString() === '0-1') {
      newSec = 59;
    }

    setMin(newMin);
    setSec(newSec);

    if (newMin.toString() === '00' && newSec.toString() === '00' && timesUp) {
      timesUp();
    }

    setSecRemaining(secRemaining - 1);
  };

  useEffect(() => {
    const timer = secRemaining >= 0 && setInterval(countDown, 1000);
    return () => clearInterval(timer);
  }, [secRemaining]);

  return (
    <div className="d-flex-polyfill" style={{ alignSelf: 'center', display: 'flex' }}>
      <b>{t('Timer.TimeLeft')}</b>
      <span style={{ marginRight: 10 }}>:</span>
      <b style={cartStyle.timer}>{min}</b>
      <span style={{ marginLeft: 10, marginRight: 10 }}>:</span>
      <b style={cartStyle.timer}>{sec}</b>
    </div>
  );
};

export default Timer;
