import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useForm, FormContext } from 'react-hook-form';
import { UseUserDeletionRequest, TokenContext } from '../../../models/Customer';
import {
  Container,
  TitleRow,
  TitleInner,
  TitleDiv,
  TitleBlock,
  Title,
  FormWidth,
  FormInner,
  FormBlock,
  FormDiv,
  TextRow,
  ButtonRow,
  ButtonDiv,
  Back,
  SubmitButton,
  LoadingContainer,
  Checkbox,
  CheckboxContainer,
  CheckboxGroup,
  Checkmark,
  Inner,
} from './styles';

const DeleteAccountSurvey = () => {
  const { t, i18n } = useTranslation();
  const { handelGetOneDeletionRequest, handelDeleteDeletionRequest } = UseUserDeletionRequest();
  const history = useHistory();
  const { token } = useParams();
  const methods = useForm({
    defaultValues: {},
  });

  const { logout, token: userToken } = useContext(TokenContext);

  const [deleteReasons, setDeleteReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');

  const { control, handleSubmit, errors, setValue, watch, register } = methods;

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) {
      handelGetOneDeletionRequest(
        token,
        (e) => {
          setIsLoading(false);
          setUserData(e);
          if (e.status === 'pending') {
            return;
          }
          if (e.status === 'completed' || e.status === 'submitted') {
            history.push({
              pathname: `/${i18n.language}/customers/DeleteAccount/success`,
            });
            return;
          }
          history.push({
            pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
          });
        },
        (err) => {
          setIsLoading(false);
          history.push({
            pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
            state: t('error'),
          });
        }
      );
      return;
    }

    setIsLoading(false);
    history.push({
      pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
      state: t('error'),
    });
  }, [token]);

  function onSave() {
    if (!token) {
      history.push({
        pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
        state: t('error'),
      });
      return;
    }

    if (deleteReasons.includes('OTHER') && !otherReason) {
      alert(t('AccountDeletion.otherError'));
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    handelDeleteDeletionRequest(
      token,
      otherReason ? { deleteReasons, otherReason } : { deleteReasons },
      () => {
        setIsLoading(false);
        if (userToken) {
          logout();
        }

        history.push({
          pathname: `/${i18n.language}/customers/DeleteAccount/success`,
        });
      },
      (err) => {
        setIsLoading(false);
        history.push({
          pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
          state: t('error'),
        });
      }
    );
  }

  useEffect(() => {
    if (errors.isAgreed) {
      alert(t('Myprofile.tncError'));
    }
  }, [errors]);

  const surveyOption = [
    {
      label: t('AccountDeletion.DUPLICATED_ACCOUNT'),
      value: 'DUPLICATED_ACCOUNT',
    },
    {
      label: t('AccountDeletion.ANOTHER_ACCOUNT'),
      value: 'ANOTHER_ACCOUNT',
    },
    {
      label: t('AccountDeletion.NO_CONTACTLESS'),
      value: 'NO_CONTACTLESS',
    },
    {
      label: t('AccountDeletion.SELDOM_USE'),
      value: 'SELDOM_USE',
    },
    {
      label: t('AccountDeletion.PRIVACY_CONCERNS'),
      value: 'PRIVACY_CONCERNS',
    },
    {
      label: t('AccountDeletion.MANY_NOTIFICATIONS'),
      value: 'MANY_NOTIFICATIONS',
    },
    {
      label: t('AccountDeletion.OTHER'),
      value: 'OTHER',
    },
  ];

  const onChecked = (e) => {
    const checkedValue = e.target.value;

    if (deleteReasons.includes(checkedValue)) {
      if (checkedValue === 'OTHER') {
        setOtherReason('');
      }
      setDeleteReasons(deleteReasons.filter((option) => option !== checkedValue));
    } else {
      setDeleteReasons([...deleteReasons, checkedValue]);
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSave)}>
      {isLoading && (
        <LoadingContainer>
          <ReactLoading type="spokes" color="#fd980f" height={80} width={80} />
        </LoadingContainer>
      )}
      {!isLoading && Object.values(userData).length > 0 && (
        <FormContext {...methods}>
          <Inner>
            <TitleRow style={{ height: '80%' }}>
              <TitleInner>
                <TitleDiv>
                  <TitleBlock style={{ maxWidth: 'max-content' }}>
                    <Title>{t('AccountDeletion.surveyTitle')}</Title>
                    <br />

                    <CheckboxGroup>
                      {surveyOption.map((option) => (
                        <CheckboxContainer key={option.value}>
                          {option.label}
                          <Checkbox
                            type="checkbox"
                            value={option.value}
                            checked={deleteReasons.includes(option.value)}
                            onChange={onChecked}
                          />
                          <Checkmark />
                          {option.value === 'OTHER' && deleteReasons.includes('OTHER') && (
                            <input
                              value={otherReason}
                              onChange={(e) => setOtherReason(e.target.value)}
                              style={{ marginLeft: 10 }}
                            />
                          )}
                        </CheckboxContainer>
                      ))}
                    </CheckboxGroup>
                  </TitleBlock>
                </TitleDiv>
              </TitleInner>
            </TitleRow>
            <FormWidth>
              <FormInner>
                <FormBlock>
                  <FormDiv>
                    <TextRow>
                      <ButtonRow>
                        <ButtonDiv>
                          <Back href={`/${i18n.language}`}>{t('IhourlyCart.Back')}</Back>
                          <SubmitButton disabled={isLoading} type="submit">
                            {t('Myprofile.delete')}
                          </SubmitButton>
                        </ButtonDiv>
                      </ButtonRow>
                    </TextRow>
                  </FormDiv>
                </FormBlock>
              </FormInner>
            </FormWidth>
          </Inner>
        </FormContext>
      )}
      {!isLoading && Object.values(userData) === 0 && <Container />}
    </Container>
  );
};

export default withRouter(DeleteAccountSurvey);
