import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import useStaticInfo from '../../models/StaticInfo';

const TitleContainer = styled.div`
  background: #fd980f;
  display: flex;
  justify-content: center;
  padding-left: 30px;

  @media (max-width: 670px) {
    padding: 10px 20px 10px 30px;
  }
`;

const TitleDiv = styled.div`
  width: 1024px;
`;

const Title = styled.span`
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  margin-left: -3px;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 670px) {
    line-height: 40px;
  }
`;

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const MainWidth = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 20px 30px 104px 30px;
  width: 1024px;
  box-shadow: 0 0 2px #bbb;

  p {
    font-size: 0.875rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }
`;

const IMonthlyTNC = () => {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo(
    'Pages-i-MONTHLY Terms & Conditions'
  );

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <div>
      <TitleContainer>
        <TitleDiv>
          <Title>{name}</Title>
        </TitleDiv>
      </TitleContainer>

      <MainContainer>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }} />
      </MainContainer>
    </div>
  );
};
export default withRouter(IMonthlyTNC);
