import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import { Checkbox } from 'pretty-checkbox-react';

const Container = styled.div`
  & .pretty {
    &:focus {
      outline: none !important;
    }
  }
`;

const Text = styled.span`
  font-size: 13px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};
`;

const Href = styled.a`
  color: ${({ error }) => (error ? '#E56E5A' : '#fd980f')};
  font-weight: bold;

  &:hover {
    color: #fd980f;
  }
`;

function CheckboxContainer(
  { name, Text1, Text2, onclick, checked, style, textStyle, rules },
  props
) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const Ref = useRef();

  return (
    <Container>
      <Controller
        style={style}
        as={<Checkbox ref={Ref} />}
        name={name}
        control={control}
        shape="curve"
        color="warning-o"
        animation="smooth"
        defaultValue={!!checked}
        onFocus={() => Ref.current.focus()}
        rules={rules}
        {...props}
      />
      <Text style={textStyle} error={error}>
        {Text1}
        &nbsp;
        <Href href="#" onClick={onclick} error={error}>
          {Text2}
        </Href>
      </Text>
    </Container>
  );
}

export default CheckboxContainer;
