import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { Checkbox } from 'pretty-checkbox-react';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none !important;
  }

  & .pretty {
    &:focus {
      outline: none !important;
    }
  }

  & .pretty input:checked ~ .state.p-primary label:after {
    background-color: #fd980f !important;
  }

  & .p-round {
    background: transparent;
  }

  &:hover {
    & .pretty .state label:before {
      border-color: ${(props) => props.shape === 'square' && '#000'};
    }
  }
`;

const Message = styled.span`
  font-size: 12px;
  color: red;
  font-weight: normal;
  padding-left: 25px;
`;

function CheckboxwithControl({ name, content, checked, shape, disabled, required }) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const { t } = useTranslation();
  return (
    <Container shape={shape}>
      <Controller
        as={Checkbox}
        name={name}
        control={control}
        shape={shape}
        color="primary"
        checked={checked}
        disabled={disabled}
        defaultValue={false}
        rules={{ required }}
      >
        {content}
      </Controller>
      {error && <Message>{t('IhourlyCart.ErrorMsg')}</Message>}
    </Container>
  );
}

export default CheckboxwithControl;
