import baseAPIService from './baseAPIService';

export async function submitOrder(planId, carId, pickupId, paymentId) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/order`;
  const data = {
    planId,
    carId,
    pickupId,
    paymentId,
  };

  return baseAPIService(url, 'POST', data, {
    Accept: 'application/json',
  });
}

export async function getOrderDetails(orderId) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/order/${orderId}`;

  return baseAPIService(url, 'GET', null, {
    Accept: 'application/json',
  });
}

export async function getPickUpLocation() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/pick-up-location`;

  return baseAPIService(url, 'GET', null, {
    Accept: 'application/json',
  });
}

export async function getPaymentConfig(orderId, locale = 'en_US') {
  const url = `${process.env.REACT_APP_API_DOMAIN}/firstdata/${orderId}/checkout`;

  return baseAPIService(
    url,
    'POST',
    {
      locale,
    },
    {
      Accept: 'application/json',
    }
  );
}
