import styled from 'styled-components';

const ButtonItem = styled.div`
  display: flex;
  @media (max-width: 850px) {
    -webkit-flex: 1;
flex: 1;
    margin-left: ${(props) => (props.register ? '30px' : null)};
  }
`;

export default ButtonItem;
