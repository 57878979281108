import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams } from 'react-router-dom';
import { range } from 'lodash';
import AliceCarousel from 'react-alice-carousel';
import { Container, Inner } from './containers/style';
import 'react-alice-carousel/lib/alice-carousel.css';
import FpsPaymentDetail from './containers/FpsPaymentDetail';
import { useFpsPayment } from '../../models/Cart';
import ButtonRow from './containers/ButtonRow';
import { useBooking } from '../../models/Booking';
import './containers/sweetalert.css';

function FPSPayment() {
  const { orderId } = useParams();

  const items = range(1);

  const { t } = useTranslation();

  const { qrCode, amount, orderReference, bookingDetail } = useFpsPayment(orderId);

  const { GetOneBooking, OrderDetail } = useBooking();

  const { bookingStatus } = OrderDetail;

  useEffect(() => {
    if (orderId) {
      GetOneBooking(orderId, t('fpsPayment.alertText1'));
    }
  }, [orderId]);

  return (
    <Container>
      <div style={{ backgroundColor: '#fd980f', height: 250, width: '100%' }} />
      {qrCode && (
        <Inner items={items.length}>
          <AliceCarousel
            responsive={{
              0: { items: 1 },
              568: { items: 2 },
              1024: { items: 3 },
            }}
            mouseTracking={items.length > 1}
            disableDotsControls
            disableButtonsControls
            items={items.map((e, index) => {
              return (
                <FpsPaymentDetail
                  key={e}
                  qrCode={qrCode}
                  amount={amount}
                  index={index}
                  bookingDetail={bookingDetail}
                />
              );
            })}
          />
          <ButtonRow
            type="fps"
            orderReference={orderReference}
            bookingStatus={bookingStatus}
            OrderDetail={OrderDetail}
          />
        </Inner>
      )}
    </Container>
  );
}

export default withRouter(FPSPayment);
