import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import useStaticInfo from '../../models/StaticInfo';

const PageContainer = styled.div`
  height: 100%;
  background: #fff;
`;

const TitleRow = styled.div`
  background: #fd980f;
  padding: 15px;
  justify-content: center;
  display: flex;
`;

const TitleWidth = styled.div`
  width: 1000px;
`;

const Title = styled.div`
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
`;

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const MainWidth = styled.div`
  background: #fff;
  padding: 40px 20px 60px 20px;
  width: 1030px;

  p {
    font-size: 0.875rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }
`;

const Disclaimer = () => {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo('Pages-Disclaimer');

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <PageContainer id="Disclaimer">
      <TitleRow>
        <TitleWidth>
          <Title>{name}</Title>
        </TitleWidth>
      </TitleRow>

      <MainContainer>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }} />
      </MainContainer>
    </PageContainer>
  );
};

export default withRouter(Disclaimer);
