import React from 'react';
import styled from 'styled-components';
import '../../../components/General/Checkbox/style.scss';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/General/PopupPrivacyPolicy';
import backgroundimage from '../../../media/images/BUSINESSENQUIRY.jpg';
import imgback from '../../../media/images/news_bg.png';
import ContentBar from './ContentBar';
import FormBox from './FormBox';

const Container = styled.div`
  flex: 1;
`;

const TitleBarBackground = styled.div`
  display: flex;
  padding: 21px;
  justify-content: center;
  background-image: url(${imgback});
  background-size: cover;
  background-position: center;

  @media (max-width: 850px) {
    padding-left: 13px;
    padding-top: 13px;
    padding-bottom: 8px;
    padding-right: 5px;
  }
`;

const TitleWidth = styled.div`
  width: 1000px;
`;

const Title = styled.span`
  color: #fff;
  font-size: 2rem;
`;

const Background = styled.div`
  display: flex;
  background-image: url(${backgroundimage});
  background-size: cover;
  background-position: center bottom;
  padding-bottom: 30px;
  flex-direction: column;
`;

const ArrowDiv = styled.div`
  background: transparent;
  justify-content: center;
  display: flex;
  position: relative;
`;

const Arrow = styled.div`
  border-top-width: 35px;
  width: 0px;
  height: 0px;
  border-left-width: 45px;
  border-right-width: 45px;
  border-bottom-width: 0px;
  border-style: solid;
  background: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  color: rgba(253, 152, 15, 0.93);

  @media (max-width: 850px) {
    border-top-width: 30px;
    border-left-width: 40px;
    border-right-width: 40px;
    color: #fd980f;
  }
`;

const BusinessEnquiry = () => {
  const { t } = useTranslation();
  const [Popupshow, setPopupShow] = React.useState(false);

  return (
    <div style={{ flex: 1 }}>
      <Container>
        <TitleBarBackground>
          <TitleWidth>
            <Title>{t('CsList.BUSINESS_ENQUIRY')}</Title>
          </TitleWidth>
        </TitleBarBackground>

        <Background>
          <ContentBar />
          <ArrowDiv>
            <Arrow />
          </ArrowDiv>
          <FormBox setPopupShow={setPopupShow} />
        </Background>
      </Container>
      <Modal onClose={() => setPopupShow(false)} Popupshow={Popupshow} />
    </div>
  );
};

export default BusinessEnquiry;
