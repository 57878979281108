import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
// import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-TW';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { method } from 'lodash';
import calander from '../../assets/images/calander.png';

const Container = styled.div`
  flex-basis: 40%;

  & .MuiSvgIcon-root {
    color: #fd980f;
  }

  & .MuiPickersStaticWrapper-staticWrapperRoot {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: absolute;
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.open ? '1' : '0')};
    border-top: 5px solid #fd980f;
    transition: all 0.3s ease-in-out;
    top: -68%;

    @media (max-width: 670px) {
      right: -136px;
    }
  }

  & .MuiPickersDay-current {
    color: #000;
  }

  & .MuiPickersDay-daySelected {
    background-color: #fd980f;
    color: #fff;

    &:hover {
      background-color: #fd980f;
    }
  }

  & .MuiPickersDay-day {
    &:hover {
      background-color: #fd980f;
      color: #fff;
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  position: relative;
`;

const Input = styled.input`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  width: 120px;
  padding: 4px 4px 4px 10px;
  font-size: 13px;
  color: #666;
  height: 32px;
  border: 1px solid
    ${({ error, passworderror, isEmailValid }) =>
      error || passworderror || isEmailValid ? '#E56E5A' : '#bbb'};
  background-repeat: no-repeat;
  background-position: center right 5px;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
`;

function StyledDatePicker({
  name = 'ReactDatePicker',
  setStartDate,
  period = null,
  // valueName = 'selected',
  // onChange = ([selected]) => selected,
}) {
  const { control, errors, setValue, getValues } = useFormContext();
  const error = errors[name];
  const Ref = useRef(null);
  const PickerRef = useRef(null);
  const { i18n } = useTranslation();
  const [picker, setPicker] = useState(false);

  useEffect(() => {
    const handleClick = (e) => {
      if (Ref.current.contains(e.target)) return;
      if (PickerRef.current && !PickerRef.current.contains(e.target)) {
        if (picker) setPicker(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    if (getValues('ChangeType') === 'temporary') {
      setStartDate(moment().format());
      setValue('ReactDatePicker', moment().format('DD/MM/YYYY'), {
        shouldValidate: true,
      });
    }
  }, [getValues('ChangeType')]);

  return (
    <Container open={picker}>
      <FieldContainer>
        <Controller
          as={
            <Input
              ref={Ref}
              style={{
                backgroundImage: `url(${calander})`,
              }}
              // TODO: Remove disabled later after client confirmation
              disabled={
                getValues('ChangeType') === 'temporary' || getValues('ChangeType') === 'permanent'
              }
            />
          }
          name="ReactDatePicker"
          control={control}
          type="text"
          error={error}
          onFocus={() => Ref.current.focus()}
          onClick={() => setPicker(true)}
          readOnly
        />
      </FieldContainer>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={i18n.language === 'zh' ? zhLocale : enLocale}
      >
        <div style={{ display: 'flex', height: '100%', position: 'absolute' }} ref={PickerRef}>
          <DatePicker
            disableToolbar
            variant="static"
            onChange={(date) => {
              setStartDate(moment(date).format());
              setValue('ReactDatePicker', moment(date).format('DD/MM/YYYY'), {
                shouldValidate: true,
              });
            }}
            value={getValues('ReactDatePicker')}
            open
            onOpen={() => setPicker(true)}
            onClose={() => setPicker(false)}
            disablePast
            autoOk
            TextFieldComponent={() => null}
            shouldDisableDate={(e) => {
              if (!period) {
                return false;
              }
              if (moment(e).isBetween(moment(period.from), moment(period.to), 'date', '[]')) {
                return false;
              }

              return true;
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </Container>
  );
}

export default StyledDatePicker;
