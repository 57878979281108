import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams, useHistory, Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Checkbox } from 'pretty-checkbox-react';
import { useForm, Controller } from 'react-hook-form';
import { UseUserDeletionRequest, TokenContext } from '../../../models/Customer';
import {
  Container,
  TitleRow,
  TitleInner,
  TitleDiv,
  TitleBlock,
  Title,
  Text,
  FormWidth,
  FormInner,
  FormBlock,
  FormDiv,
  TextRow,
  ButtonRow,
  ButtonDiv,
  Back,
  SubmitButton,
  LoadingContainer,
  Inner,
} from './styles';
import * as icon from '../../../media/icons';

const DeleteAccountTnc = () => {
  const { t, i18n } = useTranslation();
  const { handelGetOneDeletionRequest } = UseUserDeletionRequest();
  const history = useHistory();
  const { token } = useParams();
  const { control, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: { isAgreed: false },
  });
  const { logout, token: userToken } = useContext(TokenContext);

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) {
      handelGetOneDeletionRequest(
        token,
        (e) => {
          setIsLoading(false);
          setUserData(e);
          if (e.status === 'pending') {
            return;
          }
          if (e.status === 'completed' || e.status === 'submitted') {
            history.push({
              pathname: `/${i18n.language}/customers/DeleteAccount/success`,
            });
            return;
          }
          history.push({
            pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
            state: t('error'),
          });
        },
        (err) => {
          setIsLoading(false);
          history.push({
            pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
            state: t('error'),
          });
        }
      );
      return;
    }

    setIsLoading(false);
    history.push({
      pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
      state: t('error'),
    });
  }, [token]);

  function onSave() {
    if (!token) {
      history.push({
        pathname: `/${i18n.language}/customers/DeleteAccount/failed`,
        state: t('error'),
      });
      return;
    }

    history.push({
      pathname: `/${i18n.language}/customers/DeleteAccountSurvey/${token}`,
    });
  }

  useEffect(() => {
    if (errors.isAgreed) {
      alert(t('Myprofile.tncError'));
    }
  }, [errors]);

  return (
    <Container onSubmit={handleSubmit(onSave)}>
      {isLoading && (
        <LoadingContainer>
          <ReactLoading type="spokes" color="#fd980f" height={80} width={80} />
        </LoadingContainer>
      )}

      {!isLoading && Object.values(userData).length > 0 && (
        <Inner>
          <TitleRow style={{ height: '80%' }}>
            <TitleInner>
              <TitleDiv>
                <TitleBlock>
                  <Title>{t('Cart.TnC.Title')}</Title>
                  <br />

                  <div
                    style={{
                      backgroundColor: '#f3f3f3',
                      flex: 1,
                      padding: '20px 30px',
                      margin: '40px 0px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
                      <h5 style={{ marginLeft: 5, fontWeight: 'bold', fontSize: '1.125rem' }}>
                        {t('MyAccount.iMonthly.important')}
                      </h5>
                    </div>
                    <span style={{ fontSize: '1rem', color: 'rgb(102, 102, 102)' }}>
                      {t('Myprofile.importantText')}
                    </span>
                  </div>

                  <Text>
                    {t('Myprofile.tnc')}
                    {'\xa0'}
                    <Link to={`/${i18n.language}/privacy-policy`} target="_blank">
                      {t('Myprofile.PrivacyPolicy')}
                    </Link>
                    {i18n.language === 'en' ? '.' : '。'}
                  </Text>

                  <Controller
                    as={<Checkbox>{t('Myprofile.readAndAgreed')}</Checkbox>}
                    name="isAgreed"
                    control={control}
                    shape="curve"
                    color="warning-o"
                    animation="smooth"
                    rules={{ required: true }}
                    defaultValue={false}
                    style={{ color: errors?.isAgreed ? 'red' : '#666' }}
                  />
                </TitleBlock>
              </TitleDiv>
            </TitleInner>
          </TitleRow>
          <FormWidth>
            <FormInner>
              <FormBlock>
                <FormDiv>
                  <TextRow>
                    <ButtonRow>
                      <ButtonDiv>
                        <Back type="button" onClick={() => history.goBack()}>
                          {t('IhourlyCart.Back')}
                        </Back>
                        <SubmitButton type="submit">{t('MyAccountTap.nextStep')}</SubmitButton>
                      </ButtonDiv>
                    </ButtonRow>
                  </TextRow>
                </FormDiv>
              </FormBlock>
            </FormInner>
          </FormWidth>
        </Inner>
      )}
      {!isLoading && Object.values(userData) === 0 && <Container />}
    </Container>
  );
};

export default withRouter(DeleteAccountTnc);
