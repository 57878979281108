import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useNews from '../../../models/News';
import NewsPromoItem from './NewsPromoItem';
import imgback from '../../../media/images/news_bg.png';
import useGA from '../../../hooks/useGA';

const whitearrow = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-arrow_xl-white.png`;

const Container = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  background-image: url(${imgback});
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 10px;
  padding-right: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleA = styled.a`
  color: #fff;
  font-size: 48px;

  &:hover {
    text-decoration-line: none;
    color: #fff;
  }
`;

const TitleArrow = styled.img`
  color: #fff;
  height: 40px;
  margin-left: 20px;
`;

const Width = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 170px;
  text-align: center;
`;

const MainWidth = styled.div`
  width: 100%;
  max-width: 1000px;
`;

const DeskTopNews = () => {
  const { t, i18n } = useTranslation();
  const { news } = useNews();
  const { handleEventTracking } = useGA();

  const limitData = news.slice(0, 3);

  return (
    <Container>
      <MainWidth>
        <TitleContainer>
          <TitleA href={`/${i18n.language}/news/index`}>
            {t('News.NEWS')}
            <TitleArrow src={whitearrow} />
          </TitleA>
        </TitleContainer>

        <Width>
          {limitData.map((record, index) => (
            <NewsPromoItem
              key={record.id || index}
              detail={record}
              t={t}
              DeskTop
              i18n={i18n}
              index={index}
              handleEventTracking={handleEventTracking}
            />
          ))}
        </Width>
      </MainWidth>
    </Container>
  );
};

export default DeskTopNews;
