import styled from 'styled-components';

// TODO: responsive?

const MobileNumberRow = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-flex: 1;
flex: 1;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export default MobileNumberRow;
