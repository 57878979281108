import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams } from 'react-router-dom';
import { range } from 'lodash';
import AliceCarousel from 'react-alice-carousel';
import { Container, Inner } from './containers/style';
import 'react-alice-carousel/lib/alice-carousel.css';
import SevenPaymentDetail from './containers/SevenPaymentDetail';
import { useGetQrCode } from '../../models/Customer';
import { useBooking } from '../../models/Booking';
import ButtonRow from './containers/ButtonRow';
import './containers/sweetalert.css';

function SevenPayment() {
  const { orderId } = useParams();

  const [receipts, setReceipts] = useState([]);

  const { GetOneBooking, OrderDetail } = useBooking();

  const { t, i18n } = useTranslation();

  const { accountCode, amount } = OrderDetail || {};

  const { GetQrCode, QrCodeData } = useGetQrCode(accountCode);

  const limit = 5000;

  const items = range(Math.ceil(amount / limit));

  useEffect(() => {
    if (orderId && accountCode) {
      GetQrCode();
    }
  }, [orderId, accountCode]);

  useEffect(() => {
    if (orderId) {
      GetOneBooking(orderId, t('sevenPayment.alertText1'));
    }
  }, [orderId, i18n.language]);

  return (
    <Container>
      <div style={{ backgroundColor: '#fd980f', height: 250, width: '100%' }} />
      {accountCode && (
        <Inner items={items.length}>
          <AliceCarousel
            mouseTracking={items.length > 1}
            disableDotsControls
            disableButtonsControls={items.length <= 1}
            responsive={{
              0: { items: 1 },
              850: { items: 2 },
              1024: { items: 3 },
            }}
            items={items.map((e, index) => {
              return (
                <SevenPaymentDetail
                  detail={e}
                  key={e}
                  index={index}
                  QrCodeData={QrCodeData}
                  setReceipts={setReceipts}
                  receipts={receipts}
                  OrderDetail={OrderDetail}
                  limit={limit}
                  length={items.length}
                  orderId={orderId}
                />
              );
            })}
          />
          <ButtonRow />
        </Inner>
      )}
    </Container>
  );
}

export default withRouter(SevenPayment);
