import React, { useEffect, useState } from 'react';
import Reaptcha from 'reaptcha';
import { useForm, Controller } from 'react-hook-form';
import styled, { keyframes } from 'styled-components';
import '../../../components/General/Checkbox/style.scss';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Checkbox from '../../../components/General/Checkbox/index';
import { useCsForm } from '../../../models/CS';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @media (max-width: 850px) {
    margin-top: 0px;
  }
`;

const MainWidth = styled.div`
  background: #fff;
  padding: 5px 0px 0px 0px;
  width: 1024px;
  box-shadow: 0 0 2px #bbb;
`;

const TitleDiv = styled.div`
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 15px;

  @media (max-width: 850px) {
    padding-top: 23px;
  }
`;

const TitleRow = styled.div`
  padding-top: 10px;
  padding-bottom: 9px;
`;

const LevMsgRow = styled.div``;

const Title = styled.span`
  font-size: 14px;
  color: #666;
`;

const SubTitle = styled.span`
  color: #fd980f;
  font-weight: bold;
  font-size: 18px;
`;

const ItemRow = styled.div`
  flex: 0 0 50%;
  max-width: ${(props) => (props.Bottom ? '100%' : '50%')};
  display: flex;
  padding-top: 7px;
  padding-bottom: 1px;

  @media (max-width: 850px) {
    max-width: 100%;
    padding-top: 2px;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 6px;
  color: #666;
  height: 32px;
  font-size: 0.8125rem;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
`;

const ItemDiv = styled.div`
  padding-bottom: 10px;
  -webkit-flex: 1;
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
`;

const InputDiv = styled.div`
    flex:${(props) => (props.Bottom ? '5.91' : '2.3')};
    padding: 3px 15px 0px 5px;

    @media(max-width:850px){
        flex:${(props) => (props.Bottom ? '2.3' : '2.3')};
        flex:${(props) => (props.Bottom ? '2.04' : '2.04')};
    }

}
`;

const ItemFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.Bottom ? 'column' : 'row')};
  flex-wrap: wrap;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  font-size: 13px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666666')};
  font-weight: bold;
  margin-bottom: 15px;
`;

const DividerDiv = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  background: #8888;
  width: 97%;
`;

const SubTitleDiv = styled.div`
  padding: 25px 15px 10px 15px;

  @media (max-width: 850px) {
    padding: 20px 15px 10px 15px;
  }
`;

const CheckBoxDiv = styled.div`
  display: flex;
  margin-top: 12px;
  padding-left: 16px;
  align-items: flex-start;
`;

const CheckBoxText = styled.span`
  font-size: 13px;
  margin-left: 10px;
  color: ${({ error }) => (error ? '#E56E5A' : '#666')};
`;

const PolicyA = styled.a`
  color: #fd980f;
  font-size: 14px;
  &:hover {
    color: #fd7e0f;
  }
`;

const Flicker = keyframes`
  25% {  opacity: 0; } 
  50% { opacity: 1; } 
  75% { opacity: 0; } 
  100% { opacity: 1;}
`;

const RechapchaDiv = styled.div`
  margin-top: 17px;
  margin-left: 15px;
  width: 304px;
  height: 78px;
  border-radius: 5px;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
  animation: ${Flicker} ${({ error }) => (error ? '1s forwards' : 'null')};
  animation-iteration-count: 2;
`;

const ButtonDiv = styled.div`
  display: flex;
  padding: 20px 15px 10px 15px;
  justify-content: flex-end;
`;

const ButtonFlex = styled.div`
  display: flex;

  @media (max-width: 550px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Button = styled.button`
  background: ${(props) => (props.Reset ? '#888' : '#FD980F')};
  color: #fff;
  border-width: 0px;
  font-size: 0.8125rem;
  height: 32px;
  width: 130px;
  padding: 6px;

  &:hover {
    background: #6c6c6c;
  }
`;

const ContentInput = styled(TextareaAutosize)`
  width: 100%;
  border-color: ${({ error }) => (error ? '#E56E5A' : '#8888')};
  padding-left: 5px;
  color: #666;
  outline: none;
  font-size: 0.8125rem;
  height: 110px;
`;

const ErrorMsg = styled.span`
  color: #e56e5a;
  font-size: 13px;
`;

const FormBox = ({ setPopupShow }) => {
  const { t, i18n } = useTranslation();
  const { BusinessEnquiryAPI, isLoading } = useCsForm();
  const { control, handleSubmit, register, errors, setValue, getValues, reset } = useForm();
  const onSubmit = (e) => {
    BusinessEnquiryAPI(e);
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    });
  }, [i18n.language]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <MainWidth>
            <TitleDiv>
              <TitleRow>
                <Title>{t('CsList.LeaveMsg')}</Title>
              </TitleRow>
              <LevMsgRow>
                <SubTitle>{t('CsList.ContactInf')}</SubTitle>
              </LevMsgRow>
            </TitleDiv>

            <ItemFlex>
              <ItemRow>
                <ItemDiv>
                  <Item error={errors.name}>{t('CsList.Name')}</Item>
                </ItemDiv>
                <InputDiv>
                  <TextInput
                    name="name"
                    ref={register({ required: 'Required' })}
                    error={errors.name}
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <ErrorMsg>{t('CsList.NameError')}</ErrorMsg>
                  )}
                </InputDiv>
              </ItemRow>
              <ItemRow>
                <ItemDiv>
                  <Item error={errors.email}>{t('CsList.Email')}</Item>
                </ItemDiv>
                <InputDiv>
                  <TextInput
                    name="email"
                    ref={register({
                      required: 'Required',
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    })}
                    error={errors.email}
                  />
                  {errors.email && <ErrorMsg>{t('CsList.EmailError')}</ErrorMsg>}
                </InputDiv>
              </ItemRow>
            </ItemFlex>

            <ItemFlex>
              <ItemRow>
                <ItemDiv>
                  <Item error={errors.phone}>{t('CsList.ContactNo')}</Item>
                </ItemDiv>
                <InputDiv>
                  <TextInput
                    name="phone"
                    ref={register({
                      required: 'Required',
                      // pattern: /^1[0-9]{10}$|^[456789][0-9]{7}$/,
                      minLength: 8,
                      maxLength: 8,
                    })}
                    error={errors.phone}
                  />
                  {errors.phone && <ErrorMsg>{t('CsList.PhoneError')}</ErrorMsg>}
                </InputDiv>
              </ItemRow>
              <ItemRow>
                <ItemDiv>
                  <Item error={errors.companyName}>{t('CsList.CompanyName')}</Item>
                </ItemDiv>
                <InputDiv>
                  <TextInput
                    name="companyName"
                    ref={register({
                      required: 'Required',
                    })}
                    error={errors.companyName}
                  />
                  {errors.companyName && errors.companyName.type === 'required' && (
                    <ErrorMsg>{t('CsList.CompanyNameError')}</ErrorMsg>
                  )}
                </InputDiv>
              </ItemRow>
            </ItemFlex>

            <DividerDiv>
              <Divider />
            </DividerDiv>

            <SubTitleDiv>
              <SubTitle>{t('CsList.YourMsg')}</SubTitle>
            </SubTitleDiv>

            <ItemFlex Bottom>
              <ItemRow>
                <ItemDiv>
                  <Item error={errors.subject}>{t('CsList.Subject')}</Item>
                </ItemDiv>
                <InputDiv>
                  <TextInput
                    name="subject"
                    ref={register({ required: 'Required' })}
                    error={errors.subject}
                  />
                  {errors.subject && errors.subject.type === 'required' && (
                    <ErrorMsg>{t('CsList.SubjectError')}</ErrorMsg>
                  )}
                </InputDiv>
              </ItemRow>

              <ItemRow Bottom>
                <ItemDiv>
                  <Item error={errors.content}>{t('CsList.Msg')}</Item>
                </ItemDiv>
                <InputDiv Bottom>
                  <ContentInput
                    rowsMin={5.5}
                    name="content"
                    ref={register({ required: 'Required' })}
                    error={errors.content}
                  />
                  {errors.content && errors.content.type === 'required' && (
                    <ErrorMsg>{t('CsList.ContentError')}</ErrorMsg>
                  )}
                </InputDiv>
              </ItemRow>
            </ItemFlex>

            <CheckBoxDiv>
              <div>
                <Controller
                  as={Checkbox}
                  shape="curve"
                  color="warning-o"
                  animation="smooth"
                  name="isAgree"
                  control={control}
                  defaultValue={false}
                  rules={{ validate: () => getValues('isAgree') }}
                  error={errors.isAgree}
                />
              </div>

              <CheckBoxText error={errors.isAgree}>
                {t('CsList.Agree')}
                <PolicyA href="#" onClick={() => setPopupShow('PrivacyPolicy')}>
                  {' '}
                  {t('CsList.Policy')}
                  <br />
                </PolicyA>
              </CheckBoxText>
            </CheckBoxDiv>

            <RechapchaDiv error={errors.Reaptcha}>
              {show && (
                <Controller
                  as={Reaptcha}
                  name="Reaptcha"
                  control={control}
                  sitekey="6LfPFksUAAAAAIcM2UKLjQ6X7mJOHIhGBUY1_PHf"
                  onVerify={() => setValue('Reaptcha', true, { shouldValidate: true })}
                  defaultValue={false}
                  hl={i18n.language === 'zh' ? 'zh-hk' : 'en'}
                  rules={{
                    validate: () => getValues('Reaptcha'),
                  }}
                />
              )}
            </RechapchaDiv>

            <ButtonDiv>
              <ButtonFlex>
                <Button Reset type="button" onClick={() => reset('')}>
                  {t('CsList.Reset')}
                </Button>
                <div style={{ marginLeft: 30 }} />
                <Button disabled={isLoading} type="submit">
                  {t('CsList.Submit')}
                </Button>
              </ButtonFlex>
            </ButtonDiv>
          </MainWidth>
        </Container>
      </form>
    </>
  );
};

export default FormBox;
