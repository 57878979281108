import React, { useEffect } from 'react';
import Script from 'react-load-script';
import { useLocation } from 'react-router-dom';

const AddThis = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (window.addthis) {
      window.addthis.update('share', 'url', props.url);
    }
  }, [props, pathname]);

  const handleAddthisLoaded = () => {
    if (!window.addthis) {
      return;
    }

    window.addthis.init();
    window.addthis.update('share', 'url', props.url);
  };

  return (
    <>
      <Script
        url="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5ffe6107d77d998c"
        onLoad={handleAddthisLoaded}
      />
    </>
  );
};

export default AddThis;
