import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ESTABLISHED from '../../../media/images/ESTABLISHED.png';
import MANAGE from '../../../media/images/MANAGE.png';
import OPERATE from '../../../media/images/OPERATE.png';
import {
  Container,
  Width,
  TitleDiv,
  Title,
  SubTextDiv,
  SubText,
  ImgContainer,
  ImgBoxFlex,
  ImgBox1,
  ImgBoxWidth,
  PromoImg,
  ImgTextDiv,
  ImgText1,
  ImgText2,
  ImgBox2,
  ImgBox3,
} from './style';

const url = `${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}`;

const TopRow = ({ data }) => {
  const { t } = useTranslation();

  const {
    description,
    image1,
    image2,
    image3,
    image1_description,
    image2_description,
    image3_description,
  } = data || {};

  return (
    <>
      <Container>
        <Width>
          <TitleDiv>
            <Title>{t('Our_company.Our_company')}</Title>
          </TitleDiv>
          <SubTextDiv>
            <SubText>{description}</SubText>
          </SubTextDiv>
          <ImgContainer>
            <ImgBoxFlex>
              <ImgBox1>
                <ImgBoxWidth>
                  <PromoImg src={`${url}${image1}`} />
                </ImgBoxWidth>
                <ImgTextDiv>
                  <ImgText1 dangerouslySetInnerHTML={{ __html: image1_description }}>
                    {/* {t('Our_company.ESTABLISHED')}
                    <br />
                    <ImgText2>1983</ImgText2> */}
                  </ImgText1>
                </ImgTextDiv>
              </ImgBox1>
            </ImgBoxFlex>
            <ImgBoxFlex>
              <ImgBox2>
                <ImgBoxWidth>
                  <PromoImg src={`${url}${image2}`} />
                </ImgBoxWidth>
                <ImgTextDiv>
                  <ImgText1 dangerouslySetInnerHTML={{ __html: image2_description }}>
                    {/* {t('Our_company.MANAGE')}
                    <br />
                    <ImgText2>100,000+</ImgText2>
                    <br />
                    {t('Our_company.BAYS')} */}
                  </ImgText1>
                </ImgTextDiv>
              </ImgBox2>
            </ImgBoxFlex>
            <ImgBoxFlex>
              <ImgBox3>
                <ImgBoxWidth>
                  <PromoImg src={`${url}${image3}`} />
                </ImgBoxWidth>
                <ImgTextDiv>
                  <ImgText1 dangerouslySetInnerHTML={{ __html: image3_description }}>
                    {/* {t('Our_company.OPERATE')}
                    <br />
                    <ImgText2>400</ImgText2>
                    <br />
                    {t('Our_company.CARS_PARKS')} */}
                  </ImgText1>
                </ImgTextDiv>
              </ImgBox3>
            </ImgBoxFlex>
          </ImgContainer>
        </Width>
      </Container>
    </>
  );
};

export default TopRow;
