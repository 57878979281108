import styled from 'styled-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

// TODO: responsive?

const Container = styled.div`
  display: flex;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  flex-direction: row;
  font-weight: bold;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const Title = styled.span`
  font-size: 13px;
  display: flex;
  color: ${({ error, isEmailValid }) => (error || isEmailValid ? '#E56E5A' : '#666')};
`;

const Star = styled.span`
  color: #fd980f;
  display: flex;
  font-size: 75%;
  margin-top: -0.25rem;
`;

function FieldTitle({ required, title, name, isEmailValid }) {
  const { errors } = useFormContext();
  const error = errors[name];
  return (
    <Container>
      <Title error={error} name={name} isEmailValid={isEmailValid}>
        {title}
      </Title>
      {required && <Star>*</Star>}
    </Container>
  );
}

export default FieldTitle;
