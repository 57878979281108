import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import StyledLink from './StyledLink';

export default function Example({ show, onClose, next, isLoading }) {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onClose} backdrop={false} dialogClassName="ConfirmModal">
      <Modal.Body>{t('Cart.ConfirmChange')}</Modal.Body>
      <Modal.Footer>
        <StyledLink color="grey" onClick={onClose} label={t('Cart.Back')} />
        <StyledLink
          color="yellow"
          onClick={next}
          label={t('Cart.Confirm')}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
