/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { Marker } from '@react-google-maps/api';
import * as icon from '../../../media/icons';
import DesktopInfoDisplay from './DesktopInfoDisplay';

export default function MarkerView({ mapRef, marker, selectedMarker, setSelectedMarker }) {
  const { id, isIMonthly = false, coordinates } = marker;
  const { latitude, longitude } = coordinates || {};
  const [onHover, setOnHover] = useState(false);

  return (
    <Marker
      opacity={onHover ? 0.8 : 1}
      onMouseOut={() => setOnHover(false)}
      onMouseOver={() => setOnHover(true)}
      zIndex={onHover || selectedMarker?.id === marker?.id ? 11 : 10}
      animation={(selectedMarker?.id === marker?.id && 1) || (selectedMarker === null && 2)}
      key={id}
      onClick={() => {
        setSelectedMarker(marker);
        setTimeout(() => {
          if (mapRef) {
            mapRef.panTo({
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            });
          }
        }, 0);
      }}
      position={{
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      }}
      icon={{
        // url: isIMonthly ? icon.markerBlue : icon.markerRed,
        url: icon.markerRed,
        scaledSize: {
          width: 28,
          height: 34,
          widthUnit: 'px',
          heightUnit: 'px',
        },
      }}
    >
      {selectedMarker?.id === marker?.id && (
        <DesktopInfoDisplay marker={marker} onMarkerClick={(data) => setSelectedMarker(data)} />
      )}
    </Marker>
  );
}
