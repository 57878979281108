import Carousel from 'react-bootstrap/Carousel';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Carousel.css';
import moment from 'moment';
import useBanner from '../../models/Banner';
import useEncrypt from '../../hooks/useEncrypt';
import { TokenContext } from '../../models/Customer';
import useWilRegistration from '../../models/WilRegistration';

function Carousell({ bookingDetail }) {
  const { i18n } = useTranslation();
  const { id: bookingId, reference } = bookingDetail || {};
  const { token } = useContext(TokenContext);
  const { parseUrl } = useEncrypt();
  const { banners } = useBanner({ bannerType: 'payment_link' });

  const [index, setIndex] = React.useState(0);
  const { dataFromWil, getWilInfo } = useWilRegistration();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (!token && bookingDetail?.id) {
      getWilInfo(bookingId, reference, moment().valueOf());
    }
  }, [token, bookingDetail]);

  function getParsedUrl(url, timestamp) {
    if (token) {
      return parseUrl(
        url,
        {
          token,
          timestamp,
        },
        process.env.REACT_APP_SECRET
      );
    }
    if (dataFromWil?.token) {
      return parseUrl(
        url,
        {
          token: dataFromWil?.token,
          timestamp,
        },
        process.env.REACT_APP_SECRET
      );
    }

    return url;
  }

  return (
    <Carousel id="paymentLinkBanner" activeIndex={index} onSelect={handleSelect} controls={false}>
      {banners.map(({ chineseImage, chineseTitle, chineseUrlLink, image, id, title, urlLink }) => {
        const url = i18n.language === 'en' ? urlLink : chineseUrlLink;
        const parsedUrl = getParsedUrl(url, moment().valueOf());
        return (
          <Carousel.Item key={id}>
            <a
              onClick={(event) => {
                if (url === '#') {
                  event.preventDefault();
                }
              }}
              style={{ cursor: url !== '#' ? 'pointer' : 'default' }}
              href={parsedUrl}
              target={url !== '#' && '_blank'}
              rel="noreferrer"
            >
              <img
                src={
                  i18n.language === 'en'
                    ? `${process.env.REACT_APP_CP_MEDIA_DOMAIN}${image}`
                    : `${process.env.REACT_APP_CP_MEDIA_DOMAIN}${chineseImage}`
                }
                alt={i18n.language === 'en' ? title : chineseTitle}
                style={{ width: '100%', maxHeight: 500, objectFit: 'contain' }}
              />
            </a>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default Carousell;
