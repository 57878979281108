import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import useWaitingList from '../../../../models/WaitingList';
import Bottombar from '../Bottombar';
import WaitingListItem from './WaitingListItem';
import WaitingListModal from '../../../CarPark/Components/WaitingListModal';

const Container = styled.div`
  background: #fff;
  border-top: 1px solid #ccc;
`;

const WaitingList = () => {
  const { t, i18n } = useTranslation();

  const {
    waitingList,
    totalPage,
    waitingListLoading,
    cancelWaitingListStatus,
    changePage,
    page,
    editWaitingList,
  } = useWaitingList();

  const [showWaitingListModal, setShowWaitingModal] = useState({ show: false, data: {} });

  function changeLPN(ParkingPlanId, CarTypeId, waitingListId, status, CarParkId) {
    setShowWaitingModal({
      show: true,
      data: {
        ParkingPlanId,
        CarTypeId,
        waitingListId,
        status,
        CarParkId,
      },
    });
  }

  return (
    <>
      {waitingListLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
          <ReactLoading type="spokes" color="#fd980f" height={30} width={30} />
        </div>
      ) : (
        <Container>
          <>
            {waitingList
              .filter((e) => {
                return e.status === 'confirmed';
              })
              .sort((a, b) => b.reference - a.reference)
              .map((record, index) => {
                return (
                  <WaitingListItem
                    pageNumber={page}
                    key={record.id}
                    index={index}
                    record={record}
                    cancelWaitingListStatus={cancelWaitingListStatus}
                    changeLPN={changeLPN}
                  />
                );
              })}
            <Bottombar page={page} setPage={changePage} totalPage={totalPage} />
          </>

          <WaitingListModal
            show={showWaitingListModal.show}
            setShow={setShowWaitingModal}
            data={showWaitingListModal.data}
            onConfirm={async (CarId, ParkingPlanId, handleClose) => {
              await editWaitingList(
                showWaitingListModal.data.waitingListId,
                { CarId },
                handleClose
              );
            }}
            waitingList={waitingList}
            title={t('MyAccount.WaitingList.changeVehicleTitle')}
            buttonText={t('MyAccount.WaitingList.changeVehicle')}
          />
        </Container>
      )}
    </>
  );
};

export default WaitingList;
