import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MainContainer = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: block;
  }
`;

const LinksContainer = styled.div`
  background: #e3e3e3;
  padding: 14px 15px;
  display: flex;
  justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
`;

const BackToMap = styled(Link)`
  background: #888;
  color: #fff;
  border: 1px solid #fff;
  padding: 6px;
  outline: none;
  font-size: 0.8rem;
  transition: all 0.2s ease-out;
  &:hover {
    color: #fff;
    background: #666;
    text-decoration: none;
  }
`;

const LinkButton = styled.a`
  background: ${(props) => props.background};
  display: flex;
  width: 45px;
  height: 45px;
  line-height: 42px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const BreakLine = styled.hr`
  width: 100%;
  height: 1px;
  color: #bbb;
  margin: 0;
`;

const Icon = styled.img`
  margin: 11px 0;
  width: auto;
  height: ${(props) => `${props.height}px`};
`;

const whatappUrl = 'https://www.wilsonparking.com.hk/img/icon-share_whatsapp.png';
const facebookUrl = 'https://www.wilsonparking.com.hk/img/icon-share_fb.png';
const linkUrl = 'https://www.wilsonparking.com.hk/img/icon-share_link.png';
const emailUrl = 'https://www.wilsonparking.com.hk/img/icon-share_email.png';

function ShareLinks({ carParkId }) {
  const { t, i18n } = useTranslation();
  return (
    <MainContainer>
      <LinksContainer>
        <BackToMap to={`${i18n.language}/car_parks/map`}>{t('CarParkDetail.BackToMap')}</BackToMap>
      </LinksContainer>
      <div style={{ background: '#e3e3e3' }}>
        <BreakLine />
      </div>
      <LinksContainer centered>
        <LinkButton background="#01c854" className="addthis_button_whatsapp" href="#">
          <Icon src={whatappUrl} alt="link" height="20" />
        </LinkButton>
        <LinkButton
          background="#3b5998"
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=http://wpnewwebstoragesstaging.z7.web.core.windows.net/car_parks/detail/${carParkId}`}
        >
          <Icon src={facebookUrl} alt="facebook" height="20" />
        </LinkButton>
        <LinkButton background="#55acee" className="addthis_button_link " href="#">
          <Icon src={linkUrl} alt="link" height="20" />
        </LinkButton>
        <LinkButton background="#738a8d" className="addthis_button_email" href="#">
          <Icon src={emailUrl} alt="email" height="12" />
        </LinkButton>
      </LinksContainer>
    </MainContainer>
  );
}

export default ShareLinks;
