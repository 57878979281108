import styled from 'styled-components';
import React from 'react';

const YellowButton = styled.button`
  background-color: ${(props) =>
    (props.color === 'yellow' && '#fd980f') ||
    (props.color === 'white' && '#fff') ||
    (props.color === 'grey' && '#888')};
  font-size: 1rem;
  border: ${(props) =>
    (props.color === 'yellow' && 'none') ||
    (props.color === 'white' && '1px solid #fd980f') ||
    (props.color === 'grey' && 'none')};
  -webkit-flex: 1;
  flex: 1;
  min-height: 30px;
  transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  max-width: 120px;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 0px 10px;
  width: 100%;
  height: 100%;
  display: flex;
  text-transform: uppercase;

  &:hover {
    background: ${(props) =>
      (props.color === 'yellow' && '#666') ||
      (props.color === 'white' && '#fef1e5') ||
      (props.color === 'grey' && '#666')};
    color: ${(props) =>
      (props.color === 'yellow' && '#fff') ||
      (props.color === 'white' && 'rgb(253, 152, 15)') ||
      (props.color === 'grey' && '#fff')};
    text-decoration: none;
  }

  &:disabled {
    background-color: #bbb;
    cursor: no-drop;
    color: #fff;
    border: 1px solid #fff;

    &:hover {
      color: #fff;
    }
  }

  a[disabled] {
    cursor: no-drop;
  }

  @media (max-width: 760px) {
    max-width: 100%;
    margin: ${(props) => (props.widthoutMargin ? '10px 0px 0px 0px' : '0px 20px 0px 20px')};
    margin-left: ${(props) => props.left && '10px'};
  }

  color: ${(props) =>
    (props.color === 'yellow' && '#fff') ||
    (props.color === 'white' && '#fd980f') ||
    (props.color === 'grey' && '#fff')};

  &:hover {
    text-decoration: none;
    color: ${(props) =>
      (props.color === 'yellow' && '#fff') ||
      (props.color === 'white' && '#fd980f') ||
      (props.color === 'grey' && '#fff')};
  }
`;

export default function StyledButton({
  label,
  color,
  onClick,
  style,
  widthoutMargin,
  disabled,
  type,
  children,
  left,
}) {
  return (
    <YellowButton
      color={color}
      onClick={onClick}
      style={style}
      widthoutMargin={widthoutMargin}
      disabled={disabled}
      type={type}
      left
    >
      {label}
      {children}
    </YellowButton>
  );
}
