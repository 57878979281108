import React, { useEffect, useState } from 'react';
import Reaptcha from 'reaptcha';
import '../../../components/General/Checkbox/style.scss';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from '../../../components/General/Checkbox/index';
import { useCsForm } from '../../../models/CS';
import {
  FormBoxContainer,
  MainWidth,
  TitleDiv,
  TitleRow,
  LeaveMsg,
  LevMsgRow,
  SubTitle,
  ItemFlex,
  ItemRow,
  ItemDiv,
  Item,
  InputDiv,
  TextInput,
  ErrorMsg,
  DividerDiv,
  Divider,
  SubTitleDiv,
  ContentInput,
  CheckBoxDiv,
  CheckBoxText,
  PolicyA,
  RechapchaDiv,
  ButtonDiv,
  ButtonFlex,
  Button,
} from './style';

const FormBox = ({ setPopupShow }) => {
  const { t, i18n } = useTranslation();
  const { CustomerServiceAPI, isLoading } = useCsForm();
  const { control, handleSubmit, register, errors, setValue, getValues, reset } = useForm();
  const onSubmit = (e) => {
    CustomerServiceAPI(e);
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    });
  }, [i18n.language]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBoxContainer>
        <MainWidth>
          <TitleDiv>
            <TitleRow>
              <LeaveMsg>{t('CsList.LeaveMsg')}</LeaveMsg>
            </TitleRow>
            <LevMsgRow>
              <SubTitle>{t('CsList.ContactInf')}</SubTitle>
            </LevMsgRow>
          </TitleDiv>
          <ItemFlex>
            <ItemRow>
              <ItemDiv>
                <Item error={errors.name}>{t('CsList.Name')}</Item>
              </ItemDiv>
              <InputDiv>
                <TextInput
                  name="name"
                  ref={register({
                    required: 'Required',
                  })}
                  error={errors.name}
                />
                {errors.name && errors.name.type === 'required' && (
                  <ErrorMsg>{t('CsList.NameError')}</ErrorMsg>
                )}
              </InputDiv>
            </ItemRow>
            <ItemRow>
              <ItemDiv>
                <Item error={errors.email}>{t('CsList.Email')}</Item>
              </ItemDiv>
              <InputDiv>
                <TextInput
                  name="email"
                  ref={register({
                    required: 'Required',
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  })}
                  error={errors.email}
                />
                {errors.email && <ErrorMsg>{t('CsList.EmailError')}</ErrorMsg>}
              </InputDiv>
            </ItemRow>
          </ItemFlex>

          <ItemFlex>
            <ItemRow>
              <ItemDiv>
                <Item error={errors.phone}>{t('CsList.ContactNo')}</Item>
              </ItemDiv>
              <InputDiv>
                <TextInput
                  name="phone"
                  ref={register({
                    required: 'Required',
                    // pattern: /^1[0-9]{10}$|^[456789][0-9]{7}$/,
                    minLength: 8,
                    maxLength: 8,
                  })}
                  error={errors.phone}
                />
                {errors.phone && <ErrorMsg>{t('CsList.PhoneError')}</ErrorMsg>}
              </InputDiv>
            </ItemRow>
          </ItemFlex>

          <DividerDiv>
            <Divider />
          </DividerDiv>

          <SubTitleDiv>
            <SubTitle>{t('CsList.YourMsg')}</SubTitle>
          </SubTitleDiv>

          <ItemFlex Bottom>
            <ItemRow>
              <ItemDiv>
                <Item error={errors.subject}>{t('CsList.Subject')}</Item>
              </ItemDiv>
              <InputDiv>
                <TextInput
                  name="subject"
                  ref={register({
                    required: 'Required',
                  })}
                  error={errors.subject}
                />
                {errors.subject && errors.subject.type === 'required' && (
                  <ErrorMsg>{t('CsList.SubjectError')}</ErrorMsg>
                )}
              </InputDiv>
            </ItemRow>

            <ItemRow Bottom>
              <ItemDiv>
                <Item error={errors.content}>{t('CsList.Msg')}</Item>
              </ItemDiv>
              <InputDiv Bottom>
                <ContentInput
                  rowsMin={5.5}
                  name="content"
                  ref={register({
                    required: 'Required',
                  })}
                  error={errors.content}
                />
                {errors.content && errors.content.type === 'required' && (
                  <ErrorMsg>{t('CsList.ContentError')}</ErrorMsg>
                )}
              </InputDiv>
            </ItemRow>
          </ItemFlex>

          <CheckBoxDiv>
            <div>
              <Controller
                as={Checkbox}
                shape="curve"
                color="warning-o"
                animation="smooth"
                name="isAgree"
                control={control}
                defaultValue={false}
                rules={{ validate: () => getValues('isAgree') }}
                error={errors.isAgree}
              />
            </div>

            <CheckBoxText error={errors.isAgree}>
              {t('CsList.Agree')}
              <PolicyA href="#" onClick={() => setPopupShow('PrivacyPolicy')}>
                {' '}
                {t('CsList.Policy')}
                <br />
              </PolicyA>
            </CheckBoxText>
          </CheckBoxDiv>

          <RechapchaDiv error={errors.Reaptcha}>
            {show && (
              <Controller
                as={Reaptcha}
                name="Reaptcha"
                control={control}
                sitekey="6LfPFksUAAAAAIcM2UKLjQ6X7mJOHIhGBUY1_PHf"
                onVerify={() => setValue('Reaptcha', true, { shouldValidate: true })}
                defaultValue={false}
                hl={i18n.language === 'zh' ? 'zh-hk' : 'en'}
                rules={{
                  validate: () => getValues('Reaptcha'),
                }}
              />
            )}
          </RechapchaDiv>

          <ButtonDiv>
            <ButtonFlex>
              <Button Reset type="button" onClick={() => reset('')}>
                {t('CsList.Reset')}
              </Button>
              <div style={{ marginLeft: 30 }} />
              <Button disabled={isLoading} type="submit">
                {t('CsList.Submit')}
              </Button>
            </ButtonFlex>
          </ButtonDiv>
        </MainWidth>
      </FormBoxContainer>
    </form>
  );
};

export default FormBox;
