/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';

const StyledButton = styled.input`
  color: #fff;
  font-size: 12px;
  background: ${({ disabled, gray }) => (disabled ? '#AAA' : gray ? '#888' : '#FD980F')};
  border: 0;
  padding: ${(props) => (props.bottom ? '5px' : null)};
  width: ${(props) => (props.bottom ? '120px' : null)};
  height: ${(props) => (props.bottom ? '35px' : null)};

  &:hover:enabled {
    background: ${({ gray }) => (gray ? '#666' : '#888')};
    color: #fff;
  }

  &:focus:enabled {
    color: #fff;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 850px) {
    margin-top: ${(props) => (props.bottom ? null : '20px')};
    height: ${(props) => (props.bottom ? '35px' : '30px')};
    margin-bottom: ${(props) => (props.gray ? '30px' : null)};
    margin-bottom: ${(props) => (props.bottom ? '0px' : null)};
  }
`;

function Button({ Title, gray, bottom, submit, onClick, disabled, style, children }) {
  return (
    <StyledButton
      name={Title}
      type={submit ? 'submit' : 'button'}
      value={Title}
      gray={gray}
      bottom={bottom}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
