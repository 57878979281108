import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import commonStyle from '../../styles/common';
import * as icon from '../../media/icons';

const CartHeaderContainer = styled.div`
  width: 100%;
  background: #fd980f;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 80px;

  @media (max-width: 670px) {
    padding: 10px 20px 10px 30px;
  }
`;

const CartHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1024px;
  padding: 0 10px;
`;

const ProgressBarContainer = styled.div`
  background: #f8f8f8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 146px;
  -webkit-box-shadow: 0 0 2px #bbb;
  -o-box-shadow: 0 0 2px #bbb;
  -ms-box-shadow: 0 0 2px #bbb;
  box-shadow: 0 0 2px #bbb;

  @media (max-width: 760px) {
    height: 100px;
  }

  @media (max-width: 570px) {
    height: 75px;
  }
`;

const StepRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 1024px;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #f7f7f7;
    margin-top: -11px;
    margin-left: -10px;
    -webkit-box-shadow: 2px 2px 2px #dedede;
    -o-box-shadow: 2px 2px 2px #dedede;
    -ms-box-shadow: 2px 2px 2px #dedede;
    box-shadow: 2px 2px 2px #dedede;
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

    @media (max-width: 570px) {
      content: '';
    }
  }

  &:before {
    position: absolute;
    z-index: 1;
    top: 60px;
    left: 50%;
    display: inline-block;
    width: ${({ totalStep }) => (totalStep > 2 ? '75%' : '50%')};
    margin: 0 auto;
    content: '';
    padding-top: 2px;
    background-color: #bbb;
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    @media (max-width: 760px) {
      top: 30px;
      width: 70%;
      width: ${({ totalStep }) => (totalStep > 2 ? '70%' : '50%')};
    }

    @media (max-width: 570px) {
      padding-top: 1px;
      background-color: #000;
    }
  }
`;

const HeaderLink = styled(Link)`
  color: white;
  transition: 0.2s;
  position: relative;
  padding: 0 35px 0 15px;

  &:hover {
    color: #444;
    text-decoration: none;

    &:after {
      background: #e3890e url(${icon.nextwhite}) no-repeat center;
      background-size: auto 55%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 4px;
    transition: inherit;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    background: #fff url(${icon.nextOrange}) no-repeat center;
    background-size: auto 55%;
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

const InnerView = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const StepBox = styled.li`
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 25%;
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;
  vertical-align: top;
  flex: 0 0 25%;
  max-width: 25%;

  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    display: ${({ isCurrentStep }) => (isCurrentStep ? 'inline-block' : 'none')};
    width: 40px;
    height: 40px;
    background-color: #f7f7f7;
    content: '';
    margin-top: -21px;
    margin-left: -20px;
    -webkit-box-shadow: 2px 2px 2px #dedede;
    -o-box-shadow: 2px 2px 2px #dedede;
    -ms-box-shadow: 2px 2px 2px #dedede;
    box-shadow: 2px 2px 2px #dedede;
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;

    @media (max-width: 570px) {
      display: none;
    }
  }

  @media (max-width: 760px) {
    padding: 15px 0;
  }

  @media (max-width: 570px) {
    padding: 15px 0 30px 0;
    margin-top: 4px;
  }
`;

const StepNumber = styled.span`
  color: #fff;
  background-color: ${(props) => (props.isCurrentStep ? '#fd980f' : '#666')};
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 60px;
  height: 60px;
  font-family: monospace, FreeMono, Courier;
  font-size: 2rem;
  line-height: 60px;
  border-left: 1.3rem solid #f7f7f7;
  border-right: 1.3rem solid #f7f7f7;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-background-clip: padding-box;
  -ms-background-clip: padding-box;
  background-clip: padding-box;

  @media (max-width: 760px) {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    line-height: 40px;
    margin-top: -4px;
  }

  @media (max-width: 570px) {
    width: 30px;
    height: 30px;
    font-size: 1rem;
    line-height: 30px;
    margin-top: -4px;
    border-left: 5px solid #f7f7f7;
    border-right: 5px solid #f7f7f7;
  }
`;

const StepLabel = styled.span`
  color: ${(props) => (props.isCurrentStep ? '#fd980f' : '#666')};
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;

  @media (max-width: 760px) {
    font-size: 0.75rem;
  }

  @media (max-width: 570px) {
    display: none;
  }
`;

const MobileLabelView = styled.li`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;

  @media (max-width: 570px) {
    display: inline-block;
  }
`;

const MobileLabel = styled.p`
  width: 100%;
  padding: 0;
  color: #444;
  font-size: 0.8rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const ProgressBar = ({ title, currentStep, stepDisplay }) => {
  const currentLabel = stepDisplay[currentStep - 1]?.label;
  return (
    <>
      <CartHeader title={title} />

      <ProgressBarContainer>
        <StepRow totalStep={stepDisplay?.length}>
          <InnerView>
            {stepDisplay.map((item, index) => {
              const isCurrentStep = currentStep === item.step;
              const boxLabel = stepDisplay[index].label;
              return (
                <StepBox key={item.step} isCurrentStep={isCurrentStep}>
                  <StepNumber isCurrentStep={isCurrentStep}>{item.step}</StepNumber>
                  <StepLabel isCurrentStep={isCurrentStep}>{boxLabel}</StepLabel>
                </StepBox>
              );
            })}
            <MobileLabelView>
              <MobileLabel>{currentLabel}</MobileLabel>
            </MobileLabelView>
          </InnerView>
        </StepRow>
      </ProgressBarContainer>
    </>
  );
};

export default ProgressBar;

const CartHeader = ({ title }) => {
  const { t } = useTranslation();

  return (
    <CartHeaderContainer>
      <CartHeaderContent>
        <h2 style={{ marginTop: 10 }}>{title}</h2>

        <div style={{ display: 'flex' }}>
          <HeaderLink to="/imonthly-tutorial-and-faq">
            <span>{t('Cart.ProgressBar.Tutorial')}</span>
          </HeaderLink>

          <div style={commonStyle.verticalLine} />

          <HeaderLink to="/customer_enquiries/index">
            <span>{t('Cart.ProgressBar.ContactUs')}</span>
          </HeaderLink>
        </div>
      </CartHeaderContent>
    </CartHeaderContainer>
  );
};
