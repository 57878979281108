import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: rgb(235, 235, 235);
`;

const Inner = styled.div`
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  max-width: 1024px;
  flex-direction: column;
  align-items: center;
  .alice-carousel {
    margin-top: -180px;

    @media (max-width: 850px) {
      margin-top: -250px;
    }

    .alice-carousel__stage-item {
      display: ${({ items }) => (items < 2 ? 'flex' : 'inline-block')};
      justify-content: center;
    }
  }

  .alice-carousel__prev-btn {
    position: absolute;
    top: 35%;
    left: 0;
    width: auto;
    display: ${({ items }) => items < 3 && 'none'};

    @media (max-width: 850px) {
      display: flex;
    }
  }

  .alice-carousel__next-btn {
    position: absolute;
    top: 35%;
    right: 0;
    width: auto;
    display: ${({ items }) => items < 3 && 'none'};

    @media (max-width: 850px) {
      display: flex;
    }
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    font-size: 60px;
    color: #fd980f;
  }
`;

const Column = styled.div`
  min-width: 320px;
  padding: 10px 15px 20px 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 20px;
  max-width: 400px;

  @media (max-width: 850px) {
    max-width: 100%;
  }
`;

const Text = styled.p`
  font-size: ${({ fontsize }) => fontsize};
  color: rgb(50, 50, 50);
  margin-top: ${({ margintop }) => margintop};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  text-align: center;
`;

const UploadButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;

  .reactLoading {
    margin-top: 10px;
  }
`;

const UploadView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonRowDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const MatchedDiv = styled.div`
  display: flex;
  align-items: center;

  span {
    color: rgba(46, 204, 113);
  }
  img {
    width: 20px;
    height: 20px;
  }
`;

const FPSRemarkDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px 1px 5px 1px;
  margin-bottom: 10px;

  & > p {
    line-height: 1.3;
    margin: 0;
  }
`;

export {
  Container,
  Inner,
  Column,
  Text,
  UploadButton,
  UploadView,
  ButtonRowDiv,
  MatchedDiv,
  FPSRemarkDiv,
};
