import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useParams } from 'react-router-dom';
import { Container, Inner, Column, Text } from './containers/style';
import 'react-alice-carousel/lib/alice-carousel.css';
import sevenIcon from '../../assets/images/sevenIcon.jpg';
import { useGetQrCode } from '../../models/Customer';

function QrCode() {
  const { accountCode } = useParams();
  const { t } = useTranslation();
  const { GetQrCode, QrCodeData } = useGetQrCode(accountCode);

  const { img } = QrCodeData || {};

  useEffect(() => {
    if (accountCode) {
      GetQrCode();
    }
  }, [accountCode]);


  return (
    <Container>
      <div style={{ backgroundColor: '#fd980f', height: "30%", width: '100%', position: 'absolute' }} />
      <Inner>
        <Column className="item">
          <img alt="" src={sevenIcon} style={{ height: 30, width: 30 }} />
          <Text fontsize="20px" margintop="10px" bold>
            {t('sevenPayment.text1')}
          </Text>
         {img &&  <img alt="" src={img?.src} style={{ width: "100%", maxWidth: 256, objectFit: "contain" }} />}
        </Column>
      </Inner>
    </Container>
  );
}

export default withRouter(QrCode);
