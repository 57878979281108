import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import imageEng from '../../media/images/ihourlyEng.jpg';
import imageZh from '../../media/images/ihourlyZh.jpg';
import useStaticInfo from '../../models/StaticInfo';

const TitleContainer = styled.div`
  background: #fd980f;
  display: flex;
  justify-content: center;
  padding-left: 30px;

  @media (max-width: 670px) {
    padding: 10px 20px 10px 30px;
  }
`;

const TitleDiv = styled.div`
  width: 1024px;
`;

const Title = styled.span`
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  margin-left: -3px;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 670px) {
    line-height: 40px;
  }
`;

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const MainWidth = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  width: 1024px;
  box-shadow: 0 0 2px #bbb;

  p {
    font-size: 0.875rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    color: #fd980f;
    line-height: inherit;
    vertical-align: baseline;
    background: transparent;
  }

  img {
    max-width: 100%;
  }
`;

const PromoImg = styled.img`
  margin-top: 42px;
  max-width: 100%;
  height: auto;
`;

const Answer = styled.span`
  color: #666666;
  font-size: 14px;
`;

const SubTitle = styled.span`
  color: #ff9429;
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 17px;
  margin-left: ${(props) => (props.left ? '-3.88px' : '0px')};
`;

const Qusetion = styled.span`
  font-weight: bold;
  color: #000;
  margin-top: 21px;
  font-size: 14px;
`;

const Href = styled.a`
  color: #fd980f;
  &:hover {
    color: #fd980f;
  }
`;

const Margin = styled.div`
  margin-top: ${(props) => props.top};
`;

const IHourlyFAQ = () => {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo(
    'Pages-i-HOURLY Parking Service Tutorial & FAQ'
  );

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <div>
      <TitleContainer>
        <TitleDiv>
          <Title>{name}</Title>
        </TitleDiv>
      </TitleContainer>

      <MainContainer>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }}>
          {/* <SubTitle>{t('iHourlyFAQ.h1Q')}</SubTitle>
          <Answer>{t('iHourlyFAQ.h1T')}</Answer>

          <PromoImg src={i18n.language === 'en' ? imageEng : imageZh} />
          <Margin top="-2px" />
          <SubTitle left>{t('iHourlyFAQ.h2Q')}</SubTitle>
          <Answer>{t('iHourlyFAQ.h2A1')}</Answer>
          <Answer>{t('iHourlyFAQ.h2A2')}</Answer>
          <Answer>{t('iHourlyFAQ.h2A3')}</Answer>
          <Answer>
            {t('iHourlyFAQ.h2A4')}
            <br />
            <br />
          </Answer>
          <Answer>
            <i>
              <strong>{t('iHourlyFAQ.h2T1')}</strong>
              <br />
              <br />
            </i>
          </Answer>
          <Answer>
            {t('iHourlyFAQ.h2T2')}
            <Href href="mailto:ihourly@wilsonparking.com.hk."> ihourly@wilsonparking.com.hk.</Href>
            {t('iHourlyFAQ.h2T3')}
          </Answer>
          <SubTitle>{t('iHourlyFAQ.FAQ')}</SubTitle>
          <Qusetion>{t('iHourlyFAQ.Q1')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A1')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q2')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A2')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q3')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A3')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q4')}</Qusetion>
          <Answer>
            {t('iHourlyFAQ.A4')}
            <Href href="mailto:ihourly@wilsonparking.com.hk."> ihourly@wilsonparking.com.hk.</Href>
            {t('iHourlyFAQ.A41')}
          </Answer>
          <Qusetion>{t('iHourlyFAQ.Q5')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A5')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q6')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A6')} </Answer>
          <Qusetion>{t('iHourlyFAQ.Q7')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A7')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q8')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A8')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q9')}</Qusetion>
          <Answer>{t('iHourlyFAQ.A9')}</Answer>
          <Qusetion>{t('iHourlyFAQ.Q10')}</Qusetion>
          <Answer>
            {t('iHourlyFAQ.A10')}
            <Href href="mailto:ihourly@wilsonparking.com.hk."> ihourly@wilsonparking.com.hk.</Href>
            {t('iHourlyFAQ.A101')}
          </Answer> */}
        </MainWidth>
      </MainContainer>
    </div>
  );
};
export default withRouter(IHourlyFAQ);
