import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import { useSMSVerify } from '../../models/Customer';
import {
  Container,
  MaxWidth,
  From,
  InputRow,
  InputCol,
  SendSMSRow,
  ButtonRow,
  ErrorMsg,
  Input,
} from './OTP.styles';
import StyledButton from '../../components/General/StyledButton';

const countDownTime = 120;

function OTP() {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const { fetchSkiDataMonthlyParker, SendSms } = useSMSVerify();

  const [timeLeft, setTimeLeft] = useState(countDownTime);
  const [startTimer, setStartTimer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendSMSLoading, setSendSMSLoading] = useState(false);
  const [savedState, setStateData] = useState();

  useEffect(() => {
    setStateData(state);
  }, []);

  const { handleSubmit, register, errors, watch, setError, clearError } = useForm({
    defaultValues: { phone: state?.phone },
  });

  useEffect(() => {
    if (!startTimer) return;
    if (!timeLeft) {
      setStartTimer(false);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, startTimer]);

  function handelSendSms() {
    setTimeLeft(countDownTime);
    if (!watch('phone') || !watch('phone').match(/^1[0-9]{10}$|^[456789][0-9]{7}$/)) {
      setError('phone', {
        exist: t('CsList.PhoneError'),
      });

      return;
    }

    clearError('phone');
    setSendSMSLoading(true);
    SendSms(watch('phone', i18n.language))
      .then(() => {
        setStartTimer(true);
      })
      .catch(() => {
        Swal.fire({
          position: 'center',
          text: t('OTP.unkonwError'),
          confirmButtonColor: '#fd980f',
          confirmButtonText: t('IhourlyCart.Back'),
        });
      })
      .finally(() => setSendSMSLoading(false));
  }

  const onSubmit = async (data) => {
    if (!savedState || Object.values(savedState).length === 0) {
      return;
    }
    setIsLoading(true);
    fetchSkiDataMonthlyParker(data.phone, data.code)
      .then((e) => {
        const userData = { ...savedState, phone: data.phone, VerificationCode: data.code };

        if (e.length === 0) {
          history.push({
            pathname: `/${i18n.language}/customers/registration`,
            state: userData,
          });
          return;
        }
        history.push({
          pathname: `/${i18n.language}/SkiDataMonthlyParkerRecord`,
          state: { ...userData, skiDataMonthlyParkerRecords: e },
        });
      })
      .catch(() => {
        Swal.fire({
          position: 'center',
          text: t('OTP.codeAlertError'),
          confirmButtonColor: '#fd980f',
          confirmButtonText: t('IhourlyCart.Back'),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <MaxWidth>
        <From onSubmit={handleSubmit(onSubmit)}>
          <span style={{ fontSize: 26 }}>{t('OTP.validate')}</span>

          <InputRow>
            <InputCol>
              <span>{t('OTP.phone')}</span>
              <Input placeholder="+852" disabled style={{ background: '#dedede', marginTop: 10 }} />
            </InputCol>

            <InputCol style={{ marginLeft: 20 }}>
              <Input
                error={errors.phone}
                name="phone"
                ref={register({
                  required: t('CsList.PhoneError'),
                  pattern: {
                    value: /^1[0-9]{10}$|^[456789][0-9]{7}$/,
                    message: t('CsList.PhoneError'),
                  },
                })}
              />
              {errors.phone && (
                <ErrorMsg style={{ position: 'absolute', top: 35, left: 0 }}>
                  {errors?.phone?.message}
                  {errors?.phone?.types?.exist}
                </ErrorMsg>
              )}
            </InputCol>
          </InputRow>

          <InputRow>
            <InputCol>
              <span>{t('OTP.code')}</span>
              <Input
                error={errors.code}
                name="code"
                ref={register({ required: t('OTP.codeError') })}
                style={{ marginTop: 10 }}
              />
              {errors.code && (
                <ErrorMsg style={{ position: 'absolute', top: 75, left: 0 }}>
                  {errors?.code?.message}
                </ErrorMsg>
              )}
            </InputCol>
          </InputRow>

          <SendSMSRow>
            <span style={{ color: '#666', fontSize: 14, marginTop: 20 }}>
              {t('OTP.press', { seconds: timeLeft })}
            </span>

            <StyledButton
              disabled={
                (timeLeft < countDownTime && timeLeft !== 0) || startTimer || sendSMSLoading
              }
              type="button"
              color="white"
              onClick={handelSendSms}
            >
              {timeLeft < countDownTime || startTimer ? t('OTP.resend') : t('OTP.send')}
              {sendSMSLoading && (
                <ReactLoading
                  type="spin"
                  style={{ marginBottom: 7, marginLeft: 5, width: 20, height: 20, fill: '#888' }}
                />
              )}
            </StyledButton>
          </SendSMSRow>

          <ButtonRow>
            <StyledButton disabled={isLoading} type="submit" color="yellow" style={{ margin: 0 }}>
              {t('OTP.confirm')}
              {isLoading && (
                <ReactLoading
                  type="spin"
                  style={{ marginBottom: 7, marginLeft: 5, width: 20, height: 20, fill: '#888' }}
                />
              )}
            </StyledButton>
          </ButtonRow>
        </From>
      </MaxWidth>
    </Container>
  );
}

export default withRouter(OTP);
