import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import iconpass from '../../../media/images/iconpass.png';
import iconcross from '../../../media/images/icon-cross.png';
import { useEmailVerify } from '../../../models/Customer';

const Container = styled.div`
  background: #dedede;
  height: 100%;
  flex: 1;
`;

const TitleRow = styled.div`
  padding: 5px 0;

  @media (max-width: 850px) {
    padding: 11px 0 5px 0;
  }
`;

const TitleInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const TitleDiv = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

const TitleBlock = styled.div`
  flex-grow: 1;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: -3px;
`;

const Title = styled.span`
  color: #444;
  font-size: 2rem;
  font-weight: 400;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 850px) {
    line-height: 1.4;
  }
`;

const FormWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const FormInner = styled.div`
  font-size: 0.875rem;
  display: block;

  @media (max-width: 850px) {
    margin-top: -15px;
  }
`;

const FormDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

const FormBlock = styled.div``;

const TextRow = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 850px) {
    margin-top: 19px;
  }
`;

const TextPadding = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const Text = styled.span`
  color: #444;
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.5;
`;

const ButtonRow = styled.div`
  -webkit-flex: 1;
  flex: 1;
  padding: 10px 15px;
  margin-top: 25px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

const ButtonDiv = styled.div`
  text-align: left;
  width: 60%;
  display: flex;
`;

const Submit = styled.button`
  width: 40%;
  line-height: 1.375rem;
  color: #fff;
  background-color: #666666;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    line-height: 1.1rem;
    font-size: 0.9rem;
  }

  &:hover {
    color: #fff;
    background-color: #6c6c6c;
    text-decoration-line: none;
  }
`;

const Input = styled.input`
  height: 32px;
  width: 100%;
  border: none;
  padding-left: 5px;
`;

const ErrorMsg = styled.span`
  color: #e56e5a;
  font-size: 13px;
`;

const ActiveExpired = () => {
  const { t } = useTranslation();
  const { emailVerify, error } = useEmailVerify();
  const methods = useForm({
    validationSchema: yup.object().shape({
      email: yup.string().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/),
    }),
  });
  const onSubmit = () => {
    emailVerify(methods.getValues('email'));
  };

  useEffect(() => {
    if (error) {
      alert(t('ForgotPassword.ErrorEmail'));
    }
  }, [error]);

  return (
    <Container>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TitleRow>
          <TitleInner>
            <TitleDiv>
              <TitleBlock>
                <Title>{t('Myprofile.ActiveExpiredTitle')}</Title>
              </TitleBlock>
            </TitleDiv>
          </TitleInner>
        </TitleRow>
        <FormWidth>
          <FormInner>
            <FormBlock>
              <FormDiv>
                <TextRow>
                  <TextPadding>
                    <Text>{t('Myprofile.ActiveExpiredText')}</Text>
                  </TextPadding>
                </TextRow>
                <TextRow>
                  <ButtonRow>
                    <ButtonDiv>
                      <Input name="email" placeholder="abc@example.com" ref={methods.register} />
                      <Submit>{t('Myprofile.ActiveExpiredA')}</Submit>
                    </ButtonDiv>
                    {methods.errors.email && <ErrorMsg>{t('Registration.EmailError')}</ErrorMsg>}
                  </ButtonRow>
                </TextRow>
              </FormDiv>
            </FormBlock>
          </FormInner>
        </FormWidth>
      </form>
    </Container>
  );
};

export default withRouter(ActiveExpired);
