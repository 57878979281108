const HomeselectStyles = (isDisabled) => ({
  indicatorSeparator: () => {},
  dropdownIndicator: (defaultStyles, state, isSelected, styles) => ({
    marginRight: 10,
    '&:hover': { color: 'orange' },
    ...styles,
    color: state.isFocused ? 'orange' : '#888',
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: '1rem',
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    height: 53,
    minHeight: 30,
    width: '100%',
    opacity: isDisabled ? '0.9' : '1',
    border: 'none',
    borderRadius: 0,
    fontSize: 18,
    paddingLeft: 20,
  }),

  container: (base) => ({
    ...base,
    opacity: isDisabled ? '0.9' : '1',
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 2,
    fontSize: 18,
    paddingLeft: 20,
    ...styles,
    backgroundColor: state.isFocused ? '' : null,
    fontWeight: state.isSelected ? '' : '',
    color: state.isSelected || state.isFocused ? 'black' : '',
    '&:active': {
      backgroundColor: 'white',
    },
  }),

  menu: (styles) => ({
    ...styles,
    marginTop: 0,
    borderWidth: 10,
    border: '1px solid orange',
    color: '#888',
    zIndex: 10,
  }),

  singleValue: (provided) => ({ ...provided, color: '#888' }),
});

const selectStyles = (error) => ({
  singleValue: (provided) => ({ ...provided, color: '#666' }),

  indicatorSeparator: () => {},
  dropdownIndicator: () => ({
    marginRight: 10,
    color: 'orange',
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    height: 32,
    minHeight: 32,
    '&:hover': { borderColor: 'orange' },
    border: error ? '1px solid red' : '1px solid #bbbbbb',
    borderRadius: 0,
    fontSize: 12,
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 2,
    fontSize: 12,
    paddingLeft: 5,
    ...styles,
    backgroundColor: state.isFocused ? '' : null,
    color: state.isSelected ? 'black' : '',
    fontWeight: state.isFocused ? 'bold' : '',
    '&:active': { backgroundColor: 'white' },
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderWidth: 10,
    border: '1px solid orange',
  }),
  fontSize: 12,
});

const FindCarParkSelectStyles = (isDisabled, opened) => ({
  indicatorSeparator: () => {},

  dropdownIndicator: (base) => ({
    ...base,
    '&:hover': { color: isDisabled ? 'inherit' : 'orange' },
    marginRight: 10,
    color: 'inherit',
  }),

  control: (base) => ({
    ...base,
    '&:hover': {
      boxShadow: isDisabled ? 'none' : '0 0 2px rgba(0,0,0,0.5)',
      color: isDisabled ? 'inherit' : 'orange',
      borderColor: isDisabled ? '#FD980F' : '#C35611',
    },
    border: opened ? '2px solid #C35611' : '2px solid #fff',
    color: opened ? 'orange' : 'inherit',
    boxShadow: opened ? '0 0 2px rgba(0,0,0,0.5)' : 'none',
    height: 44,
    minHeight: 30,
    width: '100%',
    opacity: isDisabled ? '0.9' : '1',
    borderRadius: 0,
    fontSize: 14,
    paddingLeft: 20,
    transition: 'border 150ms',
  }),

  container: (base) => ({
    ...base,
    width: '100%',
    marginLeft: '5px',
    marginRight: '3px',
    opacity: isDisabled ? '0.9' : '1',
    pointerEvents: 'painted',
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 2,
    fontSize: 15,
    paddingLeft: 6,
    ...styles,
    backgroundColor: state.isFocused ? '' : null,
    fontWeight: state.isSelected ? '' : '',
    color: state.isSelected || state.isFocused ? 'black' : '',
    '&:active': {
      backgroundColor: 'white',
    },
  }),

  menu: (styles) => ({
    ...styles,
    marginTop: 0,
    borderWidth: 10,
    border: '1px solid orange',
    color: '#888',
  }),

  singleValue: (provided) => ({ ...provided, color: '#888' }),
});

export { HomeselectStyles, selectStyles, FindCarParkSelectStyles };
