import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../../components/General/Checkbox/style.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/General/PopupPrivacyPolicy';
import ContentBar from './ContentBar';
import FormBox from './FormBox';
import {
  CustomerServiceContainer,
  TitleBarBackground,
  TitleWidth,
  Title,
  Background,
  ArrowDiv,
  Arrow,
} from './style';

const CustomerService = () => {
  const { t } = useTranslation();
  const [Popupshow, setPopupShow] = React.useState(false);

  return (
    <CustomerServiceContainer>
      <TitleBarBackground>
        <TitleWidth>
          <Title>{t('CsList.CUSTOMER_SERVICE')}</Title>
        </TitleWidth>
      </TitleBarBackground>

      <Background>
        <ContentBar />
        <ArrowDiv>
          <Arrow />
        </ArrowDiv>
        <FormBox setPopupShow={setPopupShow} />
      </Background>
      <Modal onClose={() => setPopupShow(false)} Popupshow={Popupshow} />
    </CustomerServiceContainer>
  );
};

export default withRouter(CustomerService);
