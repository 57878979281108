import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Line from '../../../media/images/pin_timeline.png';
import useStaticInfo from '../../../models/StaticInfo';

const orangearrow = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-arrow_xl-orange.png`;

const Container = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px 20px 10px;
`;

const Width = styled.div`
  width: 100%;
  max-width: 1000px;
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Titlea = styled.a`
  color: #666;
  font-size: 48px;
  text-decoration-line: none;
  position: relative;
  z-index: 10;

  &:hover {
    text-decoration-line: none;
    color: #666;
  }

  @media (max-width: 850px) {
    font-size: 30px;
  }
`;

const TitleArrow = styled.img`
  height: 30px;
  margin-left: 20px;
  margin-top: -10px;
`;

const HeaderText = styled.span`
  font-size: 18.6px;
  color: #888;
`;

const ImgMainRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 1.5rem;
  background-image: url(${Line});
  background-repeat: no-repeat;
  background-position: center 28%;
  background-size: 80% auto;
  @media (max-width: 850px) {
    flex-direction: column;
    background-image: none;
    align-items: center;
    margin-top: 20px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 850px) {
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
`;

const ItemWidth = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;

  @media (max-width: 850px) {
    width: 500px;
    flex-direction: row;
  }
  @media (max-width: 550px) {
    width: 300px;
  }
`;

const ImgFlex = styled.div`
  -webkit-flex: 1;
  flex: 1;
`;

const TextFlex = styled.div`
  -webkit-flex: 3;
  flex: 3;
  align-self: center;
  text-align: center;

  .point__number {
    display: inline-block;
    padding: 0.3rem 0;
    color: #fd980f;
    font-size: 2.0625rem;
  }

  p {
    display: inline-block;
    width: 100%;
    color: #666666;
    font-size: 0.9375rem;
    letter-spacing: 2px;
    vertical-align: middle;
  }
`;

const ItemImg = styled.img`
  width: 160px;
  height: 182px;
  object-fit: contain;
  @media (max-width: 850px) {
    width: 100%;
    height: 100%;
  }
`;

const url = `${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}`;

const HomeOurCompany = () => {
  const { t, i18n } = useTranslation();

  const { getStaticValue, language, StaticInfoContext } = useStaticInfo('OurCompany');

  const staticInfo = useContext(StaticInfoContext);

  const {
    description,
    image1,
    image2,
    image3,
    image1_description,
    image2_description,
    image3_description,
  } = useMemo(
    () =>
      getStaticValue(
        [
          'description',
          'image1',
          'image2',
          'image3',
          'image1_description',
          'image2_description',
          'image3_description',
        ],
        staticInfo
      ),
    [staticInfo, language]
  );

  return (
    <Container>
      <Width>
        <TitleContainer>
          <Titlea href={`/${i18n.language}/our-company`}>
            {t('Our_company.Our_company')}
            <TitleArrow src={orangearrow} />
          </Titlea>
        </TitleContainer>

        <HeaderText>{description}</HeaderText>

        <ImgMainRow>
          <ItemContainer>
            <ItemWidth>
              <ImgFlex>
                <ItemImg src={`${url}${image1}`} />
              </ImgFlex>
              <TextFlex>
                <p dangerouslySetInnerHTML={{ __html: image1_description }} />
                {/* <ImgText>
                  {t('Our_company.ESTABLISHED')}
                  <br />
                </ImgText>
                <OrangeText>1983</OrangeText> */}
              </TextFlex>
            </ItemWidth>
          </ItemContainer>

          <ItemContainer>
            <ItemWidth>
              <ImgFlex>
                <ItemImg src={`${url}${image2}`} />
              </ImgFlex>
              <TextFlex>
                <p dangerouslySetInnerHTML={{ __html: image2_description }} />
                {/* <ImgText>
                  {t('Our_company.MANAGE')}
                  <br />
                </ImgText> */}
                {/* <OrangeText>
                  100,000+
                  <br />
                </OrangeText> */}
                {/* <ImgText>{t('Our_company.BAYS')}</ImgText> */}
              </TextFlex>
            </ItemWidth>
          </ItemContainer>

          <ItemContainer>
            <ItemWidth>
              <ImgFlex>
                <ItemImg src={`${url}${image3}`} />
              </ImgFlex>
              <TextFlex>
                <p dangerouslySetInnerHTML={{ __html: image3_description }} />
                {/* <ImgText>
                  {t('Our_company.OPERATE')}
                  <br />
                </ImgText>
                <OrangeText>
                  400
                  <br />
                </OrangeText>
                <ImgText>{t('Our_company.CARS_PARKS')}</ImgText> */}
              </TextFlex>
            </ItemWidth>
          </ItemContainer>
        </ImgMainRow>
      </Width>
    </Container>
  );
};

export default HomeOurCompany;
