/* eslint-disable no-console */
import { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCarParks } from './CarPark';
// import { useCart } from './Cart';

const loginUrl = `${process.env.REACT_APP_API_DOMAIN}/login`;
const logoutUrl = `${process.env.REACT_APP_API_DOMAIN}/logout`;
const customerUrl = `${process.env.REACT_APP_API_DOMAIN}/customer`;
const checkEmailUrl = `${process.env.REACT_APP_API_DOMAIN}/checkEmail`;
const checkPhoneUrl = `${process.env.REACT_APP_API_DOMAIN}/checkPhone`;
const locationUrl = `${process.env.REACT_APP_API_DOMAIN}/location`;
const carTypeUrl = `${process.env.REACT_APP_API_DOMAIN}/carType`;
// const carsUrl = `${process.env.REACT_APP_API_DOMAIN}/customer/${id}/Cars`;
const smsUrl = `${process.env.REACT_APP_API_DOMAIN}/sms/phone`;
const getCreditCardUrl = `${process.env.REACT_APP_API_DOMAIN}/customer/{{id}}/FirstDataTokens`;
const creditCardUrl = `${process.env.REACT_APP_API_DOMAIN}/firstdatatoken`;
const carUrl = `${process.env.REACT_APP_API_DOMAIN}/car`;
const carParkUrl = `${process.env.REACT_APP_API_DOMAIN}/carPark`;
const ResetPasswordUrl = `${process.env.REACT_APP_API_DOMAIN}/password/reset`;
const ActiveParkCardUrl = `${process.env.REACT_APP_API_DOMAIN}/ppCard/phoneActivate`;
const myProfileTabActiveUrl = `${process.env.REACT_APP_API_DOMAIN}/ppCard/activate`;

const CustomerContext = createContext(null);

function useCustomer() {
  const [user, setUser] = useState({});
  const [creditCards, setCreditCards] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, [localStorage.getItem('token')]);

  useEffect(() => {
    async function fetchCustomerCreditCards() {
      try {
        const {
          data: { data: cards },
        } = await axios({
          method: 'get',
          url: getCreditCardUrl.replace('{{id}}', user.id),
          // headers: { token },
        });
        setCreditCards(cards);
      } catch (e) {
        setCreditCards([]);
      }
    }
    if (user.id) {
      fetchCustomerCreditCards();
    }
  }, [user]);

  async function login(username, password) {
    const {
      data: { token: userToken },
    } = await axios.post(loginUrl, {
      username,
      password,
    });
    setToken(userToken);
    localStorage.setItem('token', userToken);
    return userToken;
  }

  async function logout() {
    setToken(null);
    localStorage.clear();
    await axios({ url: logoutUrl, method: 'post', headers: { token } });
  }

  async function register(data, successCb, failCb) {
    try {
      const { status } = await axios({ url: customerUrl, method: 'post', data });
      if (status === 201) {
        return successCb();
      }
      return failCb();
    } catch (e) {
      // console.log({e});
      return failCb();
    }
  }

  async function checkEmail(email) {
    try {
      const {
        data: { data },
      } = await axios({ url: `${checkEmailUrl}?email=${email}` });
      return !(data && data.length > 0);
    } catch (e) {
      if (e.response.status === 404) {
        return true;
      }
      return e;
    }
  }

  async function update(data, callback) {
    try {
      // const result =
      await axios({ url: `${customerUrl}/${user.id}`, method: 'put', data });
      // console.log({ result });
      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function removeCreditCard(id, callback) {
    try {
      // const result =
      await axios({ url: `${creditCardUrl}/${id}`, method: 'delete' });
      // console.log({ result });

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    async function fetchCustomer() {
      if (!token) {
        setUser({ token: null });
        return;
      }
      try {
        const {
          data: { data },
        } = await axios({
          method: 'get',
          url: customerUrl,
          headers: { token },
        });
        const {
          Customers: [userData],
        } = Array.isArray(data) ? data[0] : data;

        setUser({ token, ...userData });
      } catch (e) {}
    }

    fetchCustomer();
  }, [token]);

  return {
    user,
    token,
    setToken,
    login,
    logout,
    register,
    checkEmail,
    update,
    creditCards,
    removeCreditCard,
  };
}

function useCars() {
  // const {
  //   i18n: { language },
  // } = useTranslation();
  const params = useParams();
  const language = params.zh || params.en;

  const { user, token } = useContext(CustomerContext);
  const [allCars, setAllCars] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [carsLoading, setLoading] = useState(true);

  const [carDetail, setCarDetail] = useState();

  useEffect(() => {
    async function getAllCars() {
      if (!user || !user.id) return;
      setLoading(true);

      try {
        const { id: userId } = user;
        const { data } = await axios.get(`${customerUrl}/${userId}/Cars`, { headers: { token } });
        const cars = data.data.map(
          ({ id, CarTypeId, registrationMark, CarType, activateCard, activateCardNumber }) => {
            const { name, chineseName } = CarType || {};

            return {
              CarTypeId,
              registrationMark,
              carType: language === 'en' ? name : chineseName,
              activateCard,
              activateCardNumber,
              carId: id,
            };
          }
        );
        setAllCars(cars);
        setCarOptions(
          data.data.map(({ id: value, registrationMark: label }) => ({ value, label }))
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    getAllCars();
  }, [user, language]);

  async function getOneCar(registrationMark) {
    try {
      const { data } = await axios.get(`${carUrl}?registrationMark=${registrationMark}`);
      setCarDetail(data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function activate(carId) {
    try {
      if (!user || !user.id) return;
      const { id: userId } = user;
      const {
        data: { data: activateData },
      } = await axios.post(`/customer/${userId}/car/${carId}activate`);
      const updateCars = allCars.map((car) => {
        if (car.id === carId) {
          return {
            ...car,
            activate: {
              isActivated: true,
              status: activateData.status,
              verificationMethod: activateData.method,
            },
          };
        }
        return { ...car };
      });

      setAllCars(updateCars);
    } catch (e) {
      console.error('Unable to activate');
    }
  }

  async function updateVerificationMethod() {
    if (!user || !user.id) return;
    const { id: userId } = user;
    const { cardNumber, action, registrationMark, verificationMethod, carTypeId, carId } =
      JSON.parse(localStorage.getItem('iPark'));
    const token = localStorage.getItem('token');
    const config = {
      headers: { token },
    };

    if (action === 'activate') {
      try {
        const { data } = await axios.put(
          `${carUrl}/${carId}`,
          {
            activateCard: verificationMethod,
            activateCardNumber: cardNumber,
          },
          config
        );
        window.location = '/customers/account';
      } catch (e) {
        alert(e);
      }
    }

    if (action === 'add') {
      try {
        const { data } = await axios.post(
          carUrl,
          {
            activateCard: verificationMethod,
            activateCardNumber: cardNumber,
            CarTypeId: carTypeId,
            registrationMark,
            CustomerID: userId,
          },
          config
        );
        console.log({ newCar: data });
        window.location = '/customers/account';
      } catch (e) {
        console.error('Unable to add vehicle');
        console.error(e);
        alert(e);
      }
    }
  }

  // async function addVehicle(CarTypeId, registrationMark) {
  //   try {
  //     const { data } = await axios.post(`${carUrl}`, {
  //       CarTypeId,
  //       registrationMark,
  //     });
  //     console.log({ data });

  //     // const updateCars = [...allCars, newCarData];

  //     // console.log({ updateCars });

  //     // setAllCars(updateCars);
  //     // return updateCars;
  //   } catch (e) {
  //     console.error('Unable to add vehicle');
  //     return e;
  //   }
  // }

  return {
    allCars,
    carOptions,
    activate,
    updateVerificationMethod,
    getOneCar,
    carDetail,
    carsLoading,
    // addVehicle,
  };
}

function useSelectOptions(condition) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { carParks } = useCarParks();

  const [prefixOptions, setPrefix] = useState([]);

  const [entryHourOptions, setEntryHour] = useState([]);
  const [entryMinsOptions, setEntryMins] = useState([]);

  const [languageOptions, setLanguage] = useState([]);

  const [location, setLocation] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [carType, setCarType] = useState([]);
  const [carParksInDistrict, setCarParksInDistrict] = useState([]);
  const [selectedCarPark, setSelectedCarPark] = useState(null);
  const [selectedCarParkObject, setSelectedCarParkObject] = useState({});
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    async function getLocation() {
      const {
        data: { data },
      } = await axios.get(`${locationUrl}`);
      setLocation(data);
    }
    // async function getCarType() {
    //   const {
    //     data: { data },
    //   } = await axios.get(carTypeUrl);
    //   console.log({ data });
    //   setCarType(data.map(({ id: value, name: label }) => ({ value, label })));
    // }
    async function getCarType() {
      const {
        data: { data },
      } = await axios.get(carTypeUrl);
      setCarType(
        data.map(({ id: value, name, chineseName }) => ({
          value,
          label: language === 'en' ? name : chineseName,
        }))
      );
    }

    // async function getAllCars() {
    //   const {
    //     data: { data },
    //   } = await axios.get(`${customerUrl}/${condition}/Cars`);
    //   setCarOptions(data.map(({ id: value, registrationMark: label }) => ({ value, label })));
    // }

    // getAllCars();
    getCarType();
    getLocation();
    setPrefix([
      { value: 'Mr', label: t('Prefix.Mr') },
      { value: 'Mrs', label: t('Prefix.Mrs') },
      { value: 'Ms', label: t('Prefix.Ms') },
    ]);
    setLanguage([
      { value: 'zh', label: t('Language.zh') },
      { value: 'en', label: t('Language.en') },
    ]);
    setEntryHour([
      { value: 0, label: '00' },
      { value: 1, label: '01' },
      { value: 2, label: '02' },
      { value: 3, label: '03' },
      { value: 4, label: '04' },
      { value: 5, label: '05' },
      { value: 6, label: '06' },
      { value: 7, label: '07' },
      { value: 8, label: '08' },
      { value: 9, label: '09' },
      { value: 10, label: '10' },
      { value: 11, label: '11' },
      { value: 12, label: '12' },
      { value: 13, label: '13' },
      { value: 14, label: '14' },
      { value: 15, label: '15' },
      { value: 16, label: '16' },
      { value: 17, label: '17' },
      { value: 18, label: '18' },
      { value: 19, label: '19' },
      { value: 20, label: '20' },
      { value: 21, label: '21' },
      { value: 22, label: '22' },
      { value: 23, label: '23' },
    ]);
    setEntryMins([
      { value: 0, label: '00' },
      { value: 15, label: '15' },
      { value: 30, label: '30' },
      { value: 45, label: '45' },
    ]);
  }, [language]);

  useEffect(() => {
    setRegionOptions(
      Object.values(
        location.reduce((output, { region, chineseRegion }) => {
          if (!output[region]) {
            Object.assign(output, {
              [region]: {
                value: region,
                label: language === 'en' ? region : chineseRegion,
              },
            });
          }
          return output;
        }, {})
      )
    );
  }, [location]);

  // console.log({selectedRegion});
  // console.log({selectedDistrict});

  useEffect(() => {
    if (!selectedRegion) return;
    if (condition === 'getCarParksInDistrict' || condition === 'changeService') {
      setDistrictOptions(
        location
          // eslint-disable-next-line max-len
          .filter(
            ({ region, isEnable, isCustomerLocation, id }) =>
              region === selectedRegion &&
              isEnable &&
              isCustomerLocation &&
              carParks.filter(({ LocationId }) => LocationId === id).length !== 0
          )
          .map(({ district, chineseDistrict, id }) => {
            const totalCarParks = carParks.filter(({ LocationId }) => LocationId === id);
            return {
              value: id,
              label:
                language === 'en'
                  ? `${district} (${totalCarParks.length})`
                  : `${chineseDistrict} (${totalCarParks.length})`,
              totalCarParks,
            };
          })
      );
    } else {
      setDistrictOptions(
        location
          // eslint-disable-next-line max-len
          .filter(
            ({ region, isEnable, isCustomerLocation }) =>
              region === selectedRegion && isEnable && isCustomerLocation
          )
          .map(({ district, chineseDistrict, id }) => {
            return {
              value: id,
              label: language === 'en' ? district : chineseDistrict,
            };
          })
      );
    }
  }, [selectedRegion, carParks]);

  useEffect(() => {
    if (condition === 'getCarParksInDistrict') {
      districtOptions.forEach(({ value, totalCarParks }) => {
        if (value === selectedDistrict) {
          setCarParksInDistrict(totalCarParks);
        }
      });
    }

    if (condition === 'changeService') {
      districtOptions.forEach(({ value, totalCarParks }) => {
        if (value === selectedDistrict) {
          const carParkOptions = totalCarParks.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }));

          setCarParksInDistrict(carParkOptions);
        }
      });
    }
  }, [selectedDistrict, location, districtOptions]);

  useEffect(() => {
    async function getServices() {
      const {
        data: { data },
      } = await axios.get(`${carParkUrl}/${selectedCarPark}/ParkingServices`);
      const servicesData = data
        .filter(({ isMonthly, isEnable }) => isMonthly && isEnable)
        .map(({ id: value, type, chineseType }) => ({
          value,
          label: language === 'en' ? type : chineseType,
        }));

      setServiceOptions(servicesData);
    }
    if (selectedCarPark !== null) getServices();
  }, [selectedCarPark, language]);

  useEffect(() => {
    const selected = carParksInDistrict.filter(({ value }) => value === selectedCarPark);

    const carParkObj = {
      name: selected[0] && selected[0].name,
      address: selected[0] && selected[0].address,
      service: selectedService,
      fee: 'tbc',
      servicePeriod: 'tbc',
      type: 'change',
    };

    setSelectedCarParkObject(carParkObj);
  }, [selectedService]);

  return {
    location,
    prefixOptions,
    carType,
    regionOptions,
    districtOptions,
    setSelectedRegion,
    setSelectedDistrict,
    selectedRegion,
    selectedDistrict,
    selectedCarPark,
    selectedService,
    carParksInDistrict,
    setCarParksInDistrict,
    setSelectedCarPark,
    selectedCarParkObject,
    serviceOptions,
    setSelectedService,
    languageOptions,
    entryHourOptions,
    entryMinsOptions,
  };
}

function useSMSVerify() {
  const [isSMSVerified, setIsSMSVerified] = useState(0);
  const [isPhoneVerified, setisPhoneVerified] = useState(0);
  const history = useHistory();
  const { i18n } = useTranslation();

  async function receiveSMS(phone) {
    const { data } = await axios.post(smsUrl, { phone });
    return data;
  }

  async function SendSMS(phone) {
    const { data } = await axios.post(smsUrl, {
      phone,
    });

    // console.log({data});
    setIsSMSVerified(true);
    return data;
  }

  async function verifyPhone(phone) {
    try {
      // console.log({phone});
      const {
        data: { data },
      } = await axios({ url: `${checkPhoneUrl}?phone=${phone}` });
      setisPhoneVerified(1);
      SendSMS(phone);
      return data;
    } catch (e) {
      if (e.response.status === 404) {
        setisPhoneVerified(2);
      }
      return null;
    }
  }

  return {
    receiveSMS,
    verifyPhone,
    isSMSVerified,
    isPhoneVerified,
  };
}

function ForgotPasswordApi() {
  const [isEmailVerified] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  async function checkEmail(email) {
    try {
      const {
        data: { data },
      } = await axios({ url: `${checkEmailUrl}?email=${email}` });
      history.push(`/${i18n.language}/customers/message/info:sendout_forgot_password_success`);
      return !(data && data.length > 0);
    } catch (e) {
      if (e.response.status === 404) {
        alert(t('ForgotPassword.ErrorEmail'));
      }
      return null;
    }
  }
  return {
    isEmailVerified,
    checkEmail,
  };
}

function useResetPassword() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  /**
   * @return {number}
   */
  async function ResetPasswordApi(token, newpassword) {
    const { status } = await axios.post(ResetPasswordUrl, { token, newpassword });
    // console.log({status});
    if (status === 200) {
      history.push(`/${i18n.language}/customers/message/info:reset_password_success`);
    }
    if (status === 401) {
      alert(t('ForgotPassword.Error'));
    }
    return status;
  }

  return {
    ResetPasswordApi,
  };
}

function useIPark() {
  const [registrationMark, setRegistrationMark] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberValidated, setCardNumberValidated] = useState('');

  useEffect(() => {
    const numberWithSpace = cardNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    // after every space, backspace doesn't remove previous digits unless highlight + backspace
    setCardNumber(numberWithSpace);
  }, [cardNumber]);

  async function validateOctopusCardNumber() {
    const cardNumberWithoutSpace = cardNumber.replace(/\s/g, '');
    const octopusRegEx = /\d{8}/g;
    const isValidated = octopusRegEx.test(cardNumberWithoutSpace);
    setCardNumberValidated(isValidated);
  }

  async function validateCreditCardNumber() {
    const cardNumberWithoutSpace = cardNumber.replace(/\s/g, '');
    const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;

    if (visaRegEx.test(cardNumberWithoutSpace)) {
      setCardNumberValidated(true);
    } else if (mastercardRegEx.test(cardNumberWithoutSpace)) {
      setCardNumberValidated(true);
    } else {
      setCardNumberValidated(false);
    }
  }
  return {
    registrationMark,
    setRegistrationMark,
    cardNumber,
    setCardNumber,
    validateOctopusCardNumber,
    validateCreditCardNumber,
    cardNumberValidated,
  };
}

function useActiveParkCard() {
  const history = useHistory();
  const [isCardVerified, setisCardVerified] = useState(undefined);
  const [isParkCardSMSVerified, setisParkCardSMSVerified] = useState(undefined);
  const { t, i18n } = useTranslation();
  async function ActiveParkCard(cardNumber, phone) {
    try {
      const { data } = await axios.post(ActiveParkCardUrl, { cardNumber });
      // console.log({data});
      return data;
    } catch (e) {}
  }

  async function SendSMS(input) {
    const { data } = await axios.post(smsUrl, { phone: input });

    // console.log({data});
    return data;
  }

  async function myProfileTabActive(cardNumber, token, phone) {
    // console.log({cardNumber, token, phone});
    try {
      const { data } = await axios({
        method: 'POST',
        url: myProfileTabActiveUrl,
        data: { cardNumber },
        headers: { token },
      });
      setisCardVerified(true);
      SendSMS(phone);
      // console.log({data});
      return data;
    } catch (e) {
      if (e.response.status === 401) {
        alert('The car park facility number cannot be found, please contact our customer service.');
        setisCardVerified(false);
      }
    }
  }

  async function verifyParkCardSMS(code, phone) {
    try {
      const { data } = await axios.put(smsUrl, {
        code,
        phone,
      });
      setisParkCardSMSVerified(true);
      alert('Success');
      return data;
    } catch (e) {
      if (e.response.status === 404) {
        setisParkCardSMSVerified(false);
      }
      return null;
    }
  }

  return {
    ActiveParkCard,
    myProfileTabActive,
    isCardVerified,
    verifyParkCardSMS,
    isParkCardSMSVerified,
  };
}

export {
  useCustomer,
  useCars,
  CustomerContext,
  useSelectOptions,
  useSMSVerify,
  useIPark,
  // useBooking,
  ForgotPasswordApi,
  useResetPassword,
  useActiveParkCard,
};
