import styled from 'styled-components';

export const Container = styled.form`
  background: #dedede;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Inner = styled.div`
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  max-width: 1024px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  //margin-top: 5vh;
  background-color: #fff;
  padding: 0px 20px;
`;

export const TitleRow = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    padding: 11px 0 5px 0;
  }
`;

export const TitleInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

export const TitleDiv = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

export const TitleBlock = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: -3px;
  margin-top: 20px;

  .pretty input {
    border: 1px solid #666;
  }
`;

export const Title = styled.span`
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 40px;

  @media (max-width: 850px) {
    line-height: 1.4;
  }
`;

export const FormWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

export const FormInner = styled.div`
  font-size: 0.875rem;
  display: block;

  @media (max-width: 850px) {
    margin-top: -15px;
  }
`;

export const FormDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

export const FormBlock = styled.div``;

export const TextRow = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: 850px) {
    margin-top: 19px;
  }
`;

export const Text = styled.p`
  color: #666;
  font-size: 1.3rem;
  line-height: 1.5;
  margin-top: 20px;
`;

export const ButtonRow = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 10px 15px;
  margin-top: 25px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
  }
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Submit = styled.a`
  width: 100%;
  margin-left: 20px;
  line-height: 1.375rem;
  color: #fff;
  background-color: #fd980f;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  transition: 300ms;
  text-transform: uppercase;

  @media (max-width: 850px) {
    line-height: 1.1rem;
    font-size: 0.9rem;
  }

  &:hover {
    color: #fff;
    background-color: #6c6c6c;
    text-decoration-line: none;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  margin-left: 20px;
  line-height: 1.375rem;
  color: #fff;
  background-color: #fd980f;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  transition: 300ms;
  text-transform: uppercase;
  border: 0px;

  @media (max-width: 850px) {
    line-height: 1.1rem;
    font-size: 0.9rem;
  }

  &:hover {
    color: #fff;
    background-color: #6c6c6c;
    text-decoration-line: none;
  }
`;

export const Back = styled.button`
  width: 100%;
  transition: 300ms;
  line-height: 1.375rem;
  color: #fff;
  background-color: #6c6c6c;
  padding: 0.5rem 1.2rem;
  font-size: 0.8125rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;

  @media (max-width: 850px) {
    line-height: 1.1rem;
    font-size: 0.9rem;
  }

  &:hover {
    color: #fff;
    background-color: #888;
    text-decoration-line: none;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  height: 100%;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  transition: all 300ms ease-in-out;

  :after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 3rem;
  margin-top: 10px;
  color: #666;

  &:hover ${Checkbox} ~ ${Checkmark} {
    background-color: #ccc;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  ${Checkbox}:checked ~ ${Checkmark} {
    background-color: #fd980f;
    border: 1px solid #dedede;

    :after {
      display: block;
    }
  }

  ${Checkmark} {
    border: 1px solid #888;
  }
`;
