import styled from 'styled-components';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import React, { useRef, useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import * as moment from 'moment';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-TW';
import { useTranslation } from 'react-i18next';
import FieldError from './FieldError';
// TODO: responsive?

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 3px;
  flex-direction: column;
  position: relative;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
    padding-left: 0;
  }

  & .MuiSvgIcon-root {
    color: #fd980f;
  }

  & .MuiPickersStaticWrapper-staticWrapperRoot {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: absolute;
    margin-top: 33px;
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.open ? '1' : '0')};
    border-top: 5px solid #fd980f;
    transition: all 0.3s ease-in-out;
  }

  & .MuiPickersDay-current {
    color: #000;
  }

  & .MuiPickersDay-daySelected {
    background-color: #fd980f;
    color: #fff;

    &:hover {
      background-color: #fd980f;
    }
  }

  & .MuiPickersDay-day {
    &:hover {
      background-color: #fd980f;
      color: #fff;
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  position: relative;
`;

const Input = styled.input`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding: 4px 4px 4px 10px;
  font-size: 13px;
  color: #666;
  height: 32px;
  border: 1px solid
    ${({ error, passworderror, isEmailValid }) =>
      error || passworderror || isEmailValid ? '#E56E5A' : '#bbb'};
  width: 100%;
`;

function EntryDateTextField({ name, placeHolder, type = 'text', children, address, errorMessage }) {
  const { control, errors, setValue, getValues } = useFormContext();
  const error = errors[name];
  const Ref = useRef(null);
  const PickerRef = useRef(null);
  const { i18n } = useTranslation();
  const [picker, setPicker] = useState(false);
  const LastTime = moment().format('YYYY/12/31 23:59:00');

  useEffect(() => {
    const handleClick = (e) => {
      if (Ref.current.contains(e.target)) return;
      if (PickerRef.current && !PickerRef.current.contains(e.target)) {
        if (picker) setPicker(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return (
    <Container address={address} open={picker}>
      <FieldContainer>
        <Controller
          as={<Input ref={Ref} />}
          name={name}
          control={control}
          placeholder={placeHolder}
          type={type}
          error={error}
          onFocus={() => Ref.current.focus()}
          onClick={() => setPicker(true)}
          readOnly
        />
      </FieldContainer>
      {/* zindex = 1 */}
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={i18n.language === 'zh' ? zhLocale : enLocale}
      >
        <div
          className="d-flex-polyfill"
          style={{ display: 'flex', height: '100%', position: 'absolute' }}
          ref={PickerRef}
        >
          <DatePicker
            disableToolbar
            variant="static"
            onChange={(date) => {
              setValue('EntryDate', moment(date).format('DD/MM/YYYY'), { shouldValidate: true });
            }}
            value={getValues('EntryDate')}
            open
            onOpen={() => setPicker(true)}
            onClose={() => setPicker(false)}
            disablePast
            autoOk
            TextFieldComponent={() => null}
            maxDate={LastTime}
          />
        </div>
      </MuiPickersUtilsProvider>
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
      {children}
    </Container>
  );
}

export default EntryDateTextField;
