import React, { useState } from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import PaymentRecordRow from './PaymentRecordRow';
import { usePaymentRecords } from '../../../models/Customer';
import Bottombar from './Bottombar';
import { useOfflinePaymentMethods, useOnlinePaymentMethods } from '../../../models/SelectOptions';

const Container = styled.div`
  background: #fff;
  border-top: 1px solid #ccc;
`;

function PaymentRecords() {
  const [pageNumber, setPageNumber] = useState(1);
  const { paymentRecords, totalPage, paymentRecordsLoading } = usePaymentRecords(pageNumber);
  const { onlinePaymentIds } = useOnlinePaymentMethods();
  const { offlinePaymentIds } = useOfflinePaymentMethods();

  return (
    <>
      {paymentRecordsLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
          <ReactLoading type="spokes" color="#fd980f" height={30} width={30} />
        </div>
      ) : (
        <Container>
          <div>
            {paymentRecords.map((record, index) => {
              return (
                <PaymentRecordRow
                  key={record.id}
                  index={index + 1}
                  details={record}
                  onlinePaymentIds={onlinePaymentIds}
                  offlinePaymentIds={offlinePaymentIds}
                />
              );
            })}
          </div>
          <Bottombar page={pageNumber} setPage={setPageNumber} totalPage={totalPage} />
        </Container>
      )}
    </>
  );
}

export default PaymentRecords;
