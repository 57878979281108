import styled from 'styled-components';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FieldError from './FieldError';

const selectStyles = (address) => ({
  singleValue: (provided) => ({ ...provided, color: '#666' }),

  indicatorSeparator: () => {},
  dropdownIndicator: () => ({
    marginRight: 10,
    color: 'orange',
    marginTop: -5,
  }),

  control: (base) => ({
    ...base,
    boxShadow: 'none',
    minHeight: 25,
    height: 25,
    '&:hover': { borderColor: 'orange' },
    border: 'none',
    borderRadius: 0,
    fontSize: 15,
    width: address ? 120 : '100%',
    background: 'transparent',
  }),

  option: (provided, state, styles) => ({
    ...provided,
    padding: 6,
    fontSize: 13,
    ...styles,
    backgroundColor: 'transparent',
    color: state.isSelected ? 'black' : '#fd980f',
    fontWeight: state.isFocused ? 'bold' : '',
    '&:hover': { backgroundColor: '#dedede' },
    textDecoration: 'underline',
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderwidth: 10,
    border: '1px solid orange',
    fontSize: 13,
    zIndex: 999,
  }),

  valueContainer: (base) => ({
    ...base,
    padding: '0px',
  }),
});

const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 60%;
  flex-direction: column;
  margin-top: -5px;

  @media (max-width: 850px) {
    -webkit-flex: 1;
    flex: 1;
    max-width: 100%;
    padding-left: 0px;
  }
`;

const Star = styled.span`
  color: #fd980f;
  display: flex;
  font-size: 75%;
  margin-top: -0.25rem;
`;

function CarSelector({
  errorMessage,
  name,
  options,
  onChange = ([selected]) => selected,
  children,
  address,
  required,
  placeholder,
  defaultValue,
  isDisabled,
  rules,
}) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const Ref = useRef();
  const { t } = useTranslation();
  return (
    <Container>
      <Controller
        as={<Select ref={Ref} />}
        name={name}
        control={control}
        options={options}
        styles={selectStyles(error, address)}
        onChange={onChange}
        isSearchable={false}
        placeholder={placeholder || t('Registration.PleaseSelect')}
        defaultValue={defaultValue}
        menuPlacement="auto"
        onFocus={() => Ref.current.focus()}
        isDisabled={isDisabled}
        rules={rules}
        captureMenuScroll={false}
        // defaultMenuIsOpen
      />
      {required && <Star>*</Star>}
      <ErrorMessage name={name} errors={errors} as={<FieldError message={errorMessage} />} />
      {children}
    </Container>
  );
}

export default CarSelector;
