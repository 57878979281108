/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Marker, Circle, useGoogleMap } from '@react-google-maps/api';
import * as icon from '../../../media/icons';
import Sidebar from '../Sidebar';
import MarkerView from './MarkerView';
import MobileInfoDisplay from './MobileInfoDisplay';

const IMonthlyLabel = styled.div`
  background: #fff;
  position: absolute;
  right: 50px;
  display: flex;
  justify-content: center;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 40px;
  top: 89%;
`;

const LabelText = styled.span`
  margin-left: 5px;
  color: #888;
  font-size: 0.82rem;
  align-self: center;
`;

export default function MapInner({
  carParksHook,
  setCarParksDisplay,
  markers,
  isLoaded,
  zoom,
  center,
  setZoom,
  setCenter,
}) {
  const { t } = useTranslation();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [radius] = useState(1000);
  const { state } = useLocation();
  const mapRef = useGoogleMap();

  const [isSearchByRegion, setIsSearchByRegion] = useState(true);

  return (
    <>
      <Sidebar
        state={state}
        radius={radius}
        setCarParksDisplay={(arr) => setCarParksDisplay(arr)}
        selectedMarker={(carpark) => setSelectedMarker(carpark)}
        setZoom={(num) => setZoom(num)}
        setCenter={(latlng) => setCenter(latlng)}
        MapIsLoaded={isLoaded}
        mapRef={mapRef}
        isSearchByRegion={isSearchByRegion}
        handelChangeIsSearchByRegion={(e) => setIsSearchByRegion(e)}
        {...carParksHook}
      />
      {selectedMarker && <MobileInfoDisplay selectedMarker={selectedMarker} />}

      {zoom === 15 && (
        <Marker position={center} zIndex={1}>
          {!isSearchByRegion && (
            <Circle
              center={center}
              radius={radius}
              options={{
                fillColor: '#A0A0A0',
                strokeColor: 'transparent',
              }}
            />
          )}
        </Marker>
      )}

      {markers.map((marker) => {
        return (
          <MarkerView
            key={marker?.id}
            mapRef={mapRef}
            marker={marker}
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
          />
        );
      })}

      {/* <IMonthlyLabel>
        <img alt="i_monthly" src={icon.markerBlue} width="10%" />
        <LabelText>{t('Map.i-Monthly')}</LabelText>
      </IMonthlyLabel> */}
    </>
  );
}
