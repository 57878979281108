import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';

const RemarkDiv = styled.div`
  font-size: 0.9375rem;
  a {
    color: #fff;
    text-decoration: none;
    font-size: 0.9375rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  color: #fff;
`;

const InfoLabel = styled.span`
  flex-basis: 25%;
  font-size: 0.9375rem;
`;

const InfoDetail = styled.span`
  flex-basis: 70%;
  font-size: 0.9375rem;
`;

const BackToMap = styled(Link)`
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 6px;
  outline: none;
  font-size: 13px;
  transition: all 0.2s ease-out;
  &:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3) !important;
    text-decoration: none;
  }
`;

function Info({ name, address, phone, remark }) {
  const { outerWidth } = ScreenWidthListener();

  const { t } = useTranslation();
  return (
    <div style={{ background: '#fd980f', padding: 15 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <h5 style={{ color: '#fff' }}>{name}</h5>
        {outerWidth > 850 && (
          <BackToMap to="/car_parks/map">{t('CarParkDetail.BackToMap')}</BackToMap>
        )}
      </div>

      <InfoRow>
        <InfoLabel>{`${t('CarParkDetail.Address')}:`}</InfoLabel>
        <InfoDetail>{address}</InfoDetail>
      </InfoRow>
      <InfoRow>
        <InfoLabel>{`${t('CarParkDetail.Phone')}:`}</InfoLabel>
        <InfoDetail>{phone}</InfoDetail>
      </InfoRow>
      {remark && remark.length && (
        <InfoRow>
          <InfoLabel>{`${t('CarParkDetail.Remark')}:`}</InfoLabel>
          <RemarkDiv
            style={{ flexBasis: '60%', color: '#fff', marginBottom: 7 }}
            dangerouslySetInnerHTML={{ __html: remark }}
          />
        </InfoRow>
      )}
    </div>
  );
}

export default Info;
