import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-xhr-backend';
import en from './locale/en';
import zh from './locale/zh';

const detectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'path', 'cookie'],

  // keys or params to lookup language from
  lookupQuerystring: 'lang',
  lookupCookie: 'lang',
  lookupFromPathIndex: 0,

  // cache user language on
  caches: ['cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

// const Languages = ["en", "zh"];

i18n
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: { translations: en },
      zh: { translations: zh },
      'zh-hk': { translations: zh },
    },
    fallbackLng: 'en',
    lng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    detection: detectorOptions,

    // whitelist: Languages,

    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false,
    },

    // react 18next configurations properties object
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
