import styled from 'styled-components';

const PageContainer = styled.div`
  background: #dedede;
  padding-bottom: 120px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    padding-bottom: 40px;
    overflow-x: hidden;
  }
`;

export default PageContainer;
