import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const OCRUrl = `${process.env.REACT_APP_API_DOMAIN}/ocr-receipt/check`;
const UploadUrl = `${process.env.REACT_APP_API_DOMAIN}/upload`;

function useOCR() {
  const [OCRResult, setOCRResult] = useState({});
  const [OCRLoading, setOCRLoading] = useState(false);

  async function checkOCR(img, orderId, amount, type) {
    setOCRLoading(true);
    try {
      const formData = new FormData();
      formData.append('image', img);
      const { data } = await axios.post(`${UploadUrl}`, formData);
      const ImgUrl = `${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}${data}`;

      try {
        const {
          data: { data: result },
        } = await axios.post(`${OCRUrl}`, {
          imageURL: ImgUrl,
          OrderId: orderId,
          verifyAmount: amount,
          receiptType: type,
        });
        setOCRResult(result);
        setOCRLoading(false);
      } catch (error) {
        setOCRResult(error);
        setOCRLoading(false);
      }
    } catch (error) {
      setOCRResult(error);
      setOCRLoading(false);
    }
  }

  return { checkOCR, OCRResult, OCRLoading };
}

export default useOCR;
