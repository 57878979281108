import React from 'react';
import { withRouter } from 'react-router-dom';

function Row(props) {
  const { children, staticContext, ...rest } = props;
  return (
    <div className="menu-row" {...rest}>
      {children}
    </div>
  );
}

export default withRouter(Row);
