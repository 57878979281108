import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CartItemContainer = styled.div`
  background: #fff;
  padding: 25px 0px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  width: 100%;
  padding-right: 30px;
  margin-top: 20px;

  @media (max-width: 760px) {
    flex-direction: column;
    padding-right: 0px;
    margin-top: 10px;
  }
`;

const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;

  @media (max-width: 760px) {
    width: 100%;
    margin-top: 20px;
    justify-content: space-around;
  }
`;

const BackLink = styled(Link)`
  color: #fd980f;
  margin-left: 10px;
  transition: all 0.1s ease-out;
  font-size: 0.8rem !important;
  text-decoration: none !important;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #e3890e;
  }

  &::before {
    background-image: url('https://www.wilsonparking.com.hk/img/icon-next-white.png');
    background-color: #fd980f;
    transform: rotate(180deg);
    content: '';
    border-radius: 50px;
    display: inline-block;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: auto 55%;
    background-position: center;
    vertical-align: text-top;
    margin: 0 5px 0 10px !important;
  }

  @media (max-width: 760px) {
    align-self: flex-start;
  }
`;

export { CartItemContainer, ButtonRow, InnerRow, BackLink };
