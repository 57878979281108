import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
import {
  DetailRightBox,
  SigninTitle,
  SigninText,
  FormInner,
  FormContainer,
  DetailRightDiv,
  RightBoxArrow,
  RightBoxHeaderBar,
} from '.';
import { TokenContext } from '../../../models/Customer';
import Arrow from '../../../media/images/icon-expend-white.png';

const Input = styled.input`
  border: 1px solid ${({ error }) => (error ? '#E56E5A' : '#bbb')};
`;

const Errormsg = styled.div`
  color: #e56e5a !important;
  font-size: 13px;
  margin-top: 5px;
`;

function SigninBox() {
  const { t } = useTranslation();
  const { login } = useContext(TokenContext);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleLogin = () => {
    login(username, password);
  };

  return (
    <DetailRightDiv>
      <DetailRightBox open={open}>
        <RightBoxHeaderBar onClick={() => setOpen(!open)}>
          <SigninTitle>{t('IhourlyCart.SignBoxTitle')}</SigninTitle>
          <SigninText>{t('IhourlyCart.SignBoxText')}</SigninText>
          <RightBoxArrow src={Arrow} open={open} />
        </RightBoxHeaderBar>
        <FormContainer open={open}>
          <FormInner>
            <div>
              <span>{t('IhourlyCart.Username')}</span>
            </div>
            <Input onChange={(e) => setUsername(e.target.value)} error={error} />
          </FormInner>
          <FormInner>
            <div>
              <span>{t('IhourlyCart.Password')}</span>
            </div>
            <Input type="password" onChange={(e) => setPassword(e.target.value)} error={error} />
          </FormInner>
          {error && <Errormsg>{t('IhourlyCart.LoginError')}</Errormsg>}
          <FormInner>
            <button
              type="button"
              onClick={() => {
                if (!username || !password) return;
                if (
                  username.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) &&
                  password.match(/^(?=.*[A-Z])(?=.*\d).{8,15}$/)
                ) {
                  setError(false);
                  handleLogin();
                } else {
                  setError(true);
                }
              }}
            >
              {t('IhourlyCart.Login')}
            </button>
          </FormInner>
        </FormContainer>
      </DetailRightBox>
    </DetailRightDiv>
  );
}

export default SigninBox;
