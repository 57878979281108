import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm, FormContext } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import accountStyle from '../../../styles/account';
import { useSelectOptions } from '../../../models/User';
import * as icon from '../../../media/icons';
import octopusCardHorizontal from '../../../assets/images/octopusCard_horizontal.png';
import mastercard from '../../../assets/images/mastercard.png';
import { SelectField, TextField } from '../../../components/FormComponents';
import Modal from '../../../components/General/Modal';
import PopUpModal from '../../../components/General/PopUpModal';

const ModalTitle = styled.span`
  font-weight: bold;
  font-size: 1.75rem;
  color: #444;
  align-self: center;
`;

const ModalItemLabel = styled.span`
  font-size: 0.8125rem;
  color: #666;
  flex-basis: 25%;
  align-self: ${(props) => (props.unset ? 'unset' : 'center')};
`;

export const ActionButton = styled.button`
  background: #fd980f;
  color: white;
  border: none;
  outline: none;
  padding: ${(props) => (props.padding ? props.padding : '8px 20px')};
  font-size: 0.8125rem;
  transition: all 0.1s ease-out;
  text-decoration: none !important;
  align-self: center;
  margin: ${(props) => props.hasMargin && '10px 0 15px 27%'};
  &:hover {
    background: #666;
  }
`;

const IPark = ({ carsLoading, allCars }) => {
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [showSupportedCarParks, setShowSupportedCarParks] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem('iPark');
  }, []);

  return (
    <>
      {carsLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
          <ReactLoading type="spokes" color="#fd980f" height={30} width={30} />
        </div>
      ) : (
        <div style={{ backgroundColor: '#fff', borderTop: '1px solid #ccc' }}>
          {allCars.map((record, index) => (
            <IParkRow
              key={record.carId}
              index={index + 1}
              details={record}
              setShowSupportedCarParks={setShowSupportedCarParks}
            />
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <ActionButton onClick={() => setShowAddVehicle(true)} padding="10px 32px">
              {t('IPark.addVehicle')}
            </ActionButton>
          </div>

          {/* TODO: only render this when clicked on */}
          <AddVehicle
            showAddVehicle={showAddVehicle}
            back={() => setShowAddVehicle(false)}
            allCars={allCars}
          />

          {/* TODO: only render this when clicked on */}
          <SupportedCarParks
            allCars={allCars}
            showSupportedCarParks={showSupportedCarParks}
            back={() => setShowSupportedCarParks(false)}
          />
        </div>
      )}
    </>
  );
};

export default IPark;

const IParkRow = ({ index, details, setShowSupportedCarParks }) => {
  const backgroundColor = index % 2 === 0 ? '#fbf1e7' : 'fff';
  const { t } = useTranslation();

  const {
    activateCard,
    activateCardNumber,
    activationStatus,
    carType,
    CarTypeId,
    registrationMark,
    carId,
  } = details;

  const renderActivated = () => {
    const card = activateCardNumber;

    const cardNumber =
      activateCard === 'octopus' ? card.replace(/\s/g, '') : card.slice(card.length - 5);
    return (
      <div style={{ flexBasis: '100%' }}>
        <h5>{carType}</h5>
        <h5 style={{ color: '#fd980f' }}>{registrationMark}</h5>

        <div style={{ margin: '10px 0' }}>
          <img style={{ marginRight: 10 }} src={icon.hourglass} width="25" alt="pending" />
          <b>{activationStatus}</b>
        </div>

        <p style={{ color: 'red', fontSize: '0.9rem', margin: 5 }}>
          {activateCard === 'octopus' ? t('IPark.pleaceUseOctopus') : t('IPark.pleaceUseCredit')}
          <button
            type="button"
            style={{ color: '#fd980f', background: 'none', border: 'none' }}
            onClick={() => setShowSupportedCarParks(true)}
          >
            {t('IPark.iParkSupport')}
          </button>
          {t('IPark.once')}
        </p>

        <CardInfoDisplay cardType={activateCard} cardNumber={cardNumber} />

        <button
          type="button"
          style={{ background: 'none', border: 'none', color: '#fd980f', fontSize: '0.9rem' }}
          onClick={() => {
            const iParkObj = {
              action: 'change',
              CarTypeId,
              registrationMark,
              verificationMethod: 'octopus',
              carId,
            };
            localStorage.setItem('iPark', JSON.stringify(iParkObj));
            window.location = '/customers/activate/1';
          }}
        >
          {t('IPark.changeVer')}
        </button>
      </div>
    );
  };

  const renderUnactivated = () => (
    <>
      <div>
        <h5>{carType}</h5>
        <h5 style={{ color: '#fd980f' }}>{registrationMark}</h5>
      </div>

      <ActionButton
        type="button"
        padding="10px 35px"
        onClick={() => {
          const iParkObj = {
            action: 'activate',
            CarTypeId,
            registrationMark,
            verificationMethod: 'octopus',
            carId,
          };
          localStorage.setItem('iPark', JSON.stringify(iParkObj));
          window.location = '/customers/activate/1';
        }}
      >
        {t('IPark.activate')}
      </ActionButton>
    </>
  );

  return (
    <div style={{ ...accountStyle.iParkRowContainer, background: backgroundColor }}>
      {activateCard === null ? renderUnactivated() : renderActivated()}
    </div>
  );
};

export const CardInfoDisplay = ({ cardType, cardNumber }) => (
  <>
    {cardType === 'octopus' ? (
      <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            display: 'inline-flex',
            border: '1px solid #ccc',
            padding: '5px 0',
          }}
        >
          <img alt="" src={octopusCardHorizontal} height="24" style={{ objectFit: 'contain' }} />
        </div>
        <span
          style={{
            fontSize: '1.125rem',
            fontWeight: 'bold',
            letterSpacing: '0.1rem',
            color: '#666666',
            marginLeft: 5,
          }}
        >
          {cardNumber}
        </span>
      </div>
    ) : (
      <div style={{ marginBottom: 5, display: 'flex' }}>
        <div
          style={{
            display: 'inline-flex',
            border: '1px solid #ccc',
            padding: '0 10px',
            background: '#fff',
          }}
        >
          <img alt="" src={mastercard} height="30" style={{ objectFit: 'contain' }} />
        </div>

        <span style={{ fontSize: '0.3rem', marginLeft: 10, alignSelf: 'center', color: '#666666' }}>
          &#9679;&#9679;&#9679;&#9679; &nbsp;&nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: '1.125rem',
            fontWeight: 'bold',
            letterSpacing: '0.1rem',
            color: '#666666',
          }}
        >
          {cardNumber}
        </span>
      </div>
    )}
  </>
);

const SupportedCarParks = ({ showSupportedCarParks, back, allCars }) => {
  const itemsPerPage = 20;
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);

  const renderCarPark = (data1, data2) => {
    return (
      <div key={data1}>
        <div
          style={{
            flexBasis: '27%',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '1rem',
            marginLeft: 30,
          }}
        >
          <span>{data1}</span>
          <span>{data2}</span>
        </div>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
      </div>
    );
  };

  const showItems = (carPark) => {
    const allCarParks = [];
    for (let i = 0; i < records; i += 1) {
      if (carPark[i] !== undefined) {
        allCarParks.push(renderCarPark(carPark[i].id, 'private car car'));
      }
    }
    return allCarParks;
  };

  const loadMore = () => {
    if (records >= allCars.length) {
      setHasMoreItems(false);
    } else {
      setTimeout(() => {
        setRecords(records + itemsPerPage);
        // (posts.length-records)>10? setRecords(records + 10):setRecords(records+15);
      }, 1000);
    }
  };

  return (
    <Modal
      show={showSupportedCarParks}
      onHide={() => {
        setRecords(20);
        setHasMoreItems(true);
        back();
      }}
      style={{
        overflowY: 'auto',
        height: '65vh',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 10,
      }}
    >
      <h3 style={{ fontWeight: 'bold', marginBottom: 15, marginLeft: 30 }}>iPARK CAR PARKS</h3>
      <hr />
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMoreItems}
        loader={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        }
        useWindow={false}
      >
        {showItems(allCars)}
      </InfiniteScroll>
    </Modal>
  );
};

const AddVehicle = ({ showAddVehicle, back, allCars }) => {
  const { t } = useTranslation();
  const { carType: carTypeOptions } = useSelectOptions();
  const methods = useForm();

  const renderPreviousRecord = (carTypeId, carType, registrationMark) => {
    return (
      <div
        // key={carTypeId}
        style={{ display: 'flex', marginBottom: 15, justifyContent: 'space-between' }}
      >
        <div style={{ flexBasis: '27%', display: 'flex', flexDirection: 'column' }}>
          <ModalItemLabel unset>{carType}</ModalItemLabel>
          <ModalItemLabel unset>{registrationMark}</ModalItemLabel>
        </div>
        <ActionButton
          type="button"
          onClick={() => {
            const iParkObj = {
              action: 'add',
              carTypeId,
              registrationMark,
              verificationMethod: 'octopus',
            };
            localStorage.setItem('iPark', JSON.stringify(iParkObj));
            window.location = '/customers/activate/1';
          }}
        >
          {t('IPark.select')}
        </ActionButton>
      </div>
    );
  };

  const handleAddVehicle = () => {
    const carType = methods.watch('carType') || {};
    const regMark = methods.watch('registrationMark');
    const iParkObj = {
      action: 'add',
      carTypeId: carType.value,
      registrationMark: regMark,
      verificationMethod: 'octopus',
    };

    if (Object.keys(carType).length > 0 && regMark !== undefined) {
      localStorage.setItem('iPark', JSON.stringify(iParkObj));
      window.location = '/customers/activate/1';
    } else {
      alert('Please fill in everything');
    }
  };

  return (
    <PopUpModal Popupshow={showAddVehicle} onClose={back} widthStyle={{ minWidth: 800 }}>
      <FormContext {...methods}>
        <ModalTitle>{t('IPark.addVehicle')}</ModalTitle>

        <div style={{ display: 'flex', marginTop: 20 }}>
          <ModalItemLabel>{t('IPark.carType')}</ModalItemLabel>
          <SelectField
            name="carType"
            getOptionLabel={(label) => label}
            getOptionValue={(value) => value}
            maxMenuHeight={500}
            options={carTypeOptions}
          />
        </div>

        <div style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
          <ModalItemLabel>{t('IPark.VRH')}</ModalItemLabel>
          <TextField address="address" name="registrationMark" style={{ flex: 1 }} />
        </div>

        <ActionButton type="button" onClick={handleAddVehicle} hasMargin>
          {t('IPark.add')}
        </ActionButton>

        <hr />

        <ModalItemLabel>{t('IPark.selectFrom')}</ModalItemLabel>

        <div
          style={{
            height: '30vh',
            overflowY: 'auto',
            marginTop: 20,
            maxWidth: '35vh',
            paddingRight: 15,
            paddingLeft: 15,
          }}
        >
          {allCars.map(({ id, carType, registrationMark }) => {
            return renderPreviousRecord(id, carType, registrationMark);
          })}
        </div>
      </FormContext>
    </PopUpModal>
  );
};
