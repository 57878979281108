import React, { useState } from 'react';
import styled from 'styled-components';
import Slidediv from './Slidediv';

const StyledBurger = styled.div`
  display: none;
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 7px;
  margin-left: 10px;
  div {
    width: 20px;
    height: 3px;
    margin-top: 4px;
    background-color: #444;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? ' translate3d(0,7px,0) rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? ' translate3d(0,-7px,0) rotate(-45deg)' : 'rotate(0)')};
    }
  }
  @media (max-width: 980px) {
    display: block;
  }
`;

const MobileSlideMenu = (changeLanguage) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>

      <Slidediv open={open} changeLanguage={changeLanguage} setopen={setOpen} />
    </div>
  );
};

export default MobileSlideMenu;
