/* eslint-disable react/jsx-props-no-spreading */
import { FormContext, useForm } from 'react-hook-form';
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import PopUpModal from '../../../components/General/PopUpModal';
import { RadioButton, RadioGroup } from '../../../components/FormComponents';
import { CartContext } from '../../../models/Cart';
import { CarParkContext } from '../../../models/CarPark';
import StyledButton from '../../../components/General/StyledButton';

const Radio = styled(RadioButton)`
  margin-top: 10px;
  font-size: 0.9rem;
  color: #888;
  width: 100%;
`;

const TodayRadio = styled(Radio)`
  width: 55%;
`;

const DateLabel = styled.p`
  margin-left: 35px;
  margin-top: 5px;
  font-size: 0.875rem;
  color: #888;
`;

const FeeLabel = styled(DateLabel)`
  margin-top: -15px;
`;

const RadioLabel = styled.span`
  margin-bottom: 25px;
  color: #fd980f;
  font-weight: bold;
  font-size: 0.875rem;
`;

const TodayHint = styled.p`
  margin-left: 35px;
  font-size: 0.8rem;
  width: 40%;
  color: #888;
`;

const NextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalTitle = styled.span`
  font-weight: bold;
  font-size: 1.7rem;
  color: #444444;
`;

// const dateFormat = 'DD MMM YYYY';
const halfMonthMark = 15;
const quarterMonthMark = parseInt(process.env.REACT_APP_RENEWAL_DATE || '22', 10);

function BookingModal({ show, setShow, plan = {}, carParkInfo }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const methods = useForm();

  const to = 'CarParkDetail.Booking.to';
  const { carPark } = useContext(CarParkContext);
  const {
    generalCart: { book },
  } = useContext(CartContext);

  const { id: ParkingPlanId, amount, isReserved, subTitle, CarTypeId } = plan || {};

  const [currentOption, setCurrentOption] = useState({});
  const [nextOption, setNextOption] = useState({});
  const [dateFormat, setFormat] = useState('DD MMM YYYY');

  useEffect(() => {
    setFormat(language === 'en' ? 'DD MMM YYYY' : 'YYYY年 MMM DD日');
  }, [language]);

  useEffect(() => {
    const API_DATE_FORMAT = 'DD/MM/YYYY';
    const today = moment().locale(language === 'en' ? 'en' : 'zh-hk');
    const startMonth = today.clone().startOf('month');
    const halfMonth = today.clone().date(halfMonthMark);
    const quarterMonth = today.clone().date(quarterMonthMark);
    const endMonth = today.clone().endOf('month');

    if (today.isBetween(quarterMonth, endMonth, undefined, '[]')) {
      // const start = halfMonth.add(1, 'day').format(dateFormat);
      const start = today;
      const next = today.clone().add(1, 'month').startOf('month');
      const end = today.clone().add(1, 'month').endOf('month');
      setCurrentOption({
        displayPeriod: `${start.format(dateFormat)} ${t(to)} ${end.format(dateFormat)}`,
        period: `${start.format(API_DATE_FORMAT)} to ${end.format(API_DATE_FORMAT)}`,
        amount: parseInt(amount, 10) * 1.5,
      });
      setNextOption({
        displayPeriod: `${next.format(dateFormat)} ${t(to)} ${end.format(dateFormat)}`,
        period: `${next.format(API_DATE_FORMAT)} to ${end.format(API_DATE_FORMAT)}`,
        amount: parseInt(amount, 10),
      });
      return;
    }

    if (today.isBetween(startMonth, halfMonth, undefined, '[]')) {
      const start = today;
      const end = today.clone().endOf('month');
      setCurrentOption({
        displayPeriod: `${start.format(dateFormat)} ${t(to)} ${end.format(dateFormat)}`,
        period: `${start.format(API_DATE_FORMAT)} to ${end.format(API_DATE_FORMAT)}`,
        amount: parseInt(amount, 10),
      });
      return;
    }

    if (today.isBetween(halfMonth, quarterMonth, undefined, '()')) {
      // const start = halfMonth.add(1, 'day').format(dateFormat);
      const start = today;
      const end = today.clone().endOf('month');
      setCurrentOption({
        displayPeriod: `${start.format(dateFormat)} ${t(to)} ${end.format(dateFormat)}`,
        period: `${start.format(API_DATE_FORMAT)} to ${end.format(API_DATE_FORMAT)}`,
        amount: parseInt(amount, 10) * 0.5,
      });
      return;
    }

    const start = today;
    const end = today.clone().endOf('month');
    setCurrentOption({
      displayPeriod: `${start.format(dateFormat)} ${t(to)} ${end.format(dateFormat)}`,
      period: `${start.format(API_DATE_FORMAT)} to ${end.format(API_DATE_FORMAT)}`,
      amount: parseInt(amount, 10),
    });
  }, [plan]);

  const addToCart = async () => {
    const option = methods.getValues('servicePeriodChoice');

    if (option) {
      const selected = option === 'today' ? currentOption : nextOption;

      await book({
        carPark,
        name: carParkInfo.name,
        address: carParkInfo.address,
        ParkingPlanId,
        type: 'new',
        servicePeriod: selected.period,
        fee: selected.amount,
        isReserved,
        service: subTitle,
        CarTypeId,
        createdDate: moment().toISOString(),
      });

      window.location = '/cart';
    }
  };

  const radios = [
    <RadioButton
      key="today"
      value="today"
      name="servicePeriodChoice"
      style={{ width: '50%', cursor: 'pointer' }}
    >
      <RadioLabel>{t('CarParkDetail.Booking.FromToday')}</RadioLabel>
      <DateLabel>{currentOption.displayPeriod}</DateLabel>
      <FeeLabel>
        HK$
        {currentOption.amount}
      </FeeLabel>
    </RadioButton>,
  ];

  if (Object.keys(nextOption).length > 0) {
    radios.push(
      <RadioButton
        key="nextMonth"
        value="nextMonth"
        name="servicePeriodChoice"
        style={{ width: '50%', cursor: 'pointer' }}
      >
        <RadioLabel>{t('CarParkDetail.Booking.FromNextMonth')}</RadioLabel>
        <DateLabel>{nextOption.displayPeriod}</DateLabel>
        <FeeLabel>
          HK$
          {nextOption.amount}
        </FeeLabel>
      </RadioButton>
    );
  }

  return (
    <PopUpModal Popupshow={show} onClose={() => setShow(false)} center>
      <ModalTitle>{t('CarParkDetail.Booking.SelectPrompt')}</ModalTitle>

      <FormContext {...methods}>
        <form style={{ marginTop: 5 }}>
          <RadioGroup groupStyle={{ display: 'flex' }}>{radios.map((radio) => radio)}</RadioGroup>

          {!isEmpty(nextOption) && methods.watch('servicePeriodChoice') === 'nextMonth' && (
            <TodayHint>{t('CarParkDetail.Booking.FromTodayHint')}</TodayHint>
          )}

          <NextContainer>
            <StyledButton
              color="yellow"
              type="button"
              onClick={addToCart}
              label={t('CarParkDetail.Booking.Next')}
            />
          </NextContainer>
        </form>
      </FormContext>
    </PopUpModal>
  );
}

export default BookingModal;
