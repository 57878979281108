import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import closeimg from '../../media/images/icons8-close-window-52.png';
import ScreenWidthListener from './ScreenWidthListener';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: ${(props) => (props.Popupshow ? 'flex' : 'none')};
  justify-content: center;
  z-index: 99999;
  overflow: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 980px) {
    margin-top: 40px;

    #embedded-checkout-modal {
      max-height: 90vh !important;
      overflow: scroll !important;
    }
  }

  #embedded-checkout-modal {
    width: 80vw;
    height: 90vh;
    max-width: 850px;
    padding: 20px;
    background-color: #fff;
  }
`;

const MainWidth = styled.div`
  background-color: #fff;
  z-index: 2000;
  position: absolute;
  background: #fff;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 30px 30px 50px 10px;
  box-shadow: 0 0 2px #bbb;

  @media (max-width: 550px) {
    margin: 0px 0px 0px 0px;
  }
`;

const ContentContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const ContentWidth = styled.div`
  background: #fff;
  padding: 20px;
  width: 100%;
`;

const SubTitle = styled.p`
  font-size: 14px;
  text-decoration-line: underline;
  font-weight: bold;
  color: #666;
`;

const Text = styled.p`
  font-size: 14px;
  color: #666666;
`;

const Margin = styled.div`
  margin-top: ${(props) => (props.XLmargin ? '60px' : '20px')};
`;

const Li = styled.li`
  font-size: 14px;
  color: #666666;
  list-style: disc;
`;

const MarginLi = styled.li`
  font-size: 14px;
  color: #666666;
  margin: 20px 0px 20px 0px;
`;

const UL = styled.ul`
  list-style: disc;
  margin-left: 1.2rem;
`;

const SecondSubTitle = styled.p`
  font-size: 14px;
  text-decoration-line: underline;
  color: #666;
`;

const CloseButton = styled.button`
  background: #fff;
  border-radius: 200px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -15px;
  border: none;
  background: transparent;

  @media (max-width: 570px) {
    top: 5px;
    right: -5px;
  }
`;

const CloseImg = styled.img`
  width: 25px;
  height: 25px;
  z-index: 2000;
  border-radius: 100px;
  border: 2px solid #fff;
  box-shadow: 0 2px 2px #6c6c6c;
`;

const BackDrop = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
`;

const Modal = ({
  onClose,
  Popupshow,
  children,
  //  PrivacyPolicy, iMONTHLYTerms
}) => {
  const { t, i18n } = useTranslation();
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { outerWidth } = ScreenWidthListener();

  useLayoutEffect(() => {
    if (targetRef.current && Popupshow) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [outerWidth, targetRef, Popupshow]);

  useEffect(() => {
    if (Popupshow === 'PrivacyPolicy' || Popupshow === 'iMONTHLYTerms') {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.touchAction = 'auto';
    }
  }, [Popupshow]);

  return (
    <Container Popupshow={Popupshow}>
      <BackDrop
        language={i18n.language}
        height={dimensions.height + 100}
        onClick={typeof onClose === 'function' ? onClose() : () => {}}
      />

      <MainWidth>
        {onClose && (
          <CloseButton onClick={onClose}>
            <CloseImg src={closeimg} />
          </CloseButton>
        )}

        <ContentContainer ref={targetRef}>
          {Popupshow === 'PrivacyPolicy' && (
            <ContentWidth>
              <SubTitle>
                {t('PRIVACYPOLICY.Title1')}
                <br />
              </SubTitle>
              <Text>{t('PRIVACYPOLICY.T1text1')}</Text>

              <Text>{t('PRIVACYPOLICY.T1text2')}</Text>

              <SecondSubTitle>
                {t('PRIVACYPOLICY.Title2')}
                <br />
              </SecondSubTitle>
              <Text>{t('PRIVACYPOLICY.T2text1')}</Text>

              <Text>{t('PRIVACYPOLICY.T2text2')}</Text>

              <Text>{t('PRIVACYPOLICY.T2text3')}</Text>

              <Text>{t('PRIVACYPOLICY.T2text4')}</Text>

              <Text>{t('PRIVACYPOLICY.T2text5')}</Text>

              <Text>{t('PRIVACYPOLICY.T2text6')}</Text>
              <div style={{ marginTop: 40 }} />

              <SubTitle>
                {t('PRIVACYPOLICY.Title3')}
                <br />
              </SubTitle>
              <Text>{t('PRIVACYPOLICY.T3text1')}</Text>

              <SecondSubTitle>
                {t('PRIVACYPOLICY.Title4')}
                <br />
              </SecondSubTitle>
              <Text>{t('PRIVACYPOLICY.T4text1')}</Text>
              <Text>{t('PRIVACYPOLICY.T4text2')}</Text>

              <SecondSubTitle>
                {t('PRIVACYPOLICY.Title5')}
                <br />
              </SecondSubTitle>
              <div className="d-flex-polyfill" style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ margin: '0' }}>{` ${t('PRIVACYPOLICY.T5text1')} `}</Text>
                <Li>{t('PRIVACYPOLICY.T5text2')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text3')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text4')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text5')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text6')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text7')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text8')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text9')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text10')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text11')}</Li>
                <Li>{t('PRIVACYPOLICY.T5text12')}</Li>
                <Margin />
                <Text>{t('PRIVACYPOLICY.T5text13')}</Text>
              </div>

              <SecondSubTitle>
                {t('PRIVACYPOLICY.Title6')}
                <br />
              </SecondSubTitle>
              <Text>{t('PRIVACYPOLICY.T6text1')}</Text>

              <SecondSubTitle>
                {t('PRIVACYPOLICY.Title7')}
                <br />
              </SecondSubTitle>
              <Text>{t('PRIVACYPOLICY.T7text1')}</Text>

              <SecondSubTitle>
                {t('PRIVACYPOLICY.Title8')}
                <br />
              </SecondSubTitle>
              <Text>{t('PRIVACYPOLICY.T8text1')}</Text>

              <Text>{t('PRIVACYPOLICY.T8text2')}</Text>

              <SubTitle>
                {t('PRIVACYPOLICY.Title9')}
                <br />
              </SubTitle>
              <Text>{t('PRIVACYPOLICY.T9text1')}</Text>

              <Text>{`${t('PRIVACYPOLICY.T9text2')}.`}</Text>
            </ContentWidth>
          )}
          {Popupshow === 'iMONTHLYTerms' && (
            <ContentWidth>
              <SecondSubTitle style={{ fontWeight: 'bold' }}>
                {t('iMONTHLYTerms.Title')}
                <br />
              </SecondSubTitle>
              <Text>{t('iMONTHLYTerms.Text1')}</Text>

              <Text>{t('iMONTHLYTerms.Text2')}</Text>
              <UL>
                <MarginLi>{t('iMONTHLYTerms.Text3')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text4')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text5')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text6')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text7')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text8')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text9')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text10')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.Text11')}</MarginLi>
              </UL>

              <Text style={{ marginTop: 60 }}>
                {t('iMONTHLYTerms.T2Title')}
                <br />
              </Text>

              <UL>
                <MarginLi>{t('iMONTHLYTerms.T2Text')}</MarginLi>
              </UL>

              <Text style={{ marginTop: 60 }}>
                {t('iMONTHLYTerms.T3Title')}
                <br />
              </Text>
              <UL>
                <MarginLi>{t('iMONTHLYTerms.T3Text1')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T3Text2')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T3Text3')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T3Text4')}</MarginLi>
              </UL>

              <Text style={{ marginTop: 60 }}>
                {t('iMONTHLYTerms.T4Title')}
                <br />
              </Text>

              <UL>
                <MarginLi>{t('iMONTHLYTerms.T4Text1')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T4Text2')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T4Text3')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T4Text4')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T4Text5')}</MarginLi>
              </UL>

              <Text style={{ marginTop: 60 }}>
                {t('iMONTHLYTerms.T5Title')}
                <br />
              </Text>
              <UL>
                <MarginLi>{t('iMONTHLYTerms.T5Text1')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text2')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text3')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text4')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text5')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text6')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text7')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text8')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text9')}</MarginLi>
                <MarginLi>{t('iMONTHLYTerms.T5Text10')}</MarginLi>
              </UL>

              <SecondSubTitle style={{ marginTop: 60, fontWeight: 'bold' }}>
                {t('iMONTHLYTerms.T6Title')}
                <br />
              </SecondSubTitle>
              <Text>{t('iMONTHLYTerms.T6Text1')}</Text>
              <Text>{t('iMONTHLYTerms.T6Text2')}</Text>
            </ContentWidth>
          )}
          {Popupshow === 'Custom' && children}
        </ContentContainer>
      </MainWidth>
    </Container>
  );
};

export default Modal;
