/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

function Test() {
  const lineOffset = 4;
  const anchrSize = 2;

  const [mouseDown, setMouseDown] = useState(false);
  const [postion, setPostion] = useState({
    x1: -1,
    y1: -1,
    x2: -1,
    y2: -1,
  });
  const [clickedArea, setClickedArea] = useState({
    box: -1,
    pos: 'o',
  });
  const [boxes, setBoxes] = useState([]);
  const [tmpBox, setTemBox] = useState(null);

  function findCurrentArea(x, y) {
    for (let i = 0; i < boxes.length; i += 1) {
      const box = boxes[i];
      const xCenter = box.x1 + (box.x2 - box.x1) / 2;
      const yCenter = box.y1 + (box.y2 - box.y1) / 2;
      if (box.x1 - lineOffset < x && x < box.x1 + lineOffset) {
        if (box.y1 - lineOffset < y && y < box.y1 + lineOffset) {
          return {
            box: i,
            pos: 'tl',
          };
        }
        if (box.y2 - lineOffset < y && y < box.y2 + lineOffset) {
          return {
            box: i,
            pos: 'bl',
          };
        }
        if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
          return {
            box: i,
            pos: 'l',
          };
        }
      } else if (box.x2 - lineOffset < x && x < box.x2 + lineOffset) {
        if (box.y1 - lineOffset < y && y < box.y1 + lineOffset) {
          return {
            box: i,
            pos: 'tr',
          };
        }
        if (box.y2 - lineOffset < y && y < box.y2 + lineOffset) {
          return {
            box: i,
            pos: 'br',
          };
        }
        if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
          return {
            box: i,
            pos: 'r',
          };
        }
      } else if (xCenter - lineOffset < x && x < xCenter + lineOffset) {
        if (box.y1 - lineOffset < y && y < box.y1 + lineOffset) {
          return {
            box: i,
            pos: 't',
          };
        }
        if (box.y2 - lineOffset < y && y < box.y2 + lineOffset) {
          return {
            box: i,
            pos: 'b',
          };
        }
        if (box.y1 - lineOffset < y && y < box.y2 + lineOffset) {
          return {
            box: i,
            pos: 'i',
          };
        }
      } else if (box.x1 - lineOffset < x && x < box.x2 + lineOffset) {
        if (box.y1 - lineOffset < y && y < box.y2 + lineOffset) {
          return {
            box: i,
            pos: 'i',
          };
        }
      }
    }
    return {
      box: -1,
      pos: 'o',
    };
  }

  function newBox(x1, y1, x2, y2) {
    const boxX1 = x1 < x2 ? x1 : x2;
    const boxY1 = y1 < y2 ? y1 : y2;
    const boxX2 = x1 > x2 ? x1 : x2;
    const boxY2 = y1 > y2 ? y1 : y2;
    if (boxX2 - boxX1 > lineOffset * 2 && boxY2 - boxY1 > lineOffset * 2) {
      return {
        x1: boxX1,
        y1: boxY1,
        x2: boxX2,
        y2: boxY2,
        lineWidth: 1,
        color: 'DeepSkyBlue',
      };
    }
    return null;
  }

  function drawBoxOn(box, context) {
    const xCenter = box.x1 + (box.x2 - box.x1) / 2;
    const yCenter = box.y1 + (box.y2 - box.y1) / 2;

    context.strokeStyle = box.color;
    context.fillStyle = box.color;

    context.rect(box.x1, box.y1, box.x2 - box.x1, box.y2 - box.y1);
    context.lineWidth = box.lineWidth;
    context.stroke();

    context.fillRect(box.x1 - anchrSize, box.y1 - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(box.x1 - anchrSize, yCenter - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(box.x1 - anchrSize, box.y2 - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(xCenter - anchrSize, box.y1 - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(xCenter - anchrSize, yCenter - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(xCenter - anchrSize, box.y2 - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(box.x2 - anchrSize, box.y1 - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(box.x2 - anchrSize, yCenter - anchrSize, 2 * anchrSize, 2 * anchrSize);
    context.fillRect(box.x2 - anchrSize, box.y2 - anchrSize, 2 * anchrSize, 2 * anchrSize);
  }

  function redraw() {
    // canvas.width = canvas.width;
    const context = document.getElementById('canvas').getContext('2d');
    context.clearRect(0, 0, 800, 600);
    context.beginPath();
    for (let i = 0; i < boxes.length; i += 1) {
      drawBoxOn(boxes[i], context);
    }
    if (clickedArea.box === -1) {
      setTemBox(newBox(postion.x1, postion.y1, postion.x2, postion.y2));
      if (tmpBox != null) {
        drawBoxOn(tmpBox, context);
      }
    }
  }

  function onMouseDown(e) {
    setMouseDown(true);
    setClickedArea(findCurrentArea(e.nativeEvent.offsetX, e.nativeEvent.offsetY));
    setPostion({
      ...postion,
      x1: e.nativeEvent.offsetX,
      y1: e.nativeEvent.offsetY,
      x2: e.nativeEvent.offsetX,
      y2: e.nativeEvent.offsetY,
    });
  }

  function onMouseUp() {
    if (clickedArea.box === -1 && tmpBox != null) {
      setBoxes((prev) => [...prev, tmpBox]);
    } else if (clickedArea.box !== -1) {
      const selectedBox = boxes[clickedArea.box];
      if (selectedBox.x1 > selectedBox.x2) {
        const previousX1 = selectedBox.x1;
        selectedBox.x1 = selectedBox.x2;
        selectedBox.x2 = previousX1;
      }
      if (selectedBox.y1 > selectedBox.y2) {
        const previousY1 = selectedBox.y1;
        selectedBox.y1 = selectedBox.y2;
        selectedBox.y2 = previousY1;
      }
    }

    setClickedArea({
      box: -1,
      pos: 'o',
    });
    setTemBox(null);
    setMouseDown(false);
  }

  function onMouseOut() {
    if (clickedArea.box !== -1) {
      const selectedBox = boxes[clickedArea.box];
      if (selectedBox.x1 > selectedBox.x2) {
        const previousX1 = selectedBox.x1;
        selectedBox.x1 = selectedBox.x2;
        // selectedBox.x2 > previousX1;
      }
      if (selectedBox.y1 > selectedBox.y2) {
        const previousY1 = selectedBox.y1;
        selectedBox.y1 = selectedBox.y2;
        // selectedBox.y2 > previousY1;
      }
    }
    setMouseDown(false);
    if (clickedArea.box !== -1) {
      boxes.splice(clickedArea.box, 1);
      redraw();
    }
    setClickedArea({
      box: -1,
      pos: 'o',
    });
    setTemBox(null);
  }

  function onMouseMove(e) {
    if (mouseDown && clickedArea.box === -1) {
      setPostion({
        ...postion,
        x2: e.nativeEvent.offsetX,
        y2: e.nativeEvent.offsetY,
      });

      redraw();
    } else if (mouseDown && clickedArea.box !== -1) {
      setPostion({
        ...postion,
        x2: e.nativeEvent.offsetX,
        y2: e.nativeEvent.offsetY,
      });

      const xOffset = e.nativeEvent.offsetX - postion.x1;
      const yOffset = e.nativeEvent.offsetY - postion.y1;

      setPostion({
        ...postion,
        x1: e.nativeEvent.offsetX,
        y1: e.nativeEvent.offsetY,
      });

      if (
        clickedArea.pos === 'i' ||
        clickedArea.pos === 'tl' ||
        clickedArea.pos === 'l' ||
        clickedArea.pos === 'bl'
      ) {
        boxes[clickedArea.box].x1 += xOffset;
      }
      if (
        clickedArea.pos === 'i' ||
        clickedArea.pos === 'tl' ||
        clickedArea.pos === 't' ||
        clickedArea.pos === 'tr'
      ) {
        boxes[clickedArea.box].y1 += yOffset;
      }
      if (
        clickedArea.pos === 'i' ||
        clickedArea.pos === 'tr' ||
        clickedArea.pos === 'r' ||
        clickedArea.pos === 'br'
      ) {
        boxes[clickedArea.box].x2 += xOffset;
      }
      if (
        clickedArea.pos === 'i' ||
        clickedArea.pos === 'bl' ||
        clickedArea.pos === 'b' ||
        clickedArea.pos === 'br'
      ) {
        boxes[clickedArea.box].y2 += yOffset;
      }
      redraw();
    }
  }

  useEffect(() => {
    console.log({ boxes });
  }, [boxes]);

  return (
    <div>
      <canvas
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onMouseUp={onMouseUp}
        id="canvas"
        width="500"
        height="500"
        style={{ background: '#000' }}
      />
    </div>
  );
}

export default withRouter(Test);
