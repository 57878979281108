import { useState, useEffect, createContext, useContext } from 'react';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useOfflinePaymentMethods, useOnlinePaymentMethods } from './SelectOptions';
import useEncrypt from '../hooks/useEncrypt';
// const smsUrl = `${process.env.REACT_APP_API_DOMAIN}/sms/phone`;
// const carParkUrl = `${process.env.REACT_APP_API_DOMAIN}/carPark`;
// const PasswordUrl = `${process.env.REACT_APP_API_DOMAIN}/password/forget`;

const CustomerContext = createContext(null);
export const TokenContext = createContext(null);

function useAuth() {
  const loginUrl = `${process.env.REACT_APP_API_DOMAIN}/login`;
  const logoutUrl = `${process.env.REACT_APP_API_DOMAIN}/logout`;
  const getMeUrl = `${process.env.REACT_APP_API_DOMAIN}/customer`;
  const { t, i18n } = useTranslation();
  const { parseUrl } = useEncrypt();

  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loginError, setLoginError] = useState(undefined);

  const [{ loading, response }, execLogin] = useAxios(
    { url: loginUrl, method: 'POST' },
    { manual: true }
  );
  const [, execLogout] = useAxios(
    { url: logoutUrl, method: 'POST' },
    { manual: true, useCache: false }
  );
  const [{ getMeResult }, fetchInfo] = useAxios(
    { url: getMeUrl, method: 'GET' },
    { manual: true, useCache: false }
  );

  async function loginWithToken(paramToken) {
    localStorage.setItem('token', paramToken);
    setToken(paramToken);
    try {
      await fetchInfo({ headers: { token: paramToken } });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: t('Login.invalidToken'),
        position: 'center',
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('sevenPayment.close'),
      });
    }
  }

  async function login(username, password, from) {
    try {
      const {
        data: { token: userToken },
      } = await execLogin({ data: { username, password } });
      localStorage.setItem('token', userToken);
      setToken(userToken);

      if (from === 'wil') {
        const parsedUrl = `${parseUrl(
          `${process.env.REACT_APP_WIL_DOMAIN}/register?source=wp_web&lang=${i18n.language}&timestamp={{timestamp}}&token={{token}}&hash={{hash:worhashing256.\${token}|\${timestamp}:endhash}}`,
          {
            token: userToken,
            timestamp: moment().valueOf(),
          },
          process.env.REACT_APP_SECRET
        )}`;

        window.open(parsedUrl, '_blank');
      }
    } catch (e) {
      alert(t('Login.Error'));
      setLoginError(e);
    }
  }

  function logout() {
    setToken(null);
    localStorage.clear();
    execLogout({ headers: { token } });
  }

  // useEffect(() => {
  //   if (!response || !response.data || loading) return;
  //   const { token: userToken } = response.data || {};
  //   console.log({ userToken });
  //   localStorage.setItem('token', userToken);
  //   setToken(userToken);
  // }, [response, loading]);

  return {
    token,
    login,
    logout,
    loading,
    loginError,
    response,
    loginWithToken,
  };
}

function useCustomer() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/customer`;

  const { token } = useContext(TokenContext);
  const [customer, setCustomer] = useState({});
  const [customerId, setCustomerId] = useState({});

  const [{ data }, fetchInfo] = useAxios({ url, method: 'GET' }, { manual: true, useCache: false });

  const [updateSuccess, setUpdateSuccess] = useState(undefined);
  const [updateError, setUpdateError] = useState(undefined);

  const { i18n } = useTranslation();

  const history = useHistory();

  const [{ response: updateResponse }, callUpdateCustomer] = useAxios(
    { baseURL: url, method: 'PUT' },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    if (!token) return;
    fetchInfo({ headers: { token } });
  }, [token]);

  useEffect(() => {
    if (!data || !data.data) return;
    const [{ Customers }] = data.data;

    if (!Array.isArray(Customers)) return;

    const userData = Customers.filter(({ isMajor }) => !!isMajor);
    if (userData.length === 0) return;

    setCustomer(userData[0]);
    setCustomerId(userData[0].id);
  }, [data]);

  async function updateCustomer(input) {
    if (!customerId) {
      setUpdateError(new Error('Customer not recognized'));
      return;
    }
    try {
      setUpdateError(undefined);
      setUpdateSuccess(undefined);
      await callUpdateCustomer({ url: `/${customerId}`, data: input });
    } catch (e) {
      setUpdateSuccess(false);
      setUpdateError(e);
    }
  }

  useEffect(() => {
    if (!updateResponse || !updateResponse.status) return setUpdateSuccess(undefined);
    if (updateResponse.status === 200) {
      setUpdateSuccess(true);
      history.push(`/${i18n.language}/customers/ProfileUpdated`);
    }
    return setUpdateSuccess(false);
  }, [updateResponse]);

  return { customer, customerId, updateCustomer, updateSuccess, updateError, fetchInfo };
}

function useRegister() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/customer`;
  const [registerSuccess, setRegisterSuccess] = useState(undefined);
  const [registerError, setRegisterError] = useState(undefined);
  const history = useHistory();
  const { i18n } = useTranslation();
  const [{ response }, callCreateCustomer] = useAxios(
    { url, method: 'POST' },
    { manual: true, useCache: false }
  );

  async function registerAPI(data, setIsLoading) {
    try {
      setRegisterError(undefined);
      setRegisterSuccess(undefined);
      await callCreateCustomer({ data });
      history.push(`/${i18n.language}/customers/message/info%3Aregistration_success`);
    } catch (e) {
      setRegisterSuccess(false);
      alert(
        e?.response?.data?.error ||
          'Registration Failed, Please contact System Administrator for more information'
      );

      setRegisterError(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!response || !response.status) return setRegisterSuccess(undefined);
    if (response.status === 201) {
      return setRegisterSuccess(true);
    }
    return setRegisterSuccess(false);
  }, [response]);

  return { registerSuccess, registerError, registerAPI };
}

function useCars() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/car`;

  const { token } = useContext(TokenContext);
  const [cars, setCars] = useState([]);
  const [options, setOptions] = useState([]);

  const [{ data }, fetchCar] = useAxios({ url, method: 'GET' }, { manual: true, useCache: false });

  useEffect(() => {
    if (!token) return;
    fetchCar({ headers: { token } });
  }, [token]);

  useEffect(() => {
    if (!data || !data.data) return;
    const carData = data.data;
    setCars(
      carData.map(({ id, registrationMark, CarType, activateCard, activateCardNumber }) => ({
        id,
        registrationMark,
        carType: CarType ? CarType.name : 'N/A',
        activateCard,
        activateCardNumber,
      }))
    );

    const carSortedByDate = carData
      .map((car) => {
        const { id: value, registrationMark: label, createdAt, CarTypeId } = car;
        return { value, label, createdAt, CarTypeId };
      })
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    setOptions(carSortedByDate);
  }, [data]);

  function createNewCars(carsData) {
    const promises = carsData.map(({ CarTypeId, registrationMark }) => {
      const car = { CarTypeId: CarTypeId.value, registrationMark };
      return axios.post(url, car, { headers: { token } });
    });

    axios.all(promises).then((response) => {
      console.log(response);
      window.location.reload();
    });
  }

  async function createNewCar(carData) {
    try {
      const {
        data: { data: newCar },
      } = await axios.post(url, carData, { headers: { token } });

      await fetchCar({ headers: { token } });

      return Promise.resolve(newCar);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    cars,
    options,
    createNewCars,
    createNewCar,
  };
}

function useBookings() {
  const dateFormat = 'DD MMM YYYY';
  const url = `${process.env.REACT_APP_API_DOMAIN}/booking`;

  const { token } = useContext(TokenContext);
  const [bookings, setBookings] = useState([]);

  const [{ data }, fetchBooking] = useAxios(
    { url, method: 'GET' },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    if (!token) return;
    fetchBooking({ headers: { token } });
  }, [token]);

  useEffect(() => {
    if (!data || !data.data) return;
    const bookingData = data.data;
    setBookings(
      bookingData
        .filter(({ CarId, OrderId }) => CarId !== null && OrderId !== null)
        .map(
          ({
            status,
            id,
            validFrom,
            validTo,
            ParkingPlanId,
            CarParkId,
            ParkingPlan: {
              amount,
              subTitle: service,
              CarPark: { name, address },
            },
            Order: { Payment: updatedAt },
            Car: { registrationMark },
            CarId,
          }) => {
            const period = `${moment(validFrom).format(dateFormat)} - ${moment(validTo).format(
              dateFormat
            )}`;
            const lastPaymentDate = moment(updatedAt).format(dateFormat);

            return {
              ParkingPlanId,
              CarParkId,
              CarId,
              name,
              address,
              status,
              id,
              service,
              registrationMark,
              period,
              amount,
              lastPaymentDate,
            };
          }
        )
    );
  }, [data]);

  return { bookings };
}

function usePaymentRecords(page) {
  const dateFormat = 'DD/MM/YYYY';
  const { t, i18n } = useTranslation();

  const { customerId } = useCustomer();

  const [totalBookings, setTotalBookings] = useState(1);
  const totalPage = Math.floor(totalBookings / 20 + (totalBookings % 20 > 0 ? 1 : 0));

  const [paymentRecords, setPaymentRecords] = useState([]);

  const [paymentRecordsLoading, setLoading] = useState(true);

  useEffect(() => {
    async function getBookings() {
      if (typeof customerId !== 'number') return;
      const bookingWithPage = `${process.env.REACT_APP_API_DOMAIN}/Booking?page=${page}&limit=20&sortOrder=descend&CustomerId=${customerId}`;
      setLoading(true);
      try {
        const {
          data: { data, count },
        } = await axios.get(bookingWithPage);

        const records = data
          .filter(({ CarId, OrderId }) => CarId !== null && OrderId !== null)
          .map(({ type, ParkingPlan, Order, Car }) => {
            const { subTitle: service, CarPark } = ParkingPlan || {};
            const { name, chineseName } = CarPark || {};
            const { reference } = Order;
            const {
              Payment: { id, paid: cost, updatedAt, status, PaymentMethodId },
            } = Order.Payment ? Order : { Payment: {} };
            const { registrationMark } = Car || {};
            const paymentDate = moment(updatedAt).format(dateFormat);

            return {
              carPark: i18n.language === 'en' ? name : chineseName,
              type,
              paymentRefNo: reference,
              status,
              id,
              service,
              registrationMark,
              cost,
              paymentDate,
              PaymentMethodId,
            };
          });
        setPaymentRecords(records);
        setTotalBookings(count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getBookings();
  }, [customerId, i18n.language, page]);

  return { paymentRecords, paymentRecordsLoading, totalPage };
}

function useFirstDataToken() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/firstDataToken`;

  const { token } = useContext(TokenContext);
  const [firstDataTokens, setFirstDataTokens] = useState([]);

  const [{ data }, fetchCard] = useAxios({ url, method: 'GET' }, { manual: true, useCache: false });

  useEffect(() => {
    if (!token) return;
    fetchCard({ headers: { token } });
  }, [token]);

  useEffect(() => {
    if (!data || !data.data) return;
    const cardData = data.data;
    setFirstDataTokens(cardData);
  }, [data]);

  return { firstDataTokens };
}

function useEmailVerifyMyprofile() {
  const [isEmailValid, setIsValid] = useState(false);
  const url = `${process.env.REACT_APP_API_DOMAIN}/checkEmail`;

  const [{}, fetchEmail] = useAxios({ url, method: 'GET' }, { manual: true, useCache: false });

  async function VerifyEmail(email, status, checkOldSystem = false) {
    if (email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      try {
        const params = JSON.parse(JSON.stringify({ email, status, checkOldSystem }));
        const response = await fetchEmail({ data: { email }, params });
        setIsValid(true);
        return Promise.resolve(response);
      } catch (error) {
        setIsValid(false);
        return Promise.reject(error);
      }
    }
    return Promise.reject('formatError');
  }

  return { isEmailValid, VerifyEmail };
}

function useEmailVerify() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/checkEmail`;
  const ActiveAccountUrl = `${process.env.REACT_APP_API_DOMAIN}/customer/activate`;
  const SendEmailUrl = `${process.env.REACT_APP_API_DOMAIN}/password/forget`;
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [{ data, error }, fetchEmail] = useAxios({ url, method: 'GET' }, { manual: true });

  const [{ data: ActiveAccountdata, error: ActiveAccounterror }, callActiveAccount] = useAxios(
    { url: ActiveAccountUrl, method: 'POST' },
    { manual: true }
  );

  const [{ SendEmaildata }, callSendFrogetPasswordEmail] = useAxios(
    { url: SendEmailUrl, method: 'POST' },
    { manual: true }
  );

  async function emailVerify(email) {
    await fetchEmail({ data: { email }, params: { email } });
    await callSendFrogetPasswordEmail({ data: { email } });
  }

  function ActiveAccountAPI(token) {
    callActiveAccount({ data: { token } });
  }

  useEffect(() => {
    if (ActiveAccounterror) {
      alert(t('ForgotPassword.Error403'));
    }
    if (ActiveAccountdata) {
      history.push(`/${i18n.language}/customers/message/info%3Aactivate_success`);
    }
  }, [ActiveAccounterror, ActiveAccountdata]);

  return {
    emailVerify,
    ActiveAccountAPI,
    error,
  };
}

function useSMSVerify() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/sms/phone`;
  const checkPhoneurl = `${process.env.REACT_APP_API_DOMAIN}/checkPhone`;
  const SkiDataMonthlyParkerURL = `${process.env.REACT_APP_API_DOMAIN}/SkiDataMonthlyParker/checkPhone`;
  const [usertoken, setUsertoken] = useState('');
  const { t } = useTranslation();

  const [isSMSVerified, setIsSMSVerified] = useState(undefined);
  const [isPhoneExist, setisPhoneExist] = useState(undefined);

  const [{ data: verifySmsResult, error: verifySmsError }, putSms] = useAxios(
    { url, method: 'PUT' },
    { manual: true }
  );

  const [{ data: sendSMSResult }, callSendSms] = useAxios(
    { url, method: 'POST' },
    { manual: true }
  );

  const [{ data: checkPhoneResponse, error: checkPhoneError }, callCheckPhone] = useAxios(
    { url: checkPhoneurl, method: 'GET' },
    { manual: true }
  );

  const [
    { data: fetchSkiDataMonthlyParkerResponse, error: fetchSkiDataMonthlyParkerError },
    callFetchSkiDataMonthlyParker,
  ] = useAxios({ url: SkiDataMonthlyParkerURL, method: 'POST' }, { manual: true });

  function verifyMobile(phone, code, isForget) {
    putSms({ data: { phone, code, isForget } });
  }

  async function SendSms(phone, language) {
    await callSendSms({ data: { phone, language } });
  }

  async function fetchPhone(phone, status, checkOldSystem = false) {
    const params = JSON.parse(JSON.stringify({ phone, status, checkOldSystem }));
    await callCheckPhone({ data: { phone }, params });
  }

  async function RGCheckPhoneExist(phone, language) {
    try {
      await callCheckPhone({ data: { phone }, params: { phone } });
    } catch (e) {
      SendSms(phone, language);
    }
  }

  async function fetchSkiDataMonthlyParker(phone, code) {
    try {
      const response = await callFetchSkiDataMonthlyParker({
        data: { phone, code },
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function FGCheckPhoneExist(phone, language) {
    try {
      const data = await callCheckPhone({ data: { phone }, params: { phone } });
      SendSms(phone, language);
      return Promise.resolve(data);
    } catch (e) {
      alert(t('ForgotPassword.notExistPhone'));
      setisPhoneExist(false);
      return Promise.reject(e);
    }
  }

  useEffect(() => {
    if (verifySmsResult) {
      setIsSMSVerified(true);
      setUsertoken(verifySmsResult.token);
    }
    if (verifySmsError) setIsSMSVerified(false);
  }, [verifySmsResult, verifySmsError]);

  useEffect(() => {
    if (checkPhoneResponse) setisPhoneExist(true);
    if (checkPhoneError) setisPhoneExist(false);
  }, [checkPhoneResponse, checkPhoneError]);

  return {
    verifyMobile,
    isSMSVerified,
    SendSms,
    setIsSMSVerified,
    usertoken,
    RGCheckPhoneExist,
    isPhoneExist,
    FGCheckPhoneExist,
    fetchPhone,
    fetchSkiDataMonthlyParker,
  };
}

function useResetPassword() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const url = `${process.env.REACT_APP_API_DOMAIN}/password/reset`;
  const [isLoading, setIsLoading] = useState(false);

  const [{ data: resetResult, error: restError }, callresetPassword] = useAxios(
    { url, method: 'POST' },
    { manual: true }
  );

  function ResetPasswordApi(token, newpassword) {
    setIsLoading(true);
    callresetPassword({ data: { token, newpassword } });
  }

  useEffect(() => {
    if (resetResult) {
      setIsLoading(false);
      history.push(`/${i18n.language}/customers/message/info%3Areset_password_success`);
    }
    if (restError) {
      setIsLoading(false);
      alert(t('ForgotPassword.Error'));
    }
  }, [restError, resetResult]);

  return {
    ResetPasswordApi,
    isLoading,
  };
}

function useActiveParkCard() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isActivePPcard, setisActivePPcard] = useState(undefined);
  const [ppcardExist, setPPcardExist] = useState(undefined);
  const [isParkCardSMSVerified, setisParkCardSMSVerified] = useState(undefined);
  const url = `${process.env.REACT_APP_API_DOMAIN}/sms/phone`;
  const phoneActiveParkCardUrl = `${process.env.REACT_APP_API_DOMAIN}/ppCard/phoneActivate`;
  const myProfileTabActiveUrl = `${process.env.REACT_APP_API_DOMAIN}/ppCard/activate`;
  const fetchPPcardUrl = `${process.env.REACT_APP_API_DOMAIN}/ppCard`;

  const [{ data: phoneActiveResult, error: phoneActiveError }, callPhoneActive] = useAxios(
    { url: phoneActiveParkCardUrl, method: 'POST' },
    { manual: true }
  );

  async function PhoneActive(cardNumber, phone) {
    await callPhoneActive({ data: { cardNumber } });
  }

  const [{ data: sendSMSResult }, callSendSms] = useAxios(
    { url, method: 'POST' },
    { manual: true }
  );

  const [, callfetchPPcard] = useAxios({ url: fetchPPcardUrl, method: 'GET' }, { manual: true });

  async function fetchPPcard(cardNumber, phone, language) {
    try {
      await callfetchPPcard({ data: { cardNumber }, params: { cardNumber } });
      callSendSms({ data: phone, language });
      setPPcardExist(true);
    } catch (e) {
      alert(t('Myprofile.PPcardError'));
      setPPcardExist(false);
    }
  }

  const [{ data: ActivePPcardResult, error: ActivePPcardError }, ActivePPcard] = useAxios(
    { url: myProfileTabActiveUrl, method: 'POST' },
    { manual: true }
  );

  const [{ data: verifySmsResult, error: verifySmsError }, putSms] = useAxios(
    { url, method: 'PUT' },
    { manual: true }
  );

  async function ProfileTabVerifySms(phone, code, isForget, cardNumber, token) {
    await putSms({ data: { phone, code, isForget } });
    ActivePPcard({ data: { cardNumber }, headers: { token } });
  }

  if (sendSMSResult) console.log(sendSMSResult.data.code);

  useEffect(() => {
    if (ActivePPcardResult) setisActivePPcard(true);
    if (ActivePPcardError) {
      setisActivePPcard(false);
      alert(t('Myprofile.PPcardError'));
    }
    if (verifySmsResult) setisParkCardSMSVerified(true);
    if (verifySmsError) setisParkCardSMSVerified(false);
    if (phoneActiveError) alert(t('Myprofile.PPcardError'));
  }, [ActivePPcardResult, ActivePPcardError, verifySmsResult, verifySmsError, phoneActiveError]);

  return {
    isActivePPcard,
    isParkCardSMSVerified,
    ProfileTabVerifySms,
    fetchPPcard,
    PhoneActive,
    ppcardExist,
  };
}

function useGuestCart() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/guestCart`;
  const [orderId, setOrderId] = useState();
  const [{ data: guestCartData, error: guestCartError }, callguestCart] = useAxios(
    { url, method: 'POST' },
    { manual: true }
  );

  function guestCartAPI({
    prefix,
    lastName,
    firstName,
    email,
    phone,
    CarTypeId,
    registrationMark,
    EntryDate,
    entryHour,
    entryMins,
    ParkingPlanId,
    PaymentMethodId,
    FirstDataTokenId,
    amount,
    language,
  }) {
    callguestCart({
      data: {
        prefix,
        lastName,
        firstName,
        email,
        phone,
        CarTypeId,
        registrationMark,
        EntryDate,
        entryHour,
        entryMins,
        ParkingPlanId,
        PaymentMethodId,
        FirstDataTokenId,
        amount,
        language,
      },
    });
  }

  useEffect(() => {
    if (guestCartError) console.log({ guestCartError });
    if (guestCartData) {
      setOrderId(guestCartData.data.Payment.OrderId);
      console.log(guestCartData);
    }
  }, [guestCartData, guestCartError]);

  return {
    guestCartAPI,
    orderId,
  };
}

function useGetQrCode(accountCode) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/seven-eleven-qr?accountCode=${accountCode}`;
  const [{ data: QrCodeData, error: QrCodeError }, callGetQrCode] = useAxios(
    { url, method: 'GET' },
    { manual: true }
  );

  async function GetQrCode() {
    try {
      await callGetQrCode({ data: { accountCode } });
    } catch (e) {
      console.log(e);
    }
  }

  return { GetQrCode, QrCodeData };
}

function useGetCQMBillNumber(accountCode) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/cqm-account?accountCode=${accountCode}`;
  const [{ data: BillNumber, error: QrCodeError }, callGetBillNumber] = useAxios(
    { url, method: 'GET' },
    { manual: true }
  );

  async function GetBillNumber() {
    try {
      await callGetBillNumber();
    } catch (e) {
      console.log(e);
    }
  }

  return { GetBillNumber, BillNumber };
}

function UseUserDeletionRequest() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/UserDeletionRequest`;

  const [isUserDeletionLoading, setIsUserDeletionLoading] = useState(false);

  const [{ data: response, error }, createUserDeletionRequest] = useAxios(
    { url, method: 'POST' },
    { manual: true }
  );

  async function handelCreateUserDeletionRequest(
    token,
    user,
    successCallback = null,
    failCallback = null
  ) {
    setIsUserDeletionLoading(true);
    try {
      const result = await createUserDeletionRequest({
        headers: { token },
        data: {
          phone: user.phone,
          email: user.email,
          UserId: user.UserId,
        },
      });
      if (typeof successCallback === 'function') successCallback(result);
    } catch (e) {
      if (typeof failCallback === 'function') failCallback(e);
    } finally {
      setIsUserDeletionLoading(true);
    }
  }

  async function handelGetOneDeletionRequest(token, successCallback = null, failCallback = null) {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/UserDeletionRequest/${token}`
      );
      if (typeof successCallback === 'function') successCallback(data.data);
    } catch (error) {
      if (typeof failCallback === 'function') failCallback(error);
    }
  }

  async function handelDeleteDeletionRequest(
    token,
    reson,
    successCallback = null,
    failCallback = null
  ) {
    try {
      const data = await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/UserDeletionRequest/submit/${token}`,
        reson
      );
      if (typeof successCallback === 'function') successCallback(data);
    } catch (error) {
      if (typeof failCallback === 'function') failCallback(error);
    }
  }

  return {
    handelCreateUserDeletionRequest,
    handelGetOneDeletionRequest,
    handelDeleteDeletionRequest,
    isUserDeletionLoading,
  };
}

// function useGetOneOrder(orderId) {
//   const [orderDetail, setOrderdetail] = useState();
//   const url = `${process.env.REACT_APP_API_DOMAIN}/Order/${orderId}`;
//   const [{ data: OrderData, error: OrderDetailError }, callGetOrder] = useAxios(
//     { url, method: 'GET' },
//     { manual: true }
//   );

//   async function GetOneOrder() {
//     try {
//       await callGetOrder({ data: { orderId } });
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   useEffect(() => {
//     if (OrderData) {
//       const { data } = OrderData;
//       const { Bookings } = data;
//       console.log(data);
//       setOrderdetail(Bookings[0]);
//     }
//   }, [OrderData]);

//   return { GetOneOrder, orderDetail };
// }

export {
  useAuth,
  useCustomer,
  useRegister,
  useCars,
  useBookings,
  useFirstDataToken,
  usePaymentRecords,
  useSMSVerify,
  useEmailVerifyMyprofile,
  CustomerContext,
  useResetPassword,
  useActiveParkCard,
  useEmailVerify,
  useGuestCart,
  useGetQrCode,
  useGetCQMBillNumber,
  UseUserDeletionRequest,
  // useGetOneOrder,
};
