import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerMargin,
  ArrowDiv,
  Container,
  Width,
  ContentRow,
  PromoImg,
  TextDiv,
  ContentTitle,
  ContentText,
} from './style';
import VISION from '../../../media/images/VISION.jpg';
import MISSION from '../../../media/images/MISSION.jpg';
import VALUES from '../../../media/images/VALUES.jpg';

const MiddleRow = ({ data }) => {
  const { t } = useTranslation();

  const { vision_description, mission_description, values_description } = data || {};

  return (
    <>
      <ContainerMargin>
        <ArrowDiv />
        <Container>
          <Width>
            <ContentRow>
              <PromoImg width="300px" src={VISION} />
              <TextDiv>
                <ContentTitle>{t('Our_company.VISION')}</ContentTitle>
                <ContentText>{vision_description}</ContentText>
              </TextDiv>
            </ContentRow>

            <ContentRow middle>
              <PromoImg middle width="200px" src={MISSION} />
              <TextDiv>
                <ContentTitle>{t('Our_company.MISSION')}</ContentTitle>
                <ContentText>{mission_description}</ContentText>
              </TextDiv>
            </ContentRow>

            <ContentRow>
              <PromoImg width="275px" src={VALUES} />
              <TextDiv>
                <ContentTitle>{t('Our_company.VALUES')}</ContentTitle>
                <ContentText>{values_description}</ContentText>
              </TextDiv>
            </ContentRow>
          </Width>
        </Container>
      </ContainerMargin>
    </>
  );
};

export default MiddleRow;
