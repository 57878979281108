import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import commonStyle from '../../styles/common';

const TimesUpModal = ({ showModal, removeAll }) => {
  const { t } = useTranslation();
  return (
    <Modal size="lg" show={showModal} centered>
      <Modal.Body style={{ padding: 30 }}>
        <h4 style={{ fontWeight: 'bold' }}>{t('Cart.expired')}</h4>

        <p style={{ marginTop: 20 }}>{t('Cart.timeUp')}</p>

        <div className="d-flex-polyfill" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            type="button"
            style={commonStyle.orangeButton}
            onClick={() => {
              removeAll();
              window.location = '/cart/1';
            }}
          >
            {/* clear services in state when clicked */}
            {t('Cart.close')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default TimesUpModal;
