import ReactLoading from 'react-loading';
import React from 'react';

export default function Loading({ loading, msg = '' }) {
  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(0,0,0,0.5)',
            zIndex: 999999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <ReactLoading type="spokes" color="#fd980f" />
          <p style={{ color: '#fff', fontSize: 30, marginTop: 10 }}>{msg}</p>
        </div>
      )}
    </>
  );
}
