import styled, { keyframes } from 'styled-components';
import React from 'react';
import { Controller, useFormContext, useForm } from 'react-hook-form';
import Reaptcha from 'reaptcha';
import { useTranslation } from 'react-i18next';

// TODO: responsive?

const Flicker = keyframes`
  25% {  opacity: 0; } 
  50% { opacity: 1; } 
  75% { opacity: 0; } 
  100% { opacity: 1;}
`;

const Container = styled.div`
  margin-top: 40px;
  background: ${({ error }) => (error ? 'red' : 'none')};
  width: 304px;
  height: 78px;
  border: ${({ error }) => (error ? '1px solid #E56E5A' : '')};
  animation: ${Flicker} ${({ error }) => (error ? '1s forwards' : 'null')};
  animation-iteration-count: 2;
`;

function RecapchaContainer({ name, methods }) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const { i18n } = useTranslation();
  return (
    <Container error={error}>
      <Controller
        as={Reaptcha}
        name={name}
        control={control}
        sitekey="6LfPFksUAAAAAIcM2UKLjQ6X7mJOHIhGBUY1_PHf"
        onVerify={() => methods.setValue('Reaptcha', true, { shouldValidate: true })}
        defaultValue={false}
        hl={i18n.language === 'en' ? 'en' : 'zh-hk'}
      />
    </Container>
  );
}

export default RecapchaContainer;
