/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import React from 'react';
import { Radio, useRadioState, RadioGroup as PrettyRadioGroup } from 'pretty-checkbox-react';
import 'pretty-checkbox';
import '../General/Checkbox/style.scss';

const Container = styled.div`
  fieldset {
    display: ${(props) => props.column && 'flex'};

    @media (max-width: 850px) {
      flex-direction: ${(props) => props.mobile && 'column'};
    }
  }
  & .pretty input:checked ~ .state.p-warning-o label:before {
    border-color: #bdc3c7;
  }

  & .pretty {
    &:disabled {
      opacity: 0.3;
    }
  }

  &:focus {
    outline: none !important;
  }

  & .pretty {
    &:focus {
      outline: none !important;
    }
  }
`;

const Label = styled.label`
  opacity: ${({ disabled }) => disabled && 0.3};
`;

const RadioGroup = ({ children, column, style, mobile }) => {
  // const error = errors[name];
  const radio = useRadioState();

  const radios = React.Children.map(children, (child) => {
    if (!child) return null;
    return React.cloneElement(child, { radio });
  });

  return (
    <Container column={column} mobile={mobile}>
      <PrettyRadioGroup {...radio} style={style}>
        {radios}
      </PrettyRadioGroup>
    </Container>
  );
};

const RadioButton = ({
  checked,
  radio,
  name,
  value,
  children,
  style,
  hidden,
  onClick,
  disabled,
}) => {
  return (
    <Controller
      as={
        <Label style={style} hidden={hidden} disabled={disabled}>
          <Radio checked={checked} color="warning-o" value={value} disabled={disabled} {...radio} />
          {children}
        </Label>
      }
      onClick={onClick}
      name={name}
    />
  );
};

export { RadioGroup as default, RadioButton };
