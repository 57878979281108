import React, { useEffect, useContext, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useCustomer, useCars, TokenContext } from '../../models/Customer';
import * as icon from '../../media/icons';
import cartStyle from '../../styles/cart';
// import Modal from '../../components/General/Modal';
import { useSelectOptions } from '../../models/User';
import {
  FieldContainer,
  FieldTitle,
  TextField,
  SelectField,
  FieldInner,
  ItemMargin,
  SubTitle,
  Divider,
  Checkbox,
  CheckBoxMargin,
  RecapchaContainer,
  DetailContainer,
  DetailLeftDiv,
  SelectorDiv,
  SigninBox,
  Submit,
  EntryDateTextField,
} from '../Cart/CartFormComponents';
import { Container, ContentContainer, InnerContainer } from './IhourlyComponents/index';
import Modal from '../../components/General/PopupPrivacyPolicy';

const IhourlyStep1 = ({ Data }) => {
  const { token } = useContext(TokenContext);
  const history = useHistory();
  const { customer: user } = useCustomer();
  const { options: carOptions } = useCars();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { location: SelectLocation } = useSelectOptions(t);
  const methods = useForm({
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      Region: yup.string().required(),
      District: yup.string().required(),
      Carpark: yup.string().required(),
      Type: yup.string().required(),
      email: yup
        .string()
        .required(t('Registration.EmailEnterError'))
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t('Registration.EmailError')),
      EntryDate: yup.string().required(),
      entryHour: yup.string().required(),
      entryMins: yup.string().required(),
      prefix: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      VehicleRegistrationMark: yup
        .string()
        .matches(/^(?:([A-HJ-NPR-Z0-9])(?!\1{4,})){1,8}$/)
        .max(8)
        .test('', '', (e) => {
          if (e.match(/\s{2,}/g) || e.match(/\s$/)) {
            return false;
          }
          return true;
        })
        .required(),
      phone: yup
        .string()
        .required(t('Registration.phoneEnterError'))
        .matches(/^1[0-9]{10}$|^[456789][0-9]{7}$/, t('Registration.phoneError')),
      isAgreedToPrivacy: yup.boolean().default(false).oneOf([true]),
      Reaptcha: yup
        .boolean()
        .default(false)
        .test('Reaptcha', 'Reaptcha must be true', (val) => val === true),
    }),
  });
  const { prefixOptions, entryHourOptions, entryMinsOptions } = useSelectOptions(t);
  const [Popupshow, setPopupShow] = useState(false);

  const Today = moment().format('YYYY/MM/DD');
  const LastTime = moment().format('YYYY/12/31 23:59:00');

  useEffect(() => {
    if (Data) {
      const userLocation = SelectLocation.find((item) => item.id === Data.LocationId);
      // eslint-disable-next-line no-nested-ternary
      const Region = userLocation
        ? language === 'en'
          ? userLocation.region
          : userLocation.chineseRegion
        : '';
      // eslint-disable-next-line no-nested-ternary
      const District = userLocation
        ? language === 'en'
          ? userLocation.district
          : userLocation.chineseDistrict
        : '';
      methods.setValue('Region', Region);
      methods.setValue('District', District);
      methods.setValue('Carpark', Data.carParkName);
      methods.setValue('Type', Data.activeCarType);
    }
  }, [Data, SelectLocation]);

  useEffect(() => {
    if (user) {
      const Prefix = prefixOptions.find((option) => option.value === user.title);
      methods.setValue('prefix', Prefix);
      methods.setValue(
        'firstName',
        language === 'en' || user.chineseFirstName === '' ? user.firstName : user.chineseFirstName
      );
      methods.setValue(
        'lastName',
        language === 'en' || user.chineseLastName === '' ? user.lastName : user.chineseLastName
      );
      methods.setValue('phone', user.phone);
      methods.setValue('email', user.email);
      if (carOptions.length > 0) methods.setValue('VehicleRegistrationMark', carOptions[0].label);
    }
  }, [user, prefixOptions, carOptions]);

  const onSubmit = (data) => {
    const prefix = methods.getValues('prefix').value;
    const entryHour = methods.getValues('entryHour').value;
    const entryMins = methods.getValues('entryMins').value;
    // const { address, subTitle, amount } = state;

    const { lastName, firstName, email, phone, Type, VehicleRegistrationMark, EntryDate } = data;

    const CustomerData = {
      lastName,
      firstName,
      email,
      phone,
      Type,
      VehicleRegistrationMark,
      EntryDate,
      prefix,
      entryHour,
      entryMins,
    };

    history.push({
      pathname: `/${language}/IhourlyCart/2`,
      state: CustomerData,
    });
  };

  return (
    <>
      <Container>
        <ContentContainer>
          <div style={{ textAlign: 'left', width: '100%', padding: '0px 10px 0px 10px' }}>
            <div style={{ display: 'flex' }}>
              <img src={icon.alert} width="22px" height="22px" alt="read_carefully" />
              <p>
                <b>{t('IhourlyCart.DetailTitle')}</b>
              </p>
            </div>

            <p style={{ fontSize: '0.85rem' }}>{t('IhourlyCart.DetailText1')}</p>
            <p style={{ fontSize: '0.85rem' }}>{t('IhourlyCart.DetailText2')}</p>
          </div>

          <InnerContainer>
            <>
              <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <SubTitle>{t('IhourlyCart.Carpark')}</SubTitle>
                  <FieldContainer>
                    <FieldInner>
                      <FieldTitle name="Region" title={t('IhourlyCart.Region')} />
                      <TextField
                        name="Region"
                        errorMessage={methods.errors.Region ? methods.errors.Region.message : null}
                        readonly
                      />
                    </FieldInner>

                    <ItemMargin />

                    <FieldInner>
                      <FieldTitle name="District" title={t('IhourlyCart.District')} />
                      <TextField
                        name="District"
                        errorMessage={
                          methods.errors.District ? methods.errors.District.message : null
                        }
                        readonly
                      />
                    </FieldInner>
                  </FieldContainer>

                  <FieldContainer>
                    <FieldInner>
                      <FieldTitle name="Carpark" title={t('IhourlyCart.Carpark')} />
                      <TextField
                        name="Carpark"
                        errorMessage={
                          methods.errors.Carpark ? methods.errors.Carpark.message : null
                        }
                        readonly
                      />
                    </FieldInner>

                    <ItemMargin />

                    <FieldInner>
                      <FieldTitle name="Type" title={t('IhourlyCart.Type')} />
                      <TextField
                        name="Type"
                        errorMessage={methods.errors.Type ? methods.errors.Type.message : null}
                        readonly
                      />
                    </FieldInner>
                  </FieldContainer>

                  <SubTitle>{t('IhourlyCart.Date')}</SubTitle>

                  <FieldContainer>
                    <FieldInner>
                      <FieldTitle
                        info
                        tooltipMsg={
                          <span>
                            {t('IhourlyCart.EntryDateinfo')}
                            &nbsp;
                            {Today}
                            &nbsp;
                            {t('IhourlyCart.EntryDateinfo1')}
                            &nbsp;
                            {LastTime}
                            &nbsp;
                            {t('IhourlyCart.EntryDateinfo2')}
                          </span>
                        }
                        name="EntryDate"
                        title={t('IhourlyCart.EntryDate')}
                      />
                      <EntryDateTextField
                        name="EntryDate"
                        errorMessage={t('IhourlyCart.EntryDateError')}
                      />
                    </FieldInner>

                    <ItemMargin />

                    <FieldInner>
                      <FieldTitle
                        info
                        tooltipMsg={t('IhourlyCart.EntryTimeinfo')}
                        name="EntryTime"
                        title={t('IhourlyCart.EntryTime')}
                      />
                      <SelectorDiv>
                        <SelectField
                          name="entryHour"
                          options={entryHourOptions}
                          placeholder={t('Registration.PleaseSelect')}
                          errorMessage={t('IhourlyCart.EntryHourError')}
                        />
                        <span>:</span>
                        <SelectField
                          name="entryMins"
                          options={entryMinsOptions}
                          placeholder={t('Registration.PleaseSelect')}
                          errorMessage={t('IhourlyCart.EntryMinsError')}
                        />
                      </SelectorDiv>
                    </FieldInner>
                  </FieldContainer>

                  <Divider />

                  <SubTitle>{t('IhourlyCart.CustomerDetails')}</SubTitle>

                  <DetailContainer>
                    {!token && <SigninBox />}
                    <DetailLeftDiv token={token}>
                      <FieldContainer>
                        <FieldInner top>
                          <FieldTitle prefix name="prefix" title={t('IhourlyCart.Prefix')} />
                          <SelectField
                            prefix
                            name="prefix"
                            options={prefixOptions}
                            placeholder={t('Registration.PleaseSelect')}
                            errorMessage={t('Registration.prefixError')}
                          />
                        </FieldInner>
                        <FieldContainer />
                      </FieldContainer>

                      <FieldContainer>
                        <FieldInner>
                          <FieldTitle
                            detail
                            name="firstName"
                            title={t('IhourlyCart.FirstNameEng')}
                          />
                          <TextField
                            name="firstName"
                            errorMessage={t('Registration.firstNameError')}
                          />
                        </FieldInner>

                        <ItemMargin />

                        <FieldInner>
                          <FieldTitle detail name="lastName" title={t('IhourlyCart.LastNameEng')} />
                          <TextField
                            name="lastName"
                            errorMessage={t('Registration.lastNameError')}
                          />
                        </FieldInner>
                      </FieldContainer>

                      <FieldContainer>
                        <FieldInner>
                          <FieldTitle detail name="phone" title={t('IhourlyCart.Phone')} />
                          <TextField
                            name="phone"
                            errorMessage={
                              methods.errors.phone ? methods.errors.phone.message : null
                            }
                          />
                        </FieldInner>

                        <ItemMargin />

                        <FieldInner>
                          <FieldTitle detail name="email" title={t('IhourlyCart.Email')} />
                          <TextField
                            name="email"
                            errorMessage={
                              methods.errors.email ? methods.errors.email.message : null
                            }
                          />
                        </FieldInner>
                      </FieldContainer>

                      <FieldContainer>
                        <FieldInner>
                          <FieldTitle
                            detail
                            name="VehicleRegistrationMark"
                            title={t('IhourlyCart.VehicleRegistrationMark')}
                          />
                          <TextField
                            name="VehicleRegistrationMark"
                            errorMessage={t('Registration.registrationMarkError')}
                          />
                        </FieldInner>

                        <ItemMargin />

                        <FieldInner />
                      </FieldContainer>
                    </DetailLeftDiv>
                  </DetailContainer>

                  <CheckBoxMargin>
                    <Checkbox
                      name="isAgreedToPrivacy"
                      Text1={t('Registration.Iagree')}
                      Text2={t('Registration.AgreePrivacy')}
                      onclick={() => {
                        setPopupShow('PrivacyPolicy');
                      }}
                    />
                  </CheckBoxMargin>

                  <RecapchaContainer name="Reaptcha" methods={methods} />

                  <div style={cartStyle.buttonsRow}>
                    <Submit
                      type="submit"
                      // to={{
                      //  pathname: '/cart/2',
                      //  state: { action },
                      // }}
                    >
                      NEXT
                    </Submit>
                  </div>
                </form>
              </FormContext>
            </>
          </InnerContainer>
        </ContentContainer>
      </Container>
      <Modal onClose={() => setPopupShow(false)} Popupshow={Popupshow} />
    </>
  );
};

export default IhourlyStep1;
