import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useCarPark } from '../../../models/CarPark';

const ServiceName = styled.h5`
  color: #fd980f;
  font-size: 1.1rem;
`;

const PlanDetail = styled.div`
  font-size: 0.85rem;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
`;

const SubTitle = styled.span`
  width: 50%;
  font-size: 0.9375rem;
  color: #666;

  @media (max-width: 980px) {
    width: auto;
  }
`;

const TimeOfDay = styled.span`
  font-size: 0.9375rem;
  color: #666;
`;

const Amount = styled.span`
  text-align: right;
  color: #666;
  font-size: 0.9375rem;
`;

const Remark = styled.div`
  margin-top: 15px;
  color: #888;
`;

const MonthlyDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.top ? '10px' : '0px')};
  margin-right: 10px;
`;

const BookingButton = styled.button`
  background: #fd980f;
  color: white;
  border: none;
  font-size: 0.8125rem;
  outline: none;
  padding: 5px 10px;
  min-height: 32px;
  min-width: 80px;
  margin-left: auto;
  transition: background 0.3s;
  &:hover {
    background: #666;
  }

  &:disabled {
    background: #bbb;
    cursor: not-allowed;
  }
`;

const ServiceDetailContainer = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;

  div:first-child {
    margin-top: 0px;
  }

  div:last-child {
    margin-bottom: 0px;
  }
`;

const ServiceDetailInner = styled.div`
  color: #666;
  font-size: 0.9375rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
  //border-bottom: 1px solid #dedede;
`;

const InnerDiv = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

function ServiceDetail({ plans, service, activeCarType }) {
  const { t, i18n } = useTranslation();
  const { carParkId } = useParams();
  const { services, carPark } = useCarPark(carParkId);
  const filterIsHourly = services.filter(({ isIHourly }) => isIHourly);
  const { name: carParkName, LocationId, address } = carPark;
  const history = useHistory();

  return (
    <ServiceDetailContainer>
      <ServiceName dangerouslySetInnerHTML={{ __html: service }} />
      {plans
        .sort((a, b) => a.priority - b.priority)
        .map((plan, index) => {
          const {
            id,
            amount = 0,
            remark = '',
            subTitle = '',
            timeOfDay = '',
            type: PlanType,
          } = plan;

          const isHourly = filterIsHourly.some(({ type }) => type === PlanType);

          return (
            <InnerDiv key={id}>
              <TitleRow>
                <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />
                <TimeOfDay>{timeOfDay}</TimeOfDay>
                <Amount>{`HK $${amount}`}</Amount>
                {isHourly && (
                  <BookingButton
                    disabled
                    onClick={() =>
                      history.push({
                        pathname: `/${i18n.language}/IhourlyCart/1`,
                        state: {
                          activeCarType,
                          carParkName,
                          subTitle,
                          amount,
                          LocationId,
                          address,
                          carParkId,
                          timeOfDay,
                        },
                      })
                    }
                  >
                    {t('CarParkDetail.Full')}
                  </BookingButton>
                )}
              </TitleRow>
              <ServiceDetailInner key={id}>{/**/}</ServiceDetailInner>
              {plans.length >= 1 && index === plans.length - 1 && (
                <Remark dangerouslySetInnerHTML={{ __html: remark }} />
              )}
            </InnerDiv>
          );
        })}
    </ServiceDetailContainer>
  );
}

const NonReservedDiv = styled.div`
  display: inline-block;
  padding: 2px 10px 2px 10px;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.5;
  background-color: rgba(0, 0, 0, 0.3);
  vertical-align: text-top;
  text-transform: uppercase;
  border-radius: 15px;
  -webkit-background-clip: padding-box;
  -ms-background-clip: padding-box;
  background-clip: padding-box;
`;

const SubTitleText = styled.span`
  font-size: 0.9375rem;
  color: #666;
  line-height: 1.2;
`;

const DollarText = styled.span`
  font-size: 0.9375rem;
  font-weight: bold;
  color: #666;
  margin-top: 5px;
`;

const PlanBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
`;

function MonthlyServiceDetail({ plans, activate, waitingList, isLogin, applyWaitingList }) {
  const { t, i18n } = useTranslation();
  const [bookable, setBookable] = useState();
  const [appliedAtOffice, setAppliedAtOffice] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!plans || plans.length === 0) return;
    setBookable(plans.some(({ isBookable }) => isBookable));
    setAppliedAtOffice(plans.every(({ isAppliedAtOffice }) => isAppliedAtOffice !== false));
  }, [plans]);

  function bookButtonProps(plan) {
    const targetWaitingList = waitingList
      .filter((e) => e.status === 'confirmed' || e.status === 'processed')
      .find((e) => e.ParkingPlanId === plan?.id);

    if (!plan?.isFull) {
      return {
        text: t('CarParkDetail.Book'),
        disable: false,
        onClick: () => activate(plan),
      };
    }

    if (plan?.isWaitingList) {
      return {
        text: t('MyAccount.WaitingList.addWaiting'),
        disable: false,
        onClick: () => {
          if (!isLogin) {
            history.push({
              pathname: `/${i18n.language}/customers/login`,
              state: { redirectedFrom: location.pathname },
            });

            return;
          }

          applyWaitingList(plan?.id, plan?.CarTypeId, targetWaitingList?.id);
        },
      };
    }

    return {
      text: t('CarParkDetail.Full'),
      disable: true,
      onClick: () => {},
    };
  }

  return (
    <>
      {!bookable && appliedAtOffice && (
        <NonReservedDiv>
          <span>{t('CarParkDetail.isBookable')}</span>
        </NonReservedDiv>
      )}
      {plans
        .sort((a, b) => a.priority - b.priority)
        .map((plan) => {
          const { id, amount, subTitle = '', isBookable } = plan;

          const { text, onClick, disable } = bookButtonProps(plan);

          return (
            <PlanDetail key={id}>
              {isBookable ? (
                <PlanBox>
                  <MonthlyDetail>
                    <SubTitleText dangerouslySetInnerHTML={{ __html: subTitle }} />
                    <DollarText>{`HK $${amount}`}</DollarText>
                  </MonthlyDetail>
                  <BookingButton type="button" onClick={onClick} disabled={disable}>
                    {text}
                  </BookingButton>
                </PlanBox>
              ) : (
                <div>
                  <MonthlyDetail top>
                    <SubTitleText dangerouslySetInnerHTML={{ __html: subTitle }} />
                    <DollarText>{`HK $${amount}`}</DollarText>
                  </MonthlyDetail>
                </div>
              )}
            </PlanDetail>
          );
        })}
    </>
  );
}

export { ServiceDetail, MonthlyServiceDetail };
