import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../Carousel.css';
import moment from 'moment';
import useBanner from '../../../models/Banner';
import useEncrypt from '../../../hooks/useEncrypt';
import { TokenContext } from '../../../models/Customer';
import useGA from '../../../hooks/useGA';

const Promoimg = styled.div`
  background-image: ${(props) => `url("${props.img}")`};
  background-size: cover;
  //background-position: 50% 50%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  //background-position: top;

  @media (max-width: 950px) {
    display: none;
  }
`;

const MobilePromoimg = styled.img`
  @media (max-width: 950px) {
    display: flex;
    height: 100%;
    width: 100%;
  }
  @media (min-width: 950px) {
    display: none;
  }
`;

const A = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const Promotextdiv = styled.div`
  text-align: left;
  width: 600px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    display: none;
  }
`;

const Marginleft = styled.div`
  margin-left: 600px;
  @media (max-width: 950px) {
    display: none;
  }
`;

const PromoTitle = styled.p`
  color: #000;
  font-size: 2.5rem;
  line-height: 1.2;
  @media (max-width: 950px) {
    display: none;
  }
`;

const PromoText = styled.span`
  color: #000;
  font-size: 1.125rem;
  @media (max-width: 950px) {
    display: none;
  }
`;

const MobileDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const MobileTextdiv = styled.div`
  background-color: #fff;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const MobileTitle = styled.p`
  color: #000;
  font-size: 16px;
  font-weight: bold;
`;

const MobileText = styled.p`
  color: #000;
  font-size: 15px;
`;

function PromoCarousel() {
  const { t, i18n } = useTranslation();
  const [index, setIndex] = React.useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const { parseUrl } = useEncrypt();
  const { banners } = useBanner({ bannerType: 'home' });
  const { token } = useContext(TokenContext);
  const { handleEventTracking } = useGA();

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      id="promobar"
      controls={false}
      interval={3750}
    >
      {banners.map((banner) => {
        const {
          title = '#',
          chineseImage,
          chineseTitle,
          chineseUrlLink = '#',
          image,
          urlLink,
          content,
          chineseContent,
        } = banner || {};
        const url = i18n.language === 'en' ? urlLink : chineseUrlLink;

        const parsedUrl = `${parseUrl(
          url,
          {
            token: token || '',
            timestamp: token ? moment().valueOf() : '',
          },
          process.env.REACT_APP_SECRET
        )}`;

        const encodedImageUrl =
          i18n.language === 'en'
            ? process.env.REACT_APP_CP_MEDIA_DOMAIN + image
            : `${process.env.REACT_APP_CP_MEDIA_DOMAIN}${chineseImage}`;
        return (
          <Carousel.Item key={banner.id}>
            <A
              onClick={(event) => {
                if (url === '#') {
                  event.preventDefault();
                }
                handleEventTracking('banner_click', { index, id: banner.id });
              }}
              style={{ cursor: url !== '#' ? 'pointer' : 'default', textDecoration: 'none' }}
              href={parsedUrl}
              target={url !== '#' && '_blank'}
              rel="noreferrer"
            >
              <Promoimg img={encodedImageUrl}>
                <Marginleft />
                <Promotextdiv>
                  <PromoTitle
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'en' ? title : chineseTitle,
                    }}
                  />
                  <PromoText
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'en' ? content : chineseContent,
                    }}
                  />
                </Promotextdiv>
              </Promoimg>

              <MobileDiv>
                <MobilePromoimg src={encodedImageUrl} />
                <MobileTextdiv>
                  <MobileTitle dangerouslySetInnerHTML={{ __html: title }} />
                  <MobileText
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'en' ? content : chineseContent,
                    }}
                  />
                </MobileTextdiv>
              </MobileDiv>
            </A>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default PromoCarousel;
