import styled from 'styled-components';

const ContainerMargin = styled.div`
  background-color: transparent;
  margin-top: -55px;

  @media (max-width: 850px) {
    margin-top: -25px;
  }
`;

const ArrowDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  color: white;
  font-size: 11px;
  text-align: center;
  padding-top: 55px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    width: 0;
    height: 0;
    content: '';
    border-top: 550rem solid transparent;
    border-left: 700rem solid white;
    border-right: 700rem solid white;
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  @media (max-width: 850px) {
    padding-top: 25px;
  }
`;

const Container = styled.div`
  background-color: #ffffff;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 850px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Width = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
`;

const ContentRow = styled.div`
  width: 100%;
  padding: 0px 30px;
  vertical-align: middle;
  text-align: ${(props) => (props.middle ? 'right' : 'left')};

  @media (max-width: 850px) {
    text-align: center;
    padding: 15px 0;
  }
`;

const PromoImg = styled.img`
  display: inline-block;
  border: 5px solid #ffffff;
  vertical-align: middle;
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  -webkit-background-clip: padding-box;
  -ms-background-clip: padding-box;
  background-clip: padding-box;
  width: ${(props) => props.width};

  @media (max-width: 850px) {
    width: ${(props) => (props.middle ? '160px' : '200px')};
  }
`;

const TextDiv = styled.div`
  display: inline-block;
  padding: 0 15px;
  text-align: left;
  vertical-align: middle;
  max-width: 460px;

  @media (max-width: 850px) {
    text-align: center;
    margin-top: 10px;
  }
`;

const ContentTitle = styled.p`
  color: #fd980f;
  font-size: 2rem;
  margin: 0;
  padding: 0;

  @media (max-width: 850px) {
    font-size: 24px;
    font-weight: bold;
  }
`;

const ContentText = styled.p`
  font-size: 1.125rem;
  margin-top: 10px;
  margin-bottom: 0;
  color: #888;
  line-height: 1.5;

  @media (max-width: 850px) {
    font-size: 13.2px;
    color: #000;
  }
`;

export {
  ContainerMargin,
  ArrowDiv,
  Container,
  Width,
  ContentRow,
  PromoImg,
  TextDiv,
  ContentTitle,
  ContentText,
};
