import React, { useContext, useState } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import moment from 'moment';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3Hook';
import Step4 from './Step4Hook';
import { CustomerContext } from '../../models/User';
import ProgressBar from '../../components/General/ProgressBar';
import { CartContext, useBuyNewCart } from '../../models/Cart';
import { useOfflinePaymentMethods, useOnlinePaymentMethods } from '../../models/SelectOptions';
import { getQueryStringValue } from '../../components/General/QueryString';
import LoadingSprinner from '../../components/Loading';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  padding: 20px ${(props) => props.paddingHorizontal};
  -webkit-flex: 1;
  flex: 1;
`;

const ContentContainer = styled.div`
  max-width: 1024px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

function Cart() {
  const { t, i18n } = useTranslation();
  const { generalCart, singleCart } = useContext(CartContext);
  const { toCart, toCheckout, buyNewOrderId, clearBuyNewCart, toCartVoid } = useBuyNewCart();
  const location = useLocation();
  const { action, spareOrderId } = location.state || {};
  const { search } = location;
  const history = useHistory();

  const {
    cart,
    orderId,
    paymentMethodId,
    checkout,
    onSelectPickUp,
    onSelectPaymentMethod,
    onSelectToken,
    clearCart,
    onSelectCar,
    removeAll,
    replace,
  } = action ? singleCart : generalCart;

  const { offlinePaymentIds, allPaymentMethods } = useOfflinePaymentMethods();
  const { onlinePaymentIds } = useOnlinePaymentMethods();
  const { token, user } = useContext(CustomerContext);
  const { step } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const getSubTotalFee = () => {
    return cart.reduce((accumulator, { fee }) => accumulator + fee, 0);
  };

  const getHandlingFee = () => {
    return cart.reduce(
      (accumulator, { handlingFee }) => (handlingFee ? accumulator + handlingFee : 0),
      0
    );
  };

  async function createBuyNewCart(validCartItems) {
    setIsLoading(true);
    const carts = validCartItems.map((e) => {
      const [validFrom = null, validTo = null] =
        e.servicePeriod.replace(/\s/g, '').split(t('CarParkDetail.Booking.to')) || [];

      return {
        ParkingPlanId: e?.ParkingPlanId,
        CarParkId: e?.carPark?.id,
        CarId: e.CarId,
        validTo: validTo ? moment(validTo, 'DD/MM/YYYY').toISOString() : null,
        validFrom: validFrom ? moment(validFrom, 'DD/MM/YYYY').toISOString() : null,
      };
    });

    const buyNewCartData = await toCart(carts, token);
    if (buyNewCartData) {
      history.push({
        pathname: `/${i18n.language}/cart/2`,
        state: { action, buyNewCartData },
      });
    }
    setIsLoading(false);
  }

  async function redirectionToStep2() {
    const validCartItems = cart.filter((e) => moment().isSame(moment(e.createdDate), 'date'));
    const hasExpiredItem = cart.length !== validCartItems.length;
    const displayItems = cart
      .filter((e) => moment().isAfter(moment(e.createdDate), 'date'))
      .map((e) => `<br/>${e.name} - ${e.service}`)
      .toString();

    if (hasExpiredItem) {
      await Swal.fire({
        position: 'center',
        text: t('Cart.expiredItemRemind'),
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('MyAccount.iMonthly.confirm'),
        allowOutsideClick: false,
      });

      replace(validCartItems);

      if (validCartItems.length === 0) {
        return;
      }
    }

    const isAllBuyNew = validCartItems.every((e) => e?.type === 'new');

    if (validCartItems.length > 1) {
      Swal.fire({
        position: 'center',
        text: t('Cart.multipleReminder'),
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('MyAccount.iMonthly.confirm'),
        showCancelButton: true,
        cancelButtonText: t('MyAccount.iMonthly.cancel'),
        cancelButtonColor: '#888',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (isAllBuyNew) {
            createBuyNewCart(validCartItems);
            return;
          }

          history.push({ pathname: `/${i18n.language}/cart/2`, state: { action } });
        } else {
          Swal.close();
        }
      });

      return;
    }

    if (isAllBuyNew) {
      createBuyNewCart(validCartItems);
      return;
    }

    history.push({ pathname: `/${i18n.language}/cart/2`, state: { action } });
  }

  const renderSteps = () => {
    switch (step) {
      case '2':
        return (
          <PageContainer paddingHorizontal="15px">
            <ContentContainer>
              <Step2
                user={user}
                toCheckout={toCheckout}
                allPaymentMethods={allPaymentMethods}
                confirm={() => checkout(token)}
                cart={cart}
                onSelectPickUp={onSelectPickUp}
                onSelectPaymentMethod={onSelectPaymentMethod}
                onSelectToken={onSelectToken}
                checkout={checkout}
                removeAll={removeAll}
                action={action}
                offlinePaymentIds={offlinePaymentIds}
                onlinePaymentIds={onlinePaymentIds}
                redirectToStep3={() =>
                  history.push({ pathname: `/${i18n.language}/cart/3`, state: { action } })
                }
                getSubTotalFee={getSubTotalFee}
                getHandlingFee={getHandlingFee}
                toCartVoid={toCartVoid}
              />
            </ContentContainer>
          </PageContainer>
        );
      case '3':
        return (
          <PageContainer paddingHorizontal="0">
            <ContentContainer>
              <Step3
                clearBuyNewCart={clearBuyNewCart}
                paymentMethodId={parseInt(paymentMethodId, 10)}
                onlinePaymentIds={onlinePaymentIds}
                offlinePaymentIds={offlinePaymentIds}
                orderId={orderId || buyNewOrderId}
                redirectToStep4={() =>
                  history.push({ pathname: `/${i18n.language}/cart/4`, state: { action } })
                }
                action={action}
                spareOrderId={spareOrderId}
              />
            </ContentContainer>
          </PageContainer>
        );
      case '4':
        return (
          <Step4
            paymentMethodId={parseInt(
              getQueryStringValue('paymentMethodId', search) || paymentMethodId,
              10
            )}
            onlinePaymentIds={onlinePaymentIds}
            orderId={getQueryStringValue('orderId', search) || orderId || buyNewOrderId}
            clearCart={clearCart}
            action={action}
          />
        );
      case '1':
      default:
        return (
          <PageContainer paddingHorizontal="15px">
            <ContentContainer>
              <Step1
                cart={cart}
                onSelectCar={onSelectCar}
                action={action}
                redirectToStep2={redirectionToStep2}
              />
            </ContentContainer>
          </PageContainer>
        );
    }
  };

  const { fpsId } = onlinePaymentIds;

  const stepDisplay =
    paymentMethodId === fpsId
      ? [
          { step: '1', label: t('Cart.Steps.Step1') },
          { step: '2', label: t('Cart.Steps.Step2') },
          { step: '3', label: t('Cart.Steps.Step3') },
        ]
      : [
          { step: '1', label: t('Cart.Steps.Step1') },
          { step: '2', label: t('Cart.Steps.Step2') },
          { step: '3', label: t('Cart.Steps.Step3') },
          { step: '4', label: t('Cart.Steps.Step4') },
        ];

  return (
    <div style={{ flex: 1 }}>
      <LoadingSprinner loading={isLoading} />
      <ProgressBar
        title={step === 'Ihourly' ? 'BOOK i-HOURLY' : t('Cart.ProgressBar.Title')}
        currentStep={step === undefined || step === 'Ihourly' ? '1' : step}
        stepDisplay={stepDisplay}
      />
      {renderSteps()}
    </div>
  );
}

export default withRouter(Cart);
