import styled from 'styled-components';

const MainContainerInner = styled.div`
  background: #fff;
  padding: 15px;
  width: 994px;
  flex-direction: column;
  display: block;
  box-shadow: 0 0 2px #bbb;
`;

export default MainContainerInner;
