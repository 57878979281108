import styled from 'styled-components';

// TODO: responsive?

const MobileNumerContainer = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  font-weight: bold;
  flex-direction: row;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export default MobileNumerContainer;
