import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as icon from '../../media/icons';
import { CartContext, useServicePeriodDisplay } from '../../models/Cart';
import StyledLink from '../../components/General/StyledLink';
import StyledButton from '../../components/General/StyledButton';

export const ItemContainer = styled.div`
  display: flex;
  padding: 30px 0;
  background: ${(props) => props.background};
  width: 100%;
  //overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const ItemNumberLabel = styled.div`
  background: #fd980f;
  display: inline-block;
  height: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 9px;
  margin-right: 15px;
`;

export const ItemNumber = styled.span`
  color: #fff;
  font-size: 1.3rem;
  padding: 0 10px;
`;

export const CarParkName = styled.span`
  height: auto;
  color: #fd980f;
  font-size: 1.5rem;
  line-height: 1;
`;

export const CarParkAddress = styled.span`
  color: #888888;
  margin-left: 7px;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: normal;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const DetailContainer = styled.tr`
  display: flex;
  flex-direction: row;
  //align-items: center;
  align-items: flex-start;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const Label = styled.td`
  width: ${(props) => (props.width ? props.width : '28%')};
  font-size: 0.875rem;
  font-weight: bold;
  color: #fd980f;

  @media (max-width: 760px) {
    width: 50%;
  }
`;

export const Detail = styled.td`
  width: ${(props) => (props.width ? props.width : '70%')};
  font-size: 0.875rem;

  @media (max-width: 760px) {
    margin-top: -10px;
    width: 100%;
  }
`;

export const SupportedPaymentMethodsRow = styled.td`
  width: 100%;
  font-size: 0.875rem;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 760px) {
    margin-top: 10px;
  }
`;

export const StatusDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DetailInnerDiv = styled.div`
  border: 1px solid #bbb;
  border-radius: 20px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DollarSign = styled.span`
  font-weight: 800;
  font-size: 1.2rem;
`;

export const Fee = styled.span`
  margin-top: -5px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 2.2rem;
`;

export const ChangeCarButton = styled.button`
  color: #fd980f;
  margin-left: 10px;
  align-self: center;
  background: none;
  border: none;
  outline: none;
  font-size: 0.875rem;
`;

const CarParkInfo = styled.div`
  flex: 73%;
  display: flex;
  padding-right: 10px;

  @media (max-width: 760px) {
    flex: 100%;
    flex-direction: column;
    padding-right: 0px;
  }
`;

const CarParkDetail = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 760px) {
    margin-left: 0px;
    flex-direction: column;
  }
`;

const TableLeft = styled.table`
  flex: 50%;

  @media (max-width: 570px) {
    flex: 100%;
  }
`;

const TableRight = styled.table`
  flex: 50%;

  @media (max-width: 570px) {
    flex: 100%;
  }
`;

const BreakLineContainer = styled.div`
  flex: 4%;
  display: flex;
  justify-content: center;

  @media (max-width: 760px) {
    flex: 100%;
    padding: 15px 0;
    margin: 0 10px;
  }
`;

const BreakLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: #dedede;

  @media (max-width: 760px) {
    width: 100%;
    height: 1px;
  }
`;

const AmountContainer = styled.div`
  flex: 23%;
  margin-top: 20px;
  padding: 0 35px 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 760px) {
    flex: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    flex-direction: row;
  }
`;

const FeeContainer = styled.div`
  display: flex;

  @media (max-width: 760px) {
    flex: 55%;
    justify-content: center;
  }
`;

const RemoveButtonContainer = styled.div`
  @media (max-width: 760px) {
    flex: 45%;
    padding-right: 10px;
    display: flex;
    justify-content: center;
  }
`;

const DetailInner = styled.div`
  width: 100%;

  @media (max-width: 760px) {
    padding: 15px 15px 0px 15px;
  }
`;

const CartItem = ({
  index,
  details,
  showModal,
  carSelectField,
  action,
  totalCart,
  offlinePaymentIds,
  onlinePaymentIds,
  allAvailablePaymentMethods,
  handleChangeSelectedCarType,
}) => {
  const { t } = useTranslation();
  const {
    generalCart: { remove: generalRemove, onSelectCar },
    singleCart: { remove: singleCartRemove },
  } = useContext(CartContext);

  const handleRemoveItem = () => {
    Swal.fire({
      position: 'center',
      text: t('Cart.ConfirmRemove'),
      confirmButtonColor: '#fd980f',
      confirmButtonText: t('MyAccount.iMonthly.confirm'),
      showCancelButton: true,
      cancelButtonText: t('MyAccount.iMonthly.cancel'),
      cancelButtonColor: '#888',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (!action) {
          generalRemove(index);
        } else {
          singleCartRemove(index);
        }
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  };

  return (
    <ItemContainer background={index % 2 === 0 ? '#fff' : '#fef1e5'}>
      <CarParkInfo>
        <ItemNumberLabel>
          <ItemNumber>{index + 1}</ItemNumber>
        </ItemNumberLabel>

        <DetailInner>
          <CarParkName>{details.name}</CarParkName>
          <div style={{ margin: '4px 0 20px 0', display: 'flex' }}>
            <img src={icon.location} width="15" height="20" alt="location" />
            <CarParkAddress>{details.address}</CarParkAddress>
          </div>

          {carSelectField ? (
            <Step1Summary
              details={details}
              assignCar={onSelectCar}
              showModal={showModal}
              carSelectField={carSelectField}
              action={action}
              handleChangeSelectedCarType={handleChangeSelectedCarType}
            />
          ) : (
            <Step2Summary
              allAvailablePaymentMethods={allAvailablePaymentMethods}
              details={details}
              action={action}
              totalCart={totalCart}
              offlinePaymentIds={offlinePaymentIds}
              onlinePaymentIds={onlinePaymentIds}
            />
          )}
        </DetailInner>
      </CarParkInfo>
      <BreakLineContainer>
        <BreakLine />
      </BreakLineContainer>
      <AmountContainer>
        <FeeContainer>
          <DollarSign>HK$</DollarSign>
          <Fee>{details.fee}</Fee>
        </FeeContainer>

        {carSelectField && (
          <RemoveButtonContainer>
            <StyledButton
              type="button"
              onClick={handleRemoveItem}
              color="grey"
              label={t('Cart.Remove').toUpperCase()}
              style={{
                padding: '7px 35px',
                minHeight: 'auto',
                maxWidth: '100%',
                fontSize: '0.75rem',
              }}
            />
          </RemoveButtonContainer>
        )}
      </AmountContainer>
    </ItemContainer>
  );
};

export default CartItem;

const Step2RightTable = styled.table`
  width: 55%;
  font-size: 0.8rem;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

const Step2LeftTable = styled.table`
  width: 45%;
  font-size: 0.8rem;

  @media (max-width: 760px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const Step1Summary = ({ details, showModal, carSelectField, action }) => {
  const { t } = useTranslation();

  const servicePeriodDisplay = useServicePeriodDisplay();

  return (
    <CarParkDetail>
      <TableLeft cellPadding="5">
        <tbody>
          <DetailContainer>
            <Label>{t('Cart.BookingTypeTitle')}</Label>
            <Detail>{t(`Cart.BookingTypes.${action || 'New'}`)}</Detail>
          </DetailContainer>

          <DetailContainer>
            <Label>{t('Cart.ServiceTitle')}</Label>
            <Detail>{details.service}</Detail>
          </DetailContainer>
        </tbody>
      </TableLeft>

      <TableRight cellPadding="5">
        <tbody>
          <DetailContainer>
            <Label>{t('Cart.ServicePeriodTitle')}</Label>
            <Detail>{servicePeriodDisplay(details.servicePeriod)}</Detail>
          </DetailContainer>

          <DetailContainer>
            <Label>{t('Cart.CarTitle')}</Label>
            <Detail style={{ display: 'flex' }}>
              {carSelectField}
              <ChangeCarButton type="button" onClick={showModal}>
                <span style={{ color: '#000' }}>(</span>
                <u>{t('Cart.ChangeCar')}</u>
                <span style={{ color: '#000' }}>)</span>
              </ChangeCarButton>
            </Detail>
          </DetailContainer>
        </tbody>
      </TableRight>
    </CarParkDetail>
  );
};

const Step2Summary = ({
  details,
  action,
  totalCart,
  offlinePaymentIds,
  onlinePaymentIds,
  allAvailablePaymentMethods,
}) => {
  const { t } = useTranslation();

  const servicePeriodDisplay = useServicePeriodDisplay();

  const defaultPaymentMethodId = [
    ...Object.values(offlinePaymentIds),
    ...Object.values(onlinePaymentIds),
  ];

  const isEnablePaymentMethods = (allAvailablePaymentMethods[details.carPark.id] || []).filter(
    ({ id: paymentMethodId, isEnable }) =>
      isEnable && defaultPaymentMethodId.includes(paymentMethodId)
  );

  return (
    <CarParkDetail>
      <Step2RightTable cellPadding="5">
        <tbody>
          <DetailContainer>
            <Label>{t('Cart.BookingTypeTitle')}</Label>
            <Detail>{t(`Cart.BookingTypes.${action || 'New'}`)}</Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.ServiceTitle')}</Label>
            <Detail>{details.service}</Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.ServicePeriodTitle')}</Label>
            <Detail>{servicePeriodDisplay(details.servicePeriod)}</Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.CarTitle')}</Label>
            <Detail>{details.registrationMark}</Detail>
          </DetailContainer>
        </tbody>
      </Step2RightTable>

      <Step2LeftTable cellPadding="5">
        <tbody>
          <DetailContainer>
            <Detail>
              <b>{t('Cart.Price')}</b>
            </Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.ServiceFee')}</Label>
            <Detail>
              HK$
              {details.fee}
            </Detail>
          </DetailContainer>
          <DetailContainer>
            <Label>{t('Cart.HandlingFee')}</Label>
            <Detail>
              HK$
              {details.handlingFee ? details.handlingFee : 0}
            </Detail>
          </DetailContainer>
        </tbody>
      </Step2LeftTable>

      {totalCart > 1 && (
        <DetailContainer style={{ width: '100%', paddingLeft: 5, marginTop: 5 }}>
          <Label width="14%">{t('Cart.support')}</Label>
          <SupportedPaymentMethodsRow>
            {isEnablePaymentMethods.map((e) => (
              <Detail style={{ width: 'auto', marginRight: 10 }}>
                {e.icon ? (
                  <img
                    src={`${process.env.REACT_APP_CP_MEDIA_DOMAIN}${e.icon}`}
                    alt=""
                    style={{ height: 50, width: 100, objectFit: 'contain', marginBottom: 10 }}
                  />
                ) : (
                  e.name
                )}
              </Detail>
            ))}
          </SupportedPaymentMethodsRow>
        </DetailContainer>
      )}
    </CarParkDetail>
  );
};
