import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import useStaticInfo from '../../models/StaticInfo';

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const MainWidth = styled.div`
  display: flex;
  background: #dedede;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 2px #bbb;
  font-size: 16px;
  color: #888;
  line-height: 1.5;
`;

const BookingTNC = () => {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo(
    'Pages-Payment Terms & Conditions'
  );

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <div>
      <MainContainer>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }} />
      </MainContainer>
    </div>
  );
};
export default withRouter(BookingTNC);
