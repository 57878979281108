import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledLink from '../../../components/General/StyledLink';
import { ButtonRowDiv } from './style';

export default function ButtonRow() {
  const { t, i18n } = useTranslation();

  return (
    <ButtonRowDiv>
      <StyledLink
        type="button"
        label={t('sevenPayment.text10')}
        color="yellow"
        style={{ maxWidth: 200, minHeight: 50, marginTop: 20, width: '100%' }}
        to={`/${i18n.language}`}
      />
    </ButtonRowDiv>
  );
}
