import styled from 'styled-components';

const AddressContainer = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  max-width: 100%;
  flex-direction: column;
`;

export default AddressContainer;
