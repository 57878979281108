import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useStaticInfo from '../../models/StaticInfo';

const TitleContainer = styled.div`
  background: #fd980f;
  display: flex;
  justify-content: center;
  padding-left: 30px;

  @media (max-width: 670px) {
    padding: 10px 20px 10px 30px;
  }
`;

const TitleDiv = styled.div`
  width: 1024px;
`;

const Title = styled.span`
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  margin-left: -3px;
  line-height: 80px;
  text-transform: uppercase;

  @media (max-width: 670px) {
    line-height: 40px;
  }
`;

const MainContainer = styled.div`
  background: #dedede;
  justify-content: center;
  display: flex;
`;

const MainWidth = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 20px 30px 20px 30px;
  width: 1024px;
  box-shadow: 0 0 2px #bbb;

  p {
    font-size: 0.875rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    color: #fd980f;
    line-height: inherit;
    vertical-align: baseline;
    background: transparent;
  }
  img {
    max-width: 100%;
  }
`;

const IMonthlyFAQ = () => {
  const { getStaticValue, StaticInfoContext, language } = useStaticInfo(
    'Pages-i-MONTHLY Parking Service Tutorial & FAQ'
  );

  const staticInfo = useContext(StaticInfoContext);

  const { name, content, keywords, description } = useMemo(
    () => getStaticValue(['name', 'content', 'keywords', 'description'], staticInfo),
    [staticInfo, language]
  );

  return (
    <div>
      <TitleContainer>
        <TitleDiv>
          <Title>{name}</Title>
        </TitleDiv>
      </TitleContainer>

      <MainContainer>
        <MainWidth dangerouslySetInnerHTML={{ __html: content }}>
          {/* <HeaderText>
            <span>{t('iMonthlyFAQ.HeaderText')}</span>
          </HeaderText>
          <HeaderText>
            <MarginText>{t('iMonthlyFAQ.HeaderText1')}</MarginText>
            <Href
              to={{
                pathname: `/${i18n.language}/customers/login`,
                state: 'registration',
              }}
            >
              &nbsp;
              {t('iMonthlyFAQ.HeaderText2en')}
            </Href>
            {i18n.language === 'zh' && <br />}
            {t('iMonthlyFAQ.HeaderText2')}
            <Href
              to={{
                pathname: `/${i18n.language}/customers/login`,
                state: 'registration',
              }}
            >
              &nbsp;
              {t('iMonthlyFAQ.HeaderText2zh')}
            </Href>
          </HeaderText>

          <div>
            <PromoImg src={i18n.language === 'en' ? imageEng : imageZh} />
          </div>

          <SubTitle>{t('iMonthlyFAQ.Title1')}</SubTitle>
          <Qusetion top>{t('iMonthlyFAQ.T1Q1')}</Qusetion>
          <Answer>
            {t('iMonthlyFAQ.T1A1')}
            {i18n.language === 'zh' && (
              <div>
                <br />
              </div>
            )}
            {t('iMonthlyFAQ.T1A1a')}
          </Answer>

          <Qusetion>{t('iMonthlyFAQ.T1Q2')}</Qusetion>
          <Answer>
            {t('iMonthlyFAQ.T1Q2A1')}
            <b>]{t('iMonthlyFAQ.T1Q2A11')} </b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q2A2')}
            <b> {t('iMonthlyFAQ.T1Q2A21')} </b>
            {t('iMonthlyFAQ.T1Q2A22')}{' '}
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q2A3')}
            <b> {t('iMonthlyFAQ.T1Q2A31')} </b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q2A4')}
            <b> {t('iMonthlyFAQ.T1Q2A41')} </b> {t('iMonthlyFAQ.T1Q2A42')}
            <b>{t('iMonthlyFAQ.T1Q2A43')}</b>
            {t('iMonthlyFAQ.T1Q2A44')}
          </Answer>
          <Answer>{t('iMonthlyFAQ.T1Q2A5')}</Answer>

          <Qusetion>{t('iMonthlyFAQ.T1Q3')}</Qusetion>
          <Answer>
            {t('iMonthlyFAQ.T1Q3A1')}
            <b> {t('iMonthlyFAQ.T1Q3A11')} </b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q3A2')}
            <b> {t('iMonthlyFAQ.T1Q3A21')} </b> {t('iMonthlyFAQ.T1Q3A22')}
            <b> {t('iMonthlyFAQ.T1Q3A23')} </b>
          </Answer>
          <Answer> {t('iMonthlyFAQ.T1Q3A3')} </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q3A4')}
            <b> {t('iMonthlyFAQ.T1Q3A41')} </b>
            {t('iMonthlyFAQ.T1Q3A42')}
            <b> {t('iMonthlyFAQ.T1Q3A43')} </b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q3A5')}
            <b>{t('iMonthlyFAQ.T1Q3A51')}</b> {t('iMonthlyFAQ.T1Q3A52')}
          </Answer>

          <Qusetion>{t('iMonthlyFAQ.T1Q4')}</Qusetion>
          <Answer>
            {t('iMonthlyFAQ.T1Q4A1')}
            <b>{t('iMonthlyFAQ.T1Q4A11')}</b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q4A2')}
            <b> {t('iMonthlyFAQ.T1Q4A21')} </b> {t('iMonthlyFAQ.T1Q4A22')}
            <b> {t('iMonthlyFAQ.T1Q4A23')} </b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q4A3')}
            <b>{t('iMonthlyFAQ.T1Q4A31')}</b> {t('iMonthlyFAQ.T1Q4A32')}
          </Answer>

          <Qusetion id="Change_Vehicle">{t('iMonthlyFAQ.T1Q5')}</Qusetion>
          <Answer>
            {t('iMonthlyFAQ.T1Q5A1')}
            <b>{t('iMonthlyFAQ.T1Q5A11')}</b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q5A2')}
            <b> {t('iMonthlyFAQ.T1Q5A21')} </b> {t('iMonthlyFAQ.T1Q5A22')}
            <b> {t('iMonthlyFAQ.T1Q5A23')} </b>
          </Answer>
          <Answer> {t('iMonthlyFAQ.T1Q5A3')} </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q5A4')}
            <b>{t('iMonthlyFAQ.T1Q5A41')} </b>
            {t('iMonthlyFAQ.T1Q5A42')}
            <b>{t('iMonthlyFAQ.T1Q5A43')}</b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q5A5')}
            <b>{t('iMonthlyFAQ.T1Q5A51')}</b> {t('iMonthlyFAQ.T1Q5A52')}
            <b>{t('iMonthlyFAQ.T1Q5A53')}</b> {t('iMonthlyFAQ.T1Q5A54')}
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q5A6')}
            <b>{t('iMonthlyFAQ.T1Q5A61')}</b>
          </Answer>
          <Answer>{t('iMonthlyFAQ.T1Q5A7')} </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q5A8')}
            <br />
            <br />
          </Answer>
          <Answer>{t('iMonthlyFAQ.T1Q5strt')} </Answer>

          <Qusetion id="Change_Service"> {t('iMonthlyFAQ.T1Q6')} </Qusetion>
          <Answer>
            {t('iMonthlyFAQ.T1Q6Text')}
            <br />
            <br />
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q6A1')}
            <b>{t('iMonthlyFAQ.T1Q6A11')}</b>
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q6A2')}
            <b>{t('iMonthlyFAQ.T1Q6A21')}</b> {t('iMonthlyFAQ.T1Q6A22')}
            <b>{t('iMonthlyFAQ.T1Q6A23')}</b>
          </Answer>
          <Answer>{t('iMonthlyFAQ.T1Q6A3')}</Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q6A4')} <b>{t('iMonthlyFAQ.T1Q6A41')}</b>
            {t('iMonthlyFAQ.T1Q6A42')}
          </Answer>
          <Answer>
            {t('iMonthlyFAQ.T1Q6A5')}
            <b>{t('iMonthlyFAQ.T1Q6A51')}</b>
            {t('iMonthlyFAQ.T1Q6A52')}
          </Answer>

          <SubTitle>
            {t('iMonthlyFAQ.Title2')}
            <br />
            <br />
            <br />
          </SubTitle>
          <div style={{ marginTop: -50 }} />
          <Qusetion2> {t('iMonthlyFAQ.T2Q1')} </Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A1')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q2')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A2')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q3')} </Qusetion2>
          <Answer>
            {t('iMonthlyFAQ.T2A3')} <Href to="#Change_Vehicle">{t('iMonthlyFAQ.T2A3x')}</Href>
            <br /> {t('iMonthlyFAQ.T2A31')}
            <Href to="#Change_Vehicle">{t('iMonthlyFAQ.T2A32')}</Href>
          </Answer>
          <Qusetion2> {t('iMonthlyFAQ.T2Q4')} </Qusetion2>
          <Answer> {t('iMonthlyFAQ.T2A4')} </Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q5')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A5')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q6')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A6')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q7')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A7')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q8')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A8')} </Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q9')} </Qusetion2>
          <Answer>
            {t('iMonthlyFAQ.T2A9')}
            <Href to="mailto:imember@wilsonparking.com.hk">imember@wilsonparking.com.hk.</Href>
          </Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q10')} </Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A10')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q11')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A11')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q12')}</Qusetion2>
          <Answer>{t('iMonthlyFAQ.T2A12')}</Answer>
          <Qusetion2>{t('iMonthlyFAQ.T2Q13')}</Qusetion2>
          <Answer>
            {t('iMonthlyFAQ.T2A13')}
            <Href to="#Change_Service"> {t('iMonthlyFAQ.T2A131')}</Href>{' '}
          </Answer>
          <SubTitle>{t('iMonthlyFAQ.Title3')}</SubTitle>
          <div style={{ marginTop: 20 }} />
          <Answer>
            {t('iMonthlyFAQ.T3Text')}
            <Href to="mailto:imember@wilsonparking.com.hk"> imember@wilsonparking.com.hk.</Href>
            {t('iMonthlyFAQ.T3Text1')}
          </Answer> */}
        </MainWidth>
      </MainContainer>
    </div>
  );
};

export default withRouter(IMonthlyFAQ);
