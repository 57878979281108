import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ItemContainer = styled.div`
  background: #f8f1e8;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  width: 100%;
  max-width: 300px;
  position: relative;
`;

const Container = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 200px;
  border: 5px solid #fff;
`;

const ImgContainer = styled.div`
  margin-top: -150px;
`;

const Margin = styled.div`
  margin-top: ${(props) => props.top};
`;

const ItemTitle = styled.span`
  font-size: 32px;
  color: #888;
  line-height: 1.2;
`;

const TextContainer = styled.div`
  padding: 10px;
`;

const Text = styled.span`
  font-size: 14px;
  color: #888;
  text-align: center;
`;

const ReadMoreContainer = styled(Link)`
  background: #fd980f;
  padding: 5px;
  width: 200px;
  position: absolute;
  margin-top: 320px;
  transition: all 0.3s ease;
  &:hover {
    background: #6c6c6c;
    text-decoration-line: none;
  }
`;

const MobileReadMoreContainer = styled(Link)`
  background: #fd980f;
  padding: 5px;
  width: 200px;
  position: absolute;
  top: 95%;
  transition: all 0.3s ease;

  &:hover {
    background-color: #6c6c6c;
    text-decoration-line: none;
  }
`;

const ReadMore = styled.span`
  color: #fff;
  font-size: 13px;
`;

const Item = styled.div`
  background: #f8f1e8;
  width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  justify-content: center;
  text-align: center;

  @media (max-width: 400px) {
    width: 220px;
  }
`;

const MobileItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const NewsPromoItem = ({ detail, t, DeskTop, Mobile, i18n, index, handleEventTracking }) => (
  <Container>
    {DeskTop && (
      <ItemContainer>
        <ImgContainer>
          <Img src={detail.image} />
        </ImgContainer>
        <Margin top="20px" />
        <ItemTitle>
          {i18n.language === 'en' ? detail.title : detail.chineseTitle}
          <br />
        </ItemTitle>
        <TextContainer>
          <Text>{i18n.language === 'en' ? detail.abstract : detail.chineseAbstract}</Text>
        </TextContainer>
        {/* <ReadMoreContainer to={`${i18n.language}/News/${index}`}>
          <ReadMore>{t('News.Readmore')}</ReadMore>
        </ReadMoreContainer> */}
        <ReadMoreContainer
          onClick={() => {
            handleEventTracking('news_click', {
              title: detail.title,
              event_label: detail.title,
              event_category: detail.title,
            });
          }}
          to={`${i18n.language}/news/index#${index}`}
        >
          <ReadMore>{t('News.Readmore')}</ReadMore>
        </ReadMoreContainer>
      </ItemContainer>
    )}
    {Mobile && (
      <MobileItemContainer>
        <Item>
          <Margin top="-140px">
            <Img src={detail.image} />
          </Margin>

          <Margin top="20px" />
          <ItemTitle>
            {i18n.language === 'en' ? detail.title : detail.chineseTitle}
            <br />
          </ItemTitle>
          <TextContainer>
            <Margin top="5px" />
            <Text>{i18n.language === 'en' ? detail.abstract : detail.chineseAbstract}</Text>
          </TextContainer>
          {/* <MobileReadMoreContainer to={`${i18n.language}/News/${index}`}>
            <ReadMore>{t('News.Readmore')}</ReadMore>
          </MobileReadMoreContainer> */}
          <MobileReadMoreContainer
            onClick={() =>
              handleEventTracking('news_click', {
                title: detail.title,
                event_label: detail.title,
                event_category: detail.title,
              })}
            to={`${i18n.language}/news/index#${index}`}
          >
            <ReadMore>{t('News.Readmore')}</ReadMore>
          </MobileReadMoreContainer>
        </Item>
      </MobileItemContainer>
    )}
  </Container>
);

export default NewsPromoItem;
