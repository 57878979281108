import React from 'react';
import styled from 'styled-components';

const RightBoxArrowimg = styled.img`
  width: 20px;
  height: 20px;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: all 0.3s ease-in-out;
`;

const Container = styled.div`
  display: none;
  background: #bbb;
  height: 40px;
  width: 40px;
  float: right;
  margin-top: -40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  @media (max-width: 850px) {
    display: flex;
  }
`;

function RightBoxArrow({ src, open }) {
  return (
    <Container>
      <RightBoxArrowimg src={src} open={open} />
    </Container>
  );
}

export default RightBoxArrow;
