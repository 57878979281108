import { useState, useEffect } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { useCarParks } from './CarPark';

const carParkUrl = `${process.env.REACT_APP_API_DOMAIN}/carPark`;

function useOfflinePaymentMethods() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/payment-method`;
  const [offlinePaymentIds, setOfflinePaymentIds] = useState({});
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data) return;
    setAllPaymentMethods(data.data);
    let chequeId;
    let sevenElevenId;

    data.data.forEach(({ id: paymentMethodId, originalId }) => {
      if (originalId === 3) {
        chequeId = paymentMethodId;
      }
      if (originalId === 4) {
        sevenElevenId = paymentMethodId;
      }
    });

    setOfflinePaymentIds({ chequeId, sevenElevenId });
  }, [data]);

  return { offlinePaymentIds, allPaymentMethods };
}

function useOnlinePaymentMethods() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/payment-method?isOnlineMethod=1`;
  const [onlinePaymentIds, setOnlinePaymentIds] = useState({});

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data) return;
    let visaId;
    let ppsId;
    let fpsId;

    data.data.forEach(({ id: paymentMethodId, originalId }) => {
      if (originalId === 6) {
        visaId = paymentMethodId;
      }
      if (originalId === 1) {
        ppsId = paymentMethodId;
      }
      if (originalId === 9) {
        fpsId = paymentMethodId;
      }
    });

    setOnlinePaymentIds({ visaId, ppsId, fpsId });
  }, [data]);

  return { onlinePaymentIds };
}

function usePaymentMethods() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/paymentMethod`;
  const [options, setOptions] = useState([]);
  const [map, setMap] = useState(new Map());

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data || !data.data) return;
    const methodData = data.data;
    setOptions(methodData.map(({ id: value, name: label }) => ({ value, label })));
    setMap(
      methodData.reduce((output, { id, name, icon }) => output.set(id, { name, icon }), new Map())
    );
  }, [data]);

  return { options, map };
}

function useCarType() {
  const {
    i18n: { language },
  } = useTranslation();
  const url = `${process.env.REACT_APP_API_DOMAIN}/carType`;
  const [options, setOptions] = useState([]);
  const [map, setMap] = useState(new Map());

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data || !data.data) return;
    const carData = data.data;
    setOptions(
      carData.map(({ id: value, name: label, chineseName }) => ({
        value,
        label: language === 'en' ? label : chineseName,
      }))
    );
    setMap(carData.reduce((output, { id, name }) => output.set(id, { name }), new Map()));
  }, [data, language]);

  return { options, map };
}

function useCarParkLocations() {
  const {
    i18n: { language },
  } = useTranslation();
  const url = `${process.env.REACT_APP_API_DOMAIN}/location?isCarParkLocation=true`;
  const [regions, setRegions] = useState([]);
  const [options, setOptions] = useState([]);
  const [activeRegion, setActiveRegion] = useState(null);
  const [map, setMap] = useState(new Map());

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data || !data.data) return;
    const locationData = data.data;
    setMap(
      locationData.reduce((output, { id, region, chineseRegion, district, chineseDistrict }) => {
        const regionLabel = language === 'en' ? region : chineseRegion;
        const districtLabel = language === 'en' ? district : chineseDistrict;
        if (!output.has(region)) {
          setRegions([...regions, { label: regionLabel, value: region }]);
          return output.set(region, [{ label: districtLabel, value: id }]);
        }
        return output.set(region, [...output.get(region), { label: districtLabel, value: id }]);
      }, new Map())
    );
  }, [data]);

  useEffect(() => {
    if (!activeRegion) return;
    setOptions(map.get(activeRegion));
  }, [activeRegion]);

  return { regions, options, setActiveRegion };
}

function useCustomerLocations() {
  const {
    i18n: { language },
  } = useTranslation();
  const url = `${process.env.REACT_APP_API_DOMAIN}/location?isCustomerLocation=true`;
  const [regions, setRegions] = useState([]);
  const [options, setOptions] = useState([]);
  const [activeRegion, setActiveRegion] = useState(null);
  const [map, setMap] = useState(new Map());

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data || !data.data) return;
    const locationData = data.data;
    setMap(
      locationData.reduce((output, { id, region, chineseRegion, district, chineseDistrict }) => {
        const regionLabel = language === 'en' ? region : chineseRegion;
        const districtLabel = language === 'en' ? district : chineseDistrict;
        if (!output.has(region)) {
          setRegions([...regions, { label: regionLabel, value: region }]);
          return output.set(region, [{ label: districtLabel, value: id }]);
        }
        return output.set(region, [...output.get(region), { label: districtLabel, value: id }]);
      }, new Map())
    );
  }, [data]);

  useEffect(() => {
    if (!activeRegion) return;
    setOptions(map.get(activeRegion));
  }, [activeRegion]);

  return { regions, options, setActiveRegion };
}

function useParkingService() {
  const url = `${process.env.REACT_APP_API_DOMAIN}/parkingService?isSearchParam=true`;
  const [options, setOptions] = useState([]);
  const [map, setMap] = useState(new Map());

  const [{ data }] = useAxios({ url, method: 'GET' });

  useEffect(() => {
    if (!data || !data.data) return;
    const carData = data.data;
    setOptions(carData.map(({ id: value, type: label }) => ({ value, label })));
    setMap(carData.reduce((output, { id, type }) => output.set(id, { type }), new Map()));
  }, [data]);

  return { options, map };
}

function useSelectOptions(condition) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { carParks } = useCarParks();

  const [prefixOptions, setPrefix] = useState([]);

  const [languageOptions, setLanguage] = useState([]);

  const [selectedCarPark, setSelectedCarPark] = useState(null);
  const [selectedCarParkObject, setSelectedCarParkObject] = useState({});
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    setPrefix([
      { value: 'Mr', label: t('Prefix.Mr') },
      { value: 'Mrs', label: t('Prefix.Mrs') },
      { value: 'Ms', label: t('Prefix.Ms') },
    ]);
    setLanguage([
      { value: 'zh', label: t('Language.zh') },
      { value: 'en', label: t('Language.en') },
    ]);
  }, []);

  useEffect(() => {
    async function getServices() {
      const {
        data: { data },
      } = await axios.get(`${carParkUrl}/${selectedCarPark}/ParkingServices`);
      const servicesData = data.map(({ id: value, type: label }) => ({ value, label }));

      setServiceOptions(servicesData);
    }
    if (selectedCarPark !== null) getServices();
  }, [selectedCarPark]);

  useEffect(() => {
    // const selected = carParksInDistrict.filter(({ value }) => value === selectedCarPark);

    const carParkObj = {
      // name: selected[0] && selected[0].name,
      // address: selected[0] && selected[0].address,
      service: selectedService,
      fee: 'tbc',
      servicePeriod: 'tbc',
      type: 'change',
    };

    setSelectedCarParkObject(carParkObj);
  }, [selectedService]);

  return {
    prefixOptions,
    selectedCarPark,
    selectedService,
    setSelectedCarPark,
    selectedCarParkObject,
    serviceOptions,
    setSelectedService,
    languageOptions,
  };
}

export {
  useSelectOptions,
  useOfflinePaymentMethods,
  useOnlinePaymentMethods,
  usePaymentMethods,
  useCarType,
  useCarParkLocations,
  useCustomerLocations,
  useParkingService,
};
