/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { Marker, InfoWindow, useGoogleMap } from '@react-google-maps/api';
import * as icon from '../../../media/icons';
import DesktopInfoDisplay from './DesktopInfoDisplay';
import MobileInfoDisplay from './MobileInfoDisplay';

export default function MarkerView({ marker, selectedMarker, onMarkerClick }) {
  const { id, isIMonthly, coordinates } = marker;
  const { latitude, longitude } = coordinates || {};

  const [onHover, setOnHover] = useState(false);

  const mapRef = useGoogleMap();

  return (
    <Marker
      opacity={onHover ? 0.8 : 1}
      onMouseOut={() => setOnHover(false)}
      onMouseOver={() => setOnHover(true)}
      animation={(selectedMarker?.id === marker?.id && 1) || (selectedMarker === null && 2)}
      key={id}
      onClick={() => {
        onMarkerClick(marker);
        setTimeout(() => {
          if (mapRef) {
            mapRef.panTo({
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            });
          }
        }, 0);
      }}
      position={{
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      }}
      icon={{
        // url: isIMonthly ? icon.markerBlue : icon.markerRed,
        url: icon.markerRed,
        scaledSize: {
          width: 28,
          height: 34,
          widthUnit: 'px',
          heightUnit: 'px',
        },
      }}
    >
      {selectedMarker === marker && (
        <DesktopInfoDisplay marker={marker} onMarkerClick={onMarkerClick} />
      )}
    </Marker>
  );
}
