import styled from 'styled-components';

// TODO: responsive?

const MobileNumberMargin = styled.div`
  display: flex;
  margin-top: 20px;
  -webkit-flex: 1;
flex: 1;
  flex-direction: row;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export default MobileNumberMargin;
