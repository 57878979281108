import CryptoJS from 'crypto-js';

export default function useEncrypt() {
  const parseHash = (message = '', data = {}) => {
    const variables = message ? message.match(/(\${.*?})/g) : [];
    let parsedMessage = message;
    if (Array.isArray(variables)) {
      variables.forEach((variable) => {
        const key = variable.replace(/(\${)|(}$)/g, '');
        if (data[key]) parsedMessage = parsedMessage.replace(variable, data[key]);
      });
    }
    return parsedMessage;
  };

  const parseUrl = (url = '', data = {}, secret) => {
    const variables = url ? url.match(/({{.*?}})/g) : [];
    let parsedUrl = url;
    if (Array.isArray(variables)) {
      variables.forEach((variable) => {
        const key = variable.replace(/(^{{)|(}}$)/g, '');
        if (data[key]) parsedUrl = parsedUrl.replace(variable, data[key]);
        if (data[key] === '') parsedUrl = parsedUrl.replace(variable, '');

        if (/(^hash:)(.+?)(:endhash)/.test(key)) {
          const plainText = key.replace(/(^hash:)|(:endhash$)/g, '');
          const parsedHash = parseHash(plainText, data);

          const hash = CryptoJS.HmacSHA256(parsedHash, secret);
          const hexEncodedHash = CryptoJS.enc.Hex.stringify(hash);
          parsedUrl = parsedUrl.replace(variable, hexEncodedHash);
        }
      });
    }

    return parsedUrl;
  };

  return {
    parseUrl,
  };
}
