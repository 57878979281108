import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 35px 20px;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  background: #fff;
  font-size: 13px;
`;

const Page = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

function Bottombar({ page, setPage, totalPage }) {
  const { t } = useTranslation();

  return (
    <Container>
      <Button
        onClick={() => {
          setPage(page - 1);
        }}
        disabled={page === 1}
      >
        {t('MyAccountTap.Previous')}
      </Button>
      <Page>
        {t('MyAccountTap.Page')}
        &nbsp;&nbsp;
        {page}
        {t('MyAccountTap.Pagezh')}
        &nbsp;&nbsp;
        {t('MyAccountTap.Of')}
        &nbsp;&nbsp;
        {totalPage}
        {t('MyAccountTap.Pagezh')}
      </Page>
      <Button
        onClick={() => {
          setPage(page + 1);
        }}
        disabled={page === totalPage || totalPage === 0}
      >
        {t('MyAccountTap.Next')}
      </Button>
    </Container>
  );
}

export default Bottombar;
